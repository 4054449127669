import { Stack, styled } from '@mui/material';

export const Container = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.gray20,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
        padding: '20px'
    }
}));

export const Wrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '42.5rem',
    padding: '3.125rem 2.75rem 2.125rem 2.75rem',
    background: 'white',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px'
}));

export const Form = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',
    width: '100%',
    maxWidth: '80vw'
}));

export const Row = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '0.75rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    }
}));

export const Field = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    maxWidth: 'calc(50% - 0.75rem)',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '100%'
    }
}));

export const Label = styled('label')(({ theme }) => ({
    ...theme.typography.pn3
}));
