/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography, styled } from '@mui/material';
import { useGetPerson } from 'authentication';
import { Activity } from 'pages/NewActivities/Activity';
import { ActivityGroup } from 'pages/NewActivities/ActivityGroup';
import { Empty } from 'pages/NewActivities/Empty';
import { Loader } from 'pages/NewActivities/Loader';
import { useActivities } from 'pages/NewActivities/Provider';
import { ACTIVITY_STATUS, DATE_FILTER_VALUES, STATUS_FILTER } from 'pages/NewActivities/constants';
import { isAdhocTask, mapActivities } from 'pages/NewActivities/utils';
import { allowedTaskTypes } from 'pages/Study';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ActivitiesPage() {
    const { t } = useTranslation();
    document.title = t('activities:index:title');

    const { isLoading: isPersonLoading } = useGetPerson();
    const [statusFilter, setStatusFilter] = useState(null);
    const [dayFilter, setDayFilter] = useState(30);

    const {
        activities: { isLoading: areActivitiesLoading, data }
    } = useActivities();

    function changeStatusFilter(statusFilter) {
        setDayFilter(null);
        setStatusFilter(statusFilter);
    }

    function changeDayFilter(days) {
        setStatusFilter(null);
        setDayFilter(days);
    }

    const now = new Date();
    const filterDay = new Date();
    filterDay.setDate(now.getDate() + dayFilter);

    const activities = useMemo(() => {
        return mapActivities(data)
            .filter((activity) => allowedTaskTypes.includes(activity.task_type))
            .filter((activity) =>
                statusFilter === STATUS_FILTER.ACTIVE
                    ? activity.status === ACTIVITY_STATUS.ACTIVE || activity.status === ACTIVITY_STATUS.IN_PROGRESS
                    : statusFilter === STATUS_FILTER.ALL
                    ? true
                    : dayFilter && new Date(activity.start_date) < filterDay
            )
            .sort((a, b) => (new Date(b.start_date) > new Date(a.start_date) ? -1 : 1))
            .sort((a, b) => (isAdhocTask(b) && !isAdhocTask(a) && a.status === ACTIVITY_STATUS.ACTIVE ? -1 : 1));
    }, [data, statusFilter, dayFilter]);

    const isLoading = isPersonLoading || areActivitiesLoading;

    if (isLoading) {
        return <Loader />;
    }

    if (data.length === 0) {
        return <Empty />;
    }

    return (
        <Container>
            <Wrapper>
                <Header>
                    <Title>{t('activities:index:title')}</Title>
                    <Filters>
                        <StatusFilters>
                            <StatusFilter
                                onClick={() => changeStatusFilter(STATUS_FILTER.ACTIVE)}
                                isActive={statusFilter === STATUS_FILTER.ACTIVE}
                            >
                                {t('activities:index:filters:active')}
                            </StatusFilter>

                            <Typography color='#595959'>|</Typography>

                            <StatusFilter
                                onClick={() => changeStatusFilter(STATUS_FILTER.ALL)}
                                isActive={statusFilter === STATUS_FILTER.ALL}
                            >
                                {t('activities:index:filters:all')}
                            </StatusFilter>
                        </StatusFilters>

                        <Box sx={{ justifyContent: 'center', display: 'flex', gap: '10px' }}>
                            {DATE_FILTER_VALUES.map((days) => (
                                <DayFilterButton onClick={() => changeDayFilter(days)} isActive={dayFilter === days}>
                                    {days} {t('activities:index:days')}
                                </DayFilterButton>
                            ))}
                        </Box>
                    </Filters>
                </Header>

                <List>
                    {activities?.length === 0 && (
                        <Box display='flex' alignItems='center' justifyContent='center' flex={1}>
                            <Empty />
                        </Box>
                    )}

                    {activities.map((activity) =>
                        activity.type === 'group' ? <ActivityGroup {...activity} /> : <Activity {...activity} />
                    )}
                </List>
            </Wrapper>
        </Container>
    );
}

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100dvw',
    height: '100dvh',
    flex: 1,
    overflowY: 'auto'
});

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '820px',
    width: '100dvw',
    padding: '50px 0',
    flex: 1,
    [theme.breakpoints.down('md')]: {
        width: '90%',
        padding: '20px 0 30px 0'
    }
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '20px',
    width: '100%',
    marginBottom: '50px',
    [theme.breakpoints.down('md')]: {
        marginBottom: '20px'
    }
}));

const Filters = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        // flexDirection: 'column',
        width: '100%'
    }
}));

const StatusFilters = styled(Box)(({ theme, isActive }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '8px',
    marginRight: '8px'
}));

const StatusFilter = styled(Button)(({ theme, isActive }) => ({
    ...theme.typography.pb5,
    padding: 0,
    minWidth: 0,
    color: isActive ? theme.palette.primary.main : '#595959',
    cursor: 'pointer',
    '&: hover': {
        backgroundColor: 'transparent'
    }
}));

const DayFilterButton = styled(Button)(({ theme, isActive }) => ({
    ...theme.typography.pb5,
    padding: '8px 15px',
    color: isActive ? 'white' : theme.palette.primary.gray75,
    textTransform: 'lowercase',
    backgroundColor: isActive ? theme.palette.primary.main : theme.palette.primary.gray10,
    cursor: 'pointer',
    '&: hover': {
        color: isActive ? 'white' : theme.palette.primary.gray75,
        backgroundColor: isActive ? theme.palette.primary.main : theme.palette.primary.gray10
    }
}));

const Title = styled(Box)(({ theme }) => ({
    ...theme.typography.h2,
    color: theme.palette.primary.gray100
}));

const List = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    width: '100%',
    flex: 1
}));
