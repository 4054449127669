import { styled } from '@mui/material';
import { ReactComponent as Sign } from 'images/profile/sign.svg';

export const EmptyConsentsWrapper = styled('div')({
  maxWidth: '235',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
});

export const StyledIconContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '60px',
  height: '60px',
  color: theme.palette.primary.darkBlue50,
  marginBottom: '20px',
}));

export const StyledIconBackPlate = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '7px',
  opacity: '0.1',
  backgroundColor: theme.palette.primary.lightBlue,
}));

export const StyledSignIcon = styled(Sign)(({ theme }) => ({
  color: theme.palette.primary.blue,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));
