export const surveyDataES = {
    0: {
        id: 0,
        type: 'skip'
    },
    1: {
        id: 1,
        title: 'Por favor, responda a las dos preguntas siguientes sobre la NIgA.',
        caregiverTitle: 'Por favor, responda a las dos preguntas siguientes sobre la NIgA.',
        type: 'igAN',
        questions: [
            {
                id: 0,
                label: 'Edad en el momento del diagnóstico de IgAN',
                answer: '',
                values: [
                    {
                        label: 'Introducir Edad',
                        caregiverLabel: 'Introducir Edad',
                        value: ''
                    }
                ]
            },
            {
                id: 1,
                label: '¿Ha participado en un ensayo clínico de IgAN?',
                answer: '',
                values: [
                    {
                        label: 'Si',
                        caregiverLabel: 'Si',
                        value: 1
                    },
                    {
                        label: 'No',
                        caregiverLabel: 'No',
                        value: 0
                    }
                ]
            }
        ]
    }
};
