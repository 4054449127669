import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FilledButton } from 'components/Buttons';

export const Container = styled(Box)(({ theme }) => ({
    maxWidth: '1080px',
    margin: '0 auto'
}));

export const Card = styled(Box)(({ theme }) => ({
    background: theme.palette.primary.white,
    height: '100%'
}));

export const CardContent = styled(Box)({
    padding: '60px'
});

export const ButtonLink = styled(FilledButton)(({ disabled, theme }) => ({
    background: disabled ? theme.palette.primary.gray5 : theme.palette.primary.main,
    color: theme.palette.primary.white,
    textDecoration: 'none',
    padding: '15px 25px 15px 30px',
    gap: '7px',
    // width: '156px',
    height: 'auto',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px'
}));
