import { Box, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { updateLastConnectedProvider } from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { ReactComponent as AddedPreviouslyCheck } from 'images/data-sync/added-previously.svg';
import { ReactComponent as AddedSuccessfullyCheck } from 'images/data-sync/added-successfully.svg';
import LinkImage from 'images/data-sync/link-simple.png';
import { ReactComponent as ManualProviderLogo } from 'images/data-sync/manual-provider.svg';
import { ReactComponent as ManualRequest } from 'images/data-sync/manual-request.svg';
import { ReactComponent as GreenCheck } from 'images/shared/green-check.svg';
import { useEffect, useState } from 'react';
import { parseHealthProviderAddress } from 'services/oneUpService';
import { isMobile } from 'utils/isMobile';
import { useConnectHandler } from '../hooks';
import { ProviderAddress, ProviderCardContainer, IconWrapper, ProviderName, TitleContainer } from './styles';

export const ProviderCardV2 = ({
    provider,
    participantTaskId,
    navigate,
    onConnectedProviderSelect,
    onManualProviderSelect,
    connected,
    setLoadingProviders,
    setIsRedirecting,
    setConnectingProvider,
    addManualProvider
}) => {
    const dispatch = useDispatch();
    const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
    const { inProgressProviders } = oneUpHealthData;
    const inProgress = inProgressProviders.map((p) => p?.id).includes(provider.id);
    const isManualProvider = provider.logo === 'manualProvider';
    const { name, locations } = provider;
    const { person_id } = useGetCurrentUserQuery(undefined, {
        selectFromResult: ({ data }) => ({
            person_id: data?.data?.included?.[0]?.[0]?.data?.id
        })
    });
    const connectHandler = useConnectHandler(
        provider,
        setLoadingProviders,
        setIsRedirecting,
        setConnectingProvider,
        person_id,
        participantTaskId
    );

    const logo = isManualProvider ? (
        <ManualProviderLogo width={50} height={50} />
    ) : (
        <img src={provider.logo} alt='health center' width={50} height={50} />
    );
    const theme = useTheme();

    let icon;
    let pointerCSS = { cursor: 'pointer' };
    let paddingTop = '0';
    let paddingRight = '0';
    let clickHandler = navigate ?? connectHandler;
    // let clickHandler = onProviderSelect;

    const hasLastConnectedTransitioned = oneUpHealthData?.lastConnectedProvider?.isCheckStateTransitioned;
    const [addedIcon, setAddedIcon] = useState(
        hasLastConnectedTransitioned ? (
            <GreenCheck width={20} height={20} />
        ) : (
            <AddedSuccessfullyCheck width={141} height={24} />
        )
    );

    useEffect(() => {
        const intervalID = setTimeout(() => {
            if (!hasLastConnectedTransitioned) {
                setAddedIcon(<GreenCheck width={20} height={20} />);
                dispatch(
                    updateLastConnectedProvider({
                        ...provider,
                        connectedAt: oneUpHealthData?.lastConnectedProvider?.connectedAt,
                        isCheckStateTransitioned: true
                    })
                );
            }
        }, 3000);

        return () => clearTimeout(intervalID);
    }, [dispatch, hasLastConnectedTransitioned, oneUpHealthData?.lastConnectedProvider?.connectedAt, provider]);

    console.log('🚀 ~ file: index.js:88 ~ connected && !inProgress:', connected, !inProgress);
    if (connected && !inProgress) {
        icon = isManualProvider ? (
            <ManualRequest width={141} height={24} />
        ) : (
            <AddedPreviouslyCheck width={141} height={24} />
        );
        console.log('1');
        clickHandler = onConnectedProviderSelect;
        pointerCSS = {};
    } else {
        icon = <img src={LinkImage} alt='health center' width={20} height={10} />;
        console.log('2');
        clickHandler = isManualProvider ? onManualProviderSelect : connectHandler;
    }

    if (connected && inProgress) {
        icon = addedIcon;
    }

    return (
        <ProviderCardContainer
            sx={{ bgcolor: theme.palette.primary.white, ...pointerCSS }}
            onClick={isManualProvider ? onManualProviderSelect : clickHandler}
        >
            <Box sx={{ display: 'flex', maxWidth: isMobile() ? '315px' : '600px' }}>
                <IconWrapper>{logo}</IconWrapper>
                <TitleContainer>
                    <ProviderName>{name}</ProviderName>
                    <ProviderAddress>
                        {locations?.length === 1 ? parseHealthProviderAddress(locations[0]?.address) : ''}
                    </ProviderAddress>
                </TitleContainer>
            </Box>
            <Box
                sx={{
                    paddingTop,
                    paddingRight,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {icon}
            </Box>
        </ProviderCardContainer>
    );
};
