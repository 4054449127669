import { createContext, useContext, useMemo, useState } from 'react';

const ActiveActivityContext = createContext(null);

export function ActiveActivityProvider({ children }) {
    const [activeActivity, setActiveActivity] = useState(null);

    const contextValue = useMemo(
        () => ({
            activeActivity,
            setActiveActivity
        }),
        [activeActivity]
    );

    return <ActiveActivityContext.Provider value={contextValue}>{children}</ActiveActivityContext.Provider>;
}

export function useActiveActivity() {
    const data = useContext(ActiveActivityContext);

    if (data === null) {
        throw new Error('useActiveActivity should be inside ActiveActivityProvider');
    }

    return data;
}
