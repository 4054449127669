import { Box, Modal, Typography, styled } from '@mui/material';
import { FilledButton } from 'components/Buttons';
import { GhostButton } from 'configUI/components/Buttons';
import { useTranslation } from 'react-i18next';

export function DeleteMedicationModal({ medication, open, onClose, onRemove }) {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onClose}>
            <Container>
                <Wrapper>
                    <Typography variant='h4' color='primary.gray75'>
                        {t('activities:medications:deleted-medication:title')}
                    </Typography>

                    <Typography variant='pl3' color='primary.gray75'>
                        <strong>
                            <i>{medication?.medication}</i>
                        </strong>{' '}
                        {t('activities:medications:deleted-medication:description')}
                    </Typography>

                    <FilledButton onClick={onRemove}>
                        {t('activities:medications:deleted-medication:buttons:remove')}
                    </FilledButton>
                    <GhostButton onClick={onClose}>
                        {t('activities:medications:deleted-medication:buttons:cancel')}
                    </GhostButton>
                </Wrapper>
            </Container>
        </Modal>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
}));

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '405px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    padding: '60px 40px 20px 40px',
    gap: '15px',
    textAlign: 'center'
}));
