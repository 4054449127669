import { Box, styled } from '@mui/system';
import { ProfileCard } from 'components/Profile/ProfileCard';
import { ReactComponent as Sign } from 'images/profile/sign.svg';
import { ReactComponent as MyProfileIcon } from 'images/profile/user-cog.svg';

const CARDS_PROPERTIES = [
    {
        path: '/profile/consents',
        title: 'My consents',
        description: 'Sign and manage your consents and study participation',
        icon: Sign
    },
    {
        path: '/profile/view',
        title: 'My profile',
        description: 'Review and update your personal information',
        icon: MyProfileIcon
    }
];

export const ProfileCards = () => {
    return (
        <Container>
            {CARDS_PROPERTIES.map((cardProperties) => (
                <ProfileCard {...cardProperties} />
            ))}
        </Container>
    );
};

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px'
});
