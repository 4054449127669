import { AuthenticationProvider } from 'authentication';
import { ScrollToTop } from 'components/ScrollToTop';
import { Loader } from 'components/loading';
import { LanguageProvider } from 'internationalization';
import { APP_ROUTES } from 'navigation';
import { ContactPage, FAQPage, HomePage, PrivacyPolicyPage, TermsOfUsePage, WelcomeLayout } from 'pages';
import { IgANQuestionnairePage } from 'pages/AdHoc/IgAN/IgANQuestionnairePage';
import { LanguageQuestionnairePage } from 'pages/AdHoc/Language/LanguageQuestionnairePage';
import { PhoneQuestionnairePage } from 'pages/AdHoc/Phone/PhoneQuestionnairePage';
import { HealthQuestionnairePage } from 'pages/EQ5D5L/HealthQuestionnairePage';
import { ErrorPage } from 'pages/Error';
import Main from 'pages/Main';
import { MedicationsPage } from 'pages/Medications/MedicationsPage';
import { ActivitiesPage } from 'pages/NewActivities/Page';
import Profile from 'pages/Profile';
import ConsentsList from 'pages/Profile/Consents/List';
import { PaymentsPage } from 'pages/Profile/Payments/Page';
import { RegistryPage } from 'pages/Profile/Registry';
import UserProfile from 'pages/Profile/UserProfile';
import { ShowPhoneNumberUpdateProvider } from 'pages/Profile/UserProfile/Provider';
import WithdrawPage from 'pages/Profile/Withdraw';
import { SignInPage } from 'pages/SignIn';
import { SignOutPage } from 'pages/SignOut';
import { Study } from 'pages/Study';
import FinalPage from 'pages/SyncData/FinalPage';
import { SpecificFinalPage } from 'pages/SyncData/FinalPage/specific-final';
import ProviderPage from 'pages/SyncData/ProviderPage';
import SearchProvidersInstructionPage from 'pages/SyncData/SearchProvidersInstructionPage/SearchProvidersInstructionPage';
import SearchProvidersV2 from 'pages/SyncData/SearchProvidersV2';
import { TaskThankYouPage } from 'pages/TaskThankYouPage/TaskThankYouPage';
import { ThankYouPage } from 'pages/TaskThankYouPage/ThankYouPage';
import TokenizationWithdrawSuccess from 'pages/TokenizationWithdrawSuccess';
import ResourceLibraryPage from 'pages/Welcome/ResourceLibraryPage';
import WithdrawSuccess from 'pages/WithdrawSuccess';
import { OnboardingLayout } from 'pages/onboarding';
import ConsentsPage from 'pages/onboarding/Consents';
import { ConsentDeclinedPage } from 'pages/onboarding/Consents/ConsentDeclinedPage';
import DisqualifiedPage from 'pages/onboarding/Disqualified';
import { EmailVerifiedPage } from 'pages/onboarding/EmailVerified/EmailVerifiedPage';
import InformationPage from 'pages/onboarding/Information';
import { OnboardingPage } from 'pages/onboarding/OnboardingPage';
import { PhonePage } from 'pages/onboarding/Phone';
import { PrescreeningPage } from 'pages/onboarding/Prescreening/PrescreeningPage';
import { VerifyEmailPage } from 'pages/onboarding/VerifyEmail/VerifyEmailPage';
import WithdrawnPage from 'pages/onboarding/Withdrawn';
import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

export function Router() {
    return (
        <BrowserRouter>
            <AuthenticationProvider>
                <ShowPhoneNumberUpdateProvider>
                    <LanguageProvider>
                        <Suspense fallback={Loader}>
                            <ScrollToTop />

                            <Routes>
                                <Route element={<WelcomeLayout />}>
                                    <Route path={APP_ROUTES.WELCOME} element={<HomePage />} />
                                    <Route path={APP_ROUTES.CONTACT} element={<ContactPage />} />
                                    <Route path={APP_ROUTES.FAQ} element={<FAQPage />} />
                                    <Route path={APP_ROUTES.TERMS_OF_USE} element={<TermsOfUsePage />} />
                                    <Route path={APP_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
                                    <Route path={APP_ROUTES.RESOURCES} element={<ResourceLibraryPage />} />
                                </Route>

                                <Route path={APP_ROUTES.EMAIL_VERIFIED} element={<EmailVerifiedPage />} />

                                <Route element={<OnboardingLayout />}>
                                    <Route path={APP_ROUTES.VERIFY_EMAIL} element={<VerifyEmailPage />} />
                                    <Route path={APP_ROUTES.PRESCREENING} element={<PrescreeningPage />} />
                                    <Route path={APP_ROUTES.DISQUALIFIED} element={<DisqualifiedPage />} />
                                    <Route path={APP_ROUTES.WITHDRAWN} element={<WithdrawnPage />} />
                                    <Route path={APP_ROUTES.CONSENTS} element={<ConsentsPage />} />
                                    <Route path={APP_ROUTES.INFORMATION} element={<InformationPage />} />
                                    <Route path={APP_ROUTES.ONBOARDING} element={<OnboardingPage />} />
                                    <Route path={APP_ROUTES.REJECTED} element={<ConsentDeclinedPage />} />
                                    <Route path={APP_ROUTES.PHONE} element={<PhonePage />} />
                                </Route>
                                <Route path={APP_ROUTES.WITHDRAWN_SUCCESS} element={<WithdrawSuccess />} />
                                <Route
                                    path={APP_ROUTES.TOKENIZATION_SUCCESS}
                                    element={<TokenizationWithdrawSuccess />}
                                />

                                <Route element={<Main />}>
                                    <Route element={<Profile />}>
                                        <Route path={APP_ROUTES.CONSENTS}>
                                            <Route element={<ConsentsList />} />
                                        </Route>

                                        <Route path={APP_ROUTES.PROFILE_PAYMENTS} element={<PaymentsPage />} />
                                        <Route path={APP_ROUTES.PROFILE_CONSENTS} element={<ConsentsList />} />
                                        <Route path={APP_ROUTES.PROFILE_VIEW} element={<UserProfile />} />
                                        <Route path={APP_ROUTES.PROFILE_REGISTRY} element={<RegistryPage />} />
                                        <Route path={APP_ROUTES.PROFILE_WITHDRAW} element={<WithdrawPage />} />
                                    </Route>

                                    <Route path={APP_ROUTES.PROGRESS} element={<Study />} />
                                    <Route path={APP_ROUTES.ACTIVITIES} element={<ActivitiesPage />} />
                                    <Route path={APP_ROUTES.MEDICATIONS} element={<MedicationsPage />} />
                                    <Route
                                        path={APP_ROUTES.HEALTH_QUESTIONNAIRE}
                                        element={<HealthQuestionnairePage />}
                                    />
                                    <Route path={APP_ROUTES.IGAN_QUESTIONNAIRE} element={<IgANQuestionnairePage />} />
                                    <Route path={APP_ROUTES.PHONE_QUESTIONNAIRE} element={<PhoneQuestionnairePage />} />
                                    <Route
                                        path={APP_ROUTES.LANGUAGE_QUESTIONNAIRE}
                                        element={<LanguageQuestionnairePage />}
                                    />
                                    <Route
                                        path={APP_ROUTES.SYNC_DATA_INFO}
                                        element={<SearchProvidersInstructionPage />}
                                    />
                                    <Route path={APP_ROUTES.SYNC_DATA} element={<SearchProvidersV2 />} />
                                    <Route path={APP_ROUTES.TASK_THANK_YOU} element={<TaskThankYouPage />} />
                                    <Route path={APP_ROUTES.THANK_YOU} element={<ThankYouPage />} />
                                    <Route path={APP_ROUTES.PROVIDER} element={<ProviderPage />} />
                                    <Route path={APP_ROUTES.SYNC_DATA_FINAL} element={<FinalPage />} />
                                </Route>

                                <Route path={APP_ROUTES.SIGN_IN} element={<SignInPage />} />
                                <Route path={APP_ROUTES.ERROR} element={<ErrorPage />} />
                                <Route path={APP_ROUTES.CALLBACK} element={<SpecificFinalPage />} />
                                <Route path={APP_ROUTES.SIGN_OUT} element={<SignOutPage />} />
                                <Route path='*' element={<Navigate to={APP_ROUTES.WELCOME} />} />
                            </Routes>
                        </Suspense>
                    </LanguageProvider>
                </ShowPhoneNumberUpdateProvider>
            </AuthenticationProvider>
        </BrowserRouter>
    );
}
