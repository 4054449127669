function isEmpty(value) {
  return value === undefined || value === null || value === '';
}

export const queryString = (params) => {
  console.log('queryString: ', params);
  return Object.keys(params)
    .filter((key) => params[key] && !isEmpty(params[key]))
    .map((key) => key + '=' + params[key])
    .join('&');
};
