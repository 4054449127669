import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { TextField } from 'components/Input/TextField';

export const MainContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '95vh'
}));

export const InstructionsContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.gray2,
    borderTop: '1px solid ' + theme.palette.primary.gray10,
    borderBottom: '1px solid ' + theme.palette.primary.gray10,
    flexGrow: 1
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
    height: '8vh',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '15px'
}));

export const MobileButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingTop: '15px',
    justifyContent: 'center'
}));

export const SpinnerContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    height: '100dvh',
    [theme.breakpoints.down('md')]: {
        height: 'calc(100dvh - 120px)'
    }
}));

export const LeftContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '50px',
    flexDirection: 'column'
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
    maxWidth: '820px',
    width: '100%',
    margin: '0 auto'
}));

export const BottomContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '10vh',
    background: 'white',
    padding: '15px 35px 12px'
}));

export const RightContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '40%',
    padding: '50px',
    flexDirection: 'column',
    background: '#FBFBFB'
}));

export const ConnectContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.gray2,
    color: theme.palette.secondary.gray4,
    [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.primary.gray20
    }
}));

export const ConnectContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '40px',
    maxWidth: '295px',
    height: 'calc(100vh - 120px)',
    margin: 'auto',
    paddingTop: '59px',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '325px',
        height: '100vh',
        paddingTop: '0px'
    }
}));

export const getStyles = (theme) => ({
    h4: {
        lineHeight: '29px'
    },
    text: {
        lineHeight: '20px'
    }
});

export const SearchBox = styled(TextField)(({ theme }) => ({
    borderRadius: '0px',
    height: '7vh',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '10px',
    marginRight: 'auto',
    marginLeft: 'auto',
    minWidth: '320px',
    borderColor: theme.palette.primary.gray10,
    [theme.breakpoints.up('sm')]: {
        borderColor: theme.palette.primary.gray10,
        borderRadius: '8px',
        height: '50px',
        paddingLeft: '8px',
        paddingRight: '17px',
        marginTop: '0px'
    }
}));

export const SearchProvidersTitle = styled(Box)(({ theme }) => ({
    color: theme.palette.secondary.gray4,
    fontWeight: 600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    paddingBottom: '15px'
}));

export const SearchProvidersTitleMobile = styled(Box)(({ theme }) => ({
    color: theme.palette.secondary.gray4,
    fontWeight: 600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    paddingLeft: '15px'
}));

export const CentralContainer = styled(Box)(({ theme }) => ({
    paddingTop: '35px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    width: '60%'
}));
