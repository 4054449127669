import { Box, styled, Typography } from '@mui/material';
import { useLogout } from 'authentication';
import { useTranslation } from 'react-i18next';
import { Logout } from '../assets';

export function ProfileFooter() {
    const { logout } = useLogout();
    const { t } = useTranslation();

    return (
        <LogoutButton onClick={logout}>
            <Typography variant='pb3'>{t('profile:index:logout')}</Typography>
            <Logout />
        </LogoutButton>
    );
}

const LogoutButton = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    width: '100%',
    padding: '1rem 1.25rem',
    maxWidth: '20rem',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: theme.palette.primary.gray5,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
    cursor: 'pointer'
}));
