/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react';
import { APP_ROUTES } from 'navigation/constants';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const useAuth0Redirections = () => {
    const { loginWithRedirect } = useAuth0();
    const { pathname } = useLocation();
    const { i18n } = useTranslation();

    const redirectToOnboardingRoutes = [APP_ROUTES.WELCOME, APP_ROUTES.PRIVACY_POLICY];
    const returnTo = redirectToOnboardingRoutes.some((route) => pathname.includes(route))
        ? APP_ROUTES.ONBOARDING
        : pathname;

    const redirectToLogin = useCallback(() => {
        console.log(returnTo);

        loginWithRedirect({
            appState: { returnTo },
            screen_hint: 'signin',
            ui_locales: i18n.language
        });
    }, []);

    const redirectToSignUp = useCallback(() => {
        loginWithRedirect({
            appState: { returnTo },
            screen_hint: 'signup',
            ui_locales: i18n.language
        });
    }, []);

    return {
        redirectToLogin,
        redirectToSignUp
    };
};

export const useRedirections = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const { redirectToLogin: redirectToAuth0Login, redirectToSignUp: redirectToAuth0SignUp } = useAuth0Redirections();

    const reload = useCallback(() => navigate(0), []);

    const redirectToLogin = useCallback(
        () => (isAuthenticated ? navigate(APP_ROUTES.ONBOARDING) : redirectToAuth0Login()),
        [isAuthenticated]
    );

    const redirectToSignUp = useCallback(
        () => (isAuthenticated ? navigate(APP_ROUTES.ONBOARDING) : redirectToAuth0SignUp()),
        [isAuthenticated]
    );

    const redirectToOnboarding = useCallback(() => navigate(APP_ROUTES.ONBOARDING), []);

    const redirectToWelcome = useCallback(() => navigate(APP_ROUTES.WELCOME), []);
    const redirectToTermsOfUse = useCallback(() => navigate(APP_ROUTES.TERMS_OF_USE), []);
    const redirectToPrivacyPolicy = useCallback(() => navigate(APP_ROUTES.PRIVACY_POLICY), []);
    const redirectToFAQ = useCallback(() => navigate(APP_ROUTES.FAQ), []);
    const redirectToContact = useCallback(() => navigate(APP_ROUTES.CONTACT), []);
    const redirectToResources = useCallback(() => navigate(APP_ROUTES.RESOURCES), []);

    const redirectToProgress = useCallback((replace = false) => navigate(APP_ROUTES.PROGRESS, { replace }), []);
    const redirectToProfileView = useCallback(() => navigate(APP_ROUTES.PROFILE_VIEW), []);
    const redirectToActivities = useCallback(() => navigate(APP_ROUTES.ACTIVITIES), []);
    const redirectToMedications = useCallback(() => navigate(APP_ROUTES.MEDICATIONS), []);
    const redirectToHealthQuestionnaire = useCallback(() => navigate(APP_ROUTES.HEALTH_QUESTIONNAIRE), []);
    const redirectToLanguageQuestionnaire = useCallback(() => navigate(APP_ROUTES.LANGUAGE_QUESTIONNAIRE), []);
    const redirectToPhoneQuestionnaire = useCallback(() => navigate(APP_ROUTES.PHONE_QUESTIONNAIRE), []);
    const redirectToIganQuestionnaire = useCallback(() => navigate(APP_ROUTES.IGAN_QUESTIONNAIRE), []);
    const redirectToShareMedicalRecords = useCallback(() => navigate(APP_ROUTES.SYNC_DATA), []);
    const redirectToShareMedicalRecordsInstructions = useCallback(() => navigate(APP_ROUTES.SYNC_DATA_INFO), []);
    const redirectToShareMedicalRecordsFinal = useCallback(() => navigate(APP_ROUTES.SYNC_DATA_FINAL), []);
    const redirectToTaskThankYou = useCallback(() => navigate(APP_ROUTES.TASK_THANK_YOU), []);
    const redirectToThankYou = useCallback(() => navigate(APP_ROUTES.THANK_YOU), []);
    const redirectToError = useCallback(() => navigate(APP_ROUTES.ERROR), []);
    const redirectToWithdrawnSuccess = useCallback(() => navigate(APP_ROUTES.WITHDRAWN_SUCCESS), []);
    const redirectToTokenizationSuccess = useCallback(() => navigate(APP_ROUTES.TOKENIZATION_SUCCESS), []);
    const redirectToPhoneVerification = useCallback(() => navigate(APP_ROUTES.PHONE), []);
    const redirectToProfileWithdraw = useCallback(() => navigate(APP_ROUTES.PROFILE_WITHDRAW), []);

    return {
        reload,
        redirectToLogin,
        redirectToSignUp,
        redirectToProgress,
        redirectToOnboarding,
        redirectToWelcome,
        redirectToTermsOfUse,
        redirectToPrivacyPolicy,
        redirectToFAQ,
        redirectToContact,
        redirectToResources,
        redirectToProfileView,
        redirectToMedications,
        redirectToActivities,
        redirectToHealthQuestionnaire,
        redirectToLanguageQuestionnaire,
        redirectToPhoneQuestionnaire,
        redirectToIganQuestionnaire,
        redirectToShareMedicalRecords,
        redirectToShareMedicalRecordsInstructions,
        redirectToShareMedicalRecordsFinal,
        redirectToTaskThankYou,
        redirectToThankYou,
        redirectToError,
        redirectToWithdrawnSuccess,
        redirectToTokenizationSuccess,
        redirectToPhoneVerification,
        redirectToProfileWithdraw
    };
};
