import background from 'images/background.svg';

const getStyles = ({ palette, breakpoints }) => ({
    title: {
        color: '#2B3A41',
        fontWeight: 600,
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Inter',
        paddingLeft: '30px',
        paddingBottom: '20px'
    },
    container: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
    },
    formContainer: {
        background: palette.primary.white,
        marginTop: '40px',
        padding: '20px 20px 40px 20px',
        boxShadow: '  0px 0px 30px rgba(0, 84, 135, 0.05)',
        borderRadius: '8px'
    },
    formHeader: {
        paddingTop: '25px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5px',
        svg: {
            width: '101px'
        }
    },
    fieldContainer: {
        paddingTop: '25px',
        width: '500px',
        position: 'relative',
        '& .MuiInputBase-input': {
            paddingRight: '40px !important'
        },
        [breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formWrapper: {
      marginLeft: '30px',
      [breakpoints.down('sm')]: {
          margin: '10px',
      }
    },
    cityStateWrapper: {
        display: 'flex',
        width: '500px',
        [breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    stateFieldContainer: {
        paddingTop: '25px',
        marginLeft: '20px',
        width: '180px',
        position: 'relative',
        '& .MuiInputBase-input': {
            paddingRight: '40px !important'
        },
        [breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    cityFieldContainer: {
        paddingTop: '25px',
        width: '300px',
        position: 'relative',
        '& .MuiInputBase-input': {
            paddingRight: '40px !important'
        },
        [breakpoints.down('sm')]: {
            width: 'auto'
        }
    },
    leftBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gridGap: 200,
        padding: '35px'
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: palette.primary.white,
        minHeight: '100vh'
    },
    mainContainerMob: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    sectionBox: {
        paddingTop: '15px',
        paddingBottom: '15px',
        maxWidth: '560px'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    stickyFooter: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: palette.primary.white,
        width: '100%',
        height: '120px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '45px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px'
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px'
    },
    radio: {
        width: '145px',
        '& .StyledLabel': {
            opacity: 1,
            color: palette.primary.gray75,
            borderRadius: '8px',
            minHeight: '48px',
            padding: '14px 15px 10px 50px'
        }
    },
    icon: {
        width: '20px',
        position: 'absolute',
        right: 19,
        top: 68
    },
    datePicker: {
        borderRadius: '8px',
        borderColor: palette.primary.gray15,
        '&:hover': {
            borderColor: palette.primary.gray15
        },
        '&.Mui-focused': {
            borderColor: palette.primary.fuchsia75
        },
        '& .MuiInputBase-input::placeholder': {
            color: palette.primary.gray75,
            opacity: 1
        }
    },
    input: {
        height: '48px',
        opacity: 1,
        borderRadius: '8px',
        '& ::placeholder': {
            color: palette.primary.gray75,
            opacity: 1
        }
    },
    inputLabel: {
        fontSize: '14px',
        fontWeight: 500,
        marginBottom: '8px',
        color: palette.primary.gray75
    },
    selectInput: {
        '& .MuiInputBase-root': {
            height: '48px',
            borderRadius: '8px',
            opacity: 1,
            borderColor: palette.primary.gray15,
            '&: hover': {
                borderColor: palette.primary.gray50
            }
        }
    },
    submitButton: {
        '&:disabled': {
            opacity: 1,
            background: palette.primary.fuchsia75
        }
    },
    modalWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 560,
        width: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '8px',
        [breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '#EAECEC solid 1px',
        paddingTop: '20px',
        width: '560px',
        [breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    iconWrapper: { marginRight: '15px', cursor: 'pointer' },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        borderTop: '1px solid #EAECEC',
        paddingTop: '10px',
        marginTop: '20px'
    },
    continueButton: { width: '119px', paddingTop: '10px', marginRight: '30px' }
});

export default getStyles;
