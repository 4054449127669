import { Box, Typography, styled } from '@mui/material';
import { TITLES_MAP, TITLE_PREFIXES } from 'pages/Profile/Payments/constants';
import { GreenCoins } from 'pages/Profile/assets';
import { useTranslation } from 'react-i18next';

const today = new Date();
const TZ_DIFFERENCE = today.getTimezoneOffset() * 60 * 1000;

export function Payment({ task, points, date }) {
    const { t } = useTranslation();

    const title = task in TITLES_MAP ? TITLES_MAP[task] : task;
    const prefix = TITLE_PREFIXES.find((prefix) => title.includes(prefix));
    const formatedTitle = prefix ? title.replace(prefix + ' ', '') : title;

    const d = new Date(date);
    const startDateWithTimezone = new Date(d.getTime() - TZ_DIFFERENCE);
    const formatedDate = Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
        .format(startDateWithTimezone)
        .split('/')
        .join(' / ');

    return (
        <Container>
            <GreenCoins />

            <Info>
                <Typography variant='pl4' color='primary.gray100'>
                    {formatedDate}
                </Typography>

                <Typography variant='pl5' color='primary.gray50'>
                    {t('activities:index:tasks')[formatedTitle]?.title ?? t('profile:payments:enrollment')}
                </Typography>
            </Info>

            <Typography variant='pl4' color='primary.gray75' marginLeft='auto'>
                ${points}
            </Typography>
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '15px',
    alignItems: 'center'
}));

const Info = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '2px'
}));
