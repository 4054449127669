/* eslint-disable react-hooks/exhaustive-deps */
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Modal,
    styled,
    TextField,
    Typography
} from '@mui/material';
import { Stack } from '@mui/system';
import { CustomizedDatePicker } from 'components/DatePicker';
import { TextInput } from 'components/Input';
import { StyledFormLabel } from 'components/Input/TextField.styles';
import { ErrorWrapper } from 'configUI/components';
import { ButtonWithCheck, GhostButton } from 'configUI/components/Buttons';
import { StyledInputLabel } from 'configUI/components/TextArea';
import { useMedications } from 'pages/Medications/MedicationsProvider';
import {
    getPlaceholderStyles,
    StyledSelect
} from 'pages/onboarding/PersonalInformation/forms/PersonalInformationForm/styles';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Close, Trash } from './assets';

const isValidDate = (date) => {
    console.log({ date });

    if (date === undefined) {
        return false;
    }

    const parsedDate = new Date(date);
    const now = new Date();

    const currentYear = now.getFullYear();

    return parsedDate instanceof Date && !isNaN(parsedDate) && currentYear - 100 <= parsedDate.getFullYear();
};

export function UpdateMedicationModal({ open, onClose, onRemove, openShowDeleteMedicationModal, medication }) {
    const { medications, updateMedication } = useMedications();
    const { t } = useTranslation();

    const selectedMedication = medications.find((med) => med.medication === medication.medication);
    console.log('PREV MEDICATION', {
        selectedMedication,
        medication
    });

    const { control, formState, handleSubmit, reset, getValues } = useForm({
        mode: 'all',
        defaultValues: {
            ...medication,
            end_date: medication?.end_date ?? undefined
        }
    });

    const { errors, isSubmitting, isValid } = formState;
    const previousMedicationRef = useRef(medication);

    console.log('MEDICATION', {
        medication
    });

    const [startDate, setStartDate] = useState();
    const [startDateError, setStartDateError] = useState('');
    const [endDate, setEndDate] = useState();
    const [endDateError, setEndDateError] = useState('');

    function handleOnClose() {
        updateMedication({
            ...previousMedicationRef.current
        });

        reset();
        onClose();
    }

    function handleUpdateMedication(data) {
        const start_date = new Date();
        start_date.setFullYear(startDate.getFullYear());
        start_date.setMonth(startDate.getMonth());
        start_date.setDate(startDate.getDate());

        let end_date;

        if (endDate) {
            end_date = new Date();
            end_date.setFullYear(endDate.getFullYear());
            end_date.setMonth(endDate.getMonth());
            end_date.setDate(endDate.getDate());
        }

        console.log('END MEDICATION', end_date);

        updateMedication({
            ...data,
            medication: medication.medication,
            isPreviousMedication: false,
            start_date,
            end_date
        });

        onClose();
    }

    function handleOnChangeStartDate(...params) {
        console.log({ params });
    }

    useEffect(() => {
        if (!previousMedicationRef.current && medication) {
            previousMedicationRef.current = medication;
        }
    }, [medication]);

    useEffect(() => {
        reset(medication);
    }, [medication]);

    const disabled = isSubmitting || !formState.isDirty || !isValid || Boolean(startDateError) || Boolean(endDateError);

    console.log({ startDateError, endDateError });

    return (
        <Modal open={open} onClose={handleOnClose}>
            <Container>
                <Wrapper>
                    <Header>
                        <Typography variant='h5' color='primary.gray100'>
                            {t('activities:medications:add-medications:update-medication')}
                        </Typography>

                        <Box display='flex' gap={10}>
                            <CloseButton onClick={handleOnClose} />
                        </Box>
                    </Header>

                    <form onSubmit={handleSubmit(handleUpdateMedication)}>
                        <Body>
                            <Grid container spacing='20px'>
                                <Grid item xs={12} spacing='8px'>
                                    <StyledFormLabel>
                                        {t('activities:medications:add-medications:labels:drug-name')}
                                    </StyledFormLabel>

                                    <ErrorWrapper>
                                        <Controller
                                            name='medication'
                                            control={control}
                                            error={errors.medication}
                                            render={({ field, fieldState }) => (
                                                <Autocomplete
                                                    {...field}
                                                    freeSolo
                                                    value={medication?.medication}
                                                    disabled
                                                    getOptionLabel={(option) => {
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }

                                                        if (
                                                            typeof option === 'object' &&
                                                            typeof option.label === 'string'
                                                        ) {
                                                            return option.label;
                                                        }

                                                        return '';
                                                    }}
                                                    sx={(theme) => ({
                                                        flex: 1,
                                                        marginTop: '8px',
                                                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: theme.palette.primary.gray15
                                                        }
                                                    })}
                                                    error={!!fieldState.error}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t(
                                                                'activities:medications:add-medications:placeholders:drug-name'
                                                            )}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </ErrorWrapper>
                                </Grid>

                                <Grid item xs={6} alignItems='flex-start'>
                                    <StyledFormLabel>
                                        {t('activities:medications:add-medications:labels:frequency')}
                                    </StyledFormLabel>

                                    <ErrorWrapper>
                                        <Controller
                                            name='frequency'
                                            control={control}
                                            rules={{
                                                required: true,
                                                min: 1
                                            }}
                                            error={errors.frequency}
                                            render={({ field, fieldState }) => (
                                                <TextInput
                                                    inputProps={{ min: 1 }}
                                                    type='number'
                                                    error={!!fieldState.error}
                                                    fullWidth={true}
                                                    placeholder={t(
                                                        'activities:medications:add-medications:placeholders:frequency'
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </ErrorWrapper>
                                </Grid>

                                <Grid item xs={6} marginTop='auto'>
                                    <ErrorWrapper>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='frequency_period'
                                                control={control}
                                                rules={{
                                                    required: true
                                                }}
                                                error={errors.frequency_period}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <StyledInputLabel
                                                            sx={getPlaceholderStyles(field.value === '')}
                                                            id='frequency_period_label'
                                                        >
                                                            {t(
                                                                'activities:medications:add-medications:placeholders:frequency-period'
                                                            )}
                                                        </StyledInputLabel>

                                                        <StyledSelect
                                                            fullWidth
                                                            {...field}
                                                            error={!!fieldState.error}
                                                            label={'Select period'}
                                                            labelId='frequency_period_label'
                                                        >
                                                            {Object.entries(
                                                                t(
                                                                    'activities:medications:add-medications:selects:frequencies'
                                                                )
                                                            ).map(([key, value]) => (
                                                                <MenuItem key={key} value={key}>
                                                                    {value}
                                                                </MenuItem>
                                                            ))}
                                                        </StyledSelect>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </ErrorWrapper>
                                </Grid>

                                <Grid item xs={6} spacing='8px'>
                                    <StyledFormLabel>
                                        {t('activities:medications:add-medications:labels:date-started')}
                                    </StyledFormLabel>

                                    <Controller
                                        name='start_date'
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        error={errors.start_date}
                                        render={({ field, fieldState }) => {
                                            setStartDate(field.value);
                                            const isDate = field.value instanceof Date;

                                            const isInvalidDate = isDate ? !isValidDate(field.value) : false;
                                            const isDateAfterEndDate = compareDates(endDate, field.value) === -1;
                                            const isFuture = isFutureDate(field.value);

                                            const hasErrors =
                                                isDateAfterEndDate ||
                                                isInvalidDate ||
                                                isFuture ||
                                                Boolean(fieldState.error);

                                            console.log('START DATE', {
                                                isDate,
                                                isInvalidDate,
                                                isDateAfterEndDate,
                                                isFuture,
                                                hasErrors
                                            });

                                            if (hasErrors) {
                                                if (isDateAfterEndDate) {
                                                    setStartDateError(
                                                        t('activities:medications:errors:invalidStartDate')
                                                    );
                                                } else if (isInvalidDate) {
                                                    setStartDateError(t('activities:medications:errors:invalidDate'));
                                                } else if (isFuture) {
                                                    setStartDateError(t('activities:medications:errors:futureDate'));
                                                } else {
                                                    setStartDateError('');
                                                }
                                            } else {
                                                setStartDateError('');
                                            }

                                            return (
                                                <>
                                                    <CustomizedDatePicker
                                                        disableFuture
                                                        error={Boolean(startDateError)}
                                                        onChange={handleOnChangeStartDate}
                                                        placeholder={t(
                                                            'activities:medications:add-medications:placeholders:date-started'
                                                        )}
                                                        {...field}
                                                        value={startDate}
                                                    />

                                                    {startDateError && (
                                                        <Typography variant='pl3' color='primary.red'>
                                                            {startDateError}
                                                        </Typography>
                                                    )}
                                                </>
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6} spacing='8px'>
                                    <StyledFormLabel>
                                        {t('activities:medications:add-medications:labels:date-ended')}
                                    </StyledFormLabel>

                                    <ErrorWrapper>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='end_date'
                                                control={control}
                                                rules={{
                                                    required: false
                                                }}
                                                error={errors.end_date}
                                                render={({ field, fieldState }) => {
                                                    setEndDate(field.value);
                                                    const isDate = field.value instanceof Date;

                                                    const isInvalidDate = isDate ? !isValidDate(field.value) : false;

                                                    const isDateBeforeStartDate =
                                                        compareDates(startDate, field.value) === 1;

                                                    const isFuture = isFutureDate(field.value);

                                                    const hasErrors =
                                                        isDateBeforeStartDate ||
                                                        isInvalidDate ||
                                                        isFuture ||
                                                        Boolean(fieldState.error);

                                                    console.log('END DATE', {
                                                        isDate,
                                                        isInvalidDate,
                                                        isDateBeforeStartDate,
                                                        isFuture,
                                                        hasErrors
                                                    });

                                                    if (hasErrors) {
                                                        if (isDateBeforeStartDate) {
                                                            setEndDateError(
                                                                t('activities:medications:errors:invalidEndDate')
                                                            );
                                                        } else if (isInvalidDate) {
                                                            setEndDateError(
                                                                t('activities:medications:errors:invalidDate')
                                                            );
                                                        } else if (isFuture) {
                                                            setEndDateError(
                                                                t('activities:medications:errors:futureDate')
                                                            );
                                                        } else {
                                                            setEndDateError('');
                                                        }
                                                    } else {
                                                        setEndDateError('');
                                                    }

                                                    return (
                                                        <>
                                                            <CustomizedDatePicker
                                                                disableFuture
                                                                error={Boolean(endDateError)}
                                                                placeholder={t(
                                                                    'activities:medications:add-medications:placeholders:date-started'
                                                                )}
                                                                {...field}
                                                                value={endDate}
                                                            />

                                                            {endDateError && (
                                                                <Typography variant='pl3' color='primary.red'>
                                                                    {endDateError}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </ErrorWrapper>
                                </Grid>

                                <FormGroup sx={{ padding: '20px' }}>
                                    <Controller
                                        name='different_dosage'
                                        control={control}
                                        error={errors.different_dosage}
                                        render={({ field, fieldState }) => (
                                            <FormControlLabel
                                                {...field}
                                                error={!!fieldState.error}
                                                onChange={(state) => {
                                                    console.log({ state });

                                                    updateMedication({
                                                        ...medication,
                                                        different_dosage: state.target.checked
                                                    });

                                                    field.onChange(state.target.checked);
                                                }}
                                                checked={field.value}
                                                control={<Checkbox />}
                                                label={
                                                    <Typography color='primary.gray100'>
                                                        {t(
                                                            'activities:medications:add-medications:labels:different_dosage'
                                                        )}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </Grid>
                        </Body>

                        <Footer>
                            <Stack
                                direction='row'
                                alignItems='center'
                                spacing='20px'
                                sx={{ cursor: 'pointer', marginRight: 'auto' }}
                                onClick={() => {
                                    openShowDeleteMedicationModal(medication);
                                }}
                            >
                                <Trash />
                                <Typography variant='pb3' color='primary.main'>
                                    {t('activities:medications:medication:remove')}
                                </Typography>
                            </Stack>

                            <GhostButton onClick={handleOnClose}>
                                {t('activities:medications:add-medications:buttons:cancel')}
                            </GhostButton>

                            <ButtonWithCheck type='submit' disabled={disabled}>
                                {t('activities:medications:add-medications:buttons:save')}
                            </ButtonWithCheck>
                        </Footer>
                    </form>
                </Wrapper>
            </Container>
        </Modal>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
}));

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '560px',
    maxWidth: '560px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px'
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '25px 30px 20px 30px',
    borderBottom: '1px solid',
    borderColor: theme.palette.primary.gray10
}));

const CloseButton = styled(Close)(({ theme }) => ({
    cursor: 'pointer'
}));

const Body = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '25px 30px 20px 30px'
}));

const Footer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    gap: '5px',
    padding: '25px 30px 20px 30px',
    borderTop: '1px solid',
    borderColor: theme.palette.primary.gray10
}));

export function isFutureDate(date) {
    if (date instanceof Date) {
        return new Date() < date;
    }
}

export function compareDates(date1, date2) {
    if (date1 instanceof Date && date2 instanceof Date) {
        const y1 = date1.getFullYear();
        const m1 = date1.getMonth();
        const d1 = date1.getDate();

        const y2 = date2.getFullYear();
        const m2 = date2.getMonth();
        const d2 = date2.getDate();

        if (y1 > y2 || (y1 === y2 && (m1 > m2 || (m1 === m2 && d1 > d2)))) {
            return 1; // date1 greater than date2
        } else if (y1 === y2 && m1 === m2 && d1 === d2) {
            return 0; // date1 equals date2
        } else {
            return -1; // date1 smaller than date2
        }
    }
}
