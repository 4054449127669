import { FilledButton, LinkButton } from 'components/Buttons';
import { ReactComponent as Fail } from 'images/shared/fail-exclamationmark.svg';
import { useTranslation } from 'react-i18next';
import { Container, HelperText, PageTitle, Row, WithdrawWrapper } from './styles';

function Withdraw({ handleClose, confirmCallback }) {
    const { t } = useTranslation();
    const onConfirm = () => {
        console.log('confirm');
        confirmCallback();
        handleClose();
    };
    const onCancel = () => {
        console.log('cancel');
        handleClose();
    };

    return (
        <>
            <WithdrawWrapper>
                <Container>
                    <Row>
                        <Fail width={200} height={198} />
                    </Row>
                    <Row>
                        <PageTitle>{t('profile:withdraw:withdraw-modal:title')}</PageTitle>
                    </Row>
                    <Row>
                        <HelperText>{t('profile:withdraw:withdraw-modal:subtitle')}</HelperText>
                    </Row>
                    <Row>
                        <FilledButton background='#E16058' onClick={onConfirm} fullWidth>
                            {t('profile:withdraw:withdraw-modal:yes')}
                        </FilledButton>
                    </Row>
                    <Row>
                        <LinkButton onClick={onCancel} fullWidth>
                            {t('profile:withdraw:withdraw-modal:cancel')}
                        </LinkButton>
                    </Row>
                </Container>
            </WithdrawWrapper>
        </>
    );
}

export default Withdraw;
