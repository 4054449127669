export const TITLES_MAP = {
    'Onboarding Payment': 'Enrollment'
};

export const TITLE_PREFIXES = [
    'Baseline',
    '3M',
    '6M',
    '9M',
    '12M',
    '15M',
    '18M',
    '21M',
    '24M',
    '27M',
    '30M',
    '33M',
    '36M'
];
