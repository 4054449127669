import { Box, Grid } from '@mui/material';
import { PPRLogo as Logo } from 'components/PPRLogo';
import { PersonalInformationFormLogic } from './forms/PersonalInformationForm';
import { Container, Wrapper } from './styles';

export default function PersonalInformation({ setShowIgAN }) {
    return (
        <Wrapper>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Logo />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <PersonalInformationFormLogic setShowIgAN={setShowIgAN} />
                    </Grid>
                </Grid>
            </Container>
        </Wrapper>
    );
}
