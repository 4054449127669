import { Box, styled } from '@mui/material';
import { useIsMobile } from 'hooks/useIsScreenSize';
import { APP_ROUTES } from 'navigation';
import { useActiveActivity } from 'pages/Main';
import { BackToMyActivities } from 'pages/Medications/BackToMyActivities';
import CompletedMedications from 'pages/Medications/CompletedMedications';
import MedicationsInstructions from 'pages/Medications/MedicationsInstructions';
import MedicationsList from 'pages/Medications/MedicationsList';
import { MEDICATIONS_STEPS, useMedications } from 'pages/Medications/MedicationsProvider';
import { MedicationsProvider } from 'pages/Medications/MedicationsProvider';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

export function MedicationsPage() {
    const { activeActivity } = useActiveActivity();

    if (!activeActivity) {
        return <Navigate to={APP_ROUTES.ACTIVITIES} replace />;
    }

    return (
        <MedicationsProvider>
            <Layout />
        </MedicationsProvider>
    );
}

function Layout() {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const { medications, step } = useMedications();
    const Component = components[step];
    const currentStep = step === MEDICATIONS_STEPS.LIST && medications.length === 0 ? '0' : '1';

    return (
        <Container>
            <Header>
                {<BackToMyActivities />}
                {currentStep && !isMobile && step !== MEDICATIONS_STEPS.INSTRUCTIONS && (
                    <Steps>
                        {t('activities:medications:common:step')} {currentStep} / 1
                    </Steps>
                )}

                <Box flex={1} />
            </Header>

            <Component />
        </Container>
    );
}

const components = {
    [MEDICATIONS_STEPS.INSTRUCTIONS]: MedicationsInstructions,
    [MEDICATIONS_STEPS.LIST]: MedicationsList,
    [MEDICATIONS_STEPS.COMPLETED]: CompletedMedications
};

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100dvh - 90px)'
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '70px',
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    boxSizing: 'border-box'
}));

const Steps = styled(Box)(({ theme }) => ({
    ...theme.typography.pb4,
    color: theme.palette.primary.blue,
    fontWeight: 700
}));
