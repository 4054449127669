import { useAuth0 } from '@auth0/auth0-react';
import { useGetPatient, useGetPerson } from 'authentication';
import { useDispatch } from 'ihp-bloom-redux/app/redux';
import {
    updateConnectingProvider,
    updateLastConnectedProvider
} from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useState } from 'react';
import { createUser, initiateDataFetch } from 'services/oneUpService';

export const useConnectHandler = (provider, setLoadingProviders, setIsRedirecting, setConnectingProvider, personId, participant_task_id) => {
    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();
    const connectedAt = new Date();

    const { isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();

    return async () => {
        if (setLoadingProviders) setLoadingProviders(true);
        if (setIsRedirecting) setIsRedirecting(true);
        if (setConnectingProvider) setConnectingProvider(provider.name);
        dispatch(updateConnectingProvider({ ...provider, connectedAt, participant_task_id }));
        dispatch(
            updateLastConnectedProvider({
                ...provider,
                connectedAt,
                isCheckStateTransitioned: false
            })
        );

        // TODO: fallback user id for testing reasons locally patientID can be null
        const participant_id = isCaregiver ? `${patientId}` : `${personId}`;

        const token = await getAccessTokenSilently();
        const response = await createUser(token, participant_id, participant_task_id, provider.id, provider.name);
        if (response.redirect_url) {
            window.location.replace(response.redirect_url);
        } else {
            alert('Something went wrong connecting with the health provider.');
        }

        if (setLoadingProviders) await setLoadingProviders(false);
        if (setConnectingProvider) await setConnectingProvider(null);
    };
};

export const useInitiateDataFetch = (callback) => {
    const { getAccessTokenSilently } = useAuth0();

    const { id: personId, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();

    const finalId = isCaregiver ? patientId : personId;

    const [loading, setLoading] = useState(false);
    const participantId = `${finalId}`;
    const initiate = async () => {
        setLoading(true);
        try {
            const token = await getAccessTokenSilently();
            await initiateDataFetch(token, participantId);
            setLoading(false);
            console.log('CALLBACK useInitiateDataFetch Error', callback);
            if (callback) callback();
        } catch (error) {
            console.log('Something went wrong while initiating data fetch');
            if (callback) callback();
        }
    };

    return { initiate, loading };
};
