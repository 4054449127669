import { Box, Typography } from '@mui/material';
import { ReactComponent as ArrowRight } from 'images/shared/arrow-right-2.svg';
import { useRedirections } from 'navigation';
import { useTranslation } from 'react-i18next';
import { GetStartedBtn, Wrapper } from './styles';

export const GetStarted = () => {
    const { redirectToSignUp } = useRedirections();
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Box>
                <Typography variant='h2' marginBottom='12px' color={'white'}>
                    {t('welcome:home:get-started')}
                </Typography>
                <Typography variant='pl3' color='primary.white' display='block' maxWidth='360px' lineHeight='145%'>
                    {t('welcome:home:get-started-desc')}
                </Typography>
            </Box>
            <GetStartedBtn onClick={redirectToSignUp} endIcon={<ArrowRight width='16px' />}>
                {t('welcome:home:register')}
            </GetStartedBtn>
        </Wrapper>
    );
};
