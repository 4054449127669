export const StudyAchievements = [
  {
    name: 'Games',
    type: 'Games',
    points: '45',
    totalPoints: '50',
  },
  {
    name: 'Surveys',
    type: 'survey',
    points: '12',
    totalPoints: '50',
  },
  {
    name: 'Health data',
    type: 'HealthData',
    points: '29',
    totalPoints: '50',
  },
  {
    name: 'Videos',
    type: 'Videos',
    points: '5',
    totalPoints: '50',
  },
  {
    name: 'Devices',
    type: 'Devices',
    points: '5',
    totalPoints: '50',
  },
  {
    name: 'Follow-ups',
    type: 'FollowUps',
    points: '7',
    totalPoints: '50',
  },
  {
    name: 'Lab tests',
    type: 'LabTests',
    points: '5',
    totalPoints: '50',
  },
  {
    name: 'Phase',
    type: 'Polls',
    points: '0',
    totalPoints: '50',
  },
];

export const COMPLETED = 'COMPLETED';
export const UPCOMING = 'UPCOMING';
export const AVAILABLE = 'AVAILABLE';

export const OverviewMainTitle = `<p><b>PERFORM Patient Registry</b></p>
<p>This is a registry of patients utilizing TARPEYO® in the United States.</p>
 `;

export const OverviewFirstSubtitle = (
  <>
    {/* This research study aims to learn about how the immune system affects
    different health conditions.
    <br />
    <br />
    The main part of this study lasts seven years. You will have an opportunity
    to earn $700 ($100 per year) in gift cards over the course of the study.
    Participants can earn additional rewards for continuing in the study longer.
    <br />
    <br />
    Eligibility is based on a selected set of criteria. You will need to answer
    some questions to help us determine if you are eligible to participate. It
    is possible that we may determine that you are not eligible after you have
    signed the informed consent. */}
  </>
);

export const OverviewTimelineDescription = `Scroll down to view the timeline of activities that will earn points toward
   your reward gift cards.`;

export const StudyOverviewData = [
  { id: 'games', title: 'Games', type: 'Games', pointsPerWeek: '10' },
  { id: 'surveys', title: 'Surveys', type: 'survey', pointsPerWeek: '5' },
  {
    id: 'healthData',
    title: 'HealthData',
    type: 'HealthData',
    pointsPerWeek: '1',
  },
];
