import { getBaseURL } from 'config/API';

const IHP_ONEUP_API = getBaseURL();

const PROJECT_NAME = 'calliditas-tarpeyo';

export const getHealthProviders = async (token) => {
    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    const response = await fetch(`${IHP_ONEUP_API}/v2/oneup/providers-list`, requestOptions)
        .then((response) => response.text())
        .catch((error) => console.log('error', error));

    return JSON.parse(response);
};

export const createUser = async (token, participant_id, participant_task_id, health_system_id, provider_name) => {
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
        participant_id,
        participant_task_id,
        // TODO: check if we need to provide another projects
        provider_name,
        project: PROJECT_NAME,
        health_system_id
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(`${IHP_ONEUP_API}/v2/oneup/create`, requestOptions)
        .then((response) => {
            return response.text();
        })
        .catch((error) => console.log('error', error));
    return JSON.parse(response);
};

export const fetchSyncedData = async (token, participant_id) => {
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'text/plain');

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    // TODO: check if we need to provide another projects
    const response = await fetch(
        `${IHP_ONEUP_API}/v2/oneup/resource-data-fetch-status?participant_id=${participant_id}&project=${PROJECT_NAME}`,
        requestOptions
    )
        .then((response) => response.text())
        .catch((error) => console.log('error', error));

    let resources = null;
    try {
        resources = JSON.parse(response);
    } catch (e) {
        console.error(e);
    }
    if (resources) {
        return Object.values(resources);
    }
    return {};
};

export const initiateDataFetch = async (token, participant_id) => {
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
        participant_id,
        // TODO: check if we need to provide another projects
        project: PROJECT_NAME
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(`${IHP_ONEUP_API}/v2/oneup/initiate-data-fetch`, requestOptions)
        .then((response) => response.text())
        .catch((error) => console.log('error', error));

    return JSON.parse(response);
};

export const fetchConnectedProviders = async (token, participant_id, participant_task_id) => {
    var myHeaders = new Headers();
    myHeaders.append('Accept', 'application/vnd.api+json');
    myHeaders.append('Content-Type', 'application/vnd.api+json');
    myHeaders.append('Authorization', `Bearer ${token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    let url_sufix = '';

    if (participant_task_id) {
      url_sufix = `?filter[participant_task_id]=${participant_task_id}`;
    }

    // const url_sufix = participant_task_id
    //     ? `?filter[participant_task_id]=${participant_task_id}&filter[type]=manual`
    //     : '?filter[type]=manual';

    const response = await fetch(`${IHP_ONEUP_API}/v3/people/${participant_id}/providers${url_sufix}`, requestOptions)
        .then((response) => response.text())
        .catch((error) => console.log('error', error));

    return JSON.parse(response);
};

export const parseHealthProviderAddress = (address) => {
    let result = '';
    let street;
    if (address?.line?.length === 1) street = address?.line[0];
    if (street) result += `${street}, `;
    if (address?.city) result += `${address.city}, `;
    if (address?.postalCode) result += `${address.postalCode}, `;
    if (address?.state) result += `${address.state}`;
    if (result === '') return 'Unknown address';
    return result;
};
