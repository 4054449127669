
export const surveyDataEN = {
    0: {
        id: 0,
        type: 'skip'
    },
    1: {
        id: 1,
        title: 'Please enter your phone number',
        caregiverTitle:
            'Please enter your phone number',
        type: 'phone',
        label: 'Phone',
        answer: '',
        placeholder: 'Enter phone number',
        value: ''
    },
};
