import { LANGUAGES } from 'internationalization';

export const surveyDataES = {
    0: {
        id: 0,
        type: 'skip'
    },
    1: {
        id: 1,
        title: 'Seleccione el idioma que prefiere para su perfil.',
        caregiverTitle: 'Seleccione el idioma que prefiere para su perfil.',
        type: 'radio',
        label: 'Idiomas disponibles',
        answer: '',
        values: [
            {
                label: 'Inglés',
                caregiverLabel: 'Inglés',
                value: LANGUAGES.ENGLISH
            },
            {
                label: 'Español',
                caregiverLabel: 'Español',
                value: LANGUAGES.SPANISH
            }
        ]
    }
};
