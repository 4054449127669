/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthenticate, useGetCurrentUser } from 'authentication/hooks';
import { useLoading } from 'components/loading';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useAuth0Redirections, useRedirections } from 'navigation';
import { isValidElement, useEffect } from 'react';

const withAuth0 =
    (Component, { redirectTo = 'login' } = {}) =>
    (props) => {
        const { setIsLoading } = useLoading();
        const { isAuthenticated, isLoading } = useAuth0();
        const { redirectToLogin, redirectToSignUp } = useAuth0Redirections();

        const redirections = {
            login: redirectToLogin,
            signup: redirectToSignUp
        };

        useEffect(() => {
            setIsLoading(true);
        }, []);

        if (isLoading) {
            return null;
        }

        if (!isAuthenticated && redirectTo in redirections) {
            redirections[redirectTo]();
            return null;
        }

        const componentToRender = <Component {...props} />;
        return isValidElement(componentToRender) ? componentToRender : null;
    };

const withRequirePerson = (Component) => (props) => {
    const { isAuthenticated } = useAuth0();
    const { authenticate } = useAuthenticate();
    const { isAuthenticated: isReduxAuthenticated } = useSelector((state) => state.authentication);
    const { user, isError, isSuccess } = useGetCurrentUser();
    const { redirectToError } = useRedirections();

    useEffect(() => {
        if (!isAuthenticated) return;
        authenticate();
    }, [isAuthenticated]);

    useEffect(() => {
        if (!isReduxAuthenticated) return;
        if (isError || (isSuccess && !user)) {
            redirectToError();
        }
    }, [isReduxAuthenticated, isError, isSuccess, user]);

    const componentToRender = <Component {...props} />;
    return isValidElement(componentToRender) ? componentToRender : null;
};

export const withAuth = (Component, config) => withAuth0(withRequirePerson(Component), config);
