import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetStarted } from '../GetStarted';
import { TermsWrapper } from './styles';

const TermsPage = () => {
    const { t } = useTranslation();
    return (
        <Box bgcolor='primary.gray20' px='24px'>
            <TermsWrapper>
                <Box>
                    <Typography component='h1' variant='h1' fontWeight='600'>
                        {t('welcome:terms:terms-of-use')}
                    </Typography>
                    <pre style={{ textWrap: 'wrap' }}>
                        <Typography variant='pn1' display='block' color='primary.gray75' margin='0.7em 0 1.5em 0'>
                            {t('welcome:terms:terms-desc').map((item) => item)}
                        </Typography>
                    </pre>
                </Box>

                <GetStarted />
            </TermsWrapper>
        </Box>
    );
};

export default TermsPage;
