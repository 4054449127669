/* eslint-disable react-hooks/exhaustive-deps */
import { useGetPatient, useGetPerson } from 'authentication';
import { getBaseURL } from 'config/API';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { getDate } from 'pages/Medications/CompletedMedications';
import { useActivities } from 'pages/NewActivities/Provider';
import { useCallback, useEffect, useMemo } from 'react';
import { createContext, useContext, useState } from 'react';

const MedicationsContext = createContext(null);

export const MEDICATIONS_STEPS = {
    INSTRUCTIONS: 'instructions',
    LIST: 'list',
    COMPLETED: 'completed'
};

export function MedicationsProvider({ children }) {
    const {
        activities: { data }
    } = useActivities();

    const { id: personId, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();
    const finalId = isCaregiver ? patientId : personId;

    const [showInstructions, setShowInstructions] = useState(true);
    const [previousMedications, setPreviousMedications] = useState([]);
    const [medications, setMedications] = useState([]);
    const [step, setStep] = useState(MEDICATIONS_STEPS.INSTRUCTIONS);
    const [isLoading, setIsLoading] = useState(false);

    const { token } = useSelector((state) => state.authentication);

    const previousMedicationsIds = data
        ?.filter((activity) => activity.data.title === 'Current Medication' && activity.data.status === 'completed')
        .sort((a, b) => (new Date(b.data.status_date) < new Date(a.data.status_date) ? -1 : 1));

    const previousMedicationsId = previousMedicationsIds?.[0]?.data?.id || '';
    console.log({ previousMedicationsIds, previousMedicationsId });

    const fetchActivities = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(
                `${getBaseURL()}/v3/people/${finalId}/participant-tasks/${previousMedicationsId}?include=participant-task-entry`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            const data = await response.json();

            if (data?.included?.[0]?.attributes?.data) {
                setMedications((previous) =>
                    previous.concat(
                        Object.entries(data.included[0].attributes.data).map(([key, value]) => {
                            return {
                                ...value,
                                medication: key,
                                start_date: new Date(value.start_date),
                                end_date: value.end_date ? new Date(value.end_date) : undefined,
                                isPreviousMedication: true
                            };
                        })
                    )
                );

                setPreviousMedications((previous) =>
                    previous.concat(
                        Object.entries(data.included[0].attributes.data).map(([key, value]) => {
                            return {
                                ...value,
                                medication: key,
                                start_date: new Date(value.start_date),
                                end_date: value.end_date ? new Date(value.end_date) : undefined
                            };
                        })
                    )
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!isLoading && previousMedicationsId && medications.length === 0) {
            fetchActivities();
        }
    }, [isLoading, previousMedicationsId, medications.length]);

    const addMedication = useCallback(
        (medication) => {
            setMedications((previous) => previous.concat(medication));
        },
        [medications]
    );

    const updateMedication = (medication) => {
        console.log('NEW MEDICATION', medication);

        setMedications((previous) => {
            const index = previous.findIndex((med) => med.medication === medication.medication);

            console.log('INDEX MEDICATION', previous[index]);
            previous[index] = medication;
            return previous;
        });
    };

    const removeMedication = useCallback(
        (name) => {
            setMedications((previous) => previous.filter((med) => med.medication !== name));
        },
        [medications]
    );

    const contextValue = useMemo(
        () => ({
            previousMedications,
            medications,
            step,
            showInstructions,
            setShowInstructions,
            addMedication,
            updateMedication,
            removeMedication,
            setStep,
            isLoading
        }),
        [previousMedications, medications, step, showInstructions, isLoading, updateMedication]
    );

    return <MedicationsContext.Provider value={contextValue}>{children}</MedicationsContext.Provider>;
}

export function useMedications() {
    const data = useContext(MedicationsContext);

    if (data === null) {
        throw new Error('useMedication should be inside MedicationProvider');
    }

    return data;
}
