import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Description = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '145%',
  color: theme.palette.secondary.gray4,
}));

export const Reward = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '145%',
  color: theme.palette.primary.gray100,
  textAlign: 'right'
}));

export const Username = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '145%',
  color: theme.palette.primary.gray100,
}));

export const HeartBeatCount = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '145%',
  color: theme.palette.primary.fuchsia75,
}));
