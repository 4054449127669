import { getBaseURL } from 'config/API';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useEffect, useState } from 'react';

const URL = getBaseURL() + '/v3/app/medication_suggest';

export function useSearchMedication(value, enabled) {
    const token = useSelector((state) => state.authentication.token);
    const [cachedMedications, setCachedMedications] = useState({});
    const [isSearchingMedications, setIsSearchingMedications] = useState({});

    useEffect(() => {
        async function searchMedication() {
            if (cachedMedications[value] || isSearchingMedications[value]) {
                return;
            }

            setIsSearchingMedications((previous) => ({ ...previous, [value]: true }));
            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    select: 'str',
                    top: 10,
                    fuzzy: false,
                    suggesterName: 'sg',
                    search: value
                })
            });

            const data = await response.json();
            setIsSearchingMedications((previous) => ({ ...previous, [value]: false }));
            setCachedMedications((previous) => ({ ...previous, [value]: data }));
        }

        if (enabled && value) {
            searchMedication(value);
        }
    }, [cachedMedications, enabled, isSearchingMedications, token, value]);

    return {
        medications: cachedMedications,
        isSearching: Object.values(isSearchingMedications).some(Boolean)
    };
}
