import { Box, styled } from '@mui/material';

const Tag = styled(Box)({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: '10px',
  lineHeight: '140%',
  display: 'flex',
  alignItems: 'center',
  gap: '7px',
  boxSizing: 'border-box',
  borderRadius: '4px',
  padding: '0 9px',
  height: '24px',
  textTransform: 'uppercase',
});

export const TagRequiredSigned = styled(Tag)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.fuchsia5,
}));

export const TagRequiredUnsigned = styled(Tag)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
}));

export const TagArchived = styled(Tag)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.gray75,
  backgroundColor: theme.palette.primary.gray10,
}));
