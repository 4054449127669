import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FilledButton } from 'components/Buttons';
import { Link } from 'react-router-dom';
// import background from 'images/background.svg';
// import mobileBackground from './images/mobile-background.svg';

export const HeroWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '50px',
    backgroundColor: theme.palette.primary.gray20,
    margin: '5em auto 5em auto',
    maxWidth: '1080px',
    gap: '2em',
    [theme.breakpoints.down('lg')]: {
        padding: '0 1em'
    },
    '@media (max-width: 768px)': {
        flexWrap: 'wrap',
        // height: 'calc(100vh - 75px)',
        textAlign: 'center',
        padding: '0px 20px',
        flexDirection: 'column'
    }
}));

// export const HeroWrapper = styled(Box)({
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   flexWrap: 'wrap',
//   paddingBottom: '50px',
//   margin: '0 auto',
//   maxWidth: '1080px',
// });

export const MobileWrapper = styled(Box)({
    height: 'calc(100vh - 75px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
});

export const Menu = styled(Box)(({ navExpanded }) => ({
    display: navExpanded ? 'block' : 'none',
    //   backgroundImage: `url(${mobileBackground})`,
    //   backgroundSize: 'cover',
    //   backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: '0px',
    zIndex: 3,
    width: '100vw',
    left: 0,
    height: 'calc(100vh + 25px)',
    gap: '0px',
    overflowX: 'none',

    svg: {
        cursor: 'pointer'
    }
}));

export const BtnWrapper = styled(Box)({
    'a, button': {
        width: '100%',
        marginBottom: '15px'
    }
});

export const PageLink = styled(Link)(({ navExpanded }) => ({
    display: 'none',
    color: '#606B71',
    textDecoration: 'none',
    fontWeight: navExpanded ? '600' : '500',
    fontSize: navExpanded ? '16px' : '14px'
}));

export const SignIn = styled(FilledButton)(({ theme }) => ({
    background: 'transparent',
    border: '2px solid #f37321',
    height: '43px',
    minWidth: '109px',
    color: '#f37321',
    fontSize: '14px',
    '&:hover': {
        color: 'white',
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
    }
}));

export const Register = styled(FilledButton)(({ theme }) => ({
    height: '43px',
    width: '109px',
    fontSize: '14px',
    background: theme.palette.primary.main,
    border: '2px solid transparent',
    '&:hover': {
        backgroundColor: 'white',
        border: '2px solid #f37321',
        color: theme.palette.primary.main
    }
}));

export const GetStartedBtn = styled(FilledButton)({
    height: '50px',
    width: '147px',
    padding: '15px 25px 15px 30px',
    // background: '#F49B1D',
    color: 'white',
    border: '2px solid transparent',
    fontSize: '15px',
    '&:hover': {
        background: 'white',
        color: '#f37321',
        border: '2px solid #f37321'
    }
});
