import { Box, CircularProgress, styled } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { Empty } from 'pages/Profile/Payments/Empty';
import { Payment } from 'pages/Profile/Payments/Payment';
import { useEffect } from 'react';

export function PaymentsPage() {
    const { id: personId, isCaregiver, isLoading: isPersonLoading } = useGetPerson();
    const { id: patientId, isLoading: isPatientLoading } = useGetPatient();
    const finalId = isCaregiver ? patientId : personId;

    const {
        data,
        isLoading: areParticipantGoalStatsLoading,
        isFetching: areParticipantGoalStatsFetching,
        refetch: refetchPayments
    } = useGetParticipantGoalStatsQuery(finalId, {
        skip: !finalId
    });

    useEffect(() => {
        if (finalId) {
            refetchPayments();
        }
    }, [finalId]);

    const payments = data?.data
        ?.filter((goal) => goal.attributes.status === 'completed')
        .map((goal) => ({
            task: goal.relationships.goal.attributes.title,
            points: goal.statistics.converted_amount,
            date: goal.attributes.completion_date
        }));

    const isLoading =
        (!finalId ||
            payments === undefined ||
            isPersonLoading ||
            (isCaregiver && isPatientLoading) ||
            areParticipantGoalStatsLoading ||
            areParticipantGoalStatsFetching) &&
        !data;

    if (isLoading) {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' flex={1}>
                <CircularProgress />
            </Box>
        );
    }

    if (payments?.length === 0) {
        return <Empty />;
    }

    return (
        <Container>
            <Wrapper>
                <List>
                    {payments.map((payment) => (
                        <Payment {...payment} />
                    ))}
                </List>
            </Wrapper>
        </Container>
    );
}

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    background: 'white',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
    padding: '30px'
});

const Wrapper = styled(Box)(({ theme }) => ({}));

const List = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
}));
