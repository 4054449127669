import {
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import { useGetPerson } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { CustomizedDatePicker } from 'components/DatePicker';
import { TextInput } from 'components/Input/TextInput';
import { ErrorWrapper } from 'configUI/components';
import {
    StyledInputLabel,
    StyledSelect,
    getPlaceholderStyles
} from 'pages/onboarding/PersonalInformation/forms/PersonalInformationForm/styles';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidDate } from 'validations';
import { StyledFormLabel, StyledFormTitle } from './styles';
import { ReactComponent as UserCircleIcon } from './user_circle.svg';

const DatePicker = styled(CustomizedDatePicker)(({ theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E9E9E9'
    }
}));

export const CaregiverPrescreeningForm = ({ form, onSubmit }) => {
    const { isLoading: isPersonLoading, isFetching: isPersonFetching } = useGetPerson();
    const { control, formState, getValues, handleSubmit } = form;
    const { errors, isSubmitting, isValid } = formState;

    const disabled = isSubmitting || !isValid || isPersonLoading || isPersonFetching;
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <StyledFormTitle variant='h4' gutterBottom>
                        {t('onboarding:prescreening:caregiver:title')}
                    </StyledFormTitle>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <StyledFormLabel>
                                {t('onboarding:prescreening:caregiver:labels:date-of-birth')}
                            </StyledFormLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='caregiver_date_of_birth'
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: (v) =>
                                            isValidDate(v) || t('onboarding:prescreening:errors:valid-date')
                                    }}
                                    error={errors.caregiver_date_of_birth}
                                    render={({ field, fieldState }) => {
                                        const minDate = new Date();
                                        minDate.setFullYear(minDate.getFullYear() - 100);

                                        return (
                                            <DatePicker
                                                disableFuture
                                                minDate={minDate}
                                                error={!!fieldState.error}
                                                fullWidth
                                                placeholder={t(
                                                    'onboarding:prescreening:caregiver:placeholders:date-of-birth'
                                                )}
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            </ErrorWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledFormLabel>
                                {t('onboarding:prescreening:caregiver:labels:relationship')}
                            </StyledFormLabel>
                            <ErrorWrapper>
                                <FormControl fullWidth>
                                    <Controller
                                        name='caregiver_patient_relationship'
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        error={errors.caregiver_patient_relationship}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <StyledInputLabel
                                                    sx={getPlaceholderStyles(field.value === '')}
                                                    id='relationship_label'
                                                >
                                                    {t('onboarding:prescreening:caregiver:placeholders:relationship')}
                                                </StyledInputLabel>
                                                <StyledSelect
                                                    fullWidth
                                                    {...field}
                                                    label={t(
                                                        'onboarding:prescreening:caregiver:placeholders:relationship'
                                                    )}
                                                    labelId='relationship_label'
                                                    error={!!fieldState.error}
                                                >
                                                    {Object.entries(
                                                        t('onboarding:prescreening:caregiver:relationships')
                                                    ).map(([key, value]) => (
                                                        <MenuItem key={key} value={key}>
                                                            {value}
                                                        </MenuItem>
                                                    ))}
                                                </StyledSelect>
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </ErrorWrapper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <StyledFormTitle variant='h4' gutterBottom>
                        {t('onboarding:prescreening:caregiver:patient-title')}
                    </StyledFormTitle>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <StyledFormLabel>
                                {t('onboarding:prescreening:caregiver:labels:patient-first-name')}
                            </StyledFormLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='patient_first_name'
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    error={errors.patient_first_name}
                                    render={({ field, fieldState }) => (
                                        <TextInput
                                            error={!!fieldState.error}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <UserCircleIcon />
                                                </InputAdornment>
                                            }
                                            fullWidth={true}
                                            placeholder={t(
                                                'onboarding:prescreening:caregiver:placeholders:patient-first-name'
                                            )}
                                            {...field}
                                        />
                                    )}
                                />
                            </ErrorWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledFormLabel>
                                {t('onboarding:prescreening:caregiver:labels:patient-last-name')}
                            </StyledFormLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='patient_last_name'
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    error={errors.patient_last_name}
                                    render={({ field, fieldState }) => (
                                        <TextInput
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <UserCircleIcon />
                                                </InputAdornment>
                                            }
                                            error={!!fieldState.error}
                                            fullWidth={true}
                                            placeholder={t(
                                                'onboarding:prescreening:caregiver:placeholders:patient-last-name'
                                            )}
                                            {...field}
                                        />
                                    )}
                                />
                            </ErrorWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledFormLabel>
                                {t('onboarding:prescreening:caregiver:labels:patient-date-of-birth')}
                            </StyledFormLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='patient_date_of_birth'
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: (v) =>
                                            isValidDate(v) || t('onboarding:prescreening:errors:valid-date')
                                    }}
                                    error={errors.patient_date_of_birth}
                                    render={({ field, fieldState }) => {
                                        const minDate = new Date();
                                        minDate.setFullYear(minDate.getFullYear() - 100);

                                        return (
                                            <DatePicker
                                                disableFuture
                                                minDate={minDate}
                                                error={!!fieldState.error}
                                                fullWidth
                                                placeholder={t(
                                                    'onboarding:prescreening:caregiver:placeholders:patient-date-of-birth'
                                                )}
                                                {...field}
                                            />
                                        );
                                    }}
                                />
                            </ErrorWrapper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <StyledFormLabel>{t('onboarding:prescreening:caregiver:labels:had-tarpeyo')}</StyledFormLabel>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name='patient_had_tarpeyo'
                            error={errors.had_tarpeyo_before}
                            render={({ field: props }) => {
                                const radioButtonStyles = (theme) => ({
                                    color: theme.palette.grey[500],
                                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                                        color: theme.palette.primary.gray3
                                    },
                                    '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                        color: theme.palette.primary.main
                                    }
                                });

                                return (
                                    <RadioGroup onChange={(e) => props.onChange(e.target.value)} {...props}>
                                        <Stack
                                            direction='row'
                                            alignItems='center'
                                            justifyContent='center'
                                            spacing='10px'
                                            width='100%'
                                        >
                                            <Box
                                                flex={1}
                                                px='14px'
                                                border={1}
                                                borderColor='primary.gray3'
                                                borderRadius='6px'
                                            >
                                                <FormControlLabel
                                                    control={<Radio sx={radioButtonStyles} size='small' />}
                                                    // We are using 1 because true was causing problems in some SQL queries
                                                    value={1}
                                                    label={
                                                        <Typography variant='pn3'>
                                                            {t('onboarding:prescreening:caregiver:labels:yes')}
                                                        </Typography>
                                                    }
                                                />
                                            </Box>

                                            <Box
                                                flex={1}
                                                px='14px'
                                                border={1}
                                                borderColor='primary.gray3'
                                                borderRadius='6px'
                                            >
                                                <FormControlLabel
                                                    control={<Radio sx={radioButtonStyles} size='small' />}
                                                    // We are using 0 because true was causing problems in some SQL queries
                                                    value={0}
                                                    label={
                                                        <Typography variant='pn3'>
                                                            {t('onboarding:prescreening:caregiver:labels:no')}
                                                        </Typography>
                                                    }
                                                />
                                            </Box>
                                        </Stack>
                                    </RadioGroup>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FilledButton
                        {...(isSubmitting
                            ? {
                                  disabled: true,
                                  startIcon: <CircularProgress size={24} color='secondary' />
                              }
                            : {})}
                        disabled={disabled}
                        type='submit'
                        width='100%'
                    >
                        {t('onboarding:prescreening:caregiver:buttons:save')}
                    </FilledButton>
                </Grid>
            </Grid>
        </form>
    );
};
