import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { StyledFormLabel } from 'components/Input/TextField.styles';
import { ButtonWithArrow } from 'configUI/components/Buttons';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { LANGUAGES } from 'internationalization';
import { useRedirections } from 'navigation';
import { useActivities } from 'pages/NewActivities/Provider';
import { useParticipantsTasksData } from 'pages/Study/useParticipantsTasksData';
import { PhoneInput, isValidPhoneNumber } from 'pages/onboarding/Phone/PhoneInput';
import { PhoneInvalid } from 'pages/onboarding/Phone/PhoneInvalid';
import { PhoneReachedLimit } from 'pages/onboarding/Phone/PhoneReachedLimit';
import { PhoneVerified } from 'pages/onboarding/Phone/PhoneVerified';
import { VerifyPhone } from 'pages/onboarding/Phone/VerifyPhone';
import { useSendOTP } from 'pages/onboarding/Phone/hooks';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTION_TYPES, INITIAL_STATE, reducerFn } from '../../EQ5D5L/helper';
import { SurveyProgressBar } from '../../EQ5D5L/survey-progress-bar';
import {
    ButtonWrapper,
    ContentWrapper,
    FilledButton,
    QuestionTitle,
    SurveyPage,
    SurveyWrapper,
    TextButton
} from '../styles.js';
import { surveyDataEN } from './surveyDataEN';
import { surveyDataES } from './surveyDataES';

export const PhoneQuestionnaire = ({ activeActivity, setIsInstructions }) => {
    const { redirectToThankYou, redirectToError } = useRedirections();
    const { id: personId, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();
    const finalId = isCaregiver ? patientId : personId;

    const { fetchActivities } = useActivities();
    const { t, i18n } = useTranslation();
    const surveyData = i18n.language === LANGUAGES.SPANISH ? surveyDataES : surveyDataEN;

    const [updateParticipantTask] = useUpdateParticipantTaskMutation();
    const { refetch: refetchParticipantsTasksData } = useParticipantsTasksData();

    const [isSubmiting, setIsSubmiting] = useState(false);

    const [state, localDispatch] = useReducer(reducerFn, INITIAL_STATE);
    const { answers } = state;

    const [currentStep, setCurrentStep] = useState(1);

    const [showVerifyPhone, setShowVerifyPhone] = useState(false);
    const [showInvalidOtp, setShowInvalidOtp] = useState(false);
    const [showValidOtp, setShowValidOtp] = useState(false);
    const [showReachedLimit, setShowReachedLimit] = useState(false);

    const [editPersonAttributes, { isLoading: isEditPersonAttributesLoading }] = useEditPersonAttributesMutation();

    const containerRef = useRef(null);

    const { mutate: sendOtp, isLoading: isSendOtpLoading } = useSendOTP({
        onSuccess: () => setShowVerifyPhone(true),
        onError: (error) => {
            if (error === 422) {
                setShowValidOtp(true);
                return;
            }

            if (error === 429) {
                setShowReachedLimit(true);
                return;
            }
        }
    });

    const isLoading = isSubmiting || isEditPersonAttributesLoading || isSendOtpLoading;

    async function handleSubmit() {
        try {
            setIsSubmiting(true);
            try {
                await updateParticipantTask({
                    participantTaskId: activeActivity.id,
                    payload: {
                        data: {
                            type: 'participant-tasks',
                            id: String(activeActivity.id),
                            attributes: {
                                status: 'completed'
                            }
                        }
                    }
                });

                fetchActivities(finalId);
                refetchParticipantsTasksData();
                setIsSubmiting(false);
                redirectToThankYou();
            } catch (error) {
                redirectToError();
            }

            redirectToThankYou();
            return;
        } catch (error) {
            setIsSubmiting(false);
            redirectToError();
        }
    }

    const onNext = async () => {
        const editPhoneAttribute = await editPersonAttributes({
            personId,
            payload: [
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'phone',
                            value: '+1' + state.answers[1]
                        }
                    }
                }
            ]
        });

        if (!editPhoneAttribute.error) {
            sendOtp();
            setShowVerifyPhone(true);
        }
    };

    const onPrevious = () => {
        if (currentStep === 0 || currentStep === 1) return;
        setCurrentStep(currentStep - 1);
    };

    const onAnswer = (payload) => {
        localDispatch({
            type: ACTION_TYPES.QUESTION_UPDATE,
            payload
        });
    };

    const onFinalize = () => {
        const values = Object.values(answers);
        handleSubmit(values);
    };

    useEffect(() => {
        containerRef?.current?.scrollTo(0, 0);
    }, [currentStep]);

    if (isLoading) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flex={1}
                height='100vh'
                bgcolor='primary.gray20'
                width='100%'
            >
                <CircularProgress />
            </Box>
        );
    }

    if (showReachedLimit) {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' width='100%' height='100%'>
                <PhoneReachedLimit onClick={() => setShowReachedLimit(false)} />
            </Box>
        );
    }

    if (showInvalidOtp) {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' width='100%' height='100%'>
                <PhoneInvalid onClick={() => setShowInvalidOtp(false)} />
            </Box>
        );
    }

    if (showValidOtp) {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' width='100%' height='100%'>
                <PhoneVerified
                    onClick={() => {
                        setShowInvalidOtp(false);
                        setShowVerifyPhone(false);
                        setShowValidOtp(false);
                        setCurrentStep((step) => step + 1);
                    }}
                />
            </Box>
        );
    }

    if (showVerifyPhone) {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' width='100%' height='100%' overflow='auto'>
                <VerifyPhone
                    sendOtp={sendOtp}
                    onSuccess={() => {
                        setShowInvalidOtp(false);
                        setShowVerifyPhone(false);
                        setShowValidOtp(true);
                    }}
                    onCancel={() => setShowVerifyPhone(false)}
                    onError={() => setShowInvalidOtp(true)}
                />
            </Box>
        );
    }

    const isValidPhone = isValidPhoneNumber(state.answers[1]);

    return (
        <SurveyWrapper ref={containerRef}>
            <SurveyProgressBar numberOfSteps={1} current={1} />

            <ContentWrapper>
                <Box>
                    {Object.values(surveyData).map((q, i) => {
                        if (q.type === 'skip' && i === currentStep) {
                            onNext();
                        }

                        if (q.type === 'phone')
                            return (
                                i === currentStep && (
                                    <Box key={q.id}>
                                        <QuestionTitle>{isCaregiver ? q.caregiverTitle : q.title}</QuestionTitle>
                                        <SurveyPage>
                                            <StyledFormLabel>{q.label}</StyledFormLabel>

                                            <PhoneInput
                                                phone={state.answers[1]}
                                                setPhone={(value) => onAnswer({ 1: value })}
                                            />
                                        </SurveyPage>
                                    </Box>
                                )
                            );

                        return null;
                    })}

                    {currentStep === Object.keys(surveyData).length ? (
                        <>
                            <Box>
                                <Typography marginBottom={'30px'} textAlign='center' color='primary.gray7' variant='h2'>
                                    {t('activities:adhoc:final:title')}
                                </Typography>

                                <Box marginBottom={'30px'}>
                                    <Typography color='primary.gray75' variant='pl3'>
                                        {t('activities:adhoc:final:description1')}
                                    </Typography>
                                </Box>

                                <Box marginBottom={'30px'}>
                                    <Typography marginBottom={'30px'} color='primary.gray75' variant='pl3'>
                                        {t('activities:adhoc:final:description2')}
                                    </Typography>
                                </Box>
                            </Box>

                            <ButtonWrapper>
                                <TextButton onClick={onPrevious} startIcon={<ArrowBackIcon />}>
                                    {t('activities:adhoc:buttons:previous')}
                                </TextButton>

                                <FilledButton
                                    disabled={isLoading}
                                    variant='contained'
                                    onClick={handleSubmit}
                                    startIcon={<CheckIcon />}
                                >
                                    {t('activities:adhoc:buttons:finalize')}
                                </FilledButton>
                            </ButtonWrapper>
                        </>
                    ) : (
                        <ButtonWrapper>
                            <TextButton onClick={() => setIsInstructions(true)} startIcon={<ArrowBackIcon />}>
                                {t('activities:adhoc:buttons:previous')}
                            </TextButton>

                            <Box display={'flex'} gap={'20px'} flex={1} flexDirection={'row'} justifyContent={'end'}>
                                {currentStep !== Object.keys(surveyData).length && (
                                    <ButtonWithArrow variant='contained' onClick={onNext} disabled={!isValidPhone}>
                                        {t('activities:adhoc:buttons:next')}
                                    </ButtonWithArrow>
                                )}
                            </Box>

                            {currentStep === Object.keys(surveyData).length && (
                                <FilledButton
                                    disabled={isLoading}
                                    variant='contained'
                                    onClick={onFinalize}
                                    startIcon={<CheckIcon />}
                                >
                                    {t('activities:adhoc:buttons:finalize')}
                                </FilledButton>
                            )}
                        </ButtonWrapper>
                    )}
                </Box>
            </ContentWrapper>
        </SurveyWrapper>
    );
};
