import { Box, Button, Slider, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const SurveyWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.gray20,
    width: '100%',
    overflowY: 'auto'
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
    maxWidth: '700px',
    margin: '0 auto',
    padding: '20px'
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
        marginBottom: '80px'
    }
}));

export const QuestionTitle = styled(Typography)(({ theme, width }) => ({
    fontWeight: '700',
    fontSize: '16px',
    color: theme.palette.primary.gray150,
    marginTop: '30px',
    marginBottom: '30px'
}));

export const SurveyPage = styled(Box)({
    maxWidth: '700px',
    backgroundColor: 'white',
    borderRadius: '8px',
    display: 'flex',
    flex: '1',
    padding: '30px',
    // minHeight: '300px',
    marginBottom: '30px',
    flexDirection: 'column'
});

export const CustomSlider = styled(Slider)(({ theme }) => ({
    minHeight: '300px',
    '& .MuiSlider-track': {
        border: 'none',
        backgroundColor: '#D9D9D9',
        width: 2
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        width: 2,
        backgroundColor: '#D9D9D9'
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#D9D9D9',
        height: 1,
        width: 12,
        '&.MuiSlider-markActive': {
            opacity: 1
        }
    }
}));

export const FilledButton = styled(Button)(({ theme }) => ({
    padding: '15px 30px',
    color: 'white',
    fontWeight: 600,
    borderRadius: '8px',
    boxShadow: 'none',
    '&: hover': {
        boxShadow: 'none'
    }
}));

export const TextButton = styled(Button)(({ theme }) => ({
    fontWeight: 600,
    borderRadius: '8px',
    textTransform: 'capitalize',
    padding: '0px 35px',
    color: theme.palette.primary.main,
    border: '2px solid transparent',
    '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: 'white',
        color: theme.palette.primary.main
    }
}));
