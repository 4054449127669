import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FilledButton } from 'components/Buttons';
import { ReactComponent as ArrowForwardIcon } from 'images/Task/arrow-forward-icon.svg';
import { isMobile } from 'utils/isMobile';

export const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    minHeight: '93vh',
    backgroundColor: theme.palette.primary.gray20,
    paddingTop: '60px',
    overflowY: 'auto'
}));

export const MobileContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.gray5,
    overflow: 'auto'
}));

export const TaskCategoryButton = styled((props) => {
    const color = props.isSelected ? '#A0438B' : '#F8F8F8';
    const fontColor = props.isSelected ? '#FFFFFF' : '#677176';
    return <FilledButton {...props} background={color} fontColor={fontColor} />;
})({
    padding: '10px 15px',
    fontSize: '12px',
    marginRight: '5px',
    fontWeight: '500',
    maxHeight: '40px'
});

export const MobileListContainer = styled(Box)(({ theme }) => ({
    padding: '20px',
    backgroundColor: theme.palette.primary.gray5,
    marginBottom: '10px'
}));

export const EmptyListWrapper = styled('div')(({ theme }) => ({
    width: '90%',
    height: '80vh',
    padding: '40px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.up('sm')]: {
        marginLeft: '60px',
        marginTop: '26px'
    }
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    maxWidth: '820px',
    margin: '0px auto'
    // [theme.breakpoints.down('md')]: {
    //     padding: '20px'
    // }
}));

export const ArrowBackwardIcon = styled(ArrowForwardIcon)({
    transform: 'rotate(180deg)'
});

export const MedicalNotesLogoContainer = styled('div')(({ theme }) => ({
    color: theme.palette.primary.gray75,
    width: '60px',
    height: '60px',
    position: 'relative',
    marginBottom: '20px'
}));

export const ActivitiesContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px'
});

export const ActivitiesDateHeader = styled(Box)({
    fontFamily: 'Inter',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '21.6px',
    color: '#606B71',
    marginBottom: '10px',
    marginTop: '33px'
});

export const MedicalNotesLogoBackground = styled('div')(({ theme }) => ({
    color: theme.palette.primary.gray75,
    width: '60px',
    height: '60px',
    backgroundColor: theme.palette.primary.darkBlue100,
    borderRadius: '7px',
    opacity: '0.1',
    position: 'absolute'
}));

export const MedicalNotesLogo = styled('div')(({ theme }) => ({
    color: theme.palette.primary.gray75,
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}));

export const LoaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
}));

export const LoaderTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '145%',
    color: theme.palette.primary.gray100
}));

export const LoaderSubTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '145%',
    color: theme.palette.primary.gray75
}));

export const MainHeaderContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
    }
}));

export const MainHeaderButtonsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minWidth: '350px'
});

export const Divider = styled(Box)({
    borderLeft: '2px solid #D9D9D9',
    height: '24px',
    marginLeft: '15px'
});

export const FiltersWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
    gap: '8px'
});

export const FiltersTitle = styled(Box)({
    color: '#A0438B',
    marginLeft: '10px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '17px'
});

export const getStyles = (theme, task, isTaskGroup, isChild, isLast) => {
    let color = '#2B3A41';
    let backgroundColor = '#FFFFFF';
    let width = isMobile() ? '95%' : '100%';
    let margin = isMobile() ? '10px' : '10px 0';
    let flexDirection = isMobile() ? 'column' : 'row';
    let borderRadius;

    if (task?.isDueTomorrow) {
        color = '#FFFFFF';
        backgroundColor = '#C080B1';
    }

    if (isTaskGroup) {
        borderRadius = '10px 10px 0 0';
        margin = isMobile() ? '0 10px 0 10px' : '10px -1px -1px';
    }
    if (isChild) {
        borderRadius = '0';
        margin = isMobile() ? '0 10px 0 10px' : '-1px';
    }
    if (isLast) {
        borderRadius = '0 0 10px 10px';
        margin = isMobile() ? margin : '-1px -1px 10px -1px';
    }

    return {
        [theme.breakpoints.up('sm')]: { margin },
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #EAECEC',
        minHeight: isTaskGroup ? '138px' : '170px',
        overflow: 'auto',
        backgroundColor,
        color,
        width,
        margin,
        flexDirection,
        borderRadius
    };
};

export const DaysButton = styled((props) => {
    const color = props.isSelected ? '#f49b1d' : '#EAECEC';
    const fontColor = props.isSelected ? '#FFFFFF' : '#606B71';
    return <FilledButton {...props} background={color} sx={{ color: fontColor }} />;
})({
    padding: '11px 20px 11px 20px',
    fontSize: '14px',
    fontWeight: '700',
    maxHeight: '40px',
    cursor: 'pointer',
    border: 'none'
});
