import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { getBaseURL } from 'config/API';
import { useLanguage } from 'internationalization';
import { parseStudyResources } from 'modules/resources/utils';

const getUrl = (language) =>
    getBaseURL() +
    `/v3/study-resource-localizations?filter[language]=${language}&include=study-resource,study-resource.study-resource-theme&page[limit]=100`;

export function useResources() {
    const { preferedLanguage } = useLanguage();
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ['resources', preferedLanguage],
        queryFn: async () => {
            const headers = new Headers();
            headers.set('Content-Type', 'application/json');

            try {
                const token = await getAccessTokenSilently();
                if (token) headers.set('Authorization', `Bearer ${token}`);
            } catch (error) {
                console.error(error);
            }

            const response = await fetch(getUrl(preferedLanguage), {
                headers
            });

            const data = await response.json();
            return parseStudyResources(data);
        },
        retry: 1
    });
}
