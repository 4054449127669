import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { getBaseURL } from 'config/API';

const sendOtpUrl = getBaseURL() + '/v3/phone-verification/send-otp';
const verifyOtpUrl = getBaseURL() + '/v3/phone-verification/verify-otp';

export function useSendOTP({ onSuccess, onError }) {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async () => {
            const headers = new Headers();
            headers.set('Content-Type', 'application/json');

            const token = await getAccessTokenSilently();
            if (token) headers.set('Authorization', `Bearer ${token}`);
            else throw Error('');

            const response = await fetch(sendOtpUrl, {
                headers,
                method: 'POST'
            });

            if (response.ok) {
                return true;
            }

            throw response.status;
        },
        onSuccess,
        onError
    });
}

export function useVerifyOTP({ otpCode, onSuccess, onError }) {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationKey: [otpCode],
        mutationFn: async () => {
            const headers = new Headers();
            headers.set('Content-Type', 'application/json');

            const token = await getAccessTokenSilently();
            if (token) headers.set('Authorization', `Bearer ${token}`);
            else throw Error('');

            const response = await fetch(verifyOtpUrl, {
                headers,
                method: 'POST',
                body: JSON.stringify({
                    otp_code: otpCode
                })
            });

            if (response.ok) {
                return true;
            }

            throw Error('');
        },
        onSuccess,
        onError
    });
}
