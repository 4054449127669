import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactWrapper, StyledBox, ItemBox, PhoneBox, EmailBox, LogoBox, StyledPhoneIcon } from './styles';

const ContactPage = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    return (
        <Box padding={!isMobile && 3} bgcolor='primary.gray20'>
            <ContactWrapper>
                <Box>
                    <Typography component='h1' variant='h1' fontWeight='600'>
                        {t('welcome:contact:contact-title')}
                    </Typography>
                    <Typography variant='pn1' display='block' color='primary.gray75' margin='0.7em 0 1.5em 0'>
                        {t('welcome:contact:contact-subtitle')}
                        <br /> {t('welcome:contact:contact-give-a-call')}
                    </Typography>
                </Box>
                <StyledBox>
                    <PhoneBox>
                        <ItemBox>
                            <LogoBox>
                                <StyledPhoneIcon width={40}></StyledPhoneIcon>
                            </LogoBox>
                            <Box>
                                <Typography variant='pn3' display='block' color='primary.gray50'>
                                    {t('welcome:contact:contact-phone-number')}
                                </Typography>

                                <Typography
                                    variant='pn1'
                                    fontSize={'24px'}
                                    display='block'
                                    fontWeight={500}
                                    color='primary.gray75'
                                >
                                    1-833-444-8277
                                </Typography>
                            </Box>
                        </ItemBox>
                    </PhoneBox>

                    <EmailBox>
                        {/* <ItemBox>
                            <LogoBox>
                                <EmailIcon width={40}></EmailIcon>
                            </LogoBox>
                            <Box>
                                <Typography
                                    variant='pn1'
                                    display='block'
                                    color='primary.gray50'>EMAIL ADDRESS</Typography>

                                <Typography
                                    variant='pn1'
                                    display='block'
                                    color='primary.gray75'>perform_patient_registry@calliditas.com
                                </Typography>
                            </Box>
                        </ItemBox> */}
                    </EmailBox>
                </StyledBox>
            </ContactWrapper>
        </Box>
    );
};

export default ContactPage;
