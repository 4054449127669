import { Typography, FormControlLabel, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFormLabel = styled('label')(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontWeight: 500,
    lineHeight: '2.4rem',
    fontSize: '14px',
    display: 'block',
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
    width: '100%',
    height: '46px',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontWeight: 500,
    borderWidth: '1px',
}));

export const StyledFormTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontWeight: 600,
    fontSize: '18px',
    display: 'block',
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.primary.gray100,
    fontWeight: 500,
    '& .MuiFormControlLabel-label': {
        fontSize: '14px',
    },
    '& .MuiSvgIcon-root': {
        width: '26px',
        height: '26px',
    },
}));
