import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';
import { queryString } from '../utils/objectToQueryParams';

export const participantTaskApiSlice = createApi({
  reducerPath: 'participantTaskApiV3',
  baseQuery: baseQuery('v3'),
  tagTypes: ['ParticipantTasks'],
  endpoints: (build) => ({
    getAllParticipantTasks: build.query({
      query: ({ participantId, query }) => ({
        url: `/app/people/${participantId}/participant-tasks?${queryString(query)}`,
        method: 'GET',
      }),
      providesTags: ['ParticipantTasks'],
    }),
    getParticipantTask: build.query({
      query: ({ participantId, participantTaskId, ...params }) => ({
        url: `/people/${participantId}/participant-tasks/${participantTaskId}?include=task,last-participant-task-entry`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, { participantTaskId }) => [
        { type: 'ParticipantTasks', id: participantTaskId },
      ],
    }),
    updateParticipantTask: build.mutation({
      query: ({ participantTaskId, payload }) => ({
        url: `/participant-tasks/${participantTaskId}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ParticipantTasks'],
    }),
    getParticipantTaskWithRelationship: build.query({
      query: ({ participantId, participantTaskId, ...params }) => ({
        url: `/people/${participantId}/participant-tasks/${participantTaskId}`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, { participantTaskId }) => [
        { type: 'ParticipantTasks', id: participantTaskId },
      ],
    }),
    uploadFile: build.mutation({
      query: ({ participantTaskId, payload }) => ({
        url: `/participant-tasks/${participantTaskId}/documents`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ParticipantTasks'],
    }),
  }),
});

export const {
  useGetAllParticipantTasksQuery,
  useGetParticipantTaskQuery,
  useGetParticipantTaskWithRelationshipQuery,
  useUpdateParticipantTaskMutation,
  useUploadFileMutation,
} = participantTaskApiSlice;
