import { Stack, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useLogout } from 'authentication';
import { ItemBox, LogoBox, PhoneBox, StyledPhoneIcon } from 'pages/Welcome/Contact/styles';
import { Button } from 'pages/onboarding/EmailVerified/styles';
import { useTranslation } from 'react-i18next';

export const ErrorCode = styled(Typography)(({ theme }) => ({
    fontSize: '100px',
    lineHeight: '120px',
    fontFamily: 'Inter',
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontFamily: 'Inter',
    lineHeight: '32px',
    fontWeight: '600',
    color: theme.palette.primary.gray75,
    textAlign: 'center'
}));

export function ErrorPage() {
    const { t } = useTranslation();
    const { logout } = useLogout();
    function redirect() {
        logout();
    }

    return (
        <Stack
            justifyContent='center'
            alignItems='center'
            spacing='20px'
            height='100vh'
            sx={{ bgcolor: (theme) => theme.palette.primary.gray20 }}
        >
            <Stack maxWidth='335px' justifyContent='center' alignItems='center'>
                <ErrorCode>500</ErrorCode>
                <ErrorMessage>{t('welcome:error:title')}</ErrorMessage>
                <br />
                <ErrorMessage>{t('welcome:error:subtitle')}</ErrorMessage>
                <br />
                <PhoneBox>
                    <ItemBox>
                        <LogoBox>
                            <StyledPhoneIcon width={40}></StyledPhoneIcon>
                        </LogoBox>
                        <Box>
                            <Typography variant='pn3' display='block' color='primary.gray50'>
                                {t('welcome:contact:contact-phone-number')}
                            </Typography>

                            <Typography
                                variant='pn1'
                                fontSize={'24px'}
                                display='block'
                                fontWeight={500}
                                color='primary.gray75'
                            >
                                1-833-444-8277
                            </Typography>
                        </Box>
                    </ItemBox>
                </PhoneBox>
                <Button
                    sx={{
                        width: '100%',
                        maxHeight: '44px',
                        fontSize: '14px',
                        marginTop: '30px'
                    }}
                    onClick={redirect}
                >
                    {t('welcome:error:back')}
                </Button>
            </Stack>
        </Stack>
    );
}
