import { useGetPatient, useGetPerson } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { PPRLogo } from 'components/PPRLogo';
import { useRedirections } from 'navigation';
import { Trans, useTranslation } from 'react-i18next';
import { Wrapper, Container, Row, PageTitle, HelperText } from './styles';

export function Reactivation() {
    const { t } = useTranslation();
    document.title = t('onboarding:index:titles:thank-you');

    const { redirectToPhoneVerification } = useRedirections();
    const { isCaregiver, ...person } = useGetPerson();
    const patient = useGetPatient();

    function handleOnClick() {
        if (isCaregiver) {
            patient?.refetch();
        }

        person?.refetch();
        redirectToPhoneVerification();
    }

    return (
        <Wrapper>
            <Container>
                <Row>
                    <PPRLogo width={200} />
                </Row>

                <Row marginBottom='10px'>
                    <PageTitle variant='h2'>{t('onboarding:index:info:reactivation:title')}</PageTitle>
                </Row>

                <Row marginBottom='10px'>
                    <HelperText variant='pl3'>
                        <Trans>{t('onboarding:index:info:reactivation:subtitle')}</Trans>
                    </HelperText>
                </Row>

                <Row marginBottom={'0px'}>
                    <FilledButton onClick={handleOnClick} fullWidth>
                        {t('onboarding:index:info:reactivation:button-text')}
                    </FilledButton>
                </Row>
            </Container>
        </Wrapper>
    );
}
