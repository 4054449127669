export const instructionsDataES = [
    {
        id: 1,
        title: '¿Cómo se comparten los historiales médicos?',
        description: ` Antes de empezar, puede resultarle útil tomar nota de los portales sanitarios que utiliza. Vas
                    a buscarlos por nombre (por ejemplo, 'Patient Online Services'). Por favor, ten a mano tu informacción de acceso antes de empezar.
                    <br />
                    <br />
                    En los pasos siguientes busca el primer portal sanitario de su lista. 2. Iniciar sesión en
                    ese portal sanitario. 3. Confirme el permiso para compartir el historial médico. Cuando complete
                    estos pasos, volverá a la pantalla de búsqueda, donde podrá añadir el siguiente proveedor
                    portal.`
    },
    {
        id: 2,
        title: ` ¿Qué historiales médicos debo compartir para este estudio?`,
        description: ` Por favor, comparta los historiales de los hospitales, clínicas o médicos implicados en su cuidado. Los portales podrían incluir médico de atención primaria, Nefrólogo, y otros especialistas médicos involucrados en el tratamiento o la gestión de la salud.`
    },
    {
        id: 3,
        title: ` ¿Qué registros se necesitan para el estudio?`,
        description: ` Para comprender la historia natural de la enfermedad, nos gustaría que compartiera su historial completo. Sólo se incluirán en los datos del estudio aquellos elementos que sean relevantes para el mismo.`
    },
    {
        id: 4,
        title: ` ¿Y ahora qué?`,
        description: ` Una vez que haya compartido los registros de cada uno de sus portales sanitarios, haga clic en "Finalizar compartición". Ya está, ¡ya ha terminado!`
    },
    {
        id: 5,
        title: ` ¿Qué ocurre si busco y mi portal de proveedores no aparece en la lista?`,
        description: ` Si su portal no aparece en la lista, indique el nombre de la organización sanitaria (por ejemplo, "Mercy Hospital"), junto con algunos datos sobre su ubicación. Esta información nos ayudará a mejorar el acceso al portal a lo largo del estudio.`
    }
];
