import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { ButtonWithArrow } from 'configUI/components/Buttons';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import {
    useFetchAllPatientTasksQuery,
    useGetParticipantGoalStatsQuery
} from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { LANGUAGES } from 'internationalization';
import { useRedirections } from 'navigation';
import { useActivities } from 'pages/NewActivities/Provider';
import { useParticipantsTasksData } from 'pages/Study/useParticipantsTasksData';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTION_TYPES, INITIAL_STATE, reducerFn } from './helper';
import { ReactComponent as EQ5D5LLogo } from './images/eq-5d-5l.svg';
import NoAnswerModal from './no-answer-modal';
import { Question } from './question';
import {
    ButtonWrapper,
    ContentWrapper,
    FilledButton,
    QuestionTitle,
    SurveyPage,
    SurveyWrapper,
    TextButton
} from './styles.js';
import { SurveyProgressBar } from './survey-progress-bar';
import { surveyDataEN } from './surveyDataEN';
import { surveyDataES } from './surveyDataES';
import { VerticalSlider } from './vertical-slider';

export const HealthQuestionnaire = ({ activeActivity, setIsInstructions }) => {
    const { id: personId, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();

    const { t, i18n } = useTranslation();
    const containerRef = useRef(null);

    const surveyData = i18n.language === LANGUAGES.SPANISH ? surveyDataES : surveyDataEN;

    const [updateParticipantTask, { isLoading: createParticipantTaskEntryLoading }] =
        useUpdateParticipantTaskMutation();

    const [isSubmiting, setIsSubmiting] = useState(false);
    const isLoading = isSubmiting || createParticipantTaskEntryLoading;

    const { redirectToActivities, redirectToTaskThankYou } = useRedirections();

    const [state, localDispatch] = useReducer(reducerFn, INITIAL_STATE);
    const { answers } = state;

    const [currentStep, setCurrentStep] = useState(0);
    const [healthValue, setHealthValue] = useState(50);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const participantTaskId = activeActivity.id;
    const subtasks = activeActivity.subtasks;

    const { refetch: refetchParticipantsTasksData } = useParticipantsTasksData();

    const finalId = isCaregiver ? patientId : personId;

    const { refetch: refetchParticipantsTasksDataV1 } = useFetchAllPatientTasksQuery(finalId, {
        skip: !finalId
    });

    const { refetch: refetchPayments } = useGetParticipantGoalStatsQuery(finalId, {
        skip: !finalId
    });

    const { fetchActivities } = useActivities();

    const handleSubmit = async (data) => {
        try {
            setIsSubmiting(true);
            await updateParticipantTask({
                participantTaskId,
                payload: {
                    data: {
                        type: 'participant-tasks',
                        id: String(participantTaskId),
                        attributes: {
                            status: 'completed',
                            entry: data
                        }
                    }
                }
            });

            refetchPayments();
            refetchParticipantsTasksDataV1();
            await fetchActivities(personId);
            await refetchParticipantsTasksData();
            setIsSubmiting(false);

            if (Array.isArray(subtasks)) {
                redirectToTaskThankYou();
            } else {
                redirectToActivities();
            }
        } catch (error) {
            redirectToActivities();
        }
    };

    const onNext = () => {
        if (currentStep === 0) {
            setCurrentStep(currentStep + 1);
            return;
        }


        if (answers.hasOwnProperty(currentStep)) {
            setCurrentStep(currentStep + 1);
            return;
        } else {
            handleOpenModal();
        }
    };

    const onPrevious = () => {
        if (currentStep === 0) {
            setIsInstructions(true);
        }

        setCurrentStep(currentStep - 1);
    };

    const onAnswer = (payload) => {
        localDispatch({
            type: ACTION_TYPES.QUESTION_UPDATE,
            payload
        });
    };

    const onFinalize = () => {
        const values = Object.values(answers);

        const submitionJSON = {
            MB: parseInt(values[0]),
            SC: parseInt(values[1]),
            UA: parseInt(values[2]),
            PD: parseInt(values[3]),
            AD: parseInt(values[4]),
            State: values[0] + values[1] + values[2] + values[3] + values[4],
            EQ_VAS: parseInt(values[5])
        };

        handleSubmit(submitionJSON);

        console.log('🚀 ~ submitionJSON:', submitionJSON);
    };

    const theme = useTheme();

    useEffect(() => {
        containerRef?.current?.scrollTo(0, 0);
    }, [currentStep]);

    if (isLoading) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flex={1}
                height='100vh'
                bgcolor='primary.gray20'
                width='100%'
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <SurveyWrapper ref={containerRef}>
            <NoAnswerModal open={openModal} handleClose={handleCloseModal} />

            <SurveyProgressBar
                numberOfSteps={Object.keys(surveyData).length - 1}
                current={currentStep >= Object.keys(surveyData).length ? 6 : currentStep}
            />

            <ContentWrapper>
                <>
                    <Box>
                        {Object.values(surveyData).map((q, i) => {
                            if (q.type === 'intro')
                                return (
                                    i === currentStep && (
                                        <Box key={q.id} textAlign='center' marginY='30px'>
                                            <Box margin={5}>
                                                <EQ5D5LLogo></EQ5D5LLogo>
                                            </Box>
                                            <Typography margin={2} variant='h2'>
                                                {q.title}
                                            </Typography>
                                            <Typography margin={2} variant='h2'>
                                                {q.subtitle}
                                            </Typography>
                                            {isCaregiver && (
                                                <Typography margin={2} variant='h2'>
                                                    {q.proxy}
                                                </Typography>
                                            )}
                                        </Box>
                                    )
                                );

                            if (q.type === 'radio')
                                return (
                                    i === currentStep && (
                                        <Box key={q.id}>
                                            <QuestionTitle>{isCaregiver ? q.caregiverTitle : q.title}</QuestionTitle>
                                            <SurveyPage>
                                                <Question
                                                    key={i}
                                                    components={q}
                                                    answer={answers[i]}
                                                    onAnswer={onAnswer}
                                                ></Question>
                                            </SurveyPage>
                                        </Box>
                                    )
                                );

                            if (q.type === 'vertical-slider')
                                return (
                                    i === currentStep && (
                                        <Box key={q.id}>
                                            <QuestionTitle>{q.title}</QuestionTitle>
                                            <SurveyPage>
                                                <VerticalSlider
                                                    q={q}
                                                    healthValue={healthValue}
                                                    setHealthValue={setHealthValue}
                                                    onAnswer={onAnswer}
                                                ></VerticalSlider>
                                            </SurveyPage>
                                        </Box>
                                    )
                                );

                            return <></>;
                        })}
                    </Box>

                    {/* {FINALIZE STEP - if there are [0,1,2,3,4,5] pages this is currentStep 6} */}
                    {currentStep === Object.keys(surveyData).length && (
                        <Box>
                            <Typography marginBottom={'30px'} textAlign='center' color='primary.gray7' variant='h2'>
                                {t('activities:eq5d5l:final:title')}
                            </Typography>
                            <Box marginBottom={'30px'}>
                                <Typography color='primary.gray75' variant='pl3'>
                                    {t('activities:eq5d5l:final:description1')}
                                </Typography>
                            </Box>
                            <Box marginBottom={'30px'}>
                                <Typography marginBottom={'30px'} color='primary.gray75' variant='pl3'>
                                    {t('activities:eq5d5l:final:description2')}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </>

                {currentStep < Object.keys(surveyData).length && (
                    <Typography textAlign='center' color={theme.palette.primary.gray75}>
                        {t('activities:eq5d5l:trademark')}
                    </Typography>
                )}

                <ButtonWrapper>
                    <TextButton disabled={isLoading} onClick={onPrevious} startIcon={<ArrowBackIcon />}>
                        {t('activities:eq5d5l:buttons:previous')}
                    </TextButton>

                    {currentStep !== Object.keys(surveyData).length && (
                        <ButtonWithArrow
                            variant='contained'
                            disabled={currentStep === Object.keys(surveyData).length || isLoading}
                            onClick={onNext}
                        >
                            {t('activities:eq5d5l:buttons:next')}
                        </ButtonWithArrow>
                    )}

                    {currentStep === Object.keys(surveyData).length && (
                        <FilledButton
                            disabled={isLoading}
                            sx={{}}
                            variant='contained'
                            onClick={onFinalize}
                            startIcon={<CheckIcon />}
                        >
                            {t('activities:eq5d5l:buttons:finalize')}
                        </FilledButton>
                    )}
                </ButtonWrapper>
            </ContentWrapper>
        </SurveyWrapper>
    );
};
