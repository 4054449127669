import { Stack } from '@mui/material';
import {
  LeftContainer,
  TitleContainer,
  MainContainer,
  OverviewListItemContainer,
  OverviewSubtitleContainer,
  TimelineTitleContainer,
  RightContainer,
  IconWrapper,
  Title,
  PointsPerWeek,
} from 'components/Study/overview';
import {
  OverviewFirstSubtitle,
  OverviewTimelineDescription,
  OverviewMainTitle,
  StudyOverviewData,
} from 'mocks/study';
import * as React from 'react';
import { SvgImageMap } from 'utils';

export const OverviewList = () => (
  <Stack sx={{ marginTop: '15px' }}>
    {StudyOverviewData.map((overview) => {
      const Icon = SvgImageMap[overview.type];
      return (
        <OverviewListItemContainer key={overview.id}>
          <LeftContainer>
            {Icon != null && (
              <IconWrapper height={'auto'}>
                <Icon />
              </IconWrapper>
            )}
            <TitleContainer>
              <Title>{overview.title}</Title>
            </TitleContainer>
          </LeftContainer>
          <RightContainer>
            <PointsPerWeek>{overview.pointsPerWeek} / week</PointsPerWeek>
          </RightContainer>
        </OverviewListItemContainer>
      );
    })}
  </Stack>
);

const Overview = () => (
  <MainContainer sx={{ bgcolor: (theme) => theme.palette.primary.gray2 }}>
    <TitleContainer dangerouslySetInnerHTML={{ __html: OverviewMainTitle }} />
    <OverviewSubtitleContainer
      sx={{ color: (theme) => theme.palette.primary.gray75 }}
    >
      {OverviewFirstSubtitle}
    </OverviewSubtitleContainer>
    <TimelineTitleContainer>Timeline</TimelineTitleContainer>
    <OverviewSubtitleContainer
      sx={{ color: (theme) => theme.palette.primary.gray75 }}
    >
      {OverviewTimelineDescription}
    </OverviewSubtitleContainer>
    <OverviewList />
  </MainContainer>
);

export default Overview;
