import { styled } from '@mui/material';

export const TokenizationWithdrawSuccessWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
}));

export const Container = styled('div')(({ theme }) => ({
    width: '300px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

}));

export const LogoBox = styled('div')(({ theme }) => ({
    width: '180px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    marginBottom: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const Row = styled('div')(({ marginBottom }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: marginBottom || '20px',
}));

