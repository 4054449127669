import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import {
    CategoryTitle,
    CategoryTitleContainer,
    HeartPoints,
    IconWrapper,
    Info,
    LeftContainer,
    RightContainer,
    TimelineCardContainer,
    Title,
    TitleContainer,
    StyledCircle
} from 'components/Study/timeline';
import { differenceInDays, format, formatDistance } from 'date-fns';
import { DollarIcon } from 'images/activity';
import { DollarIconBlack } from 'images/activity';
import { ReactComponent as CompletedIcon } from 'images/shared/check-circle-green.svg';
import { ACTIVITY_STATUS } from 'pages/NewActivities/constants';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgImageMap } from 'utils';

const today = new Date();
const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const TZ_DIFFERENCE = today.getTimezoneOffset() * 60 * 1000;

export const dateFormater = (date, separatorSymbol = '-') => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [month, day, year].join(separatorSymbol);
};

const iconMap = {
    group: SvgImageMap['survey'],
    survey: SvgImageMap['survey'],
    call: SvgImageMap['CalendarIcon'],
    games: SvgImageMap['GameboardIcon'],
    call_schedule: SvgImageMap['CalendarIcon'],
    video: SvgImageMap['Videos'],
    data_sharing: SvgImageMap['HealthData']
};

export function TimelineCard(props) {
    const { t } = useTranslation();
    const Icon = iconMap[props.task.type] || null;

    const theme = useTheme();
    const isCompleted = props.category === 'completed';
    const isExpired = props.category === 'expired';
    const isUpcoming = props.category === 'upcoming';
    const isAvailable = props.category === 'available';

    const filter = isCompleted ? 'grayscale(90%)' : '';
    const opacity = isUpcoming || isCompleted || isExpired ? 0.5 : 1;

    const completedIcon = isCompleted && (
        <Box sx={{ marginTop: '-50px', marginLeft: '-5px' }}>
            <CompletedIcon />
        </Box>
    );

    let timeAgo = props.start_date
        ? formatDistance(new Date(new Date(props.start_date).getTime() - TZ_DIFFERENCE), new Date())
              .replace('almost', '')
              .replace('over', '')
              .replace('about', '')
              .replace('hour', t('progress:index:activities:date:hour'))
              .replace('hours', t('progress:index:activities:date:hours'))
              .replace('month', t('progress:index:activities:date:month'))
              .replace('months', t('progress:index:activities:date:months'))
              .replace('year', t('progress:index:activities:date:year'))
              .replace('years', t('progress:index:activities:date:years'))
        : '';

    timeAgo = timeAgo.replace('Mess', t('progress:index:activities:date:months'));
    const title = props.task.title === 'Group of 2 survey' ? '2 surveys' : props.task.title;

    const endDate = new Date(props.end_date);
    const parsedEndDate = new Date(dateFormater(endDate, '/'));
    const daysLeft = Math.abs(differenceInDays(new Date(), endDate)) + 1;
    const safeDaysLeft = daysLeft > 30 ? 30 : daysLeft;

    // for adhoc tasks, we are checking this to hide the separator, we are now showing the end date (end_date is null).
    const isSeparatorHidden = props.end_date === null && props.status === ACTIVITY_STATUS.INCOMPLETE;

    return (
        <TimelineCardContainer sx={{ bgcolor: theme.palette.primary.white, opacity }}>
            <LeftContainer>
                {Icon != null && (
                    <IconWrapper>
                        <Box sx={{ filter }}>
                            <Icon height='40px' width='40px' />
                        </Box>
                        {completedIcon}
                    </IconWrapper>
                )}

                <TitleContainer>
                    <Title>{t('activities:index:tasks')?.[title]?.title}</Title>

                    <Info>
                        {isAvailable && props.end_date && (
                            <>
                                {safeDaysLeft}{' '}
                                {safeDaysLeft !== 1
                                    ? t('progress:index:activities:date:days')
                                    : t('progress:index:activities:date:day')}{' '}
                                {t('activities:index:chips:left').toLowerCase()}
                            </>
                        )}
                        {isUpcoming && timeAgo}
                        {isCompleted && Intl.DateTimeFormat('en-US').format(new Date(props.status_datetime))}
                        {isExpired && parsedEndDate instanceof Date && dateFormater(parsedEndDate, '/')}{' '}
                        {!isSeparatorHidden && <StyledCircle height='4px' width='4px' />}{' '}
                        {t('progress:index:activities:categories')[props.task.type.replace('_', '-')]}
                    </Info>
                </TitleContainer>
            </LeftContainer>

            {props.task?.point && (
                <RightContainer>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {isExpired ? <DollarIconBlack width={16} /> : <DollarIcon width={16} />}
                    </Box>

                    <HeartPoints style={isExpired ? { color: '#4A4A4A' } : undefined}>
                        {isExpired ? '--' : props.task?.point}
                    </HeartPoints>
                </RightContainer>
            )}
        </TimelineCardContainer>
    );
}

export function TimelineDayCard(props) {
    const title = props.title;
    const { t } = useTranslation();

    return (
        <TimelineCardContainer sx={{ marginBottom: 0 }}>
            <CategoryTitleContainer>
                <CategoryTitle sx={{ color: colors[title], opacity: title === 'AVAILABLE' ? 1 : 0.5 }}>
                    {t('progress:index:activities:status')[title.toLowerCase()]}
                </CategoryTitle>

                <CategoryTitle
                    sx={{
                        color: colors[title],
                        display: 'flex',
                        paddingRight: '3px',
                        opacity: title === 'AVAILABLE' ? 1 : 0.5
                    }}
                >
                    {props.count}
                </CategoryTitle>
            </CategoryTitleContainer>
        </TimelineCardContainer>
    );
}

const colors = {
    AVAILABLE: '#F49B1D',
    UPCOMING: '#444444',
    COMPLETED: '#028217',
    EXPIRED: '#444444'
};
