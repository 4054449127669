import { Box, Stack, Typography, styled } from '@mui/material';
import { useLanguage } from 'internationalization';
import { Pencil, Trash } from 'pages/Medications/assets';
import { useTranslation } from 'react-i18next';

export function Medication({ medication, frequency_period, frequency, start_date, onUpdate, onDelete }) {
    const { t } = useTranslation();
    const { preferedLanguage } = useLanguage();

    return (
        <Container>
            <Stack direction='column' spacing='3px'>
                <Typography variant='pb3' color='primary.gray100'>
                    {medication}
                </Typography>

                <Typography variant='pl3' color='primary.gray50'>
                    {t('activities:medications:medication:started')}{' '}
                    {Intl.DateTimeFormat('en-US').format(new Date(start_date))}
                </Typography>

                <Typography variant='pl3' color='primary.gray50'>
                    {t('activities:medications:medication:taken')} {frequency}{' '}
                    {preferedLanguage === 'us_en' ? (frequency > 1 ? 'times' : 'time') : undefined}{' '}
                    {t('activities:medications:add-medications:selects:frequencies')[frequency_period]?.toLowerCase() ??
                        frequency_period.toLowerCase()}
                </Typography>
            </Stack>

            <Stack direction='column' alignItems='flex-end' spacing='20px'>
                <Buttons direction='row' spacing='30px'>
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing='20px'
                        sx={{ cursor: 'pointer' }}
                        onClick={onUpdate}
                    >
                        <Pencil />
                        <Typography variant='pb3' color='primary.main'>
                            {t('activities:medications:medication:update')}
                        </Typography>
                    </Stack>

                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing='20px'
                        sx={{ cursor: 'pointer' }}
                        onClick={onDelete}
                    >
                        <Trash />
                        <Typography variant='pb3' color='primary.main'>
                            {t('activities:medications:medication:remove')}
                        </Typography>
                    </Stack>
                </Buttons>
            </Stack>
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    padding: '10px 20px',
    border: '1px solid #CACED0',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}));

const Buttons = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: '20px'
    }
}));
