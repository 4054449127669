import { Modal, useMediaQuery } from '@mui/material';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { selectSelectedParticipanttaskId } from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { ReactComponent as CancelLogo } from 'images/shared/fail-exclamationmark.svg';
import { APP_ROUTES } from 'navigation';
import { CancelButton } from 'pages/Profile/Redeem/components/ButtonsBar/styles';
import { Button, Text, Title, Wrapper } from 'pages/SyncData/SearchProvidersV2/styles';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotesMedical, NotesMedicalActive, Progress, ProgressActive, UserCircle, UserCircleActive } from '../assets';
import { NavDesktop } from './NavDesktop';
import { NavMobile } from './NavMobile';
import { CancelContainer } from '../styles';

export function Nav() {
    const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [lastClickedMenuItem, setLastClickedMenuItem] = useState(null);
    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
    const onCloseCancelModal = () => setIsOpenCancelModal(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
    const selectedParticipantTaskId = useSelector(selectSelectedParticipanttaskId());
    const { inProgressProviders } = oneUpHealthData;
    const { finishDataShareEnabled } = oneUpHealthData;
    /*
      We need to make sure that if current EMR task (child/patient) is same that is saved in redux state then warning modal should display
     */
    const isActiveProviderInProgress = inProgressProviders?.find(
        (item) => item.participant_task_id === selectedParticipantTaskId
    );

    const shouldShowPopUp = (finishDataShareEnabled || inProgressProviders?.length > 0) && isActiveProviderInProgress;
    const isEMR = location.pathname === APP_ROUTES.SYNC_DATA;

    const onClick = (props) => {
        if (isEMR && shouldShowPopUp) {
            return () => {
                setLastClickedMenuItem(props.redirectTo ?? props.path);
                setIsOpenCancelModal(true);
                return;
            };
        } else {
            return () => {
                setLastClickedMenuItem(props.redirectTo ?? props.path);
                navigate(props.redirectTo ?? props.path);
            };
        }
    };

    const navigateBack = () => {
        setIsOpenCancelModal(false);
        navigate(lastClickedMenuItem);
    };
    const cancelModal = (
        <>
            <Modal
                open={isOpenCancelModal}
                onClose={onCloseCancelModal}
            >
                <CancelContainer>
                    <Wrapper>
                        <CancelLogo />
                        <Title>{t('activities:cancel:title')}</Title>
                        <Text>{t('activities:cancel:subtitle')}</Text>
                        <Button  onClick={onCloseCancelModal}>{t('activities:cancel:button-text')}</Button>
                        <CancelButton color='#E16058' variant='link' onClick={navigateBack}>
                            {t('activities:cancel:link-text')}
                        </CancelButton>
                    </Wrapper>
                </CancelContainer>
            </Modal>
        </>
    );

    return (
        <Suspense>
            {isTablet ? (
                <NavMobile {...props} cancelModal={cancelModal} onClick={onClick} />
            ) : (
                <NavDesktop {...props} cancelModal={cancelModal} onClick={onClick} />
            )}
        </Suspense>
    );
}

const props = {
    items: [
        {
            path: APP_ROUTES.ACTIVITIES,
            icons: [NotesMedical, NotesMedicalActive],
            hideWhenWithdrawn: true
        },
        {
            path: APP_ROUTES.PROGRESS,
            icons: [Progress, ProgressActive]
        },
        {
            path: APP_ROUTES.PROFILE,
            icons: [UserCircle, UserCircleActive],
            redirectTo: APP_ROUTES.PROFILE_VIEW
        }
    ]
};
