import { Typography } from '@mui/material';
import { EmptyConsentsWrapper, StyledIconContainer, StyledIconBackPlate, StyledSignIcon } from './EmptyConsents.styles';

export const EmptyConsents = () => {
    return (
        <EmptyConsentsWrapper>
            <StyledIconContainer>
                <StyledIconBackPlate />
                <StyledSignIcon />
            </StyledIconContainer>

            <Typography variant='h5' align='center' color='primary.gray75'>
                You have no consents
            </Typography>
            <Typography variant='pl5' align='center' color='primary.gray50' maxWidth='220px'>
                Sign and manage your consents and study participation
            </Typography>
        </EmptyConsentsWrapper>
    );
};
