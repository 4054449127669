import { useLogout } from 'authentication/hooks';
import { useEffect, useRef } from 'react';

const LOGOUT_TIME_IN_MILISECONDS = 1_200_000; // 20 minutes

export function AuthenticationNotifier({ children }) {
    const { logout } = useLogout();
    const timerRef = useRef(null);

    function resetTimer() {
        const timer = timerRef.current;

        if (timer) {
            clearTimeout(timer);
        }

        timerRef.current = setTimeout(logout, LOGOUT_TIME_IN_MILISECONDS);
    }

    useEffect(() => {
        window.addEventListener('mousemove', resetTimer);
        return () => {
            window.removeEventListener('mousemove', resetTimer);
            clearTimeout(timerRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children;
}
