import { useState, useEffect } from 'react';

/**
 *
 * @param {string} value
 * @param {number} delay number in milliseconds for the debounce
 * @returns data type of value passed into the function
 */
export const useDebounce = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
