import { ReactComponent as Hospital } from './hospital-duotone 1.svg';
import { ReactComponent as Laptop } from './laptop-medical-duotone 1.svg';
import { ReactComponent as Doctor } from './user-doctor-hair-duotone 1.svg';

export const cards = [
    {
        id: 0,
        icon: <Doctor />
    },
    {
        id: 1,
        icon: <Laptop />
    },
    {
        id: 2,
        icon: <Hospital />
    }
];
