export const menuItemsTimezone = [
    {
        value: 'US/Central',
        abbr: 'CST',
        offset: -6,
        isdst: true, // You may need to verify if daylight saving time is observed
        text: '(UTC-06:00) US Central Time',
        utc: ['America/Chicago']
    },
    {
        value: 'US/Eastern',
        abbr: 'EST',
        offset: -5,
        isdst: true, // You may need to verify if daylight saving time is observed
        text: '(UTC-05:00) US Eastern Time',
        utc: ['America/New_York']
    },
    {
        value: 'US/Pacific',
        abbr: 'PST',
        offset: -8,
        isdst: true, // You may need to verify if daylight saving time is observed
        text: '(UTC-08:00) US Pacific Time',
        utc: ['America/Los_Angeles']
    },
    {
        value: 'US/Hawaii',
        abbr: 'HST',
        offset: -10,
        isdst: false,
        text: '(UTC-10:00) US Hawaii-Aleutian Time',
        utc: ['Pacific/Honolulu']
    },
    {
        value: 'US/Alaska',
        abbr: 'AKST',
        offset: -9,
        isdst: true, // You may need to verify if daylight saving time is observed
        text: '(UTC-09:00) US Alaska Time',
        utc: ['America/Anchorage']
    },
    {
        value: 'US/Mountain',
        abbr: 'MST',
        offset: -7,
        isdst: true, // You may need to verify if daylight saving time is observed
        text: '(UTC-07:00) US Mountain Time',
        utc: ['America/Denver']
    },
    {
        value: 'US/Samoa',
        abbr: 'SST',
        offset: -11,
        isdst: false,
        text: '(UTC-11:00) US Samoa Time',
        utc: ['Pacific/Pago_Pago']
    },
    {
        value: 'US/Chamorro',
        abbr: 'ChST',
        offset: 10,
        isdst: false,
        text: '(UTC+10:00) US Chamorro Time',
        utc: ['Pacific/Guam', 'Pacific/Saipan']
    },
    {
        value: 'US/Atlantic',
        abbr: 'AST',
        offset: -4,
        isdst: true, // You may need to verify if daylight saving time is observed
        text: '(UTC-04:00) US Atlantic Time',
        utc: ['America/Puerto_Rico', 'America/Virgin']
    },
];