import { Stack, styled } from '@mui/material';
import { ReactComponent as ChevronRight } from 'images/shared/chevron-right-1.svg';
import { Actions } from './Actions';
import { Main } from './Main';
import { Card, Divider, GoToSignedConsent } from './styles';

export const ConsentItem = ({
    consent: {
        id: consent_id,
        consent_version_id,
        title,
        description,
        participant_consent_status,
        participant_consent_date,
        submission_type,
        mandatory,
        status
    },
    onNavigateToConsentDetails,
    onRejectConsent,
    onOpenSignedConsent
}) => {
    const isSigned = participant_consent_status === 'completed' || participant_consent_status === 'terminated';
    const isRejected = participant_consent_status === 'rejected';
    const isDocusign = submission_type === 'docusign';

    const handleRejectConsent = (e) => {
        e.stopPropagation();
        onRejectConsent(consent_id);
    };

    const handleNavigateToConsentDetails = () => onOpenSignedConsent(consent_version_id);

    return (
        <Container showBorder={mandatory && !isSigned} onClick={handleNavigateToConsentDetails}>
            <Stack direction={{ sm: 'row', xs: 'column' }} flex={1} alignItems='stretch' divider={<Divider inverted />}>
                <Main
                    title={title}
                    description={description}
                    participant_consent_date={participant_consent_date}
                    isSigned={isSigned}
                    isMandatory={mandatory}
                    isRejected={isRejected}
                />

                {!isSigned && (
                    <Actions isDocusign={isDocusign} isMandatory={mandatory} onRejectConsent={handleRejectConsent} />
                )}
            </Stack>

            {isSigned && (
                <GoToSignedConsent>
                    <ChevronRight width='15px' height='15px' />
                </GoToSignedConsent>
            )}
        </Container>
    );
};

const Container = styled(Card)({
    padding: '20px'
});
