/* eslint-disable react-hooks/exhaustive-deps */
import { useCheckUserStatus, useGetPerson, useGetPersonRelationship } from 'authentication';
import { format } from 'date-fns';
import {
    useEditPersonAttributesMutation,
    useEditPersonMutation
} from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { useRedirections } from 'navigation';
import { PARTICIPANT_STATUS } from 'pages/onboarding/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { calculateAge } from 'utils/date';
import { CaregiverPrescreeningForm } from './CaregiverPrescreeningForm';

export function CaregiverPrescreeningFormLogic() {
    const { id: caregiverId, refetch } = useGetPerson();
    const [editPersonAttribute, { isError: isPersonAttributesError }] = useEditPersonAttributesMutation();
    const [editPerson, { isError: isPersonError }] = useEditPersonMutation();
    const { patientId } = useGetPersonRelationship();
    const { redirectToError } = useRedirections();
    const { verifyUserStatus } = useCheckUserStatus();

    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            caregiver_date_of_birth: null,
            caregiver_patient_relationship: '',
            patient_first_name: '',
            patient_last_name: '',
            patient_date_of_birth: null,
            patient_had_tarpeyo: null
        }
    });

    async function handleSubmit(data) {
        const isUserStatusValid = await verifyUserStatus(PARTICIPANT_STATUS.AUTHENTICATED);
        if (!isUserStatusValid) return;
        try {
            const {
                caregiver_date_of_birth,
                caregiver_patient_relationship,
                patient_first_name,
                patient_last_name,
                patient_date_of_birth,
                patient_had_tarpeyo
            } = data;

            const caregiverAttributes = [
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'date_of_birth',
                            value: format(caregiver_date_of_birth, 'yyyy-MM-dd') + 'T00:00:00.000Z'
                        }
                    }
                },
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'caregiver_patient_relationship',
                            value: caregiver_patient_relationship
                        }
                    }
                }
            ];

            const patientAttributes = [
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'first_name',
                            value: patient_first_name
                        }
                    }
                },
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'last_name',
                            value: patient_last_name
                        }
                    }
                },
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'date_of_birth',
                            value: format(patient_date_of_birth, 'yyyy-MM-dd') + 'T00:00:00.000Z'
                        }
                    }
                },
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'patient_had_tarpeyo',
                            value: Number(patient_had_tarpeyo)
                        }
                    }
                }
            ];

            const editCaregiverAttributesPromise = editPersonAttribute({
                personId: caregiverId,
                payload: caregiverAttributes
            });

            const editPatientAttributesPromise = editPersonAttribute({
                personId: patientId,
                payload: patientAttributes
            });

            const isDisqualified =
                calculateAge(caregiver_date_of_birth) < 18 ||
                calculateAge(patient_date_of_birth) < 18 ||
                Number(patient_had_tarpeyo) === 0;

            const editCaregiverPersonPromise = editPerson({
                personId: caregiverId,
                payload: {
                    data: {
                        type: 'people',
                        id: String(caregiverId),
                        attributes: {
                            state: 'enrollment',
                            status: isDisqualified ? PARTICIPANT_STATUS.DISQUALIFIED : PARTICIPANT_STATUS.REGISTERED,
                            status_reason: isDisqualified ? 'Not-eligible' : 'ini'
                        }
                    }
                }
            });

            await Promise.all([
                editCaregiverAttributesPromise,
                editPatientAttributesPromise,
                editCaregiverPersonPromise
            ]);

            await editPerson({
                personId: patientId,
                payload: {
                    data: {
                        type: 'people',
                        id: String(patientId),
                        attributes: {
                            state: 'enrollment',
                            status: isDisqualified ? PARTICIPANT_STATUS.DISQUALIFIED : PARTICIPANT_STATUS.REGISTERED,
                            status_reason: isDisqualified ? 'Not-eligible' : 'ini'
                        }
                    }
                }
            });
        } catch (error) {
            redirectToError();
        } finally {
            await refetch();
        }
    }

    useEffect(() => {
        if (isPersonAttributesError || isPersonError) {
            redirectToError();
        }
    }, [isPersonAttributesError, isPersonError]);

    return <CaregiverPrescreeningForm form={form} onSubmit={handleSubmit} />;
}
