import { Box, Typography, styled } from '@mui/material';
import { queryClient } from 'App';
import { FilledButton } from 'components/Buttons';
import { PhoneVerifiedSVG } from 'pages/onboarding/Phone/assets';
import { useTranslation } from 'react-i18next';

export function PhoneVerified({ onClick }) {
    const { t } = useTranslation();

    function handleOnClick() {
        queryClient.removeQueries('attributes');
        onClick();
    }

    return (
        <Container>
            <PhoneVerifiedSVG style={{ marginTop: '20px' }} />

            <Typography variant='h2' color='primary.gray100' marginTop='50px' textAlign='center'>
                {t('onboarding:phone:verified:title')}
            </Typography>

            <Typography variant='pl3' color='primary.gray75' marginTop='5px' textAlign='center' width='90%'>
                {t('onboarding:phone:verified:description')}
            </Typography>

            <FilledButton onClick={handleOnClick} width='100%' sx={{ marginTop: '50px', marginBottom: '5px' }}>
                {t('onboarding:phone:verified:button')}
            </FilledButton>
        </Container>
    );
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '325px',
    padding: '40px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px'
}));
