import { Box, styled, Typography } from '@mui/material';
import { AddMedicationModal } from 'pages/Medications/AddMedicationModal';
import { Add } from 'pages/Medications/assets';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function AddMedication() {
    const [showAddMedicationModal, setShowAddMedicationModal] = useState(false);
    const { t } = useTranslation();

    function openAddMedicationModal() {
        setShowAddMedicationModal(true);
    }

    function closeAddMedicationModal() {
        setShowAddMedicationModal(false);
    }

    return (
        <Fragment>
            <Container onClick={openAddMedicationModal}>
                <Add />
                <Typography variant='h6' color='primary.main'>
                    {t('activities:medications:add-medications:add-medication')}
                </Typography>
            </Container>

            <AddMedicationModal open={showAddMedicationModal} onClose={closeAddMedicationModal} />
        </Fragment>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    padding: '30px',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: theme.palette.primary.gray25,
    borderRadius: '8px',
    cursor: 'pointer'
}));
