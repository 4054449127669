import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Wrapper = styled(Box)(({ isCompleted, theme }) => ({
    border: `1px solid ${theme.palette.primary.gray15}`,
    borderRadius: '0.5em',
    display: 'flex',
    textAlign: 'left',
    width: '100%'
}));

export const RequiredPill = styled(Box)(({ isCompleted, theme }) => ({
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    display: 'flex',
    gap: '6px',
    justifyContent: 'center',
    padding: '2px 4px',
    minWidth: '80px',
    width: 'fit-content',
    span: {
        color: `1px solid ${isCompleted ? theme.palette.primary.green : theme.palette.primary.main}`,
        fontSize: '10px',
        fontWeight: 600,
        textTransform: 'uppercase'
    }
}));

export const FormContentWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
    padding: '1em',
    width: '75%'
}));

export const SignBtnWrapper = styled(Box)(({ isCompleted, theme }) => ({
    borderLeft: `1px solid ${theme.palette.primary.gray15}`,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '11px',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: '120px',
    textAlign: 'center',
    ':hover': {
        cursor: isCompleted ? 'auto' : 'pointer'
    }
}));
