import { APP_ROUTES } from 'navigation';
import IgANInstructions from 'pages/AdHoc/IgAN/IgANInstructions';
import { IgANQuestionnaire } from 'pages/AdHoc/IgAN/IgANQuestionnaire';
import { useActiveActivity } from 'pages/Main';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

export function IgANQuestionnairePage() {
    const { activeActivity } = useActiveActivity();
    const [isInstructions, setIsInstructions] = useState(true);

    if (!activeActivity) {
        return <Navigate to={APP_ROUTES.ACTIVITIES} replace />;
    }

    if (isInstructions) {
        return <IgANInstructions setIsInstructions={setIsInstructions} />;
    }

    return <IgANQuestionnaire activeActivity={activeActivity} setIsInstructions={setIsInstructions} />;
}
