import { useGetPerson } from 'authentication';
import { APP_ROUTES } from 'navigation';
import { DemographicInformation } from 'pages/onboarding/DemographicInformation';
import { IgANPage } from 'pages/onboarding/NewIgAN';
import PersonalInformation from 'pages/onboarding/PersonalInformation';
import { PARTICIPANT_STATUS } from 'pages/onboarding/constants';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

export function InformationPage() {
    const [showDemographicInformation, setShowDemographicInformation] = useState(false);
    const [showIgAN, setShowIgAN] = useState(false);

    const { status } = useGetPerson();

    if (status !== PARTICIPANT_STATUS.CONSENTED) {
        return <Navigate to={APP_ROUTES.ONBOARDING} replace />;
    }

    if (showIgAN && !showDemographicInformation) {
        return <IgANPage setShowDemographicInformation={setShowDemographicInformation} />;
    }

    if (showDemographicInformation) {
        return <DemographicInformation />;
    }

    return <PersonalInformation setShowIgAN={setShowIgAN} />;
}
