import { Typography } from '@mui/material';
import { FilledButton } from 'components/Buttons';
import { PPRLogo } from 'components/PPRLogo';
import { useRedirections } from 'navigation';
import { useTranslation } from 'react-i18next';
import { Container, LogoBox, Row, TokenizationWithdrawSuccessWrapper } from './styles';

export default function TokenizationWithdrawSuccess() {
    const { t } = useTranslation();
    const { redirectToProfileWithdraw } = useRedirections();

    return (
        <TokenizationWithdrawSuccessWrapper>
            <Container>
                <LogoBox>
                    <PPRLogo></PPRLogo>
                </LogoBox>
                <Row>
                    <Typography fontWeight='600' varient='h1' fontSize='20px' color='primary.gray75'>
                        {t('profile:tokenization:withdraw-success:title')}
                    </Typography>
                </Row>
                <Row>
                    <Typography varient='h2' fontSize='14px' color='primary.gray75'>
                        {t('profile:tokenization:withdraw-success:subtitle')}
                    </Typography>
                </Row>
                <FilledButton onClick={redirectToProfileWithdraw} fullWidth>
                    {t('profile:tokenization:withdraw-success:re-enroll')}
                </FilledButton>
            </Container>
        </TokenizationWithdrawSuccessWrapper>
    );
}
