import { useGetPatient, useGetPerson } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { ReactComponent as DoneCheckmarkIcon } from 'images/shared/done-checkmark.svg';
import { useRedirections } from 'navigation';
import { useTranslation } from 'react-i18next';
import { Container, PageTitle, Row, ThankYouPageWrapper } from './styles';

export function ThankYouPage() {
    document.title = 'Thank you';
    const { isCaregiver, ...person } = useGetPerson();
    const patient = useGetPatient();
    const { redirectToActivities } = useRedirections();
    const { t } = useTranslation();

    function handleOnClick() {
        if (isCaregiver) {
            patient.refetch();
        }

        person.refetch();
        redirectToActivities();
    }

    return (
        <ThankYouPageWrapper>
            <Container>
                <Row>
                    <DoneCheckmarkIcon width={200} />
                </Row>

                <Row>
                    <PageTitle varient='h2'>{t('activities:index:thank-you')}</PageTitle>
                </Row>

                <Row marginBottom={'0px'}>
                    <FilledButton onClick={handleOnClick} fullWidth>
                        {t('activities:index:buttons:done')}
                    </FilledButton>
                </Row>
            </Container>
        </ThankYouPageWrapper>
    );
}
