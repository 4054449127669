const VALID_SOURCE_NAMES = [
    'TTWEB',
    'DIRECTMAILING',
    'DIGITAL',
    'DOCOFFICE',
    'FOUNDATION',
    'DIRECTEMAIL',
    'SOCIAL',
    'EMAIL'
];

export const isValidSourceName = (sourceName) => VALID_SOURCE_NAMES.includes(sourceName);
