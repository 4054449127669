import { format } from 'date-fns';
import { UPCOMING, COMPLETED, AVAILABLE } from 'mocks/study';

export function syncDate(isoDateString) {
    const [year, month, day] = isoDateString.split('T')[0].split('-');

    const date = new Date();
    date.setDate(day);
    date.setMonth(month - 1);
    date.setFullYear(year);
    date.setMinutes(date.getMinutes() - 500);

    return date;
}

export const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

/**
 *
 * @param {number} num
 * @returns Time stamp based on the number passed in e.g input 1 will return 01:00 PM
 */
export const numberToTime = (num) => {
    const date = new Date();
    date.setHours(num, 0, 0);
    return format(date, 'p');
};

export const minutesBetweenDates = (startDate, endDate) => {
    var diff = endDate.getTime() - startDate.getTime();
    return diff / 60000;
};

// HyperWallet expects YYYY-MM-DD date format
export const toHyperWalletTimeFormat = (date) => {
    return new Date(date).toISOString().slice(0, 10);
};

export const getTimelineDateTitles = (dateString) => {
    // TODO: remove this when the date are fixed from backend
    if (dateString === UPCOMING) {
        return UPCOMING;
    }
    if (dateString === AVAILABLE) {
        return AVAILABLE;
    }
    if (dateString === COMPLETED) {
        return COMPLETED;
    }

    const date = new Date(dateString);
    const dayOfWeek = weekday[date.getDay()]?.toUpperCase();
    const month = months[date.getMonth()]?.toUpperCase();
    return `${dayOfWeek}, ${date.getDay()} ${month}, ${date.getFullYear()}`;
};

export const daysBetweenDates = (startDate, endDate) =>
    Math.floor(
        (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
            Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) /
            (1000 * 60 * 60 * 24)
    );

export const calculateAge = (dateOfBirth) => {
    const date = new Date(dateOfBirth);
    const today = new Date();
    const m = today.getMonth() - date.getMonth();
    let age = today.getFullYear() - date.getFullYear();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
        age--;
    }
    return age;
};

/* This helper method will format date like dd-mm-yyyy */
export const dateFormatter = (date, separatorSymbol = '-') => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join(separatorSymbol);
};

// yyyy-mm-dd
export function formatDate(date) {
    return date.toISOString().split('T')[0];
}

// "yyyy-mm-ddT00:00:00.000Z"
export function toISOString(date) {
    return date.toISOString().split('T')[0] + 'T00:00:00.000Z';
}
