import { Box, CircularProgress, styled } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { APP_ROUTES, useRedirections } from 'navigation';
import { ContactPreference } from 'pages/onboarding/Phone/ContactPreference';
import { PhoneInvalid } from 'pages/onboarding/Phone/PhoneInvalid';
import { PhoneReachedLimit } from 'pages/onboarding/Phone/PhoneReachedLimit';
import { PhoneVerified } from 'pages/onboarding/Phone/PhoneVerified';
import { VerifyPhone } from 'pages/onboarding/Phone/VerifyPhone';
import { PHONE_VERIFICATION_STEPS } from 'pages/onboarding/Phone/constants';
import { useSendOTP } from 'pages/onboarding/Phone/hooks';
import { PARTICIPANT_STATUS } from 'pages/onboarding/constants';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

export function PhonePage() {
    const [step, setStep] = useState(PHONE_VERIFICATION_STEPS.CONTACT_PREFERENCE);
    const [phone, setPhone] = useState(null);

    const {
        isCaregiver,
        isLoading: isPersonLoading,
        status,
        hasPhone,
        phoneVerified,
        refetch: refetchPerson
    } = useGetPerson();
    const patient = useGetPatient();
    const { redirectToProgress } = useRedirections();

    const { mutate: sendOtp, isLoading: isSendOtpLoading } = useSendOTP({
        onSuccess: () => {
            setStep(PHONE_VERIFICATION_STEPS.VERIFY_PHONE);
        },
        onError: (error) => {
            if (error === 422) {
                setStep(PHONE_VERIFICATION_STEPS.PHONE_VERIFIED);
                return;
            }

            if (error === 429) {
                setStep(PHONE_VERIFICATION_STEPS.REACHED_LIMIT);
                return;
            }
        }
    });

    const editPersonAttributesMutation = useEditPersonAttributesMutation();

    function redirectToProgressPage() {
        if (isCaregiver) {
            patient?.refetch();
        }

        refetchPerson();
        redirectToProgress(true);
    }

    const { isLoading: isUpdatingPersonAttributes } = editPersonAttributesMutation[1];
    const isLoading = isPersonLoading || isUpdatingPersonAttributes || isSendOtpLoading;

    if (isLoading) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flex={1}
                height='100vh'
                bgcolor='primary.gray20'
            >
                <CircularProgress />
            </Box>
        );
    }

    if (status !== PARTICIPANT_STATUS.ENROLLED || (hasPhone && Boolean(phoneVerified))) {
        return <Navigate to={APP_ROUTES.ONBOARDING} replace />;
    }

    const components = {
        [PHONE_VERIFICATION_STEPS.CONTACT_PREFERENCE]: (props) => (
            <ContactPreference
                phone={phone}
                setPhone={setPhone}
                editPersonAttributesMutation={editPersonAttributesMutation}
                onSkip={redirectToProgressPage}
                onPhoneAttributeSaved={sendOtp}
            />
        ),
        [PHONE_VERIFICATION_STEPS.VERIFY_PHONE]: () => (
            <VerifyPhone
                sendOtp={sendOtp}
                onSuccess={() => setStep(PHONE_VERIFICATION_STEPS.PHONE_VERIFIED)}
                onError={() => setStep(PHONE_VERIFICATION_STEPS.PHONE_INVALID)}
                onCancel={() => setStep(PHONE_VERIFICATION_STEPS.CONTACT_PREFERENCE)}
            />
        ),
        [PHONE_VERIFICATION_STEPS.PHONE_VERIFIED]: () => <PhoneVerified onClick={redirectToProgressPage} />,
        [PHONE_VERIFICATION_STEPS.PHONE_INVALID]: () => (
            <PhoneInvalid onClick={() => setStep(PHONE_VERIFICATION_STEPS.VERIFY_PHONE)} />
        ),
        [PHONE_VERIFICATION_STEPS.REACHED_LIMIT]: () => (
            <PhoneReachedLimit onClick={() => setStep(PHONE_VERIFICATION_STEPS.VERIFY_PHONE)} />
        )
    };

    const Component = components[step];

    return (
        <Container>
            <Component />
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: theme.palette.primary.gray20
}));
