import { styled, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'images/logo.svg';

export const ContentWrapper = styled('div')(({ theme }) => ({
    padding: '40px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '375px',
    boxShadow: 24,
    borderRadius: '8px',
    background: theme.palette.primary.white,
    textAlign: 'center'
}));

export const AcmeLogo = styled(Logo)({
    width: '102px',
    height: '70px'
});

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray100,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '140%',
    textAlign: 'center',
    marginTop: '32px',
    marginBottom: '20px'
}));

export const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.gray4,
    fontSize: '14px',
    marginBottom: '20px'
}));

export const ButtonContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: 20
}));

export const StyledLink = styled('div')(({ theme }) => ({
    fontSize: '14px',
    fontWeight: '600',
    textDecoration: 'none',
    marginTop: '27px',
    color: theme.palette.primary.fuchsia75,
    cursor: 'pointer'
}));
