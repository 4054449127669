import { Divider, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { FilledButton, LinkButton } from 'components/Buttons';
import { TextField } from 'components/Input/TextField';

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '40px 40px 20px',
  width: 500,
  background: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.04)',
  borderRadius: 2,
  boxSizing: 'border-box'
}));

export const StyledDisclaimerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'space-between',
  color: theme.palette.primary.gray75,
  marginTop: 20,
}));

export const StyledTextField = styled((props) => <TextField {...props} />)(
  () => ({
    marginTop: 30,
  })
);

export const StyledTypography = styled(Typography)(({ theme, textAlign }) => ({
  fontStyle: 'normal',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '145%',
  color: theme.palette.primary.gray75,
  textAlign,
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  color: theme.palette.primary.gray15,
  marginTop: 30,
  marginLeft: -40,
  marginRight: -40,
}));

export const ActionsStyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'space-between',
  justifyContent: 'flex-end',
  color: theme.palette.primary.gray75,
  marginTop: 20,
}));

export const StyledSaveFilledButton = styled(FilledButton)(({ theme }) => ({
  minWidth: 134,
  fontWeight: 600,
  '& .MuiButton-startIcon': {
    fontWeight: 900
  }
}));

export const StyledCancelButton = styled(LinkButton)(({ theme }) => ({
  minWidth: 134,
}));

export const StyledInfoCircleBox = styled(Box)(({ theme }) => ({
  marginRight: 10,
  paddingTop: 4,
}));
