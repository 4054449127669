import { useGetPatient, useGetPerson } from 'authentication';
import { FilledButton, LinkButton } from 'components/Buttons';
import { useLoading } from 'components/loading';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { ReactComponent as Fail } from 'images/shared/fail-exclamationmark.svg';
import { useRedirections } from 'navigation';
import { useTranslation } from 'react-i18next';
import { Container, DeclineTokenizeDataWrapper, HelperText, PageTitle, Row } from './styles';

function DeclineTokenizeData({ handleClose }) {
    const { t } = useTranslation();
    const { id: personId, isCaregiver, refetch: personRefetch } = useGetPerson();
    const { id: patientId, refetch: patientRefetch } = useGetPatient();
    const [editPersonAttributes] = useEditPersonAttributesMutation();
    const { setIsLoading } = useLoading();
    const { redirectToTokenizationSuccess } = useRedirections();

    const handleDeclineTokenizeDataSubmit = async () => {
        setIsLoading(true);

        const payload = [
            {
                data: {
                    type: 'person-attributes',
                    attributes: {
                        attribute: 'tokenization_consent',
                        value: 'withdrawn'
                    }
                }
            }
        ];

        if (isCaregiver) {
            await editPersonAttributes({
                personId: patientId,
                payload
            });

            patientRefetch();
        } else {
            await editPersonAttributes({
                personId: personId,
                payload
            });

            personRefetch();
        }

        setIsLoading(false);
        redirectToTokenizationSuccess();
    };

    const onConfirm = () => {
        handleDeclineTokenizeDataSubmit();
        handleClose();
    };
    const onCancel = () => {
        handleClose();
    };

    return (
        <DeclineTokenizeDataWrapper>
            <Container>
                <Row>
                    <Fail width={200} height={198} />
                </Row>
                <Row>
                    <PageTitle>{t('profile:withdraw:tokenize-modal:title')}</PageTitle>
                </Row>
                <Row>
                    <HelperText>{t('profile:withdraw:tokenize-modal:subtitle')}</HelperText>
                </Row>
                <Row>
                    <FilledButton background='#E16058' onClick={onConfirm} fullWidth>
                        {t('profile:withdraw:tokenize-modal:yes')}
                    </FilledButton>
                </Row>
                <Row>
                    <LinkButton color='#E16058' onClick={onCancel} fullWidth>
                        {t('profile:withdraw:tokenize-modal:cancel')}
                    </LinkButton>
                </Row>
            </Container>
        </DeclineTokenizeDataWrapper>
    );
}

export default DeclineTokenizeData;
