export const PARTICIPANT_STATUS = {
    ELIGIBLE: 'eligible',
    DISQUALIFIED: 'disqualified',
    ONBOARDED: 'onboarded',
    CONSENTED: 'consented',
    AUTHENTICATED: 'authenticated',
    REGISTERED: 'registered',
    ENROLLED: 'enrolled',
    WITHDRAWN: 'withdrawn'
};
