function isLocalhost() {
    const hostname = window.location.hostname;
    return hostname === '127.0.0.1' || hostname === 'localhost';
}

export const getBaseURL = () => {
    if (isLocalhost()) {
        return process.env.REACT_APP_API_ROOT;
    }
    return window.__API_ROOT__;
};

export const getAppDomain = () => {
    if (isLocalhost()) {
        return process.env.REACT_APP_AUTH_URL;
    }
    return window.__AUTH0_APP_DOMAIN__;
};

export const getAuth0ClientId = () => {
    if (isLocalhost()) {
        return process.env.REACT_APP_AUTH0_CLIENT_ID;
    }
    return window.__AUTH0_CLIENT_ID__;
};

export const getAudience = () => {
    if (isLocalhost()) {
        return process.env.REACT_APP_AUDIENCE;
    }
    return window.__AUTH0_AUDIENCE__;
};

export const getHealthClientId = () => {
    if (isLocalhost()) {
        return process.env.REACT_APP_ONE_UP_HEALTH_CLIENT_ID;
    }
    return window.__ONE_UP_HEALTH_CLIENT_ID__;
};
