import { TextButton } from './TextButton';

export const FilledButton = ({ children, ...rest }) => {
  return <TextButton variant='contained' {...rest}>{children}</TextButton>;
};

/*
  Example Usage

  <FilledButton
    color="primary"
    size="large"
  >
    Button
  </FilledButton>

  <FilledButton
    color="primary"
    size="large"
    endIcon={<ArrowForwardIcon />}
  >
    Button
  </FilledButton>

  <FilledButton
    color="primary"
    size="large"
    startIcon={<ArrowForwardIcon />}
  >
    Button
  </FilledButton>

  color: primary | secondary | tertiary
*/