import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Wrapper } from './styles';

export const RadioLabel = ({ label, value, checked, onClick, isReverse }) => (
    <Wrapper selected={checked} onClick={() => onClick(value)}>
        {isReverse ? null : <span>{label}</span>}
        <Radio onChange={onClick} checked={checked} value={value} checkedIcon={<CheckIcon />} />
        {isReverse ? <span>{label}</span> : null}
    </Wrapper>
);

RadioLabel.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    checked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

RadioLabel.defaultProps = {
    label: 'Name of option 1',
    value: -1,
    checked: false
};
