/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { useLoading } from 'components/loading';
import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { US_STATES } from 'pages/onboarding/PersonalInformation/forms/PersonalInformationForm/mock';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PersonalInformationForm } from './PersonalInformationForm';

export function PersonalInformationFormLogic({ setShowIgAN }) {
    const dispatch = useDispatch();
    const person = useGetPerson();
    const patient = useGetPatient();
    const { setIsLoading } = useLoading();
    const { t } = useTranslation();

    const state = patient.state in US_STATES ? patient.state : person.state in US_STATES ? person.state : '';
    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            address_1: patient.address_1 ?? person.address_1 ?? '',
            address_2: patient.address_2 ?? person.address_2 ?? '',
            city: patient.city ?? person.city ?? '',
            state,
            zip_code: patient.zip_code ?? person.zip_code ?? '',
            timezone: patient.timezone ?? person.timezone ?? ''
        }
    });

    async function handleSubmit(data) {
        dispatch(setValues({ personalInformation: data }));
        setShowIgAN(true);
    }

    const isLoading =
        person.isLoading ||
        !person.isSuccess ||
        (person.role === 'caregiver' && (patient.isLoading || !patient.isSuccess));

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        form.reset({
            address_1: patient.address_1 ?? person.address_1 ?? '',
            address_2: patient.address_2 ?? person.address_2 ?? '',
            city: patient.city ?? person.city ?? '',
            state,
            zip_code: patient.zip_code ?? person.zip_code ?? '',
            timezone: patient.timezone ?? person.timezone ?? ''
        });
    }, [person.id, patient.id]);

    if (isLoading) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h2' textAlign='center' gutterBottom>
                    {t('onboarding:personalInformation:title')}
                </Typography>

                <Typography color='primary.gray75' variant='pl3' component='p' textAlign='center' lineHeight={'150%'}>
                    {t('onboarding:personalInformation:subtitle')}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <PersonalInformationForm form={form} onSubmit={handleSubmit} />
            </Grid>
        </Grid>
    );
}
