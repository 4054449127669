import { Box, Typography } from '@mui/material';
import { useGetPerson } from 'authentication';
import { useEffect } from 'react';
import { CustomSlider } from './styles';

export const VerticalSlider = ({ q, healthValue, onAnswer, setHealthValue }) => {
    const { isCaregiver } = useGetPerson();
    useEffect(() => onAnswer({ [q.id]: healthValue }), []);
    const listItems = isCaregiver ? q.caregiverDescriptionItems : q.descriptionItems;
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <Box sx={{ textAlign: 'left', flex: 3, paddingTop: '50px' }}>
                    <Typography variant='pl3'>
                        <ul>
                            {listItems.map((item, i) => {
                                return <li dangerouslySetInnerHTML={{ __html: item }} key={i}></li>;
                            })}
                        </ul>
                    </Typography>

                    <Typography variant='h6' sx={{ marginTop: '40px', marginLeft: '20px' }}>
                        {q.valueText}{' '}
                        <Typography sx={{ display: 'inline' }} fontWeight={600} color='primary.main'>
                            {healthValue}
                        </Typography>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        textAlign: 'center',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography marginBottom={5} variant='pb3'>
                        {q.topLabel}
                    </Typography>
                    <CustomSlider
                        sx={{ marginRight: '0px' }}
                        aria-label='Temperature'
                        orientation='vertical'
                        valueLabelDisplay='on'
                        defaultValue={30}
                        marks={q.marks}
                        value={healthValue}
                        onChange={(e) => {
                            setHealthValue(e.target.value);
                        }}
                        onChangeCommitted={(e) => {
                            onAnswer({ [q.id]: healthValue });
                        }}
                    />
                    <Typography marginTop={5} variant='pb3'>
                        {q.bottomLabel}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};
