export const isValidDate = (date) => {
    if (date === undefined) {
        return false;
    }

    const parsedDate = new Date(date);
    const now = new Date();

    const currentYear = now.getFullYear();

    return (
        parsedDate instanceof Date &&
        !isNaN(parsedDate) &&
        currentYear - 100 <= parsedDate.getFullYear() &&
        parsedDate < now
    );
};
