import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
    maxWidth: '1080px',
    margin: '0 auto',
    backgroundColor: theme.palette.primary.gray5,
    padding: '4em',
    display: 'flex',
    boxSizing: 'border-box',
    gap: '2em',
    flexDirection: 'column',
    background:
        'linear-gradient(180deg, #E3F0F8 0%, rgba(218, 236, 247, 0) 100%)',
    [theme.breakpoints.down('md')]: {
        padding: '4em 2em',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '4em 1em',
    },
}));

export const InquireContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 10px',
    backgroundColor: theme.palette.primary.white,
}));
