import { Box, CircularProgress, Stack, styled, Typography } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { ButtonWithCheck, GhostButtonWithLeftArrow } from 'configUI/components/Buttons';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import {
    useFetchAllPatientTasksQuery,
    useGetParticipantGoalStatsQuery
} from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { useRedirections } from 'navigation';
import { useActiveActivity } from 'pages/Main';
import { MEDICATIONS_STEPS, useMedications } from 'pages/Medications/MedicationsProvider';
import { useActivities } from 'pages/NewActivities/Provider';
import { useParticipantsTasksData } from 'pages/Study/useParticipantsTasksData';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function getDate(date) {
    if (date instanceof Date) {
        return date.toISOString().split('T')[0] + 'T12:00:00.000Z';
    }

    return null;
}

export default function CompletedMedications() {
    const { t } = useTranslation();

    const { medications, setStep } = useMedications();
    const { id: personId, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();
    const { activeActivity } = useActiveActivity();
    const { redirectToActivities, redirectToTaskThankYou } = useRedirections();
    const [updateParticipantTask, { isLoading: isParticipantTaskEntryLoading }] = useUpdateParticipantTaskMutation();
    const { refetch: refetchParticipantsTasksData } = useParticipantsTasksData();

    const finalId = isCaregiver ? patientId : personId;

    const { refetch: refetchParticipantsTasksDataV1 } = useFetchAllPatientTasksQuery(finalId, {
        skip: !finalId
    });

    const { refetch: refetchPayments } = useGetParticipantGoalStatsQuery(finalId, {
        skip: !finalId
    });

    const [isSubmiting, setIsSubmiting] = useState(false);
    const isLoading = isParticipantTaskEntryLoading || isSubmiting;
    const { fetchActivities } = useActivities();

    const participantTaskId = activeActivity.id;
    const subtasks = activeActivity.subtasks;

    async function submitMedications() {
        setIsSubmiting(true);

        const formatedMedications = medications.reduce(
            (acc, { medication, start_date, end_date, isPreviousMedication, ...rest }) => {
                return {
                    ...acc,
                    [medication]: {
                        ...rest,
                        start_date: start_date instanceof Date ? start_date.toISOString() : null,
                        end_date: end_date instanceof Date ? end_date.toISOString() : null
                    }
                };
            },
            {}
        );

        await updateParticipantTask({
            participantTaskId,
            payload: {
                data: {
                    type: 'participant-tasks',
                    id: String(participantTaskId),
                    attributes: {
                        status: 'completed',
                        entry: formatedMedications
                    }
                }
            }
        });

        refetchParticipantsTasksDataV1();
        refetchPayments();
        await fetchActivities(personId);
        await refetchParticipantsTasksData();
        setIsSubmiting(false);

        if (Array.isArray(subtasks)) {
            redirectToTaskThankYou();
        } else {
            redirectToActivities();
        }
    }

    if (isLoading) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flex={1}
                height='100dvh'
                bgcolor='primary.gray20'
                width='100dvw'
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Wrapper>
            <Typography variant='h1' color='#444444' mb='30px'>
                {t('activities:medications:completed-medications:title')}
            </Typography>

            <pre style={{ textWrap: 'wrap', whiteSpace: 'pre-wrap' }}>
                <Typography variant='pl3' color='primary.gray75'>
                    {t('activities:medications:completed-medications:description').join('')}
                </Typography>
            </pre>

            <Stack direction='row' justifyContent='space-between' width='100%'>
                <GhostButtonWithLeftArrow onClick={() => setStep(MEDICATIONS_STEPS.LIST)} disabled={isLoading}>
                    {t('activities:medications:completed-medications:buttons:previous')}
                </GhostButtonWithLeftArrow>

                <ButtonWithCheck onClick={submitMedications} disabled={isLoading}>
                    {t('activities:medications:completed-medications:buttons:finalize')}
                </ButtonWithCheck>
            </Stack>
        </Wrapper>
    );
}

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '700px',
    paddingTop: '25px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
        margin: '10px',
        padding: '10px'
    }
}));
