/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, FormControl, Grid, MenuItem, Typography } from '@mui/material';
import { useCheckUserStatus, useGetCurrentUser, useGetPatient, useGetPerson } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { StyledFormLabel } from 'components/Input/TextField.styles';
import { PPRLogo as Logo } from 'components/PPRLogo';
import { ErrorWrapper } from 'configUI/components';
import { StyledInputLabel } from 'configUI/components/TextArea';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import {
    useEditPersonAttributesMutation,
    useEditPersonMutation
} from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { useRedirections } from 'navigation';
import { PaymentEarned } from 'pages/onboarding/PaymentEarned';
import { Reactivation } from 'pages/onboarding/Reactivation';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { APP_ROUTES } from 'utils/constants';
import {
    StyledSelect,
    WrappedTextMenuItem,
    getPlaceholderStyles
} from '../PersonalInformation/forms/PersonalInformationForm/styles';
import { PARTICIPANT_STATUS } from '../constants';
import { Container, Field, Form, Row, Wrapper } from './styled';

export function DemographicInformation() {
    const { isLiteVersion } = useGetCurrentUser();
    const person = useGetPerson();
    const patient = useGetPatient();
    const { role } = person;
    const { t } = useTranslation();

    const { redirectToError } = useRedirections();
    const [editPersonAttribute, { isError: isPersonAttributesError }] = useEditPersonAttributesMutation();
    const [editPerson, { isErro: isPersonError }] = useEditPersonMutation();
    const [showEligible, setShowEligible] = useState(false);
    const personalInformation = useSelector((state) => state.user.personalInformation);
    const iganInformation = useSelector((state) => state.user.igan);
    const { verifyUserStatus } = useCheckUserStatus();

    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            sex_at_birth: patient.sex_at_birth ?? person.sex_at_birth ?? '',
            gender: '',
            race: '',
            ethnicity: ''
        }
    });

    const { control, formState, handleSubmit } = form;
    const { errors, isSubmitting, isValid } = formState;
    const disabled = isSubmitting || !isValid;

    async function onSubmit(data) {

        const isUserStatusValid = await verifyUserStatus(PARTICIPANT_STATUS.CONSENTED);
        if (!isUserStatusValid) return;

        try {
            const {
                city,
                state,
                timezone,
                zip_code,
                sex_at_birth,
                gender,
                race,
                ethnicity,
                address_1,
                address_2,
                igan_diagnosis_age,
                igan_has_participated
            } = {
                city: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'city',
                            value: personalInformation.city
                        }
                    }
                },
                state: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'state',
                            value: personalInformation.state
                        }
                    }
                },
                timezone: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'timezone',
                            value: personalInformation.timezone
                        }
                    }
                },
                zip_code: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'postal_code',
                            value: personalInformation.zip_code
                        }
                    }
                },
                sex_at_birth: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'sex_at_birth',
                            value: data.sex_at_birth
                        }
                    }
                },
                gender: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'gender',
                            value: data.gender
                        }
                    }
                },
                race: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'race',
                            value: data.race
                        }
                    }
                },
                ethnicity: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'ethnicity',
                            value: data.ethnicity
                        }
                    }
                },
                address_1: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'address_1',
                            value: personalInformation.address_1
                        }
                    }
                },
                address_2: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'address_2',
                            value: personalInformation.address_2
                        }
                    }
                },
                igan_diagnosis_age: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'age_at_IgAN_diagnosis',
                            value: Number(iganInformation.age)
                        }
                    }
                },
                igan_has_participated: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'clinical_trial_participation',
                            value: iganInformation.options.join(',')
                        }
                    }
                }
            };

            if (role === 'caregiver') {
                const patientAttributes = [
                    sex_at_birth,
                    gender,
                    race,
                    ethnicity,
                    igan_diagnosis_age,
                    igan_has_participated
                ];
                const caregiverAttributes = [city, state, timezone, zip_code];
                const attributesPromises = [];

                if (personalInformation.address_1) {
                    caregiverAttributes.push(address_1);
                }

                if (personalInformation.address_2) {
                    caregiverAttributes.push(address_2);
                }

                const editPatientAttributesRequest = editPersonAttribute({
                    personId: patient.id,
                    payload: patientAttributes
                });

                attributesPromises.push(editPatientAttributesRequest);

                const editCaregiverttributesRequest = editPersonAttribute({
                    personId: person.id,
                    payload: caregiverAttributes
                });

                attributesPromises.push(editCaregiverttributesRequest);
                // We need to await attributes because there were some timing issues sending attributes to the call center
                const result = await Promise.allSettled(attributesPromises);
                const hasErrors = result.some((query) => query.status === 'rejected');

                if (hasErrors) {
                    redirectToError();
                    return;
                }

                const editPatientStatus = await editPerson({
                    personId: patient.id,
                    payload: {
                        data: {
                            type: 'people',
                            id: String(patient.id),
                            attributes: {
                                state: 'enrollment',
                                status: 'onboarded',
                                status_reason: 'ini'
                            }
                        }
                    }
                });

                if (editPatientStatus.error) {
                    redirectToError();
                    return;
                }
            } else {
                const attributes = [
                    city,
                    state,
                    timezone,
                    zip_code,
                    sex_at_birth,
                    gender,
                    race,
                    ethnicity,
                    igan_diagnosis_age,
                    igan_has_participated
                ];

                if (personalInformation.address_1) {
                    attributes.push(address_1);
                }

                if (personalInformation.address_2) {
                    attributes.push(address_2);
                }

                const editPersonAttributesRequest = await editPersonAttribute({
                    personId: person.id,
                    payload: attributes
                });

                if (editPersonAttributesRequest.error) {
                    redirectToError();
                    return;
                }

                // EDIT CAREGIVER STATUS
                const editParticipantStatus = await editPerson({
                    personId: person.id,
                    payload: {
                        data: {
                            type: 'people',
                            id: String(person.id),
                            attributes: {
                                state: 'enrollment',
                                status: 'onboarded',
                                status_reason: 'ini'
                            }
                        }
                    }
                });

                if (editParticipantStatus.error) {
                    redirectToError();
                    return;
                }
            }

            setShowEligible(true);
        } catch (error) {
            redirectToError();
        }
    }

    useEffect(() => {
        form.reset({
            sex_at_birth: patient.sex_at_birth ?? person.sex_at_birth ?? '',
            gender: '',
            race: '',
            ethnicity: ''
        });
    }, [person.id, patient.id]);

    useEffect(() => {
        if (isPersonAttributesError || isPersonError) {
            redirectToError();
        }
    }, [isPersonAttributesError, isPersonError]);

    if (!personalInformation) {
        return <Navigate to={APP_ROUTES.PERSONAL_INFO} replace />;
    }

    if (showEligible) {
        // If the user was in the lite version, that means they have already been paid ergo we do not have to show the payment screen but the reactivation screen
        if (isLiteVersion) {
            return <Reactivation />;
        }

        return <PaymentEarned />;
    }

    return (
        <Container>
            <Wrapper>
                <Logo />

                <Typography variant='h2' mt='1.625rem' width='50%' textAlign='center' mb='0.3125rem'>
                    {t('onboarding:demographic:title')}
                </Typography>

                <Typography variant='pl1' textAlign='center' width='90%' color='primary.gray75' mb='1.875rem'>
                    {t('onboarding:demographic:subtitle')}
                </Typography>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Field>
                            <StyledFormLabel shrink={false}>
                                {' '}
                                {t('onboarding:demographic:labels:sex-at-birth')}
                            </StyledFormLabel>
                            <ErrorWrapper>
                                <FormControl fullWidth>
                                    <Controller
                                        name='sex_at_birth'
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        error={errors.sex_at_birth}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <>
                                                    <StyledInputLabel
                                                        sx={getPlaceholderStyles(field.value === '')}
                                                        id='sex_at_birth_label'
                                                    >
                                                        {t('onboarding:demographic:placeholders:sex-at-birth')}
                                                    </StyledInputLabel>
                                                    <StyledSelect
                                                        fullWidth
                                                        {...field}
                                                        error={!!fieldState.error}
                                                        label={t('onboarding:demographic:placeholders:sex-at-birth')}
                                                        labelId='sex_at_birth_label'
                                                        disabled={isSubmitting}
                                                    >
                                                        {Object.entries(t('onboarding:demographic:sex-at-birth')).map(
                                                            ([key, value]) => (
                                                                <MenuItem key={key} value={key}>
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </StyledSelect>
                                                </>
                                            );
                                        }}
                                    />
                                </FormControl>
                            </ErrorWrapper>
                        </Field>

                        <Field>
                            <StyledFormLabel shrink={false}>
                                {t('onboarding:demographic:labels:gender')}
                            </StyledFormLabel>
                            <ErrorWrapper>
                                <FormControl fullWidth>
                                    <Controller
                                        name='gender'
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        error={errors.gender}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <>
                                                    <StyledInputLabel
                                                        sx={getPlaceholderStyles(field.value === '')}
                                                        id={t('onboarding:demographic:placeholders:gender')}
                                                    >
                                                        {t('onboarding:demographic:placeholders:gender')}
                                                    </StyledInputLabel>
                                                    <StyledSelect
                                                        fullWidth
                                                        {...field}
                                                        error={!!fieldState.error}
                                                        label={t('onboarding:demographic:placeholders:gender')}
                                                        labelId={t('onboarding:demographic:placeholders:gender')}
                                                        disabled={isSubmitting}
                                                    >
                                                        {Object.entries(t('onboarding:demographic:gender')).map(
                                                            ([key, value]) => (
                                                                <WrappedTextMenuItem key={key} value={key}>
                                                                    {value}
                                                                </WrappedTextMenuItem>
                                                            )
                                                        )}
                                                    </StyledSelect>
                                                </>
                                            );
                                        }}
                                    />
                                </FormControl>
                            </ErrorWrapper>
                        </Field>
                    </Row>

                    <Row>
                        <Field>
                            <StyledFormLabel shrink={false}>{t('onboarding:demographic:labels:race')}</StyledFormLabel>
                            <ErrorWrapper>
                                <FormControl fullWidth>
                                    <Controller
                                        name='race'
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        error={errors.race}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <>
                                                    <StyledInputLabel
                                                        sx={getPlaceholderStyles(field.value === '')}
                                                        id='race_label'
                                                    >
                                                        {t('onboarding:demographic:placeholders:race')}
                                                    </StyledInputLabel>
                                                    <StyledSelect
                                                        fullWidth
                                                        {...field}
                                                        error={!!fieldState.error}
                                                        label={t('onboarding:demographic:placeholders:race')}
                                                        labelId='race_label'
                                                        disabled={isSubmitting}
                                                    >
                                                        {Object.entries(t('onboarding:demographic:race')).map(
                                                            ([key, value]) => (
                                                                <MenuItem key={key} value={key}>
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </StyledSelect>
                                                </>
                                            );
                                        }}
                                    />
                                </FormControl>
                            </ErrorWrapper>
                        </Field>

                        <Field>
                            <StyledFormLabel shrink={false}>
                                {t('onboarding:demographic:labels:ethnicity')}
                            </StyledFormLabel>
                            <ErrorWrapper>
                                <FormControl fullWidth>
                                    <Controller
                                        name='ethnicity'
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        error={errors.ethnicity}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <>
                                                    <StyledInputLabel
                                                        sx={getPlaceholderStyles(field.value === '')}
                                                        id='ethnicity_label'
                                                    >
                                                        {t('onboarding:demographic:placeholders:ethnicity')}
                                                    </StyledInputLabel>
                                                    <StyledSelect
                                                        fullWidth
                                                        {...field}
                                                        error={!!fieldState.error}
                                                        label={t('onboarding:demographic:placeholders:ethnicity')}
                                                        labelId='ethnicity_label'
                                                        disabled={isSubmitting}
                                                    >
                                                        {Object.entries(t('onboarding:demographic:ethnicity')).map(
                                                            ([key, value]) => (
                                                                <MenuItem key={key} value={key}>
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </StyledSelect>
                                                </>
                                            );
                                        }}
                                    />
                                </FormControl>
                            </ErrorWrapper>
                        </Field>
                    </Row>

                    <Grid item xs={12} mt='1.5rem' width='100%'>
                        <FilledButton
                            disabled={disabled}
                            {...(isSubmitting
                                ? {
                                    disabled: true,
                                    startIcon: <CircularProgress size={24} color='secondary' />
                                }
                                : {})}
                            type='submit'
                            width='100%'
                        >
                            Next
                        </FilledButton>
                    </Grid>
                </Form>
            </Wrapper>
        </Container>
    );
}
