import { APP_ROUTES } from 'navigation';
import PhoneInstructions from 'pages/AdHoc/Phone/PhoneInstructions';
import { PhoneQuestionnaire } from 'pages/AdHoc/Phone/PhoneQuestionnaire';
import { useActiveActivity } from 'pages/Main';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

export function PhoneQuestionnairePage() {
    const { activeActivity } = useActiveActivity();
    const [isInstructions, setIsInstructions] = useState(true);

    if (!activeActivity) {
        return <Navigate to={APP_ROUTES.ACTIVITIES} replace />;
    }

    if (isInstructions) {
        return <PhoneInstructions setIsInstructions={setIsInstructions} />;
    }

    return <PhoneQuestionnaire activeActivity={activeActivity} setIsInstructions={setIsInstructions} />;
}
