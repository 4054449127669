import { styled } from '@mui/system';
import { BaseLayout } from 'components/BaseLayout';
import { FilledButton } from 'components/Buttons';

export const StyledBaseLayout = styled(BaseLayout)(({ theme }) => ({
    width: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.primary.gray20,
        // Let BaseLayout grow
        '& > .BaseLayout-scrollable': {
            // backgroundColor: theme.palette.primary.white,
            boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
            borderRadius: '8px'
        },
        // Remove sticky header behavior
        '& > .BaseLayout-header': {
            position: 'initial',
            borderBottom: 'none'
        }
    },

    [theme.breakpoints.down('md')]: {
        // marginBottom: '68px'
    }
}));

export const StyledFilledButton = styled(FilledButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.gray5,
    color: theme.palette.primary.main,
    '&:hover': {
        color: 'white'
    }
}));
