import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import { useGetPerson } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { CustomizedDatePicker } from 'components/DatePicker';
import { ErrorWrapper } from 'configUI/components';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidDate } from 'validations';
import { StyledFormLabel } from './styles';

const DatePicker = styled(CustomizedDatePicker)(({ theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E9E9E9'
    }
}));

export const PatientPrescreeningForm = ({ form, onSubmit }) => {
    const { isLoading: isPersonLoading, isFetching: isPersonFetching } = useGetPerson();
    const { control, formState, handleSubmit } = form;
    const { errors, isSubmitting, isValid } = formState;
    const disabled = isSubmitting || !isValid || isPersonLoading || isPersonFetching;
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StyledFormLabel>{t('onboarding:prescreening.patient:labels:date-of-birth')}</StyledFormLabel>
                    <ErrorWrapper>
                        <Controller
                            name='date_of_birth'
                            control={control}
                            rules={{
                                required: true,
                                validate: (v) => isValidDate(v) || t('onboarding:prescreening.errors.valid-date')
                            }}
                            error={errors.date_of_birth}
                            render={({ field, fieldState }) => {
                                const minDate = new Date();
                                minDate.setFullYear(minDate.getFullYear() - 100);

                                return (
                                    <DatePicker
                                        disableFuture
                                        minDate={minDate}
                                        error={!!fieldState.error}
                                        fullWidth
                                        placeholder={t('onboarding:prescreening.patient:placeholders:date-of-birth')}
                                        {...field}
                                    />
                                );
                            }}
                        />
                    </ErrorWrapper>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <StyledFormLabel>{t('onboarding:prescreening.patient:labels:had-tarpeyo')}</StyledFormLabel>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name='patient_had_tarpeyo'
                            error={errors.patient_had_tarpeyo}
                            render={({ field: props }) => {
                                const radioButtonStyles = (theme) => ({
                                    color: theme.palette.grey[500],
                                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                                        color: theme.palette.primary.gray3
                                    },
                                    '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                        color: theme.palette.primary.main
                                    }
                                });

                                return (
                                    <RadioGroup onChange={(e) => props.onChange(e.target.value)} {...props}>
                                        <Stack
                                            direction='row'
                                            alignItems='center'
                                            justifyContent='center'
                                            spacing='10px'
                                            width='100%'
                                        >
                                            <Box
                                                flex={1}
                                                px='14px'
                                                border={1}
                                                borderColor='primary.gray3'
                                                borderRadius='6px'
                                            >
                                                <FormControlLabel
                                                    control={<Radio sx={radioButtonStyles} size='small' />}
                                                    // We are using 1 because true was causing problems in some SQL queries
                                                    value={1}
                                                    label={
                                                        <Typography variant='pn3'>
                                                            {t('onboarding:prescreening.patient:labels:yes')}
                                                        </Typography>
                                                    }
                                                />
                                            </Box>

                                            <Box
                                                flex={1}
                                                px='14px'
                                                border={1}
                                                borderColor='primary.gray3'
                                                borderRadius='6px'
                                            >
                                                <FormControlLabel
                                                    control={<Radio sx={radioButtonStyles} size='small' />}
                                                    // We are using 0 because true was causing problems in some SQL queries
                                                    value={0}
                                                    label={
                                                        <Typography variant='pn3'>
                                                            {t('onboarding:prescreening.patient:labels:no')}
                                                        </Typography>
                                                    }
                                                />
                                            </Box>
                                        </Stack>
                                    </RadioGroup>
                                );
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FilledButton
                        {...(isSubmitting
                            ? {
                                  disabled: true,
                                  startIcon: <CircularProgress size={24} color='secondary' />
                              }
                            : {})}
                        disabled={disabled}
                        type='submit'
                        width='100%'
                    >
                        {t('onboarding:prescreening.patient:buttons:save')}
                    </FilledButton>
                </Grid>
            </Grid>
        </form>
    );
};
