import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '145%',
  color: theme.palette.secondary.gray4,
  cursor: 'pointer',
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '145%',
  color: theme.palette.primary.gray50,
  cursor: 'pointer',
}));

export const StyledProfileCard = styled(Box)(({ theme }) => ({
  width: '100%',
  minWidth: '300px',
  outline: `1px solid ${theme.palette.primary.gray5}`,
  gap: '15px',
  boxSizing: 'border-box',
  padding: '15px',
  backgroundColor: theme.palette.primary.white,
  borderRadius: '7px',
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    outlineColor: theme.palette.primary.main,
  },
  '&.active': {
    outlineColor: theme.palette.primary.main,
    outlineWidth: "2px"
  },
}));

export const StyledIcon = styled(Box)(({ theme }) => ({
  flex: '0 0 50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.gray50,
  color: theme.palette.primary.white,
  borderRadius: '25px',
  '&.active': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StyledContent = styled((props) => (
  <Stack {...props} spacing='2px' />
))({ flex: '1 1 auto' });

export const StyledAction = styled(Box)({
  flex: '0 0 40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});
