export const surveyDataEN = {
    0: {
        id: 0,
        type: 'intro',
        title: 'Health Questionnaire',
        subtitle: 'English version for the USA',
        proxy: 'Proxy version of the EQ-5D-5L: 1'
    },
    1: {
        id: 1,
        title: 'Please select the ONE box that best describes your health TODAY.',
        caregiverTitle:
            'Please select the ONE box that you think best describes the person’s health TODAY. You should not answer on behalf of the person, but rather rate the person’s health as you see it.',
        type: 'radio',
        label: 'MOBILITY',
        answer: '',
        values: [
            {
                label: 'I have no problems in walking about',
                caregiverLabel: 'No problems walking',
                value: '1'
            },
            {
                label: 'I have slight problems in walking about ',
                caregiverLabel: 'Slight problems walking',
                value: '2'
            },
            {
                label: 'I have moderate problems in walking about',
                caregiverLabel: 'Moderate problems walking',
                value: '3'
            },
            {
                label: 'I have severe problems in walking about',
                caregiverLabel: 'Severe problems walking',
                value: '4'
            },
            {
                label: 'I am unable to walk about',
                caregiverLabel: 'Unable to walk',
                value: '5'
            }
        ]
    },
    2: {
        id: 2,
        title: 'Please select the ONE box that best describes your health TODAY.',
        caregiverTitle:
            'Please select the ONE box that you think best describes the person’s health TODAY. You should not answer on behalf of the person, but rather rate the person’s health as you see it.',
        type: 'radio',
        answer: '',
        label: 'SELF-CARE',
        values: [
            {
                label: 'I have no problems washing or dressing myself ',
                caregiverLabel: 'No problems washing or dressing him/herself',
                value: '1',
                shortcut: ''
            },
            {
                label: 'I have slight problems washing or dressing myself ',
                caregiverLabel: 'Slight problems washing or dressing  him/herself',
                value: '2',
                shortcut: ''
            },
            {
                label: 'I have moderate problems washing or dressing myself ',
                caregiverLabel: 'Moderate problems washing or dressing  him/herself',
                value: '3',
                shortcut: ''
            },
            {
                label: 'I have severe problems washing or dressing myself ',
                caregiverLabel: 'Severe problems washing or dressing  him/herself',
                value: '4',
                shortcut: ''
            },
            {
                label: 'I am unable to wash or dress myself ',
                caregiverLabel: 'Unable to wash or dress  him/herself',
                value: '5',
                shortcut: ''
            }
        ]
    },
    3: {
        id: 3,
        title: 'Please select the ONE box that best describes your health TODAY.',
        caregiverTitle:
            'Please select the ONE box that you think best describes the person’s health TODAY. You should not answer on behalf of the person, but rather rate the person’s health as you see it.',
        type: 'radio',
        label: 'USUAL ACTIVITIES (e.g. work, study, housework, family or leisure activities)',
        answer: '',
        values: [
            {
                label: 'I have no problems doing my usual activities',
                caregiverLabel: 'No problems doing his/her usual activities',
                value: '1'
            },
            {
                label: 'I have slight problems doing my usual activities ',
                caregiverLabel: 'Slight problems doing his/her usual activities',
                value: '2'
            },
            {
                label: 'I have moderate problems doing my usual activities',
                caregiverLabel: 'Moderate problems doing his/her usual activities',
                value: '3'
            },
            {
                label: 'I have severe problems doing my usual activities',
                caregiverLabel: 'Severe problems doing his/her usual activities',
                value: '4'
            },
            {
                label: 'I am unable to do my usual activities',
                caregiverLabel: 'Unable to do his/her usual activities',
                value: '5'
            }
        ]
    },
    4: {
        id: 4,
        title: 'Please select the ONE box that best describes your health TODAY.',
        caregiverTitle:
            'Please select the ONE box that you think best describes the person’s health TODAY. You should not answer on behalf of the person, but rather rate the person’s health as you see it.',
        type: 'radio',
        label: 'PAIN / DISCOMFORT',
        answer: '',
        values: [
            {
                label: 'I have no pain or discomfort',
                caregiverLabel: 'No pain or discomfort',
                value: '1'
            },
            {
                label: 'I have slight pain or discomfort',
                caregiverLabel: 'Slight pain or discomfort',
                value: '2'
            },
            {
                label: 'I have moderate pain or discomfort',
                caregiverLabel: 'Moderate pain or discomfort',
                value: '3'
            },
            {
                label: 'I have severe pain or discomfort',
                caregiverLabel: 'Severe pain or discomfort',
                value: '4'
            },
            {
                label: 'I have extreme pain or discomfort',
                caregiverLabel: 'Extreme pain or discomfort',
                value: '5'
            }
        ]
    },
    5: {
        id: 5,
        title: 'Please select the ONE box that best describes your health TODAY.',
        caregiverTitle:
            'Please select the ONE box that you think best describes the person’s health TODAY. You should not answer on behalf of the person, but rather rate the person’s health as you see it.',
        type: 'radio',
        label: 'ANXIETY / DEPRESSION',
        answer: '',
        values: [
            {
                label: 'I am not anxious or depressed',
                caregiverLabel: 'Not anxious or depressed',
                value: '1'
            },
            {
                label: 'I am slightly anxious or depressed',
                caregiverLabel: 'Slightly anxious or depressed',
                value: '2'
            },
            {
                label: 'I am moderately anxious or depressed',
                caregiverLabel: 'Moderately anxious or depressed',
                value: '3'
            },
            {
                label: 'I am severely anxious or depressed',
                caregiverLabel: 'Severely anxious or depressed',
                value: '4'
            },
            {
                label: 'I am extremely anxious or depressed',
                caregiverLabel: 'Extremely anxious or depressed',
                value: '5'
            }
        ]
    },
    6: {
        id: 6,
        title: '',
        type: 'vertical-slider',
        topLabel: 'The best health you can imagine',
        bottomLabel: 'The worst health you can imagine',
        descriptionItems: [
            'We would like to know how good or bad your health is TODAY.',
            'You will see a scale numbered from 0 to 100.',
            `100 means the <u>best</u> health you can imagine.<br />0 means the <u>worst</u> health you can imagine.`,
            'Please indicate on the scale how your health is TODAY.'
        ],
        caregiverDescriptionItems: [
            `We would like to know how good or bad the person's health is TODAY.`,
            'You will see a scale numbered from 0 to 100.',
            `100 means the <u>best</u> health you can imagine.<br/>0 means the <u>worst</u> health you can imagine.`,
            `Please indicate on the scale how you think the person's health is TODAY.`
        ],
        valueText: 'Your Health Today: ',
        marks: [
            {
                value: 0,
                label: '0'
            },
            {
                value: 10,
                label: '10'
            },
            {
                value: 20,
                label: '20'
            },
            {
                value: 30,
                label: '30'
            },
            {
                value: 40,
                label: '40'
            },
            {
                value: 50,
                label: '50'
            },
            {
                value: 60,
                label: '60'
            },
            {
                value: 70,
                label: '70'
            },
            {
                value: 80,
                label: '80'
            },
            {
                value: 90,
                label: '90'
            },
            {
                value: 100,
                label: '100'
            }
        ]
    }
};
