import { Box, styled } from '@mui/material';
import { useGetPerson } from 'authentication';
import { PARTICIPANT_STATUS } from 'pages/onboarding';
import { Logo } from '../assets';
import { NavIcon } from './NavIcon';

export function NavDesktop({ items, onClick, cancelModal }) {
    const { status } = useGetPerson();

    return (
        <>
            {cancelModal}
            <Container>
                <PerformRegistryLogo />

                <Icons>
                    {items.map((props) =>
                        status === PARTICIPANT_STATUS.WITHDRAWN && props.hideWhenWithdrawn ? null : (
                            <NavIcon onClick={onClick(props)} {...props} />
                        )
                    )}
                </Icons>
            </Container>
        </>
    );
}

const Container = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '90px',
    padding: '1.25rem 0',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    zIndex: 2
}));

const PerformRegistryLogo = styled(Logo)(({ theme }) => ({
    marginBottom: 'auto'
}));

const Icons = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    gap: '2.5rem',
    marginBottom: 'auto'
}));
