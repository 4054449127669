import { InputAdornment } from '@mui/material';
import { TextInput } from 'components/Input';
import { ReactComponent as PhoneIcon } from 'images/shared/phone-1.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function PhoneInput({ phone, setPhone, autoFocus = true, ...rest }) {
    const [formattedPhone, setFormattedPhone] = useState(formatPhoneNumber(phone));
    const { t } = useTranslation();

    function handleInputChange(event) {
        const inputText = event.target.value;
        const cleanedInput = inputText.replace(/\D/g, '').slice(0, 10);
        const formatted = formatPhoneNumber(cleanedInput);
        setFormattedPhone(formatted);
        setPhone(cleanedInput);
    }

    function formatPhoneNumber(phoneNumber) {
        const cleaned = String(phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (!cleaned) {
            return '';
        }

        if (match) {
            return '(' + match[1] + (match[2] ? ') ' : '') + match[2] + (match[3] ? '-' : '') + match[3];
        }

        return phoneNumber;
    }

    return (
        <TextInput
            value={formattedPhone}
            onChange={handleInputChange}
            placeholder={t('onboarding:phone:common:placeholder')}
            autoFocus={autoFocus}
            fullWidth
            endAdornment={
                <InputAdornment position='end'>
                    <PhoneIcon fill='#CACED0' width={'20px'} />
                </InputAdornment>
            }
            {...rest}
        />
    );
}

export function isValidPhoneNumber(phoneNumber) {
    return String(phoneNumber).replace(/\D/g, '').length === 10;
}
