export const ACTION_TYPES = {
    QUESTION_UPDATE: 'QUESTION_UPDATE',
};

export const INITIAL_STATE = {
    answers: {}, // [{id: 1, value: '1'}]
};

export const reducerFn = (state, action) => {
    switch (action.type) {
        case 'QUESTION_UPDATE': {
            return {
                ...state,
                answers: { ...state.answers, ...action.payload },
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};
