export const surveyDataES = {
    0: {
        id: 0,
        type: 'intro',
        title: 'Cuestionario de salud',
        subtitle: 'Versión en español para EE.UU.',
        proxy: 'Versión proxy del EQ-5D-5L: 1'
    },
    1: {
        id: 1,
        title: 'Por favor, seleccione la casilla que mejor describa su salud HOY.',
        caregiverTitle:
            'Por favor, seleccione UNA casilla que crea que describe mejor la salud de la persona HOY. No debe responder en nombre de la persona, sino valorar su estado de salud tal y como usted lo ve.',
        type: 'radio',
        label: 'MOVILIDAD',
        answer: '',
        values: [
            {
                label: 'No tengo problemas en caminar',
                caregiverLabel: 'Sin problemas para caminar',
                value: '1'
            },
            {
                label: 'Tengo ligeros problemas para caminar',
                caregiverLabel: 'Ligeros problemas para caminar',
                value: '2'
            },
            {
                label: 'Tengo problemas moderados para caminar',
                caregiverLabel: 'Problemas moderados para caminar',
                value: '3'
            },
            {
                label: 'Tengo graves problemas para caminar',
                caregiverLabel: 'Problemas graves para caminar',
                value: '4'
            },
            {
                label: 'No puedo caminar',
                caregiverLabel: 'Incapaz de caminar',
                value: '5'
            }
        ]
    },
    2: {
        id: 2,
        title: 'Por favor, seleccione la casilla que mejor describa su salud HOY.',
        caregiverTitle:
            'Por favor, seleccione UNA casilla que crea que describe mejor la salud de la persona HOY. No debe responder en nombre de la persona, sino calificar su estado de salud tal y como usted lo ve.',
        type: 'radio',
        answer: '',
        label: 'AUTOCUIDADO',
        values: [
            {
                label: 'No tengo problemas para lavarme o vestirme',
                caregiverLabel: 'No tiene problemas para lavarse o vestirse',
                value: '1',
                shortcut: ''
            },
            {
                label: 'Tengo ligeros problemas para lavarme o vestirme ',
                caregiverLabel: 'Ligeros problemas para lavarse o vestirse',
                value: '2',
                shortcut: ''
            },
            {
                label: 'Tengo problemas moderados para lavarme o vestirme ',
                caregiverLabel: 'Problemas moderados para lavarse o vestirse',
                value: '3',
                shortcut: ''
            },
            {
                label: 'Tengo graves problemas para lavarme o vestirme ',
                caregiverLabel: 'Problemas graves para lavarse o vestirse',
                value: '4',
                shortcut: ''
            },
            {
                label: 'Soy incapaz de lavarme o vestirme ',
                caregiverLabel: 'Incapaz de lavarse o vestirse solo',
                value: '5',
                shortcut: ''
            }
        ]
    },
    3: {
        id: 3,
        title: 'Por favor, seleccione la casilla que mejor describa su salud HOY.',
        caregiverTitle:
            'Por favor, seleccione UNA casilla que crea que describe mejor la salud de la persona HOY. No debe responder en nombre de la persona, sino calificar su estado de salud tal y como usted lo ve.',
        type: 'radio',
        label: 'ACTIVIDADES HABITUALES (por ejemplo, trabajo, estudio, tareas domésticas, actividades familiares o de ocio)',
        answer: '',
        values: [
            {
                label: 'No tengo problemas para realizar mis actividades habituales',
                caregiverLabel: 'No tiene problemas para realizar sus actividades habituales',
                value: '1'
            },
            {
                label: 'Tengo ligeros problemas para realizar mis actividades habituales ',
                caregiverLabel: 'Ligeros problemas para realizar sus actividades habituales',
                value: '2'
            },
            {
                label: 'Tengo problemas moderados para realizar mis actividades habituales',
                caregiverLabel: 'Problemas moderados para realizar sus actividades habituales',
                value: '3'
            },
            {
                label: 'Tengo graves problemas para realizar mis actividades habituales',
                caregiverLabel: 'Problemas graves para realizar sus actividades habituales',
                value: '4'
            },
            {
                label: 'No puedo realizar mis actividades habituales',
                caregiverLabel: 'No puede realizar sus actividades habituales',
                value: '5'
            }
        ]
    },
    4: {
        id: 4,
        title: 'Por favor, seleccione la casilla que mejor describa su salud HOY.',
        caregiverTitle:
            'Por favor, seleccione UNA casilla que crea que describe mejor la salud de la persona HOY. No debe responder en nombre de la persona, sino calificar su estado de salud tal y como usted lo ve.',
        type: 'radio',
        label: 'DOLOR / MALESTAR',
        answer: '',
        values: [
            {
                label: 'No tengo dolor ni molestias',
                caregiverLabel: 'Sin dolor ni molestias',
                value: '1'
            },
            {
                label: 'Tengo dolor o molestias leves',
                caregiverLabel: 'Ligero dolor o malestar',
                value: '2'
            },
            {
                label: 'Tengo dolor o molestias moderadas',
                caregiverLabel: 'Dolor o malestar moderado',
                value: '3'
            },
            {
                label: 'Tengo dolor o molestias graves',
                caregiverLabel: 'Dolor o molestias graves',
                value: '4'
            },
            {
                label: 'Tengo dolor o molestias extremas',
                caregiverLabel: 'Dolor o malestar extremo',
                value: '5'
            }
        ]
    },
    5: {
        id: 5,
        title: 'Por favor, seleccione la casilla que mejor describa su salud HOY.',
        caregiverTitle:
            'Por favor, seleccione UNA casilla que crea que describe mejor la salud de la persona HOY. No debe responder en nombre de la persona, sino calificar su estado de salud tal y como usted lo ve.',
        type: 'radio',
        label: 'ANSIEDAD / DEPRESIÓN',
        answer: '',
        values: [
            {
                label: 'No estoy ansioso ni deprimido',
                caregiverLabel: 'No está ansioso ni deprimido',
                value: '1'
            },
            {
                label: 'Estoy ligeramente ansioso o deprimido',
                caregiverLabel: 'Ligeramente ansioso o deprimido',
                value: '2'
            },
            {
                label: 'Estoy moderadamente ansioso o deprimido',
                caregiverLabel: 'Moderadamente ansioso o deprimido',
                value: '3'
            },
            {
                label: 'Estoy muy ansioso o deprimido',
                caregiverLabel: 'Ansiedad o depresión graves',
                value: '4'
            },
            {
                label: 'Estoy muy ansioso o deprimido',
                caregiverLabel: 'Extremadamente ansioso o deprimido',
                value: '5'
            }
        ]
    },
    6: {
        id: 6,
        title: '',
        type: 'vertical-slider',
        topLabel: 'La mejor salud que pueda imaginar',
        bottomLabel: 'La peor salud que puedas imaginar',
        descriptionItems: [
            'Nos gustaría saber lo bien o mal que está su salud HOY.',
            'Verá una escala numerada de 0 a 100.',
            `100 significa la <u>mejor</u> salud que puedas imaginar.<br />0 significa la <u>peor</u> salud que puedas imaginar.`,
            'Por favor, indique en la escala cómo se encuentra su salud HOY.'
        ],
        caregiverDescriptionItems: [
            `Nos gustaría saber lo buena o mala que es la salud de la persona HOY.`,
            'Verá una escala numerada de 0 a 100.',
            `100 significa la <u>mejor</u> salud que puedas imaginar.<br/>0 significa la <u>peor</u> salud que puedas imaginar.`,
            `Por favor, indique en la escala cómo cree que está la salud de la persona HOY.`
        ],
        valueText: 'Su salud hoy: ',
        marks: [
            {
                value: 0,
                label: '0'
            },
            {
                value: 10,
                label: '10'
            },
            {
                value: 20,
                label: '20'
            },
            {
                value: 30,
                label: '30'
            },
            {
                value: 40,
                label: '40'
            },
            {
                value: 50,
                label: '50'
            },
            {
                value: 60,
                label: '60'
            },
            {
                value: 70,
                label: '70'
            },
            {
                value: 80,
                label: '80'
            },
            {
                value: 90,
                label: '90'
            },
            {
                value: 100,
                label: '100'
            }
        ]
    }
};
