import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Divider } from 'components/Profile/ConsentItem/styles';
import { ReactComponent as ArrowRight } from 'images/shared/arrow-right.svg';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

const ActionTypography = ({ flex, onClick, ...rest }) => (
    <Stack justifyContent='center' alignItems='center' flex={flex} onClick={onClick}>
        <Typography variant='pn5' align='center' color='primary.main' {...rest} />
    </Stack>
);

const MandatoryActions = ({ isDocusign, ...rest }) => {
    const downSm = useMediaQuery(useTheme().breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <ActionTypography flex={1} {...rest}>
            {isDocusign ? t('components:consent-item:review-and-sign') : 'Review & sign consent'}
            {downSm && (
                <>
                    {' '}
                    <ArrowRight width='16px' height='12px' paddingLeft='10px' />
                </>
            )}
        </ActionTypography>
    );
};

const OptionalActions = ({ handleReject, ...rest }) => (
    <Stack direction={{ sm: 'column', xs: 'row' }} divider={<Divider />} flex={1} {...rest}>
        <ActionTypography flex={1} color='primary.gray75' onClick={handleReject}>
            Reject
        </ActionTypography>
        <ActionTypography flex={1}>Review</ActionTypography>
    </Stack>
);

export const Actions = ({ isDocusign, isMandatory, onNavigateToConsentDetails, onRejectConsent, ...rest }) => {
    return (
        <Container {...rest}>
            {isMandatory ? (
                <MandatoryActions isDocusign={isDocusign} />
            ) : (
                <OptionalActions handleReject={onRejectConsent} />
            )}
        </Container>
    );
};
