import { Box } from '@mui/system';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';
import { ErrorMessage } from './styles';

export const ErrorWrapper = ({ children, manualMessage, ...rest }) => {
  let message = children?.props?.error?.message || "This field is required";
  // let message = manualMessage ? manualMessage : 'This field is required';
// if (children?.props?.error?.type === 'custom') {
//   message = children.props.error.message;
//   }
// if (children?.props?.error?.type === 'custom') {
//   message = children.props.error.message;
//   }
  return (
    <Box {...rest}>
      {children}
    {(children?.props?.error || children?.props?.error) && (
        <ErrorMessage>
          <InformationIcon />
          {message}
        </ErrorMessage>
      )}
    </Box>
  );
};
