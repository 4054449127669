import { Box, Radio, RadioGroup, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Check from 'images/components/check.svg';
import CircleCheck from 'images/components/circle-check.svg';

const StyledRadio = styled(Radio)(({ theme, checked }) => ({
  padding: '0 0 0 0',
  '& .StyledLabel': {
    padding: '15px 15px 15px 50px',
    minHeight: '50px',
    width: '100%',

    background: theme.palette.primary.white,
    backgroundImage: `url(${CircleCheck})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '18px 50%',

    color: theme.palette.primary.gray100,
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '17px',
    textTransform: 'none',
    textAlign: 'left',
    wordWrap: 'normal',
    opacity: 0.8,

    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.gray15,
    borderRadius: '6px',
    position: 'relative',

    boxSizing: 'border-box',
    resize: 'none',
  },
  '&:hover': {
    '& .StyledLabel:not(.StyledLabel-checked)': {
      borderColor: theme.palette.primary.fuchsia75,
    },
  },
  '& .StyledLabel-checked': {
    backgroundImage: `url(${Check})`,
    fontWeight: 600,
    color: theme.palette.primary.fuchsia75,
    boxShadow: `0 0 0 2px ${theme.palette.primary.fuchsia75}`,
    borderColor: 'rgba(0, 0, 0, 0)',
  },
}));

export const RadioInput = (props) => {
  const { label, ...rest } = props;
  return (
    <StyledRadio
      disableRipple
      color='default'
      checkedIcon={
        <Box className='StyledLabel StyledLabel-checked'>{label}</Box>
      }
      icon={<Box className='StyledLabel'>{label}</Box>}
      {...rest}
    />
  );
};

export const RadioGroupInput = ({
  children,
  direction = 'column',
  optionsStyles,
  ...rest
}) => (
  <RadioGroup {...rest}>
    <Stack spacing='15px' direction={direction} sx={optionsStyles}>
      {children}
    </Stack>
  </RadioGroup>
);
