import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { ButtonWithArrow } from 'configUI/components/Buttons';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { LANGUAGES } from 'internationalization';
import { useRedirections } from 'navigation';
import { useActivities } from 'pages/NewActivities/Provider';
import { useParticipantsTasksData } from 'pages/Study/useParticipantsTasksData';
import { IgANForm } from 'pages/onboarding/NewIgAN/Page';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyProgressBar } from '../../EQ5D5L/survey-progress-bar';
import { ButtonWrapper, ContentWrapper, FilledButton, SurveyWrapper, TextButton } from '../styles.js';
import { surveyDataEN } from './surveyDataEN';
import { surveyDataES } from './surveyDataES';

export const IgANQuestionnaire = ({ activeActivity, setIsInstructions }) => {
    const participantTaskId = activeActivity.id;
    const { isCaregiver, id: personId, refetch: refetchPerson } = useGetPerson();
    const { id: patientId, refetch: refetchPatient } = useGetPatient();
    const { t, i18n } = useTranslation();

    const surveyData = i18n.language === LANGUAGES.SPANISH ? surveyDataES : surveyDataEN;

    const [isSubmiting, setIsSubmiting] = useState(false);
    const isLoading = isSubmiting;
    const { redirectToThankYou, redirectToError } = useRedirections();
    const [options, setOptions] = useState([]);
    const [age, setAge] = useState();

    const finalId = isCaregiver ? patientId : personId;
    const [currentStep, setCurrentStep] = useState(0);
    const { fetchActivities } = useActivities();

    const [updateParticipantTask, { isError: isUpdateParticipantTaskError }] = useUpdateParticipantTaskMutation();
    const [editPersonAttribute] = useEditPersonAttributesMutation();
    const { refetch: refetchParticipantsTasksData } = useParticipantsTasksData();

    const containerRef = useRef(null);

    async function handleSubmit() {
        try {
            setIsSubmiting(true);
            const { igan_diagnosis_age, igan_has_participated } = {
                igan_diagnosis_age: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'age_at_IgAN_diagnosis',
                            value: Number(age)
                        }
                    }
                },
                igan_has_participated: {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'clinical_trial_participation',
                            value: options.join(',')
                        }
                    }
                }
            };

            if (isCaregiver) {
                const patientAttributes = [igan_diagnosis_age, igan_has_participated];
                const attributesPromises = [];

                const editPatientAttributesRequest = editPersonAttribute({
                    personId: patientId,
                    payload: patientAttributes
                });

                attributesPromises.push(editPatientAttributesRequest);
                // We need to await attributes because there were some timing issues sending attributes to the call center
                const result = await Promise.allSettled(attributesPromises);
                const hasErrors = result.some((query) => query.status === 'rejected');

                if (hasErrors) {
                    redirectToError();
                    return;
                } else {
                    try {
                        await updateParticipantTask({
                            participantTaskId,
                            payload: {
                                data: {
                                    type: 'participant-tasks',
                                    id: String(participantTaskId),
                                    attributes: {
                                        status: 'completed'
                                    }
                                }
                            }
                        });

                        if (!isUpdateParticipantTaskError) {
                            fetchActivities(finalId);
                            refetchParticipantsTasksData();
                            refetchPatient();
                            setIsSubmiting(false);
                            redirectToThankYou();
                        } else {
                            setIsSubmiting(false);
                            redirectToError();
                        }
                    } catch (error) {
                        redirectToError();
                    }

                    redirectToThankYou();
                    return;
                }
            } else {
                const attributes = [igan_diagnosis_age, igan_has_participated];

                const editPersonAttributesRequest = await editPersonAttribute({
                    personId: personId,
                    payload: attributes
                });

                if (editPersonAttributesRequest.error) {
                    redirectToError();
                    return;
                } else {
                    await updateParticipantTask({
                        participantTaskId,
                        payload: {
                            data: {
                                type: 'participant-tasks',
                                id: String(participantTaskId),
                                attributes: {
                                    status: 'completed'
                                }
                            }
                        }
                    });

                    if (!isUpdateParticipantTaskError) {
                        fetchActivities(finalId);
                        refetchParticipantsTasksData();
                        refetchPerson();
                        setIsSubmiting(false);
                        redirectToThankYou();
                    } else {
                        setIsSubmiting(false);
                        redirectToError();
                    }
                }
            }
        } catch (error) {
            setIsSubmiting(false);
            redirectToError();
        }
    }

    const onNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const onPrevious = () => {
        if (currentStep === 0) {
            return setIsInstructions(true);
        }

        setCurrentStep(currentStep - 1);
    };

    const onFinalize = () => {
        handleSubmit();
    };

    useEffect(() => {
        containerRef?.current?.scrollTo(0, 0);
    }, [currentStep]);

    if (isSubmiting) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flex={1}
                height='100vh'
                bgcolor='primary.gray20'
                width='100%'
            >
                <CircularProgress />
            </Box>
        );
    }

    const disabled = !age || age < 1 || age > 100 || options.length === 0;

    return (
        <SurveyWrapper ref={containerRef}>
            <SurveyProgressBar numberOfSteps={1} current={1} />

            <ContentWrapper>
                {currentStep === 0 && (
                    <>
                        <Typography variant='h6' mb='20px'>
                            {t('activities:adhoc:instruction:igan:question')}
                        </Typography>

                        <Box padding='30px' bgcolor='white' borderRadius='8px'>
                            <IgANForm options={options} setOptions={setOptions} age={age} setAge={setAge} />
                        </Box>
                    </>
                )}

                {currentStep === 1 && (
                    <Box display='flex' flexDirection='column'>
                        <Typography variant='h2' textAlign='center' mb='30px'>
                            {t('activities:adhoc:final:title')}
                        </Typography>

                        <Typography variant='pl3' color='primary.gray75' textAlign='left' mb='20px'>
                            {t('activities:adhoc:final:description1')}
                        </Typography>

                        <Typography variant='pl3' color='primary.gray75' textAlign='left'>
                            {t('activities:adhoc:final:description2')}
                        </Typography>
                    </Box>
                )}

                <ButtonWrapper>
                    <TextButton disabled={isLoading} onClick={onPrevious} startIcon={<ArrowBackIcon />}>
                        {t('activities:eq5d5l:buttons:previous')}
                    </TextButton>

                    {currentStep === 0 && (
                        <ButtonWithArrow
                            variant='contained'
                            disabled={currentStep === Object.keys(surveyData).length || isLoading || disabled}
                            onClick={onNext}
                        >
                            {t('activities:eq5d5l:buttons:next')}
                        </ButtonWithArrow>
                    )}

                    {currentStep === 1 && (
                        <FilledButton
                            disabled={isLoading}
                            sx={{}}
                            variant='contained'
                            onClick={onFinalize}
                            startIcon={<CheckIcon />}
                        >
                            {t('activities:eq5d5l:buttons:finalize')}
                        </FilledButton>
                    )}
                </ButtonWrapper>
            </ContentWrapper>
        </SurveyWrapper>
    );
};
