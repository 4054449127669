import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const userApiSliceV3 = createApi({
  reducerPath: 'userApiSliceV3',
  baseQuery: baseQuery('v3'),
  tagTypes: ['User', 'CurrentUser'],
  endpoints: (build) => ({
    getCurrentUser: build.query({
      query: () => ({
        url: '/users/current',
        method: 'GET',
      }),
      providesTags: ['CurrentUser'],
      extraOptions: {
        maxRetries: 3,
      },
    }),
    getUserAttributes: build.query({
      query: (userId) => ({
        url: `/users/${userId}/attributes`,
        method: 'GET',
      }),
      providesTags: ['UserAttributes'],
    }),
    resendVerificationEmail: build.query({
      query: (userId) => ({
        url: `/users/${userId}/resend-verification-emails`,
      }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useResendVerificationEmailQuery,
  useGetUserAttributesQuery,
} = userApiSliceV3;
