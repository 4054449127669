import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FilledButton } from 'components/Buttons';
import CelebrationScreen from 'pages/Activities/CelebrationScreen';
import { useTranslation } from 'react-i18next';
import { buttonTextStyle, StyledCheckIcon } from './style';

function FinalPageMobile({ celebrationScreen, onDone }) {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Box
            sx={{
                bgcolor: theme.palette.primary.gray20,
                height: '100vh',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                }}
            >
                <CelebrationScreen attributes={{ ...celebrationScreen }} />
                <FilledButton
                    type='submit'
                    size='large'
                    sx={{ minWidth: '303px', marginBottom: '20px', marginTop: '30px' }}
                    onClick={onDone}
                >
                    <>
                        <StyledCheckIcon />
                        <span style={buttonTextStyle}>{t('activities:emr:buttons:done')}</span>
                    </>
                </FilledButton>
            </Box>
        </Box>
    );
}

export default FinalPageMobile;
