import { createContext, useContext, useMemo, useState } from 'react';

const ShowPhoneNumberUpdateModalContext = createContext(null);

export function ShowPhoneNumberUpdateProvider({ children }) {
    const [showModal, setShowModal] = useState(false);

    const contextValue = useMemo(
        () => ({
            setShowModal,
            showModal
        }),
        [showModal]
    );

    return (
        <ShowPhoneNumberUpdateModalContext.Provider value={contextValue}>
            {children}
        </ShowPhoneNumberUpdateModalContext.Provider>
    );
}

export function useShowPhoneNumberUpdateModal() {
    const data = useContext(ShowPhoneNumberUpdateModalContext);

    if (data === null) {
        throw new Error('useShowPhoneNumberUpdateModal should be inside ShowPhoneNumberUpdateProvider');
    }

    return data;
}
