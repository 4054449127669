import { useAuth0 } from '@auth0/auth0-react';
import { useRedirections } from 'navigation';

export function SignOutPage() {
    const { logout } = useAuth0();
    const { redirectToWelcome } = useRedirections();
    localStorage.clear();
    sessionStorage.clear();
    logout();
    redirectToWelcome();
}
