import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useRedirections } from 'navigation';

export function SignInPage() {
    const { redirectToLogin } = useRedirections();
    redirectToLogin();

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flex={1}
            height='100vh'
            bgcolor='primary.gray20'
        >
            <CircularProgress />
        </Box>
    );
}
