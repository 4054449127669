import { ACTIVITY_STATUS } from 'pages/NewActivities/constants';

const today = new Date();
const TZ_DIFFERENCE = today.getTimezoneOffset() * 60 * 1000;

export function mapActivities(activities) {
    return activities
        .reduce((acc, { data: activity }) => {
            // IF THIS IS A ACTIVITY
            if (activity.relationship.length === 0) {
                const startDate = new Date(activity.start_date);
                const startDateWithTimezone = new Date(startDate.getTime() - TZ_DIFFERENCE);

                acc.push({
                    ...activity,
                    start_date: startDateWithTimezone
                });
                return acc;
            }

            // IF THIS IS A GOAL WITH SUBACTIVITIES
            const isGroupTask = activity.relationship.find((item) => item.type === 'group');
            const taskGroup = { ...activity, type: '', subtasks: [] };

            if (isGroupTask) {
                taskGroup.type = 'group';
                taskGroup.relationship.forEach((item) => {
                    const subtask = activities.find((task) => task.data.id === item.associate_participant_task_id);

                    if (!subtask) {
                        return;
                    }

                    taskGroup.subtasks.push(subtask.data);
                });

                acc.push(taskGroup);
            }

            return acc;
        }, [])
        .map((activity) => {
            if (activity.type === 'group') {
                const subtasks = activity.subtasks.map((subtask) => {
                    const startDate = new Date(subtask.start_date);
                    const startDateWithTimezone = new Date(startDate.getTime() - TZ_DIFFERENCE);
                    const endDate = new Date(subtask.end_date);

                    const status = getActivityStatusByDate({
                        status: subtask.status,
                        start_date: startDateWithTimezone,
                        end_date: endDate
                    });

                    return {
                        ...subtask,
                        start_date: startDateWithTimezone,
                        end_date: endDate,
                        status
                    };
                });

                const startDate = new Date(activity.start_date);
                const startDateWithTimezone = new Date(startDate.getTime() - TZ_DIFFERENCE);
                const endDate = new Date(activity.end_date);

                const status = getActivityStatusByDate({
                    status: activity.status,
                    start_date: startDateWithTimezone,
                    end_date: endDate
                });

                return {
                    ...activity,
                    start_date: startDateWithTimezone,
                    end_date: endDate,
                    status,
                    subtasks
                };
            }

            const startDate = new Date(activity.start_date);
            const startDateWithTimezone = new Date(startDate.getTime() - TZ_DIFFERENCE);
            const endDate = new Date(activity.end_date);

            const status = getActivityStatusByDate({
                status: activity.status,
                start_date: startDateWithTimezone,
                end_date: activity.end_date
            });

            return {
                ...activity,
                start_date: startDateWithTimezone,
                end_date: endDate,
                status
            };
        });
}

export function getActivityStatusByDate({ status, start_date, end_date }) {
    if (end_date === null && status === ACTIVITY_STATUS.INCOMPLETE) {
        return ACTIVITY_STATUS.ACTIVE;
    }

    const now = new Date();
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    if (status === ACTIVITY_STATUS.IN_PROGRESS) {
        return ACTIVITY_STATUS.IN_PROGRESS;
    }

    if (status === ACTIVITY_STATUS.COMPLETED) {
        return ACTIVITY_STATUS.COMPLETED;
    }

    if (endDate < now) {
        return ACTIVITY_STATUS.EXPIRED;
    }

    if (startDate <= now && startDate <= endDate) {
        return ACTIVITY_STATUS.ACTIVE;
    }

    if (startDate > now) {
        return ACTIVITY_STATUS.UPCOMING;
    }

    throw new Error('Unrecheable code');
}

export function isTomorrow(date) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    const tomorrowStart = tomorrow.valueOf();
    tomorrow.setHours(23, 59, 59, 999);
    const tomorrowEnd = tomorrow.valueOf();
    return date.valueOf() >= tomorrowStart && date.valueOf() <= tomorrowEnd;
}

export function isAdhocTask(task) {
    return (
        task?.title === 'Set up Preferred Language' ||
        task?.title === 'Set up SMS text notifications' ||
        task?.title === 'Complete Patient IgAN Information'
    );
}
