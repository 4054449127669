import { useGetPatient, useGetPerson } from 'authentication';
import { useFetchAllPatientTasksQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { useMemo } from 'react';

const today = new Date();
const TZ_DIFFERENCE = today.getTimezoneOffset() * 60 * 1000;

export const useParticipantsTasksData = () => {
    const { id: personId, isCaregiver, isLoading: isPersonLoading } = useGetPerson();
    const { id: patientId, isLoading: isPatientLoading } = useGetPatient();
    const finalId = isCaregiver ? patientId : personId;

    const {
        data,
        isLoading: isLoadingResults,
        isSuccess,
        refetch
    } = useFetchAllPatientTasksQuery(finalId, {
        skip: !finalId
    });

    const isLoading = isLoadingResults || isPersonLoading || (isCaregiver && isPatientLoading);

    // gets the total points in an array of tasks
    const getTasksPoints = (tasksArray) => {
        tasksArray.reduce((prevVal, currentVal) => {
            prevVal += currentVal.task.points;
            return prevVal;
        }, 0);
    };

    const results = useMemo(() => {
        if (!data) return {};
        const allTasks = data?.data || [];
        const allTasksPoints = getTasksPoints(allTasks);
        const totalTasks = allTasks?.filter((task) => task?.task.type === 'group')?.length;
        const currentDate = Date.now();
        const completedTasks = allTasks?.filter((task) => task.status === 'completed');
        const incompleteTasks = allTasks?.filter((task) => task.status === 'incomplete');
        const expiredTasks = allTasks?.filter((task) => task.status === 'expired');

        const getAllTasksByType = (type) => {
            return allTasks?.filter((task) => task.task.type === type) ?? [];
        };

        const getCompletedTasksByType = (type) => {
            return completedTasks?.filter((task) => task.task.type === type) ?? [];
        };

        const completedTasksCount = completedTasks.length;


        const availableTasks = allTasks?.filter(
            (task) =>
                new Date(new Date(task?.start_date).getTime() - TZ_DIFFERENCE) < currentDate &&
                task.status !== 'completed' &&
                task.status !== 'expired' &&
                task.status !== 'upcoming'
        );

        const upcomingTasks = allTasks?.filter((task) => new Date(new Date(task?.start_date).getTime() - TZ_DIFFERENCE) > currentDate);

        const groupTasks = getAllTasksByType('group');
        const completedGroupTasks = getCompletedTasksByType('group');

        // grouped by completion type
        const surveyTasks = getAllTasksByType('survey');
        const completedSurveyTasks = getCompletedTasksByType('survey');

        const gameTasks = getAllTasksByType('game');
        const completedGameTasks = getCompletedTasksByType('game');

        const videoTasks = getAllTasksByType('video');
        const completedVideoTasks = getCompletedTasksByType('video');

        const callTasks = getAllTasksByType('call');
        const completedCallTasks = getCompletedTasksByType('call');

        const dataSharingTasks = getAllTasksByType('data_sharing');
        const completedDataSharingTasks = getCompletedTasksByType('data_sharing');

        const callScheduleTasks = getAllTasksByType('call_schedule');
        const completedCallScheduleTasks = getCompletedTasksByType('call_schedule');

        const labTestTasks = getAllTasksByType('lab_test');
        const completedLabTestTasks = getCompletedTasksByType('lab_test');

        const devicesTasks = getAllTasksByType('devices');
        const completedDevicesTasks = getCompletedTasksByType('devices');

        return {
            // main data
            allTasks,
            completedTasks,
            incompleteTasks,
            expiredTasks,
            availableTasks,
            upcomingTasks,
            // numbers
            totalTasks,
            completedTasksCount,
            allTasksPoints,
            // grouped by task type
            groupTasks,
            surveyTasks,
            callTasks,
            dataSharingTasks,
            callScheduleTasks,
            gameTasks,
            videoTasks,
            labTestTasks,
            devicesTasks,
            // grouped by type completion
            completedGroupTasks,
            completedSurveyTasks,
            completedGameTasks,
            completedVideoTasks,
            completedCallTasks,
            completedDataSharingTasks,
            completedCallScheduleTasks,
            completedLabTestTasks,
            completedDevicesTasks
        };
    }, [data]);

    return { ...results, isLoadingResults: isLoading, isSuccess, refetch };
};
