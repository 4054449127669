import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FilledButton } from 'components/Buttons';
import { ReactComponent as HospitalIcon } from 'images/data-sync/hospital.svg';
import { ArrowBackwardIcon } from 'pages/Activities/List/styles';

export const EmptyProvider = ({ loader, hasErrors }) => {
  const title = hasErrors
    ? 'We are experiencing some difficulties'
    : 'We are still processing your data';
  const subtitle = hasErrors
    ? 'Please retry again later'
    : 'Please wait for the data to be loaded. It can take up to 24 hours.';
  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      spacing='20px'
      height='65vh'
      sx={{ bgcolor: (theme) => theme.palette.primary.gray20 }}
    >
      <HospitalIcon height={60} width={60} sx={{ alignContent: 'center' }} />
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        <Stack justifyContent='center' spacing='5px'>
          <Typography variant='h5' color='primary.gray75' align='center'>
            {title}
          </Typography>
          <Typography variant='pl5' color='primary.gray50' align='center'>
            {subtitle}
          </Typography>
          {hasErrors && (
            <Box sx={{ paddingTop: '30px', display: 'flex' }}>
              <FilledButton
                startIcon={<ArrowBackwardIcon width={14} height={14} />}
                type='submit'
                sx={{ width: '150px', margin: 'auto' }}
              >
                Go back
              </FilledButton>
            </Box>
          )}
        </Stack>
        {loader}
      </Box>
    </Stack>
  );
};
