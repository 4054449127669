import { styled, Typography } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.primary.gray20
}));

export const Container = styled('div')(({ theme }) => ({
    maxWidth: '385px',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    padding: '60px 40px 40px',
    boxSizing: 'border-box'
}));

export const Row = styled('div')(({ marginBottom }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: marginBottom || '20px'
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '140%',
    textAlign: 'center'
}));

export const HelperText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontSize: '14px'
}));

export const SuccessBox = styled('div')(({ theme }) => ({
    color: theme.palette.primary.green100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '15px',
    gap: '15px',
    background: theme.palette.primary.green5,
    border: `1px solid ${theme.palette.primary.green25}`,
    borderRadius: '8px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px'
}));

export const DollarSign = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.green100,
    fontSize: '20px',
    fontWeight: '900',
    marginRight: '5px'
}));

export const Amount = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.green100,
    fontSize: '18px',
    fontWeight: '700',
    marginLeft: '5px'
}));
