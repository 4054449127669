/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useQueryClient } from '@tanstack/react-query';
import { queryClient } from 'App';
import { useGetPatient, useGetPerson, useGetPersonRelationship } from 'authentication';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { LanguageProvider } from 'internationalization';
import { APP_ROUTES } from 'navigation';
import { isValidSourceName } from 'pages/Welcome/SourceNames';
import { Button, Container, Link, Logo, Text, Title, Wrapper } from 'pages/onboarding/VerifyEmail/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

function Page() {
    const { isLoading, getAccessTokenSilently, user } = useAuth0();
    const { isCaregiver, ...person } = useGetPerson();
    const patient = useGetPatient();
    const personRelationshipRefetch = useGetPersonRelationship();
    const { t } = useTranslation();
    const [isAuth0Loading, setIsAuth0Loading] = useState(false);
    const [editPersonAttribute] = useEditPersonAttributesMutation();
    const queryClient = useQueryClient();

    async function reverifyEmail() {
        setIsAuth0Loading(true);

        for (const key in localStorage) {
            if (key.includes('auth0')) {
                localStorage.removeItem(key);
            }
        }

        await getAccessTokenSilently();
        await createRefSourceName();
        setIsAuth0Loading(false);

        if (isCaregiver) {
            personRelationshipRefetch.refetch();
        }
    }

    const createRefSourceName = async () => {
        const finalId = isCaregiver ? patient.id : person.id;
        const sourceName = localStorage.getItem('sourcename');

        if (isValidSourceName(sourceName)) {
            const refSourceName = {
                data: {
                    type: 'person-attributes',
                    attributes: {
                        attribute: 'ref_source_name',
                        value: sourceName.toUpperCase()
                    }
                }
            };

            await editPersonAttribute({
                personId: finalId,
                payload: [refSourceName]
            });

            localStorage.removeItem('sourcename');
        }
    };

    useEffect(() => {
        if (person.isSuccess && !person.language) {
            queryClient.removeQueries('attributes');
        }
    }, [person.isSuccess, person.language]);

    if (user.email_verified) {
        return <Navigate to={APP_ROUTES.ONBOARDING} replace />;
    }

    if (person.isLoading || (person.isCaregiver && patient.isLoading)) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flex={1}
                height='100vh'
                bgcolor='primary.gray20'
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container>
            <Wrapper>
                <Logo />
                <Title>{t('onboarding:index:info:verify-email:title')}</Title>
                <Text>{t('onboarding:index:info:verify-email:subtitle')}</Text>

                <Button
                    onClick={reverifyEmail}
                    disabled={isLoading || isAuth0Loading}
                    {...(isLoading || isAuth0Loading
                        ? {
                              disabled: true,
                              startIcon: <CircularProgress size={24} color='secondary' />
                          }
                        : {})}
                >
                    {t('onboarding:index:info:verify-email:button-text')}
                </Button>

                <Link to={APP_ROUTES.WELCOME} replace>
                    {t('onboarding:index:info:verify-email:link-text')}
                </Link>
            </Wrapper>
        </Container>
    );
}

export const VerifyEmailPage = () => (
    <LanguageProvider>
        <Page />
    </LanguageProvider>
);
