import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Stack, useTheme } from '@mui/material';

import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';

import { FilledButton } from 'components/Buttons';
import { isMobile } from 'utils/isMobile';
import { useConnectHandler } from 'pages/SyncData/hooks.js';
import {
  MainContainer,
  CategoryName,
  TopContainer,
  CategoryDate,
  HeaderContainer,
  ItemContainer,
  CenterContainer,
  FlexedBox,
  IconWrapper,
  title,
} from './styles';
import {
  generateLeftProvider,
  generateRightProvider,
  getProviderData,
  next,
  prev,
  SvgCategoryImageMap,
} from './util';
import { ReactComponent as BackArrowIcon } from 'images/data-sync/back.svg';
import { ReactComponent as CheckIcon } from 'images/data-sync/check.svg';
import { ReactComponent as GreenCheck } from 'images/shared/green-check.svg';
import { ReactComponent as LinkSimple } from 'images/data-sync/link-simple.svg';
import { ReactComponent as LinkCircle } from 'images/data-sync/link-circle.svg';
import { ReactComponent as DefaultCategory } from 'images/data-sync/default-category.svg';
import { fetchSyncedData } from 'services/oneUpService';
import { EmptyProvider } from './EmptyProvider';

function ProviderPage() {
  document.title = 'ACME Steady Study EMR';
  const theme = useTheme();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const {
    state: { provider, providers, isProfile },
  } = useLocation();

  const { person_id } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person_id: data?.data?.included?.[0]?.[0]?.data?.id,
    }),
  });

  const connectHandler = useConnectHandler(
    provider,
    () => {},
    () => {},
    person_id
  );
  const [providerResources, setProviderResources] = useState({
    entries: [],
    hasErrors: false,
  });

  const fetchTokenAndProviders = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      if (person_id === null || person_id === undefined) {
        return;
      }
      const response = await fetchSyncedData(token, person_id);

      if (response != null && Array.isArray(response)) {
        let foundProviderData = response.filter(
          (pd) => pd.health_system_id === provider.id.toString()
        );

        const hasData = foundProviderData && foundProviderData.length === 1;
        if (hasData) {
          let providerResources = getProviderData(foundProviderData);
          if (providerResources) {
            setProviderResources({
              entries: providerResources,
              hasErrors: false,
            });
          } else {
            setProviderResources({
              entries: [],
              hasErrors: true,
            });
          }
        } else {
          setProviderResources({ entries: [], hasErrors: true });
        }
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTokenAndProviders();
  }, [fetchTokenAndProviders]);

  let leftProvider = null;
  let rightProvider = null;
  const providerIndex = providers?.map((p) => p.id).indexOf(provider.id);

  if (providerIndex === 0 && providers?.length > 1) {
    rightProvider = providers[1];
  } else if (providerIndex === providers?.length - 1) {
    leftProvider = providers[providerIndex - 1];
  } else {
    if (providers) {
      leftProvider = providers[providerIndex - 1];
      rightProvider = providers[providerIndex + 1];
    }
  }

  const syncDataBtn = isMobile() ? (
    <Box sx={{ marginTop: '8px' }}>
      <LinkSimple onClick={connectHandler} />
    </Box>
  ) : (
    <FilledButton
      type='submit'
      size='large'
      startIcon={<CheckIcon width={23} height={18} />}
      sx={{ minWidth: '150px', maxHeight: '44px', fontSize: '14px' }}
      onClick={connectHandler}
    >
      Sync data
    </FilledButton>
  );
  const nextPrevWidth = isMobile() ? '90%' : '70%';

  let content;
  if (providerResources?.entries?.length === 0) {
    content = <EmptyProvider hasErrors={providerResources?.hasErrors} />;
  } else {
    content = (
      <FlexedBox sx={{ marginTop: '25px' }}>
        <Stack sx={{ width: '100%', height: '72vh', overflow: 'auto' }}>
          {Object.entries(providerResources.entries).map((pc) => {
            const recordsShared = pc[1];
            const message = `${recordsShared} records shared`;
            const Icon =
              SvgCategoryImageMap[pc[0].replace(/ /g, '')] || DefaultCategory;
            return (
              <ItemContainer sx={{ bgcolor: 'white' }} key={pc[0]}>
                <FlexedBox sx={{ marginTop: '15px' }}>
                  <Box sx={{ margin: '0px 15px 15px 15px' }}>
                    {Icon && (
                      <IconWrapper>
                        <Icon width={40} height={40} />
                      </IconWrapper>
                    )}
                  </Box>
                  <Stack sx={{ display: 'flex' }}>
                    <CategoryName>{pc[0]}</CategoryName>
                    <CategoryDate>{message}</CategoryDate>
                  </Stack>
                </FlexedBox>
                <FlexedBox sx={{ margin: '25px' }}>
                  {recordsShared > 0 ? (
                    <GreenCheck width={20} height={20} />
                  ) : (
                    <LinkCircle width={20} height={20} />
                  )}
                </FlexedBox>
              </ItemContainer>
            );
          })}
        </Stack>
      </FlexedBox>
    );
  }

  return (
    <MainContainer>
      <TopContainer sx={{ bgcolor: theme.palette.primary.gray20 }}>
        <CenterContainer>
          <FlexedBox sx={{ marginTop: '54px' }}>
            <HeaderContainer>
              <FlexedBox>
                <Box sx={{ marginTop: '10px', cursor: 'pointer' }}>
                  <BackArrowIcon
                    onClick={() =>
                      isProfile
                        ? navigate('/profile/my-providers')
                        : navigate('/activity/sync-data')
                    }
                  />
                </Box>
                <Box sx={{ ...title(theme) }}>{provider.name}</Box>
              </FlexedBox>
              {!isProfile && <FlexedBox>{syncDataBtn}</FlexedBox>}
            </HeaderContainer>
          </FlexedBox>
          {content}
        </CenterContainer>
      </TopContainer>
      <Box
        sx={{
          bgcolor: theme.palette.primary.gray2,
          height: '10vh',
          width: '100%',
        }}
      >
        <FlexedBox sx={{ justifyContent: 'center' }}>
          <FlexedBox
            sx={{ width: nextPrevWidth, justifyContent: 'space-between' }}
          >
            {leftProvider ? (
              generateLeftProvider(leftProvider, providers, prev, navigate)
            ) : (
              <FlexedBox></FlexedBox>
            )}
            {rightProvider &&
              generateRightProvider(rightProvider, providers, next, navigate)}
          </FlexedBox>
        </FlexedBox>
      </Box>
    </MainContainer>
  );
}

export default ProviderPage;
