import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FilledButton } from './FilledButton';
import { LinkButton } from './LinkButton';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  backgroundColor: 'transparent',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

export const StyledFilledButton = styled(({
  linkDisabled,
  ...rest
}) => <FilledButton {...rest}/>)(({
  theme,
  linkDisabled,
}) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: linkDisabled ? '325px' : '240px',
  }
}));

export const StyledLinkButton = styled(LinkButton)(({ disabled }) => ({
  width: '100%',
  display: disabled ? 'none' : 'inherit',
  maxWidth: '135px',
}));
