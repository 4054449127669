import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

export const OutlineButton = ({ text, onClickHandler, color, borderColor, fontFamily, width, height, sx, ...rest }) => {
    const theme = useTheme();

    return (
        <Button
            sx={{
                background: theme.palette.primary.white,
                color: color ?? theme.palette.primary.fuchsia75,
                border: '1px solid',
                borderColor: borderColor ?? theme.palette.primary.fuchsia75,
                borderRadius: 2,
                width: width ?? 295,
                minHeight: height ?? 45,
                fontWeight: 'bold',
                fontFamily: fontFamily ?? 'Inter',
                textTransform: 'none',
                '&: hover': {
                    background: theme.palette.primary.white
                },
                ...sx
            }}
            onClick={onClickHandler}
            {...rest}
        >
            {text}
        </Button>
    );
};
OutlineButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired
};
