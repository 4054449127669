import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { useLoading } from 'components/loading';
import { Loader } from 'configUI/components/Shared/Loader';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
    resetInProgressProviders,
    updateFinishDataShareEnabled
} from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import {
    useFetchAllPatientTasksQuery,
    useGetParticipantGoalStatsQuery
} from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import {
    participantTask,
    selectParticipantTaskV3,
    setParticipantTask,
    setSelectedParticipantTaskAttributes,
    setSelectedParticipantTaskId
} from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { flatten } from 'lodash';
import { useRedirections } from 'navigation';
import { useActiveActivity } from 'pages/Main';
import { useActivities } from 'pages/NewActivities/Provider';
import { useParticipantsTasksData } from 'pages/Study/useParticipantsTasksData';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchConnectedProviders, fetchSyncedData, getHealthProviders } from 'services/oneUpService';
import { dateFormatter } from 'utils/date';
import { useInitiateDataFetch } from '../hooks';
import { SearchProvidersDesktop } from './index.desktop';

function SearchProvidersV2() {
    const { t } = useTranslation();
    document.title = t('activities:emr:page-title');

    const dispatch = useDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const [loadingProviders, setLoadingProviders] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [loadingConnectedProviders, setLoadingConnectedProviders] = useState(true);
    const [hasConnectedProviders, setHasConnectedProviders] = useState(false);
    const [allProviders, setAllProviders] = useState([]);
    const [filteredProviders, setFilteredProviders] = useState([]);
    const [manualProviders, setManualProviders] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const { setIsLoading } = useLoading();

    const selectedParticipantTask = useSelector(participantTask());
    const { initiate, loading } = useInitiateDataFetch();

    const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
    const { finishDataShareEnabled, inProgressProviders } = oneUpHealthData;
    const [updateParticipantTaskMutation] = useUpdateParticipantTaskMutation();

    const { redirectToActivities, redirectToShareMedicalRecordsFinal } = useRedirections();

    const [allConnectedProviders, setAllConnectedProviders] = useState({
        entries: [],
        hasErrors: false
    });

    const { person_id, isLoading: isLoadingCurrentUser } = useGetCurrentUserQuery(undefined, {
        selectFromResult: ({ data, ...rest }) => ({
            person_id: data?.data?.included?.[0]?.[0]?.data?.id,
            ...rest
        })
    });

    const { id: patientId, isLoading: isPatientLoading } = useGetPatient();
    const { id: personId, isCaregiver, isLoading: isPersonLoading } = useGetPerson();

    const finalId = isCaregiver ? patientId : personId;
    const { refetch } = useGetParticipantGoalStatsQuery(personId, {
        skip: personId ? false : true
    });

    const {
        activities: { isLoading: areActivitiesLoading }
    } = useActivities();

    const { activeActivity } = useActiveActivity();

    let taskId = activeActivity?.id;
    if (!activeActivity) {
        let act = JSON.parse(localStorage.getItem('activeActivity'));
        taskId = act.id;
    }

    const [updateParticipantTask, { isLoading: createParticipantTaskEntryLoading }] =
        useUpdateParticipantTaskMutation();

    const { refetch: refetchParticipantsTasksData } = useParticipantsTasksData();
    const { fetchActivities } = useActivities();

    const { refetch: refetchParticipantsTasksDataV1 } = useFetchAllPatientTasksQuery(finalId, {
        skip: !finalId
    });

    const pTaskById = useSelector(selectParticipantTaskV3(Number(taskId)))?.data;
    if (taskId) {
        dispatch(setSelectedParticipantTaskId({ taskId: Number(taskId) }));
    }

    // TODO: refactor all api calls to be with RTQ queries
    const fetchTokenAndProviders = useCallback(async () => {
        let connected = [inProgressProviders.filter((p) => p.logo !== 'manualProvider')];
        let hasConnected;
        let response;
        
      let connectedProvidersResponse;
      let manualProviders;
      let electronicProviders;
        const token = await getAccessTokenSilently();

        try {
            if (!finalId || !token) {
                return;
            }
            setLoadingConnectedProviders(true);

             connectedProvidersResponse = await fetchConnectedProviders(token, finalId, taskId);

        // Filter separate out both types of providers to reuse existing logic
        manualProviders = connectedProvidersResponse?.data?.filter(
            (provider) => provider?.attributes?.type === 'manual'
          );
          electronicProviders = connectedProvidersResponse?.data?.filter(
            (provider) => provider?.attributes?.type === 'electronic'
          );
  
          if (manualProviders?.length > 0) {
            let result = manualProviders.map((mp) => ({
              id: Number(mp.id),
              name: mp.attributes.provider_info.hc_organization_name,
              phone_number: mp.attributes.provider_info.phone_number,
              logo: 'manualProvider',
              locations: [
                {
                  address: {
                    city: mp.attributes.provider_info.city,
                    state: mp.attributes.provider_info.state,
                  },
                },
              ],
              participant_id: person_id,
              participantTaskId: mp.attributes.participant_task_id,
            }));
            setManualProviders(result.reverse());
          }
            response = await fetchSyncedData(token, finalId);
            const hasData = response != null && Array.isArray(response);
            let hasPreviouslyConnected = hasData && response.filter((r) => r.status === 'success').length > 0;
            hasConnected =
            inProgressProviders.length > 0 ||
            manualProviders?.length > 0 ||
            electronicProviders?.length > 0;
            setHasConnectedProviders(hasConnected);
            setLoadingConnectedProviders(false);
        } catch (error) {
            console.error(error);
            setLoadingConnectedProviders(false);
        }

        try {
            setLoadingProviders(true);
            const providers = await getHealthProviders(token);
            
            setAllProviders(providers);
            setLoadingProviders(false);

            if (hasConnected) {
                electronicProviders.forEach((connectedProvider) => {
                  const connectedId = connectedProvider?.attributes?.health_system_id;
                  const connectedProvidersParticipantTaskId =
                    connectedProvider?.attributes?.participant_task_id;
                  // TODO: Refactor this nested forEach to use provider.find() & connected.find
                  providers.forEach((provider) => {
                    if (provider.id?.toString() === connectedId) {
                      // If "health_system_id" and "participant_task_id" both are matching then it is a unique entry because of parent, child tasks can add same provider with same "health_system_id"
                      const alreadyIn = connected.find(
                        (c) =>
                          c.id?.toString() === connectedId &&
                          c.attributes?.participant_task_id ===
                            connectedProvidersParticipantTaskId
                      );
                      if (!alreadyIn) {
                        /* make sure "connectedProvider" first spread this because "connectedProvider.id" is useless but "provider.id" is used below, in case of spearding "provider" first will override actual "provider.id" with "connectedProvider" (some DB id) which will effect fetching patient names api call*/
                        connected.push({ ...connectedProvider, ...provider });
                      }
                    }
                  });
                });
              }
            setAllConnectedProviders({ entries: connected, hasErrors: false });
        } catch (error) {
            console.error(error);
            setLoadingProviders(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAccessTokenSilently, inProgressProviders, finalId]);

    useEffect(() => {
        if (finalId) {
            console.log("🚀 ~ file: index.js:190 ~ useEffect ~ finalId:", finalId)
            fetchTokenAndProviders();
        }
    }, [fetchTokenAndProviders, finalId]);

    useEffect(() => {
        if (pTaskById) {
            dispatch(setParticipantTask(pTaskById));
        }
    }, [dispatch, pTaskById]);

    const duration = JSON.parse(pTaskById.duration);

    let data = {
        type: pTaskById.task_type,
        title: pTaskById.title ?? 'Share your medical records',
        timingDuration: duration.timing_duration,
        rewardPoints: pTaskById?.point ?? 20
    };

    const { refetch: refetchPayments } = useGetParticipantGoalStatsQuery(finalId, {
        skip: !finalId
    });

    const setFiltered = () => {
        if (searchTerm?.length > 3) {
            const filteredProviders = allProviders?.filter((p) =>
                p?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
            );
            setFilteredProviders(filteredProviders);
        } else {
            setFilteredProviders([]);
        }
    };

    const updateSelectedTask = async (status) => {
        if (!selectedParticipantTask) {
            console.log('Selected participant task is missing');
            return;
        }
        const start_time = dateFormatter(new Date(selectedParticipantTask?.start_date));
        const end_time = dateFormatter(new Date());
        const requestBody = {
            participantTaskId: selectedParticipantTask.id,
            payload: {
                data: {
                    type: 'participant-tasks',
                    id: String(selectedParticipantTask.id),
                    attributes: { status, start_time, end_time }
                }
            }
        };
        updateParticipantTaskMutation(requestBody)
            .then((response) => {
                if (response?.error) {
                    console.log('Error: ', response);
                    return;
                }
                refetch();
                // save response in state to use on celebration screen
                dispatch(
                    setSelectedParticipantTaskAttributes({
                        attributes: response?.data?.data?.attributes
                    })
                );
            })
            .catch(console.error);
    };

    const handleSubmit = async () => {
        try {
            initiate();
            await updateParticipantTask({
                participantTaskId: pTaskById.id,
                payload: {
                    data: {
                        type: 'participant-tasks',
                        id: String(pTaskById.id),
                        attributes: {
                            status: 'completed',
                            entry: data
                        }
                    }
                }
            });

            refetchParticipantsTasksData();
            refetchParticipantsTasksDataV1();
            fetchActivities(personId);
            refetchPayments();

            dispatch(updateFinishDataShareEnabled(false));
            dispatch(resetInProgressProviders());

            redirectToShareMedicalRecordsFinal();
        } catch (error) {
            console.log('SearchProvidersV2 Error', error);
            redirectToActivities();
        }
    };

    // const handleFinish = async () => {
    //     dispatch(updateFinishDataShareEnabled(false));
    //     dispatch(resetInProgressProviders());
    //     if (selectedParticipantTask != null) {
    //         await updateSelectedTask('completed');
    //     }

    //     await initiate();
    //     navigate('/activity/sync-data-final');
    // };

    const handleAddMoreLater = async () => {
        setIsLoading(true);
        try {
            await updateParticipantTask({
                participantTaskId: pTaskById.id,
                payload: {
                    data: {
                        type: 'participant-tasks',
                        id: String(pTaskById.id),
                        attributes: {
                            status: 'in_progress',
                            entry: data
                        }
                    }
                }
            });

            refetchParticipantsTasksData();
            refetchParticipantsTasksDataV1();
            fetchActivities(personId);
            dispatch(updateFinishDataShareEnabled(false));
            initiate();
            setIsLoading(false);
            redirectToActivities();
        } catch (error) {
            console.log('SearchProvidersV2 Error Add more later', error);
            setIsLoading(false);
            redirectToActivities();
        }
    };

    if (loadingConnectedProviders || loading || isRedirecting || areActivitiesLoading || isPersonLoading || (isCaregiver && isPatientLoading)) {
        return (
            <Box
                sx={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Loader />
            </Box>
        );
    }

    const onAddManualProvider = (provider, oldProviderId) => {
        if (oldProviderId) {
            return setManualProviders([provider, ...manualProviders.filter((p) => p.id !== oldProviderId)]);
        } else {
            return setManualProviders([provider, ...manualProviders]);
        }
    };

    const onDeleteManualProvider = (provider) => {
        return setManualProviders([...manualProviders.filter((p) => p.id !== provider.id)]);
    };

    return (
        <SearchProvidersDesktop
            providers={filteredProviders}
            connectedProviders={flatten([...allConnectedProviders.entries, ...manualProviders])}
            loadingProviders={loadingProviders}
            setLoadingProviders={setLoadingProviders}
            setLoadingConnectedProviders={setLoadingConnectedProviders}
            setIsRedirecting={setIsRedirecting}
            setSearchTerm={setSearchTerm}
            setFiltered={setFiltered}
            handleFinish={handleSubmit}
            finishDataShareEnabled={finishDataShareEnabled}
            hasConnectedProviders={hasConnectedProviders}
            isLoading={loadingProviders}
            duration={data?.timingDuration ? `${data?.timingDuration / 60} min` : 'N/A'}
            timeRemaining={data?.timeLimit ? `${data?.timeLimit} min` : data?.timeLimit}
            points={data?.rewardPoints}
            updateSelectedTask={updateSelectedTask}
            handleAddMoreLater={handleAddMoreLater}
            participantTaskId={taskId}
            addManualProvider={onAddManualProvider}
            deleteManualProvider={onDeleteManualProvider}
        />
    );
}

export default SearchProvidersV2;
