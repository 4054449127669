import PickersDay from '@mui/lab/PickersDay';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

const CustomPickersDay = styled(PickersDay)(() => ({
  borderRadius: 4,
}));

const renderWeekPickerDay = (...[, , pickersDayProps]) => (
  <CustomPickersDay {...pickersDayProps} />
);

export const DatepickerInput = ({ defaultDate, ...rest }) => {
  const [date, setDate] = useState(defaultDate);
  const onChangeHandler = (value) => {
    setDate(value);
  };
  
  return (
    <StaticDatePicker
      value={date}
      onChange={onChangeHandler}
      displayStaticWrapperAs="desktop"
      renderDay={renderWeekPickerDay}
      renderInput={(params) => <TextField {...params} />}
      {...rest}
    />
  );
};
