import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '32.5vh',
    width: '100%'
}));

export const InfoMessage = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontWeight: 400,
    fontSize: '12px',
    marginTop: '10px',
    maxWidth: '300px',
    textAlign: 'center'
}));
