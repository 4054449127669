import { styled, Typography } from '@mui/material';

export const WithdrawWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh'
}));

export const Container = styled('div')(({ theme }) => ({
    maxWidth: '405px',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    padding: '60px 40px 40px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        boxShadow: 'none',
        borderRadius: '0px'
    }
}));

export const Row = styled('div')(({ marginBottom }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: marginBottom || '20px'
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray100,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '140%',
    textAlign: 'center'
}));

export const HelperText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontSize: '14px'
}));
