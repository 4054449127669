import { Box, CircularProgress, Stack, useMediaQuery } from '@mui/material';
import { OutlineButton } from 'components/Buttons/Outline';
import { ReactComponent as PurplePlusIcon } from 'images/payment/purplePlus.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProviderCardV2 } from '../../ProviderCardV2';
import { ManualProviderModal } from '../Modal/manualProviderModal';
import { Container, MainContainer, NoProviderFoundText, NoProvidersSubtitle } from './styles';

const ProvidersList = ({
    providers,
    connectedProviders,
    loadingProviders,
    setLoadingProviders,
    setIsRedirecting,
    hasConnectedProviders,
    isLoading,
    hasSearchTerm,
    setModalOpen,
    addManualProvider,
    deleteManualProvider,
    participantTaskId
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [connectingProvider, setConnectingProvider] = useState(null);
    let loader = <></>;
    const connectedIds = connectedProviders.map((cp) => cp?.id);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (selectedProvider?.id) {
            setIsOpenModal(true);
        }
    }, [selectedProvider]);

    if (loadingProviders) {
        let text = connectingProvider
            ? `${t('activities:emr:providers:connect-to-1')} ${connectingProvider} ${t(
                  'activities:emr:providers:connect-to-2'
              )}`
            : t('activities:emr:providers:loading');
        loader = (
            <Box sx={{ margin: '0 0 -15px 0' }}>
                <Stack sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress sx={{ margin: '40px' }} />
                    <NoProvidersSubtitle>{text}</NoProvidersSubtitle>
                    <NoProvidersSubtitle>{t('activities:emr:providers:please-wait')}</NoProvidersSubtitle>
                </Stack>
            </Box>
        );

        return loader;
    }

    const providersToShow = providers?.length === 0 ? connectedProviders : providers;

    // if (providersToShow.length === 0 && !hasSearchTerm) {
    //     return (
    //         <Box sx={{ display: 'flex', marginTop: '50px' }}>
    //             {!hasConnectedProviders && videoElement}
    //             <EmptyProviders
    //                 loadingProviders={loadingProviders}
    //                 loader={hasConnectedProviders && loader}
    //                 hasConnectedProviders={hasConnectedProviders}
    //                 isLoading={isLoading}
    //             />
    //         </Box>
    //     );
    // }

    if (providers.length === 0 && hasSearchTerm) {
        return (
            <MainContainer>
                <Stack sx={{ alignItems: 'center', justifyItems: 'center' }} padding='30px'>
                    <NoProviderFoundText>{t('activities:emr:providers:no-provider')}</NoProviderFoundText>
                    <OutlineButton
                        text={t('activities:emr:providers:add-provider')}
                        onClickHandler={() => setModalOpen()}
                        startIcon={<PurplePlusIcon width={14} height={14} />}
                        sx={{
                            width: '100%'
                        }}
                    />
                </Stack>
            </MainContainer>
        );
    }

    return (
        <Container>
            {/* {providersToShow.length === 0 && <SearchResultContainer>SEARCH RESULTS</SearchResultContainer>} */}
            {providersToShow.map((provider) => {
                return (
                    <ProviderCardV2
                        key={provider.id}
                        provider={provider}
                        participantTaskId={participantTaskId}
                        onManualProviderSelect={() => {
                            setSelectedProvider(provider);
                            setIsOpenModal(true);
                        }}
                        onConnectedProviderSelect={() => {
                            setIsRedirecting(true);
                            navigate('/provider', { state: { provider } });
                        }}
                        setLoadingProviders={setLoadingProviders}
                        setIsRedirecting={setIsRedirecting}
                        setConnectingProvider={setConnectingProvider}
                        connected={connectedIds.includes(provider.id)}
                        addManualProvider={addManualProvider}
                    />
                );
            })}
            {selectedProvider && (
                <ManualProviderModal
                    open={isOpenModal}
                    onClose={() => {
                        setIsOpenModal(false);
                        setSelectedProvider(null);
                    }}
                    participantTaskId={participantTaskId}
                    addManualProvider={addManualProvider}
                    removeManualProvider={deleteManualProvider}
                    provider={{
                        id: selectedProvider?.id,
                        provider_name: selectedProvider?.name,
                        phone_number: selectedProvider?.phone_number,
                        city: selectedProvider?.locations[0]?.address.city,
                        state: selectedProvider?.locations[0]?.address.state
                    }}
                />
            )}
        </Container>
    );
};

export default ProvidersList;
