import { Typography, styled } from '@mui/material';
import { useRedirections } from 'navigation';
import { useActiveActivity } from 'pages/Main';
import { Container as ActivityContainer, Header, Info, Title, Description } from 'pages/NewActivities/Activity';
import { ActivityFooter } from 'pages/NewActivities/ActivityFooter';
import { Icon } from 'pages/NewActivities/ActivityGroup';
import { useTranslation } from 'react-i18next';

export function ActivityGroupSubtask(activity) {
    const { t } = useTranslation();

    const { index, total, title, subtasks } = activity;
    const { setActiveActivity } = useActiveActivity();
    const { redirectToMedications, redirectToHealthQuestionnaire } = useRedirections();

    function handleOnClick() {
        setActiveActivity({ ...activity, subtasks });
        if (title === 'Health Questionnaire') {
            redirectToHealthQuestionnaire();
        } else {
            redirectToMedications();
        }
    }

    return (
        <Container>
            <Header>
                <Icon>
                    <IconLabel>
                        {index + 1}/{total}
                    </IconLabel>
                </Icon>

                <Info>
                    <Title>{t('activities:index:tasks')?.[title]?.title}</Title>
                    <Description>{t('activities:index:tasks')?.[title]?.description}</Description>
                </Info>
            </Header>

            <ActivityFooter {...activity} onClick={handleOnClick} />
        </Container>
    );
}

const Container = styled(ActivityContainer)(({ theme }) => ({
    padding: '25px 0',
    borderRadius: 0,
    borderTop: '1px solid',
    borderTopColor: theme.palette.primary.gray3,
    boxShadow: 'none'
}));

const IconLabel = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    fontWeight: 600,
    color: '#A2D697'
}));
