import { getBaseURL } from 'config/API';
import { useFetchConsentDetailsQuery, useGetAllConsentsListQuery } from 'ihp-bloom-redux/features/consent/consentApiV3';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useGetAllConsents = (params) => {
    const { consents, isLoading, isSuccess, error } = useGetAllConsentsListQuery(
        {
            person_id: params?.personId,
            study_role_id: params?.studyRole || 1
        },
        {
            skip: !params?.personId,
            selectFromResult: ({ data, ...rest }) => {
                const consents = data?.consents || data?.data?.consents;
                return {
                    consents: consents?.length ? consents : [],
                    ...rest
                };
            }
        }
    );

    return {
        consents,
        isLoading,
        error,
        isSuccess
    };
};

export const useNavigateToConsentDetails = (params, consent) => {
    const navigate = useNavigate();
    const [isRedirecting, setIsRedirecting] = useState(false);
    const isDocusign = consent ? consent.submission_type === 'docusign' : false;

    const {
        redirectURL,
        isLoading = false,
        isError
    } = useFetchConsentDetailsQuery(
        {
            person_id: params?.personId,
            study_role_id: params?.studyRole || 1,
            consent_id: consent?.id
        },
        {
            skip: !consent,
            selectFromResult: ({ data, ...rest }) => ({ redirectURL: data?.envelope?.redirect_url, ...rest })
        }
    );

    if (redirectURL) {
        setIsRedirecting(true);
        if (isDocusign) {
            window.location.replace(redirectURL);
        } else {
            navigate(`${getBaseURL()}/consents/${params?.consentId}`);
        }
    }

    return {
        isLoading,
        isRedirecting,
        isError
    };
};
