import { withAuth } from 'authentication';
import { AuthenticationNotifier } from 'authentication/AuthenticationNotifier';
import { Outlet } from 'react-router-dom';

export const OnboardingLayout = withAuth(() => {
    return (
        <AuthenticationNotifier>
            <Outlet />
        </AuthenticationNotifier>
    );
});
