import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme } from '@mui/material/styles';

const palette = {
    primary: {
        main: '#f49b1d', // Brand color
        mainLight: '#F7B962',
        blue: '#3a8aca', // Brand color
        lightBlue: '#a7d9f6', // Brand color
        lightOrange: '#fcc654', // Brand color
        white: '#FFFFFF',
        black: '#000000',
        green: '#43B02A',
        green5: '#F6FBF5',
        green25: '#D1EBCB',
        green100: '#48AE34',
        red: '#DF2525',
        red75: '#E16058',
        lightRed: '#D26E74',
        darkRed: '#C0242B',
        gray2: '#FBFBFB',
        gray3: '#E9E9E9',
        grey3: '#F0F0F0',
        gray4: '#BDBDBD',
        grey4: '#BABABA',
        gray7: '#4A4A4A',
        grey7: '#2E2E2E',
        gray5: '#F4F4F4',
        grey5: '#959595',
        gray6: '#424242',
        gray8: '#1E1E1E',
        gray9: '#AAAAAA',
        gray10: '#EAECEC',
        gray11: '#B8B8B8',
        gray15: '#DFE2E3',
        gray20: '#F8F8F8',
        gray25: '#CACED0',
        gray50: '#959CA0',
        gray75: '#606B71',
        gray80: '#666666',
        gray90: '#888888',
        gray100: '#2B3A41',
        gray120: '#D2D2D2',
        gray130: '#363636',
        gray150: '#393939',
        gray200: '#222222'
        // fuchsia: '#FCF9FB',
        // fuchsia5: '#F8F2F7',
        // fuchsia10: '#F3E7F0',
        // fuchsia25: '#DFC0D8',
        // fuchsia30: '#DAB6D1',
        // fuchsia50: '#C080B1',
        // fuchsia75: '#A0438B',
        // fuchsia90: '#8E2274',
        // fuchsia100: '#810964',
    },
    secondary: {
        main: '#ec6607', // Brand color
        blue: '#2c4679', // Brand color
        gray: '#f0f1eb', // Brand color
        lightGray: '#e2e3d6' // Brand color
    }
};

// Common configuration
const commonFontProps = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '140%',
    color: palette.primary.gray100
};

const headerTypes = {
    h1: {
        fontSize: '30px',
        fontWeight: 600,
        ...commonFontProps
    },
    h2: {
        fontSize: '24px',
        fontWeight: 600,
        ...commonFontProps
    },
    h3: {
        fontSize: '22px',
        fontWeight: 600,
        ...commonFontProps
    },
    h4: {
        fontSize: '20px',
        fontWeight: 600,
        ...commonFontProps
    },
    h5: {
        fontSize: '18px',
        fontWeight: 600,
        ...commonFontProps
    },
    h6: {
        fontSize: '16px',
        fontWeight: 600,
        ...commonFontProps
    }
};

const paragraphBoldTypes = {
    pb1: {
        fontSize: '16px',
        fontWeight: 600,
        ...commonFontProps
    },
    pb2: {
        fontSize: '15px',
        fontWeight: 600,
        ...commonFontProps
    },
    pb3: {
        fontSize: '14px',
        fontWeight: 600,
        ...commonFontProps
    },
    pb4: {
        fontSize: '13px',
        fontWeight: 600,
        ...commonFontProps
    },
    pb5: {
        fontSize: '12px',
        fontWeight: 600,
        ...commonFontProps
    },
    pb6: {
        fontSize: '11px',
        fontWeight: 600,
        ...commonFontProps
    },
    pb7: {
        fontSize: '10px',
        fontWeight: 600,
        ...commonFontProps
    }
};

const paragraphNormalTypes = {
    pn1: {
        fontSize: '16px',
        fontWeight: 500,
        ...commonFontProps
    },
    pn2: {
        fontSize: '15px',
        fontWeight: 500
    },
    pn3: {
        fontSize: '14px',
        fontWeight: 500,
        ...commonFontProps
    },
    pn4: {
        fontSize: '13px',
        fontWeight: 500,
        ...commonFontProps
    },
    pn5: {
        fontSize: '12px',
        fontWeight: 500,
        ...commonFontProps
    },
    pn6: {
        fontSize: '11px',
        fontWeight: 500,
        ...commonFontProps
    },
    pn7: {
        fontSize: '10px',
        fontWeight: 500,
        ...commonFontProps
    }
};

const paragraphLightTypes = {
    pl1: {
        fontSize: '16px',
        fontWeight: 400,
        ...commonFontProps
    },
    pl2: {
        fontSize: '15px',
        fontWeight: 400,
        ...commonFontProps
    },
    pl3: {
        fontSize: '14px',
        fontWeight: 400,
        ...commonFontProps
    },
    pl4: {
        fontSize: '13px',
        fontWeight: 400,
        ...commonFontProps
    },
    pl5: {
        fontSize: '12px',
        fontWeight: 400,
        ...commonFontProps
    },
    pl6: {
        fontSize: '11px',
        fontWeight: 400,
        ...commonFontProps
    },
    pl7: {
        fontSize: '10px',
        fontWeight: 400,
        ...commonFontProps
    }
};

const typography = {
    ...commonFontProps,
    ...headerTypes,
    ...paragraphBoldTypes,
    ...paragraphNormalTypes,
    ...paragraphLightTypes
};

export const theme = createTheme({
    palette: palette,
    typography: typography,
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // // the result will contain the prefix.
                    // [`& .${outlinedInputClasses.notchedOutline}`]: {
                    //     borderWidth: 1,
                    // },
                    // [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                    //     borderWidth: 1,
                    // },
                    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
                        borderWidth: 1
                    }
                }
            }
        }
    }
});

// Install this colorize extension in VSCode to see colors here!
// https://marketplace.visualstudio.com/items?itemName=kamikillerto.vscode-colorize
// config:
// .vscode/settings.json
// "colorize.languages": [
//     "javascript",
//   ]
