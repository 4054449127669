import { Box, InputLabel, MenuItem, Modal, useTheme } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { TextInput } from 'components/Input';
import { ContinueButton } from 'components/Onboarding';
import { ErrorWrapper } from 'configUI/components';
import { StyledCancelButton } from 'configUI/components/AddModal/styles';
import { SelectInput } from 'configUI/components/Select';
import { Loader } from 'configUI/components/Shared/Loader';
import { useValidatePhone } from 'hooks/useValidatePhone';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
    useCreateManualProviderMutation,
    useDeleteManualProviderMutation,
    useUpdateManualProviderMutation
} from 'ihp-bloom-redux/features/oneUpHealth/manualProvidersApi';
import { addInProgressProvider, removeInProgressProvider } from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { participantTask } from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { USStates } from 'mocks/us-states';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-input-mask';
import getStyles from './styles';
import { ReactComponent as XIcon } from './x.svg';

export const ManualProviderModal = ({
    open,
    onClose,
    participantTaskId,
    addManualProvider,
    removeManualProvider,
    provider
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const styles = getStyles(theme);
    const [createManualProvider] = useCreateManualProviderMutation();
    const [updateManualProvider] = useUpdateManualProviderMutation();
    const [deleteManualProvider] = useDeleteManualProviderMutation();
    const selectedParticipantTask = useSelector(participantTask());
    const [isLoading, setIsLoading] = useState(false);
    const isUpdate = provider != null;
    const dispatch = useDispatch();

    const { id: personId, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();

    const finalId = isCaregiver ? patientId : personId;

    const [currentManualProvider] = useState({
        provider_name: provider?.provider_name ?? '',
        phone_number: provider?.phone_number ?? '',
        city: provider?.city ?? '',
        state: provider?.state ?? ''
    });

    const validatePhone = useValidatePhone();
    const {
        control,
        formState: { errors },
        reset,
        handleSubmit
    } = useForm({
        defaultValues: {
            provider_name: currentManualProvider?.provider_name ?? '',
            phone_number: currentManualProvider?.phone_number ?? '',
            city: currentManualProvider?.city ?? '',
            state: currentManualProvider?.state ?? ''
        }
    });
    const onRemoveProvider = async (id) => {
        setIsLoading(true);

        await deleteManualProvider({
            participantId: finalId,
            providerId: provider.id
        })
            .then((response) => {
                console.log("🚀 ~ file: manualProviderModal.js:79 ~ .then ~ response:", response)
                removeManualProvider(provider);
                dispatch(removeInProgressProvider(provider.id));
                return onClose();
            })
            .catch(console.error);

        setIsLoading(false);
        return onClose();
    };

    const onSubmit = async (data) => {
        let taskId = participantTaskId ?? selectedParticipantTask?.id;
        if (taskId === undefined || taskId === null) {
            alert('Provider cannot be added without participant task id.');
            return;
        }
        setIsLoading(true);
        let payload = {
            data: {
                type: 'person-providers',
                attributes: {
                    participant_task_id: Number(taskId),
                    provider_info: {
                        hc_organization_name: data.provider_name,
                        city: data.city,
                        state: data.state,
                        phone_number: data.phone_number
                    }
                }
            }
        };
        if (isUpdate) {
            payload = { data: { ...payload.data, id: provider.id.toString() } };
            await updateManualProvider({
                participantId: finalId,
                providerId: provider.id,
                payload
            })
                .then((response) => {
                    const data = response?.data?.data;
                    let result = {
                        id: Number(data?.id),
                        name: data.attributes.provider_info.hc_organization_name,
                        phone_number: data.attributes.provider_info.phone_number,
                        logo: 'manualProvider',
                        locations: [
                            {
                                address: {
                                    city: data.attributes?.provider_info.city,
                                    state: data.attributes?.provider_info.state
                                }
                            }
                        ],
                        participant_id: finalId
                    };
                    addManualProvider(result, provider.id);
                    return onClose();
                })
                .catch(console.error);
            return onClose();
        } else {
            // CREATE
            await createManualProvider({ participantId: finalId, payload })
                .then((response) => {
                    const data = response?.data?.data;
                    let result = {
                        id: Number(data?.id),
                        name: data.attributes.provider_info.hc_organization_name,
                        phone_number: data.attributes.provider_info.phone_number,
                        logo: 'manualProvider',
                        locations: [
                            {
                                address: {
                                    city: data.attributes?.provider_info.city,
                                    state: data.attributes?.provider_info.state
                                }
                            }
                        ],
                        participant_id: finalId
                    };
                    addManualProvider(result);
                    // to enable finish data button
                    dispatch(addInProgressProvider({ ...result, logo: 'manualProvider', participant_task_id: Number(taskId) }));
                    return onClose();
                })
                .catch(console.error);
        }

        setIsLoading(false);
    };

    if (isLoading) {
        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={styles.modalWrapper}>
                    <Loader />
                </Box>
            </Modal>
        );
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={styles.modalWrapper}>
                <Box sx={styles.titleWrapper}>
                    <Box sx={styles.title}>{t('activities:emr:manual-modal:title')}</Box>
                    <Box sx={styles.iconWrapper}>
                        <XIcon
                            width='12px'
                            height='18px'
                            color={theme.palette.primary.white}
                            onClick={(e) => onClose()}
                        />
                    </Box>
                </Box>
                <Box sx={styles.formWrapper}>
                    <form>
                        <Box sx={styles.fieldContainer}>
                            <InputLabel sx={styles.inputLabel}>{t('activities:emr:manual-modal:labels:org-name')}</InputLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='provider_name'
                                    control={control}
                                    rules={{ required: true }}
                                    haserrors={errors.provider_name}
                                    render={({ field }) => (
                                        <Controller
                                            name='provider_name'
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                            haserrors={errors.provider_name}
                                            render={({ field }) => (
                                                <TextInput
                                                    value={currentManualProvider.provider_name}
                                                    fullWidth={true}
                                                    placeholder={t('activities:emr:manual-modal:placeholders:org-name')}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </ErrorWrapper>
                        </Box>

                        <Box sx={styles.fieldContainer}>
                            <InputLabel sx={styles.inputLabel}>{t('activities:emr:manual-modal:labels:phone')}</InputLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='phone_number'
                                    control={control}
                                    rules={{
                                        validate: validatePhone,
                                        required: true
                                    }}
                                    haserrors={
                                        errors.phone_number && {
                                            type: 'custom',
                                            message: errors?.phone_number?.message || 'This field is required'
                                        }
                                    }
                                    render={({ field }) => (
                                        <MaskedInput
                                            placeholder={t('activities:emr:manual-modal:placeholders:phone')}
                                            mask='+1 (999) 999-9999'
                                            {...field}
                                        >
                                            {(inputProps) => <TextInput fullWidth sx={styles.input} {...inputProps} />}
                                        </MaskedInput>
                                    )}
                                />
                            </ErrorWrapper>
                        </Box>

                        <Box sx={styles.cityStateWrapper}>
                            <Box sx={styles.cityFieldContainer}>
                                <InputLabel sx={styles.inputLabel}>{t('activities:emr:manual-modal:labels:city')}</InputLabel>
                                <ErrorWrapper>
                                    <Controller
                                        name='city'
                                        control={control}
                                        rules={{ required: true }}
                                        haserrors={errors.city}
                                        render={({ field }) => (
                                            <Controller
                                                name='city'
                                                control={control}
                                                rules={{
                                                    required: true
                                                }}
                                                haserrors={errors.city}
                                                render={({ field }) => (
                                                    <TextInput fullWidth={true} placeholder={t('activities:emr:manual-modal:placeholders:city')} {...field} />
                                                )}
                                            />
                                        )}
                                    />
                                </ErrorWrapper>
                            </Box>

                            <Box sx={styles.stateFieldContainer}>
                                <ErrorWrapper>
                                    <Controller
                                        name='state'
                                        control={control}
                                        rules={{ required: true }}
                                        haserrors={errors.state}
                                        render={({ field }) => (
                                            <SelectInput
                                                label={t('activities:emr:manual-modal:placeholders:state')}
                                                fieldLabel={t('activities:emr:manual-modal:labels:state')}
                                                placeholder={t('activities:emr:manual-modal:placeholders:state')}
                                                fullWidth
                                                sx={styles.selectInput}
                                                {...field}
                                            >
                                                {USStates?.map(({ name, abbreviation }) => (
                                                    <MenuItem key={abbreviation} value={abbreviation}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </SelectInput>
                                        )}
                                    />
                                </ErrorWrapper>
                            </Box>
                        </Box>
                    </form>
                </Box>
                <Box sx={styles.buttonsContainer}>
                    <Box sx={styles.continueButton}>
                        <ContinueButton sx={{ width: '119px' }} onClick={handleSubmit(onSubmit)}>
                            {isUpdate ? t('activities:emr:manual-modal:buttons:save') : t('activities:emr:manual-modal:buttons:continue')}
                        </ContinueButton>
                    </Box>
                    <Box sx={{ marginTop: '10px' }}>
                        <StyledCancelButton
                            onClick={() => {
                                reset({
                                    provider_name: '',
                                    phone_number: '',
                                    city: '',
                                    state: ''
                                });
                                onClose();
                            }}
                        >
                            {t('activities:emr:manual-modal:buttons:cancel')}
                        </StyledCancelButton>
                    </Box>
                    {isUpdate && (
                        <Box sx={{ marginTop: '10px', marginRight: '-30px' }}>
                            <StyledCancelButton onClick={onRemoveProvider}>{t('activities:emr:manual-modal:buttons:remove')}</StyledCancelButton>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};
