/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, styled, Typography } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { useEffect } from 'react';

export function ProfileHeader() {
    const { isCaregiver, ...person } = useGetPerson();
    const patient = useGetPatient();

    const { firstName, lastName, state, city } = {
        firstName: person.firstName ?? patient.firstName,
        lastName: person.lastName ?? patient.lastName,
        state: person.state ?? patient.state,
        city: person.city ?? patient.city
    };

    useEffect(() => {
        // Since this is cached by redux, if the user came from the onboarding page the attributes here were empty
        if (!state || !city) {
            if (isCaregiver) {
                patient?.refetch();
            }
            person?.refetch();
        }
    }, []);

    const fullName = `${firstName} ${lastName}`;

    if (!firstName || !lastName) {
        return null;
    }

    return (
        <Stack alignItems='center' justifyContent='center'>
            <Avatar>
                <Typography variant='h1' color='primary.gray100' fontWeight={600} letterSpacing={-2}>
                    {firstName[0].toUpperCase()} {lastName[0].toUpperCase()}
                </Typography>
            </Avatar>
            <Typography variant='h4' mb='0.3125rem'>
                {fullName}
            </Typography>

            {state && city && (
                <Typography variant='pl4'>
                    {city}, {state}
                </Typography>
            )}
        </Stack>
    );
}

const Avatar = styled(Box)(() => ({
    width: '100px',
    height: '100px',
    marginBottom: '1.25rem',
    background: 'rgba(244, 155, 29, 0.3)',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
}));
