
import { styled } from '@mui/material/styles';
import { TextButton } from './TextButton';

export const OutlinedButton = ({ children, ...rest }) => {
  return <OutlinedButtonStyle variant='outlined' {...rest}>{children}</OutlinedButtonStyle>;
};

export const OutlinedButtonStyle = styled(TextButton)(({ theme }) => ({
  '&: hover': {
  },
}));

/*
  Example Usage

  <OutlinedButton
    color="primary"
    size="large"
  >
    Button
  </OutlinedButton>

  <OutlinedButton
    color="primary"
    size="large"
    endIcon={<ArrowForwardIcon />}
  >
    Button
  </OutlinedButton>

  <OutlinedButton
    color="primary"
    size="large"
    startIcon={<ArrowForwardIcon />}
  >
    Button
  </OutlinedButton>

  color: primary | secondary | tertiary
*/
