import { Box, CircularProgress, Typography } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { ConsentItem, EmptyConsents } from 'components/Profile';
import { useGetAllConsents } from 'hooks/api';
import { useTranslation } from 'react-i18next';
import { useOpenSignedConsent } from 'utils/consents';
import { Content } from './styles';

const ConsentsList = () => {
    const { t } = useTranslation();
    document.title = 'My Consents';

    const { id: personId, roleId, isLoading: isPersonLoading, isCaregiver } = useGetPerson();
    const { id: patientId, isLoading: isPatientLoading } = useGetPatient();
    const finalId = isCaregiver ? patientId : personId;

    const [openSignedConsent, isOpening, documentNotFound] = useOpenSignedConsent({ personId: finalId });

    const {
        consents,
        isLoading: isConsentsLoading,
        isSuccess: isConsentsSuccess
    } = useGetAllConsents({
        // We have to send the id of the patient to sign the document
        personId: finalId,
        // If is caregiver, we have to send the study role id of the caregiver
        studyRole: roleId
    });

    const isLoading =
        isPersonLoading || (isCaregiver && isPatientLoading) || isConsentsLoading || !isConsentsSuccess || isOpening;

    if (isLoading) {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' flex={1}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Content>
            {!consents?.length && !isLoading && <EmptyConsents />}

            {consents
                ?.filter(
                    (consent) =>
                        consent?.participant_consent_status === 'completed' ||
                        consent?.participant_consent_status === 'terminated'
                )
                .map((consent) => {
                    return (
                        <div key={`${consent?.id}${consent?.consent_version_id}`}>
                            <ConsentItem
                                key={`${consent?.id}${consent?.consent_version_id}`}
                                consent={consent}
                                onOpenSignedConsent={openSignedConsent}
                            />
                        </div>
                    );
                })}

            {documentNotFound && !isLoading && <Typography variant='pb1'>{t('profile:consents:error')}</Typography>}
        </Content>
    );
};

export default ConsentsList;
