import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { PPRLogo } from 'components/PPRLogo';
import { Logo } from 'components/shared/Logo';
import { APP_ROUTES, useRedirections } from 'navigation';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { SignIn, Register } from './styles';

const StyledLogo = styled(Logo)({
    width: '148px',
    height: 'auto',
    cursor: 'pointer'
});

const StyledLink = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: '#606B71',
    cursor: 'pointer',
    fontWeight: '500',
    fontSize: '14px',
    '&:hover': {
        color: theme.palette.primary.main
    }
}));

const LogoDivider = styled('div')({
    width: '1px',
    height: '43px',
    background: '#D9D9D9',
    margin: '0 20px'
});

export function Nav() {
    const theme = useTheme();
    const { t } = useTranslation();
    const { redirectToLogin, redirectToSignUp, redirectToWelcome } = useRedirections();

    const activeStyle = {
        color: theme.palette.primary.blue,
        borderBottom: '1px solid'
    };

    return (
        <Box
            display='flex'
            flexWrap='wrap'
            justifyContent='space-between'
            alignItems='center'
            padding={{ xs: '20px 20px', md: '20px 40px' }}
            maxWidth='1080px'
            margin='0 auto'
        >
            <Box display='flex' alignItems='center'>
                <StyledLogo onClick={redirectToWelcome} />
                <LogoDivider />
                <PPRLogo />
            </Box>

            <NavOptions>
                <Links>
                    <StyledLink
                        to={APP_ROUTES.RESOURCES}
                        style={({ isActive }) => (isActive ? activeStyle : undefined)}
                    >
                        {t('welcome:home:resources')}
                    </StyledLink>
                    <StyledLink to={APP_ROUTES.FAQ} style={({ isActive }) => (isActive ? activeStyle : undefined)}>
                        {t('welcome:home:faq')}
                    </StyledLink>
                    <StyledLink to={APP_ROUTES.CONTACT} style={({ isActive }) => (isActive ? activeStyle : undefined)}>
                        {t('welcome:home:contact')}
                    </StyledLink>
                </Links>

                <Buttons>
                    <SignIn onClick={redirectToLogin}>{t('welcome:home:sign-in')}</SignIn>
                    <Register onClick={redirectToSignUp}>{t('welcome:home:register')}</Register>
                </Buttons>
            </NavOptions>
        </Box>
    );
}

const NavOptions = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '10px'
    }
}));

const Links = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
        marginTop: '20px'
    }
}));

const Buttons = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
    }
}));
