import { Box, styled } from '@mui/system';

export const ProviderCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1px',
    position: 'relative',
    borderRadius: '8px',
    cursor: 'pointer',
    padding: '15px',
    flexWrap: 'wrap',
    gap: '20px'
}));

export const ProviderName = styled('div')(({ theme }) => ({
    color: theme.palette.primary.gray100,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 14,
    fontWeight: 600,
    marginTop: '5px'
}));

export const ProviderAddress = styled('div')(({ theme }) => ({
    color: theme.palette.primary.gray50,
    fontSize: 12,
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    marginTop: '5px'
}));

export const TitleContainer = styled(Box)(() => ({
    marginLeft: '20px'
}));

export const IconWrapper = styled(Box)(() => ({
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));
