import { Box, Typography, styled } from '@mui/material';
import { StyledBaseLayout } from 'pages/Profile/Withdraw/styles';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export function RegistryPage() {
    const { t } = useTranslation();

    return (
        <StyledBaseLayout
            header={null}
            content={
                <Container>
                    <Typography variant='h5' color='primary.gray100'>
                        {t('profile:registry:title')}
                    </Typography>

                    {t('profile:registry:sections').map(({ title, description }) => (
                        <Fragment>
                            <Typography variant='h6' color='primary.main' marginTop='30px'>
                                {title}
                            </Typography>

                            <Typography color='primary.gray75' marginTop='15px'>
                                {description}
                            </Typography>
                        </Fragment>
                    ))}
                </Container>
            }
        />
    );
}

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 0px 9px 0px rgba(0, 0, 0, 0.04)'
});
