import { Box, styled } from '@mui/material';

export const ProfileCardIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    aspectRatio: '1 / 1',
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.gray50,
    borderRadius: '50%',
    '&.active': {
        backgroundColor: theme.palette.primary.mainLight
    }
}));
