import { CircularProgress, Stack, styled, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { BaseLayout } from 'components/BaseLayout';
import { SpinnerContainer } from 'pages/SyncData/SearchProviders/styles';
import { TimelineCard, TimelineDayCard } from './timelineCard';

export default function Timeline({ tasks, isLoadingTasks }) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const types = Object.keys(tasks);
    const padding = isMobile ? '0px' : '20px';

    const spinner = (
        <SpinnerContainer>
            <CircularProgress color='primary' />
        </SpinnerContainer>
    );

    if (isLoadingTasks) {
        return spinner;
    }

    return (
        <Container sx={{ padding: padding }}>
            {types.map((taskType, typeIndex) => {
                return (
                    <Box key={taskType}>
                        {tasks[taskType]?.length > 0 && (
                            <TimelineDayCard
                                title={taskType.replace('Tasks', '').toUpperCase()}
                                count={tasks[taskType]?.length}
                            />
                        )}

                        {tasks[taskType]?.map((timeline, tIndex) => {
                            const isLastTimeline = tIndex === tasks[taskType]?.length - 1;
                            const isLastDay = typeIndex === types.length - 1;
                            return (
                                <TimelineCard
                                    key={tIndex + typeIndex + taskType}
                                    showLine={!(isLastTimeline && isLastDay)}
                                    name={timeline.title}
                                    {...timeline}
                                    category={taskType.replace('Tasks', '')}
                                />
                            );
                        })}
                    </Box>
                );
            })}
        </Container>
    );
}

const Container = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        height: 'calc(100dvh)'
    }
}));

export const StyledBaseLayout = styled(BaseLayout)(({ theme }) => ({
    width: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.primary.gray20,
        // Let BaseLayout grow
        '& > .BaseLayout-scrollable': {
            // backgroundColor: theme.palette.primary.white,
            boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
            borderRadius: '8px'
        },
        // Remove sticky header behavior
        '& > .BaseLayout-header': {
            position: 'initial',
            borderBottom: 'none'
        }
    },

    [theme.breakpoints.down('md')]: {
        marginBottom: '68px'
    }
}));
