import { Box, Divider, Stack, Typography, styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useGetPatient, useGetPerson } from 'authentication';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeclineTokenizeData from './components/DeclineTokenizeData';
import OtherStudy from './components/OtherStudy';
import Withdraw from './components/Withdraw';
import WithdrawReason from './components/WithdrawReason';
import { StyledBaseLayout, StyledFilledButton } from './styles';

const RegistryContent = ({ handleDeclineTokenizeData, handleWithdraw, handleOtherStudy }) => {
    const { t } = useTranslation();
    const { tokenizationConsent: personTokenizationConsent } = useGetPerson();
    const { tokenizationConsent: patientTokenizationConsent } = useGetPatient();

    const disableTokenizationButton =
        personTokenizationConsent === 'withdrawn' ||
        patientTokenizationConsent === 'withdrawn' ||
        personTokenizationConsent === 'declined' ||
        patientTokenizationConsent === 'declined';

    return (
        <Container>
            {Object.values(t('profile:withdraw:info')).map((item) => {
                return (
                    <>
                        <Typography color='primary.main' fontWeight='600' paddingBottom={'15px'} paddingTop={'30px'}>
                            {item.title}
                        </Typography>
                        <Typography color='primary.gray75'>{item.description}</Typography>
                    </>
                );
            })}

            <Divider sx={{ marginY: '40px' }} />

            <Stack gap={'15px'}>
                {!disableTokenizationButton && (
                    <StyledFilledButton
                        onClick={handleDeclineTokenizeData}
                        fullWidth
                        sx={{ minHeight: '56px', height: 'auto' }}
                    >
                        {t('profile:withdraw:buttons:withdraw-consent')}
                    </StyledFilledButton>
                )}

                <StyledFilledButton onClick={handleWithdraw} fullWidth>
                    {t('profile:withdraw:buttons:withdraw')}
                </StyledFilledButton>
            </Stack>
        </Container>
    );
};

const Container = styled(Box)(({ theme }) => ({
    padding: '40px',
    backgroundColor: 'white',
    borderRadius: '8px'
}));

const Registry = () => {
    const [openTokenize, setOpenTokenize] = useState(false);
    const handleOpenTokenize = () => setOpenTokenize(true);
    const handleCloseTokenize = () => setOpenTokenize(false);

    const [openWithdraw, setOpenWithdraw] = useState(false);
    const handleOpenWithdraw = () => setOpenWithdraw(true);
    const handleCloseWithdraw = () => setOpenWithdraw(false);

    const [openWithdrawReason, setOpenWithdrawReason] = useState(false);
    const handleOpenWithdrawReason = () => setOpenWithdrawReason(true);
    const handleCloseWithdrawReason = () => setOpenWithdrawReason(false);

    const [openOtherStudy, setOpenOtherStudy] = useState(false);
    const handleOpenOtherStudy = () => setOpenOtherStudy(true);
    const handleCloseOtherStudy = () => setOpenOtherStudy(false);

    const DeclineTokenizeModal = ({ open, handleClose }) => (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <DeclineTokenizeData handleClose={handleClose}></DeclineTokenizeData>
        </Modal>
    );

    const WithdrawModal = ({ open, handleClose }) => (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Withdraw handleClose={handleClose} confirmCallback={handleOpenWithdrawReason}></Withdraw>
        </Modal>
    );

    const WithdrawReasonModal = ({ open, handleClose }) => (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <WithdrawReason handleClose={handleClose} confirmCallback={handleOpenOtherStudy}></WithdrawReason>
        </Modal>
    );

    const OtherStudyModal = ({ open, handleClose }) => (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <OtherStudy handleClose={handleClose}></OtherStudy>
        </Modal>
    );

    return (
        <>
            <DeclineTokenizeModal open={openTokenize} handleClose={handleCloseTokenize} />
            <WithdrawModal open={openWithdraw} handleClose={handleCloseWithdraw} />
            <WithdrawReasonModal open={openWithdrawReason} handleClose={handleCloseWithdrawReason} />
            <OtherStudyModal open={openOtherStudy} handleClose={handleCloseOtherStudy} />

            <StyledBaseLayout
                header={null}
                content={
                    <RegistryContent
                        handleWithdraw={handleOpenWithdraw}
                        handleDeclineTokenizeData={handleOpenTokenize}
                        handleOtherStudy={handleOpenOtherStudy}
                    />
                }
            />
        </>
    );
};

export default Registry;
