import { Box, Typography, styled } from '@mui/material';
import { useRedirections } from 'navigation';
import { LeftArrow } from 'pages/Main/assets';
import { useTranslation } from 'react-i18next';

export function BackToMyActivities({ backHandler = null }) {
    const { redirectToActivities } = useRedirections();
    const { t } = useTranslation();
    if (backHandler === null) backHandler = redirectToActivities;

    return (
        <Container onClick={backHandler}>
            <LeftArrow style={{ strokeWidth: 1 }} />
            <Typography variant='pn3' fontWeight={600}>
                {t('activities:medications:back-to-my-activities:title')}
            </Typography>
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({
    // position: 'absolute',
    // left: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    gap: '20px',
    cursor: 'pointer',
    flex: 1,
    '&:hover': {
        svg: {
            transform: 'translateX(-3px)'
        }
    },
    [theme.breakpoints.down('sm')]: {
        position: 'relative',
        left: '20px'
    }
}));
