import { Box, styled } from '@mui/material';
import { useGetPerson } from 'authentication';
import { ReactComponent as Sign } from 'images/profile/sign.svg';
import { ReactComponent as MyProfileIcon } from 'images/profile/user-cog.svg';
import { ReactComponent as WithdrawIcon } from 'images/shared/door-open-2.svg';
import { APP_ROUTES } from 'navigation';
import { Coins, NotesMedical } from 'pages/Profile/assets';
import { PARTICIPANT_STATUS } from 'pages/onboarding';
import { ProfileCard } from './ProfileCard';

export function ProfileCards() {
    const { status } = useGetPerson();

    return (
        <Container>
            {cards.map((cardProperties, index) =>
                status === PARTICIPANT_STATUS.WITHDRAWN && cardProperties.hideWhenWithdrawn ? null : (
                    <ProfileCard index={index} {...cardProperties} />
                )
            )}
        </Container>
    );
}

const cards = [
    {
        path: APP_ROUTES.PROFILE_PAYMENTS,
        icon: Coins,
        hideWhenWithdrawn: true
    },
    {
        path: APP_ROUTES.PROFILE_CONSENTS,
        icon: Sign
    },
    {
        path: APP_ROUTES.PROFILE_VIEW,
        icon: MyProfileIcon,
        hideWhenWithdrawn: true
    },
    {
        path: APP_ROUTES.PROFILE_REGISTRY,
        icon: NotesMedical
    },
    {
        path: APP_ROUTES.PROFILE_WITHDRAW,
        icon: WithdrawIcon,
        hideWhenWithdrawn: true
    }
];

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
});
