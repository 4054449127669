import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        document.body.scrollTo(0, 0);
    }, [pathname]);

    return null;
};
