/* eslint-disable react-hooks/exhaustive-deps */
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Modal,
    styled,
    TextField,
    Typography
} from '@mui/material';
import { CustomizedDatePicker } from 'components/DatePicker';
import { TextInput } from 'components/Input';
import { StyledFormLabel } from 'components/Input/TextField.styles';
import { ErrorWrapper } from 'configUI/components';
import { ButtonWithCheck, GhostButton } from 'configUI/components/Buttons';
import { StyledInputLabel } from 'configUI/components/TextArea';
import { useDebounce } from 'hooks/useDebounce';
import { useMedications } from 'pages/Medications/MedicationsProvider';
import { isFutureDate } from 'pages/Medications/UpdateMedicationModal';
import { useSearchMedication } from 'pages/Medications/hooks';
import {
    getPlaceholderStyles,
    StyledSelect
} from 'pages/onboarding/PersonalInformation/forms/PersonalInformationForm/styles';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidDate } from 'validations';
import { Close } from './assets';

export function AddMedicationModal({ open, onClose }) {
    const { medications: patientMedications, addMedication } = useMedications();
    const { t } = useTranslation();

    const { control, formState, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            medication: '',
            frequency: '',
            frequency_period: '',
            start_date: '',
            different_dosage: false
        }
    });

    const { errors, isSubmitting, isValid } = formState;

    const [searchValue, setSearchValue] = useState('');
    const [selectedMedication, setSelectedMedication] = useState('');
    const [startDate, setStartDate] = useState();
    const [startDateError, setStartDateError] = useState('');

    const debouncedSearchValue = useDebounce(searchValue, 300);
    const { medications, isSearching } = useSearchMedication(debouncedSearchValue, !selectedMedication?.label);

    function handleOnClose() {
        setSearchValue();
        setSelectedMedication();
        reset();
        onClose();
    }

    function handleAddMedication(data) {
        const start_date = new Date();
        start_date.setFullYear(data.start_date.getFullYear());
        start_date.setMonth(data.start_date.getMonth());
        start_date.setDate(data.start_date.getDate());

        addMedication({
            ...data,
            medication: selectedMedication.label,
            start_date
        });

        handleOnClose();
    }

    function isAddedMedication(m) {
        return Boolean(patientMedications.find((pm) => pm.medication.toLowerCase() === m?.toLowerCase()));
    }

    const searchMedicationsResult = Array.from(
        new Set(
            Object.values(medications)
                .flatMap((option) => option?.data?.map((med) => med.str))
                .filter((med) => !isAddedMedication(med))
        )
    );

    const searchMedicationLabels = searchMedicationsResult.map((m) => ({
        label: m
    }));

    const isValidSearchMedication = searchMedicationsResult.includes(searchValue);

    const disabled =
        isSubmitting ||
        !isValid ||
        !isValidSearchMedication ||
        isAddedMedication(selectedMedication.label) ||
        Boolean(startDateError);

    return (
        <Modal open={open} onClose={handleOnClose}>
            <Container>
                <Wrapper>
                    <Header>
                        <Typography variant='h5' color='primary.gray100'>
                            {t('activities:medications:add-medications:add-medication')}
                        </Typography>

                        <Box display='flex' gap={10}>
                            <CloseButton onClick={handleOnClose} />
                        </Box>
                    </Header>

                    <form onSubmit={handleSubmit(handleAddMedication)}>
                        <Body>
                            <Grid container spacing='20px'>
                                <Grid item xs={12} spacing='8px'>
                                    <StyledFormLabel>
                                        {t('activities:medications:add-medications:labels:drug-name')}
                                    </StyledFormLabel>

                                    <ErrorWrapper>
                                        <Controller
                                            name='medication'
                                            control={control}
                                            error={errors.medication}
                                            render={({ field, fieldState }) => (
                                                <Autocomplete
                                                    {...field}
                                                    loading={isSearching}
                                                    freeSolo
                                                    options={searchMedicationLabels}
                                                    value={selectedMedication}
                                                    onChange={(_, value) => setSelectedMedication(value)}
                                                    onInputChange={(_, value) => setSearchValue(value)}
                                                    getOptionLabel={(option) => {
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }

                                                        if (
                                                            typeof option === 'object' &&
                                                            typeof option.label === 'string'
                                                        ) {
                                                            return option.label;
                                                        }

                                                        return '';
                                                    }}
                                                    sx={(theme) => ({
                                                        flex: 1,
                                                        marginTop: '8px',
                                                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: theme.palette.primary.gray15
                                                        }
                                                    })}
                                                    error={!!fieldState.error}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t(
                                                                'activities:medications:add-medications:placeholders:drug-name'
                                                            )}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </ErrorWrapper>
                                </Grid>

                                <Grid item xs={6}>
                                    <StyledFormLabel>
                                        {t('activities:medications:add-medications:labels:frequency')}
                                    </StyledFormLabel>

                                    <ErrorWrapper>
                                        <Controller
                                            name='frequency'
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                            error={errors.frequency}
                                            render={({ field, fieldState }) => (
                                                <TextInput
                                                    inputProps={{ min: 1 }}
                                                    type='number'
                                                    error={!!fieldState.error}
                                                    fullWidth={true}
                                                    placeholder={t(
                                                        'activities:medications:add-medications:placeholders:frequency'
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </ErrorWrapper>
                                </Grid>

                                <Grid item xs={6} marginTop='auto'>
                                    <ErrorWrapper>
                                        <FormControl fullWidth>
                                            <Controller
                                                name='frequency_period'
                                                control={control}
                                                rules={{
                                                    required: true
                                                }}
                                                error={errors.frequency_period}
                                                render={({ field, fieldState }) => (
                                                    <>
                                                        <StyledInputLabel
                                                            sx={getPlaceholderStyles(field.value === '')}
                                                            id='frequency_period_label'
                                                        >
                                                            {t(
                                                                'activities:medications:add-medications:placeholders:frequency-period'
                                                            )}
                                                        </StyledInputLabel>

                                                        <StyledSelect
                                                            fullWidth
                                                            {...field}
                                                            error={!!fieldState.error}
                                                            label={'Select period'}
                                                            labelId='frequency_period_label'
                                                        >
                                                            {Object.entries(
                                                                t(
                                                                    'activities:medications:add-medications:selects:frequencies'
                                                                )
                                                            ).map(([key, value]) => (
                                                                <MenuItem key={key} value={key}>
                                                                    {value}
                                                                </MenuItem>
                                                            ))}
                                                        </StyledSelect>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </ErrorWrapper>
                                </Grid>

                                <Grid item xs={12} spacing='8px'>
                                    <StyledFormLabel>
                                        {t('activities:medications:add-medications:labels:date-started')}
                                    </StyledFormLabel>

                                    <Controller
                                        name='start_date'
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        error={errors.start_date}
                                        render={({ field, fieldState }) => {
                                            setStartDate(field.value);
                                            const isDate = field.value instanceof Date;

                                            const isInvalidDate = isDate ? !isValidDate(field.value) : false;
                                            const isFuture = isFutureDate(field.value);

                                            console.log({ isInvalidDate, isFuture });
                                            const hasErrors = isInvalidDate || isFuture || Boolean(fieldState.error);

                                            if (hasErrors) {
                                                if (isFuture) {
                                                    setStartDateError(t('activities:medications:errors:futureDate'));
                                                } else if (isInvalidDate) {
                                                    setStartDateError(t('activities:medications:errors:invalidDate'));
                                                } else {
                                                    setStartDateError('');
                                                }
                                            } else {
                                                setStartDateError('');
                                            }

                                            return (
                                                <Box display='flex' flexDirection='column'>
                                                    <CustomizedDatePicker
                                                        disableFuture
                                                        error={Boolean(startDateError)}
                                                        placeholder={t(
                                                            'activities:medications:add-medications:placeholders:date-started'
                                                        )}
                                                        sx={{ width: '50%' }}
                                                        {...field}
                                                        value={startDate}
                                                    />

                                                    {startDateError && (
                                                        <Typography variant='pl3' color='primary.red'>
                                                            {startDateError}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            );
                                        }}
                                    />
                                </Grid>

                                <FormGroup sx={{ padding: '20px' }}>
                                    <Controller
                                        name='different_dosage'
                                        control={control}
                                        rules={{
                                            validate: (v) =>
                                                isValidDate(v) ||
                                                t('activities:medications:add-medications:errors:different_dosage')
                                        }}
                                        error={errors.different_dosage}
                                        render={({ field, fieldState }) => (
                                            <FormControlLabel
                                                {...field}
                                                error={!!fieldState.error}
                                                control={<Checkbox />}
                                                label={
                                                    <Typography color='primary.gray100'>
                                                        {t(
                                                            'activities:medications:add-medications:labels:different_dosage'
                                                        )}
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </Grid>
                        </Body>

                        <Footer>
                            <GhostButton onClick={onClose}>
                                {t('activities:medications:add-medications:buttons:cancel')}
                            </GhostButton>

                            <ButtonWithCheck type='submit' disabled={disabled}>
                                {t('activities:medications:add-medications:buttons:save')}
                            </ButtonWithCheck>
                        </Footer>
                    </form>
                </Wrapper>
            </Container>
        </Modal>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
}));

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '560px',
    maxWidth: '560px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px'
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '25px 30px 20px 30px',
    borderBottom: '1px solid',
    borderColor: theme.palette.primary.gray10
}));

const CloseButton = styled(Close)(({ theme }) => ({
    cursor: 'pointer'
}));

const Body = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '25px 30px 20px 30px'
}));

const Footer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    gap: '5px',
    padding: '25px 30px 20px 30px',
    borderTop: '1px solid',
    borderColor: theme.palette.primary.gray10
}));
