import { Box, styled } from '@mui/material';
import { FilledButton } from 'configUI/components/Buttons';

export const Header = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
});

export const Content = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: '100%'
});

export const SignButton = styled(FilledButton)(({ theme }) => ({
    svg: {
        width: '20px'
    }
}));
