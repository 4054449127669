import i18next from 'i18next';
import adhocEN from 'internationalization/en/activities/adhoc.json';
import cancelEN from 'internationalization/en/activities/cancel.json';
import emrEN from 'internationalization/en/activities/emr.json';
import eq5d5lEN from 'internationalization/en/activities/eq5d5l.json';
import activitiesEN from 'internationalization/en/activities/index.json';
import medicationsEN from 'internationalization/en/activities/medications.json';
import thankYouEN from 'internationalization/en/activities/thank-you.json';
import consentItemEN from 'internationalization/en/components/consent-item.json';
import contactEN from 'internationalization/en/home/contact.json';
import errorEN from 'internationalization/en/home/error.json';
import faqEN from 'internationalization/en/home/faq.json';
import homeEN from 'internationalization/en/home/home.json';
import howItWorksEN from 'internationalization/en/home/how-it-works.json';
import privacyEN from 'internationalization/en/home/privacy.json';
import resourcesEN from 'internationalization/en/home/resources.json';
import tarpeyoSteadyEN from 'internationalization/en/home/tarpeyo-steady.json';
import termsEN from 'internationalization/en/home/terms.json';
import mainConsentsEN from 'internationalization/en/main/consents.json';
import onboardingConsentsEN from 'internationalization/en/onboarding/consents.json';
import onboardingDemographicEN from 'internationalization/en/onboarding/demographic.json';
import onboardingDisqualifiedEN from 'internationalization/en/onboarding/disqualified.json';
import onboardingIgANEN from 'internationalization/en/onboarding/igan.json';
import onboardingIndexEN from 'internationalization/en/onboarding/index.json';
import onboardingPersonalInformationEN from 'internationalization/en/onboarding/personal-information.json';
import phoneEN from 'internationalization/en/onboarding/phone.json';
import onboardingPrescreeningEN from 'internationalization/en/onboarding/prescreening.json';
import profileConsentsEN from 'internationalization/en/profile/consents.json';
import profileIndexEN from 'internationalization/en/profile/index.json';
import paymentsEN from 'internationalization/en/profile/payments.json';
import profileRegistryEN from 'internationalization/en/profile/registry.json';
import profileTokenizationEN from 'internationalization/en/profile/tokenization.json';
import profileViewEN from 'internationalization/en/profile/view.json';
import profileWithdrawEN from 'internationalization/en/profile/withdraw.json';
import progressEN from 'internationalization/en/progress/index.json';
import progressMobileEN from 'internationalization/en/progress/mobile.json';
import adhocES from 'internationalization/es/activities/adhoc.json';
import cancelES from 'internationalization/es/activities/cancel.json';
import emrES from 'internationalization/es/activities/emr.json';
import eq5d5lES from 'internationalization/es/activities/eq5d5l.json';
import activitiesES from 'internationalization/es/activities/index.json';
import medicationsES from 'internationalization/es/activities/medications.json';
import thankYouES from 'internationalization/es/activities/thank-you.json';
import consentItemES from 'internationalization/es/components/consent-item.json';
import contactES from 'internationalization/es/home/contact.json';
import errorES from 'internationalization/es/home/error.json';
import faqES from 'internationalization/es/home/faq.json';
import homeES from 'internationalization/es/home/home.json';
import howItWorksES from 'internationalization/es/home/how-it-works.json';
import privacyES from 'internationalization/es/home/privacy.json';
import resourcesES from 'internationalization/es/home/resources.json';
import tarpeyoSteadyES from 'internationalization/es/home/tarpeyo-steady';
import termsES from 'internationalization/es/home/terms.json';
import mainConsentsES from 'internationalization/es/main/consents.json';
import onboardingConsentsES from 'internationalization/es/onboarding/consents.json';
import onboardingDemographicES from 'internationalization/es/onboarding/demographic.json';
import onboardingDisqualifiedES from 'internationalization/es/onboarding/disqualified.json';
import onboardingIgANES from 'internationalization/es/onboarding/igan.json';
import onboardingIndexES from 'internationalization/es/onboarding/index.json';
import onboardingPersonalInformationES from 'internationalization/es/onboarding/personal-information.json';
import phoneES from 'internationalization/es/onboarding/phone.json';
import onboardingPrescreeningES from 'internationalization/es/onboarding/prescreening.json';
import profileConsentsES from 'internationalization/es/profile/consents.json';
import profileIndexES from 'internationalization/es/profile/index.json';
import paymentsES from 'internationalization/es/profile/payments.json';
import profileRegistryES from 'internationalization/es/profile/registry.json';
import profileTokenizationES from 'internationalization/es/profile/tokenization.json';
import profileViewES from 'internationalization/es/profile/view.json';
import profileWithdrawES from 'internationalization/es/profile/withdraw.json';
import progressES from 'internationalization/es/progress/index.json';
import progressMobileES from 'internationalization/es/progress/mobile.json';
import { initReactI18next } from 'react-i18next';

export const LANGUAGES = {
    ENGLISH: 'us_en',
    SPANISH: 'us_es'
};

export const resources = {
    [LANGUAGES.ENGLISH]: {
        activities: {
            index: activitiesEN,
            eq5d5l: eq5d5lEN,
            emr: emrEN,
            cancel: cancelEN,
            medications: medicationsEN,
            adhoc: adhocEN,
            thankYou: thankYouEN
        },
        components: {
            'consent-item': consentItemEN
        },
        welcome: {
            home: homeEN,
            howItWorks: howItWorksEN,
            tarpeyoSteady: tarpeyoSteadyEN,
            faq: faqEN,
            contact: contactEN,
            terms: termsEN,
            resources: resourcesEN,
            privacy: privacyEN,
            error: errorEN
        },
        profile: {
            index: profileIndexEN,
            view: profileViewEN,
            consents: profileConsentsEN,
            registry: profileRegistryEN,
            withdraw: profileWithdrawEN,
            tokenization: profileTokenizationEN,
            payments: paymentsEN
        },
        onboarding: {
            index: onboardingIndexEN,
            consents: onboardingConsentsEN,
            disqualified: onboardingDisqualifiedEN,
            prescreening: onboardingPrescreeningEN,
            demographic: onboardingDemographicEN,
            personalInformation: onboardingPersonalInformationEN,
            igan: onboardingIgANEN,
            phone: phoneEN
        },
        progress: {
            index: progressEN,
            mobile: progressMobileEN
        },
        main: {
            consents: mainConsentsEN
        }
    },
    [LANGUAGES.SPANISH]: {
        activities: {
            index: activitiesES,
            eq5d5l: eq5d5lES,
            emr: emrES,
            cancel: cancelES,
            medications: medicationsES,
            adhoc: adhocES,
            thankYou: thankYouES
        },
        components: {
            'consent-item': consentItemES
        },
        welcome: {
            home: homeES,
            howItWorks: howItWorksES,
            tarpeyoSteady: tarpeyoSteadyES,
            faq: faqES,
            contact: contactES,
            terms: termsES,
            resources: resourcesES,
            privacy: privacyES,
            error: errorES
        },
        profile: {
            index: profileIndexES,
            view: profileViewES,
            consents: profileConsentsES,
            registry: profileRegistryES,
            withdraw: profileWithdrawES,
            tokenization: profileTokenizationES,
            payments: paymentsES
        },
        onboarding: {
            index: onboardingIndexES,
            consents: onboardingConsentsES,
            disqualified: onboardingDisqualifiedES,
            prescreening: onboardingPrescreeningES,
            demographic: onboardingDemographicES,
            personalInformation: onboardingPersonalInformationES,
            igan: onboardingIgANES,
            phone: phoneES
        },
        progress: {
            index: progressES,
            mobile: progressMobileES
        },
        main: {
            consents: mainConsentsES
        }
    }
};

i18next.use(initReactI18next).init({
    lng: LANGUAGES.ENGLISH,
    defaultNS: LANGUAGES.ENGLISH,
    resources,
    returnObjects: true
});

export * from 'internationalization/LanguageNotifier';

