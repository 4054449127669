import { Link, Stack } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Box, styled } from '@mui/system';
import { FilledButton } from 'components/Buttons';
import background from 'images/background.svg';
import { ReactComponent as BloomLogo } from 'images/bloom-logo.svg';

export const OnboardingContainer = styled('div')({
  backgroundImage: `url(${background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  minHeight: '100vh',
});

export const Logo = styled(BloomLogo)({
  position: 'absolute',
  width: '70px',
  left: '20px',
  top: '20px',
});

export const HealthProgressBar = styled(LinearProgress)(({ theme }) => ({
  alignSelf: 'center',
  height: 7,
  width: 75,
  marginTop: 50,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.primary.gray10,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.fuchsia75,
  },
}));

export const ContentWrapper = styled(Box)({
  position: 'absolute',
  margin: '0 auto',
  width: '400px',
  display: 'grid',
  justifyItems: 'center',
  rowGap: '30px',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const BottomContainer = styled(Stack)(() => ({
  width: '80%',
  alignSelf: 'center',
  marginBottom: '15px',
}));

export const ContinueButton = styled(FilledButton)(() => ({
  fontSize: '16px',
  fontWeight: '700',
  marginBottom: '24px',

  svg: {
    width: '15px',
    marginLeft: '5px',
  },
}));

export const CountrySelectContainer = styled('div')(() => ({
  width: '80%',
  alignSelf: 'center',
  marginTop: '40px',
}));

export const SkipLink = styled(Link)(() => ({
  fontWeight: '600',
  fontSize: '16px',
}));

export const Question = styled('div')(({ theme }) => ({
  color: theme.palette.primary.gray7,
  fontSize: 18,
  fontWeight: 600,
  margin: '40px 50px 0px 50px',
  textAlign: 'center',
  alignSelf: 'center',
}));

export const RadioButtonsContainer = styled(Box)(() => ({
  margin: '40px 30px 30px',
  width: '80%',
  alignSelf: 'center',
}));

export const ThanksMessage = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  fontSize: 20,
  fontWeight: 600,
  margin: '40px 50px 0px 50px',
  textAlign: 'center',
  alignSelf: 'center',
}));

export const FinalMessage = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  fontSize: 14,
  fontWeight: 400,
  margin: '20px 60px 0px 60px',
  textAlign: 'center',
  alignSelf: 'center',
}));

export const FinalIconContainer = styled(Box)({
  alignSelf: 'center',
  marginTop: '80px',
});
