import { Box } from '@mui/material';
import { Content, Header, Footer, Main, Scrollable } from './BaseLayout.styles';

export const BaseLayout = ({ header, content, footer, fixedFooter = false, className, scrollBg, ...rest }) => (
    <Main className={`BaseLayout-main ${className}`} {...rest}>
        {header !== null && <Header className='BaseLayout-header'>{header}</Header>}
        <Scrollable className='BaseLayout-scrollable' scrollBg={scrollBg}>
            <Content className='BaseLayout-content'>{content}</Content>
            {!fixedFooter && <Box className='BaseLayout-footer'>{footer}</Box>}
        </Scrollable>
        {fixedFooter && <Footer className='BaseLayout-footer'>{footer}</Footer>}
    </Main>
);
