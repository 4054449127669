import { Box, Typography, styled } from '@mui/material';
import React from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
    padding: '30px',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
}));

export const Card = ({ title, description, icon }) => {
    return (
        <StyledBox>
            <Box marginBottom='1.5em'>{icon}</Box>
            <Box>
                <Typography variant='h5' color="primary.gray100" marginBottom='2px'>
                    {title}
                </Typography>
                <Typography variant='pl3' align='center' color="primary.gray75" lineHeight="17.4px">
                    {description}
                </Typography>
            </Box>
        </StyledBox>
    );
};

export const CardContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '1fr 1fr 1fr',
    margin: '50px auto 50px auto',
    maxWidth: '1080px',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
    },
}));