import { Box, Grid, Typography } from '@mui/material';
import { useGetPerson } from 'authentication';
import { PPRLogo as Logo } from 'components/PPRLogo';
import { APP_ROUTES } from 'navigation';
import { PARTICIPANT_STATUS } from 'pages/onboarding/constants';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { CaregiverPrescreeningFormLogic } from './forms/Caregiver';
import { PatientPrescreeningFormLogic } from './forms/Patient';
import { Container, Wrapper } from './styles';

export function PrescreeningPage() {
    const { t } = useTranslation();
    const { status, isCaregiver } = useGetPerson();
    const Form = isCaregiver ? CaregiverPrescreeningFormLogic : PatientPrescreeningFormLogic;

    if (status !== PARTICIPANT_STATUS.AUTHENTICATED) {
        return <Navigate to={APP_ROUTES.ONBOARDING} replace />;
    }

    return (
        <Wrapper>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Logo />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid item xs={12} marginBottom='30px'>
                            <Typography variant='h3' textAlign='center' gutterBottom>
                                {t('onboarding:prescreening:page:title')}
                            </Typography>

                            <Typography color='primary.gray75' variant='pl3' component='p' textAlign='center'>
                                {t('onboarding:prescreening:page:subtitle')}
                            </Typography>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Form />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Wrapper>
    );
}
