/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useGetPatient, useGetPerson, withAuth } from 'authentication';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { LanguageProvider } from 'internationalization';
import { useRedirections } from 'navigation';
import { isValidSourceName } from 'pages/Welcome/SourceNames';
import { Button, Container, Logo, Text, Title, Wrapper } from 'pages/onboarding/EmailVerified/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function Page() {
    const { getAccessTokenSilently } = useAuth0();
    const { redirectToOnboarding } = useRedirections();
    const { t } = useTranslation();
    const { isLoading, id: personId, isCaregiver } = useGetPerson();
    const { id: patientId, isLoading: isPatientLoading } = useGetPatient();
    const [isAuth0Loading, setIsAuth0Loading] = useState(false);

    const [editPersonAttribute, { isError: isPersonAttributesError }] = useEditPersonAttributesMutation();

    async function redirect() {
        setIsAuth0Loading(true);

        try {
            for (const key in localStorage) {
                if (key.includes('auth0')) {
                    localStorage.removeItem(key);
                }
            }

            await getAccessTokenSilently();
            await createRefSourceName();
        } finally {
            setIsAuth0Loading(false);
            redirectToOnboarding();
        }
    }

    const createRefSourceName = async () => {
        const finalId = isCaregiver ? patientId : personId;
        const sourceName = localStorage.getItem('sourcename');

        if (isValidSourceName(sourceName)) {
            const refSourceName = {
                data: {
                    type: 'person-attributes',
                    attributes: {
                        attribute: 'ref_source_name',
                        value: sourceName.toUpperCase()
                    }
                }
            };

            await editPersonAttribute({
                personId: finalId,
                payload: [refSourceName]
            });

            localStorage.removeItem('sourcename');
        }
    };

    if (isLoading || (isCaregiver && isPatientLoading)) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flex={1}
                height='100vh'
                bgcolor='primary.gray20'
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container>
            <Wrapper>
                <Logo />
                <Title>{t('onboarding:index:info:email-verified:title')}</Title>
                <Text>{t('onboarding:index:info:email-verified:subtitle')}</Text>

                <Button
                    onClick={redirect}
                    disabled={isLoading || isAuth0Loading}
                    {...(isLoading || isAuth0Loading
                        ? {
                              startIcon: <CircularProgress size={24} color='secondary' />
                          }
                        : {})}
                >
                    {t('onboarding:index:info:email-verified:button-text')}
                </Button>
            </Wrapper>
        </Container>
    );
}

export const EmailVerifiedPage = withAuth(() => (
    <LanguageProvider>
        <Page />
    </LanguageProvider>
));
