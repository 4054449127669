import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { useLoading } from 'components/loading';
import { getBaseURL } from 'config/API';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
    authenticate as bloomReduxAuthenticate,
    logout as bloomReduxLogout
} from 'ihp-bloom-redux/features/authentication/authenticationSlice';
import { useGetStudyRolesNoFilterQuery } from 'ihp-bloom-redux/features/study/studyApiV3';
import { useGetSubjectRelationshipsQuery } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { useGetCurrentUserQuery, useGetUserAttributesQuery } from 'ihp-bloom-redux/features/user/userApiSliceV3';
import { APP_ROUTES, useAuth0Redirections, useRedirections } from 'navigation';
import { useCallback, useMemo } from 'react';
import { persistor, queryClient } from '../App';

export const useGetPersonRelationship = () => {
    const { personId } = useGetCurrentUser();
    const { role } = useGetPerson();

    // TODO: If this query fail and it is caregiver, we should logout or try again
    const data = useGetSubjectRelationshipsQuery(personId, {
        skip: !personId || role !== 'caregiver',
        selectFromResult: ({ data, ...rest }) => {
            return {
                ...rest,
                patientId: data?.data?.[0]?.attributes?.associate_person_id
            };
        }
    });

    return data;
};

export const useCheckUserStatus = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { redirectToError, redirectToOnboarding, redirectToProgress } = useRedirections();
    const { id: personId } = useGetPerson();

    const verifyUserStatus = async (expectedStatus) => {
        console.log('DEBUG - VERIFY USER STATUS', {
            personId
        });

        const headers = new Headers();
        headers.set('Content-Type', 'application/json');

        try {
            const token = await getAccessTokenSilently();
            if (token) headers.set('Authorization', `Bearer ${token}`);
        } catch (error) {
            redirectToError();
            console.error(error);
            return false;
        }

        const response = await fetch(getBaseURL() + `/v3/people/${personId}?include=attributes`, {
            headers
        });

        try {
            const data = await response.json();
            const person = data?.data;
            const status = person?.attributes?.status;

            if (status === 'enrolled') {
                redirectToProgress(true);
                return false;
            }

            if (expectedStatus !== status) {
                queryClient.invalidateQueries(['attributes']);
                redirectToOnboarding();
                return false;
            } else {
                return true;
            }
        } catch (error) {
            redirectToError();
            console.log(error);
            return false;
        }
    };

    return {
        verifyUserStatus
    };
};

export const useGetPerson = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { personId } = useGetCurrentUser();

    const { user, isLoading: isAuth0Loading } = useAuth0();
    const email = user?.email;

    const {
        isFetching: isPersonAttributesFetching,
        isError: isPersonAttributesError,
        isSuccess: isPersonAttributesSuccess,
        data: response,
        refetch,
        ...rest
    } = useQuery({
        queryKey: ['attributes'],
        enabled: typeof personId === 'number',
        queryFn: async () => {
            console.log('DEBUG - USE GET PERSON', {
                personId
            });

            const headers = new Headers();
            headers.set('Content-Type', 'application/json');

            try {
                const token = await getAccessTokenSilently();
                if (token) headers.set('Authorization', `Bearer ${token}`);
            } catch (error) {
                console.error(error);
            }

            const response = await fetch(
                getBaseURL() + `/v3/people/${personId}?include=attributes,study-roles,person-relationships`,
                {
                    headers
                }
            );

            try {
                const data = await response.json();

                return {
                    ...data,
                    person: data?.data,
                    attributes: data?.included
                };
            } catch (error) {
                console.log(error);
            }
        }
    });

    const person = response?.person;
    const attributes = response?.attributes;

    const {
        roles,
        isLoading: isStudyRoleLoading,
        isFetching: isStudyRolesFetching,
        isError: isStudyRolesError,
        isSuccess: isStudyRolesSuccess
    } = useGetStudyRolesNoFilterQuery(undefined, {
        skip: !person,
        selectFromResult: ({ data, ...rest }) => ({
            roles: data?.data,
            ...rest
        })
    });

    const data = useMemo(() => {
        const status = person?.attributes?.status;
        const statusReason = person?.attributes?.status_reason;

        const firstName = attributes?.find((attribute) => attribute.attributes.attribute === 'first_name')?.attributes
            .value;

        const lastName = attributes?.find((attribute) => attribute.attributes.attribute === 'last_name')?.attributes
            .value;

        const address_1 = attributes?.find((attribute) => attribute.attributes.attribute === 'address_1')?.attributes
            .value;

        const address_2 = attributes?.find((attribute) => attribute.attributes.attribute === 'address_2')?.attributes
            .value;

        const timezone = attributes?.find((attribute) => attribute.attributes.attribute === 'timezone')?.attributes
            .value;

        const zip_code = attributes?.find((attribute) => attribute.attributes.attribute === 'postal_code')?.attributes
            .value;

        const sex_at_birth = attributes?.find((attribute) => attribute.attributes.attribute === 'sex_at_birth')
            ?.attributes.value;

        const language = attributes?.find((attribute) => attribute.attributes.attribute === 'language')?.attributes
            .value;

        const city = attributes?.find((attribute) => attribute.attributes.attribute === 'city')?.attributes.value;
        const phone = attributes?.find((attribute) => attribute.attributes.attribute === 'phone')?.attributes.value;
        const phoneVerified = attributes?.find((attribute) => attribute.attributes.attribute === 'sms_ph_verified')
            ?.attributes.value;

        const sms_notification = attributes?.find((attribute) => attribute.attributes.attribute === 'sms_notification')
            ?.attributes.value;

        const state = attributes?.find((attribute) => attribute.attributes.attribute === 'state')?.attributes.value;
        const gender = attributes?.find((attribute) => attribute.attributes.attribute === 'gender')?.attributes.value;
        const race = attributes?.find((attribute) => attribute.attributes.attribute === 'race')?.attributes.value;
        const ethnicity = attributes?.find((attribute) => attribute.attributes.attribute === 'ethnicity')?.attributes
            .value;

        const roleId = attributes?.find((attribute) => typeof attribute.attributes.study_role_id === 'number')
            ?.attributes.study_role_id;

        const role = roles?.find((role) => role.id === String(roleId))?.attributes.name;

        const hadTarpeyo =
            attributes?.find((attribute) => attribute.attributes.attribute === 'patient_had_tarpeyo')?.attributes
                .value === '1';

        const isLiteVersion =
            attributes?.find((attribute) => attribute.attributes.attribute === 'lite_ver_enrolled')?.attributes
                .value === '1';

        const iganAgeDiagnosis = attributes?.find(
            (attribute) => attribute.attributes.attribute === 'age_at_IgAN_diagnosis'
        )?.attributes.value;

        const iganHasParticipated = attributes?.find(
            (attribute) => attribute.attributes.attribute === 'clinical_trial_participation'
        )?.attributes.value;

        const tokenizationConsent = attributes?.find(
            (attribute) => attribute.attributes.attribute === 'tokenization_consent'
        )?.attributes.value;

        return {
            id: person?.id,
            status,
            statusReason,
            firstName,
            lastName,
            email,
            city,
            state,
            address_1,
            address_2,
            zip_code,
            sex_at_birth,
            timezone,
            role: role === 'participant' ? 'patient' : 'caregiver',
            roleId,
            hadTarpeyo,
            isLiteVersion,
            language,
            gender,
            race,
            phone,
            phoneVerified,
            ethnicity,
            iganAgeDiagnosis,
            iganHasParticipated,
            sms_notification,
            tokenizationConsent,
            isCaregiver: role === 'caregiver',
            isLoading:
                isStudyRoleLoading ||
                isAuth0Loading ||
                isPersonAttributesFetching ||
                isStudyRolesFetching ||
                !isStudyRolesSuccess ||
                !isPersonAttributesSuccess,
            isSuccess: isPersonAttributesSuccess && isStudyRolesSuccess
        };
    }, [
        attributes,
        email,
        isAuth0Loading,
        isPersonAttributesFetching,
        isPersonAttributesSuccess,
        isStudyRoleLoading,
        isStudyRolesFetching,
        isStudyRolesSuccess,
        person?.attributes?.status,
        person?.attributes?.status_reason,
        person?.id,
        roles
    ]);

    console.log('LOAD PA', {
        isStudyRoleLoading,
        isAuth0Loading,
        isPersonAttributesFetching,
        isStudyRolesFetching: {
            isSuccess: isPersonAttributesSuccess,
            refetch,
            isFetching: isPersonAttributesFetching,
            isError: isPersonAttributesError,
            ...rest
        }
    });

    // useEffect(() => {
    //     if (isPersonAttributesSuccess && person) {
    //         const { id, status, firstName, lastName, email } = data;
    //         dispatch(setValues({ id, status, firstName, lastName, email }));
    //     }
    // }, [isPersonAttributesSuccess, person, data, dispatch]);

    return {
        ...data,
        refetch,
        isError: isPersonAttributesError || isStudyRolesError
    };
};

export const useGetPatient = () => {
    const { getAccessTokenSilently } = useAuth0();

    const {
        patientId,
        isLoading: isRelationshipLoading,
        isFetching: isPersonRelationshipFetching,
        isSuccess: isPersonRelationshipSuccess,
        isError: isPersonRelationshipError
    } = useGetPersonRelationship();

    const {
        isFetching: isPersonAttributesLoading,
        isError: isPersonAttributesError,
        isSuccess: isPersonAttributesSuccess,
        data: response,
        refetch
    } = useQuery({
        queryKey: ['patient-attributes'],
        enabled: typeof patientId === 'number',
        queryFn: async () => {
            console.log('DEBUG - USE GET PATIENT', {
                patientId
            });

            const headers = new Headers();
            headers.set('Content-Type', 'application/json');

            try {
                const token = await getAccessTokenSilently();
                if (token) headers.set('Authorization', `Bearer ${token}`);
            } catch (error) {
                console.error(error);
            }

            const response = await fetch(
                getBaseURL() + `/v3/people/${patientId}?include=attributes,study-roles,person-relationships`,
                {
                    headers
                }
            );

            try {
                const data = await response.json();

                return {
                    ...data,
                    person: data?.data,
                    attributes: data?.included
                };
            } catch (error) {
                console.log(error);
            }
        }
    });

    const person = response?.person;
    const attributes = response?.attributes;

    const data = useMemo(() => {
        const status = person?.attributes?.status;
        const statusReason = person?.attributes?.status_reason;

        const firstName = attributes?.find((attribute) => attribute.attributes.attribute === 'first_name')?.attributes
            .value;

        const lastName = attributes?.find((attribute) => attribute.attributes.attribute === 'last_name')?.attributes
            .value;

        const email = attributes?.find((attribute) => attribute.attributes.attribute === 'email')?.attributes.value;

        const address_1 = attributes?.find((attribute) => attribute.attributes.attribute === 'address_1')?.attributes
            .value;

        const address_2 = attributes?.find((attribute) => attribute.attributes.attribute === 'address_2')?.attributes
            .value;

        const timezone = attributes?.find((attribute) => attribute.attributes.attribute === 'timezone')?.attributes
            .value;

        const zip_code = attributes?.find((attribute) => attribute.attributes.attribute === 'postal_code')?.attributes
            .value;

        const sex_at_birth = attributes?.find((attribute) => attribute.attributes.attribute === 'sex_at_birth')
            ?.attributes.value;

        const date_of_birth = attributes?.find((attribute) => attribute.attributes.attribute === 'date_of_birth')
            ?.attributes.value;

        const city = attributes?.find((attribute) => attribute.attributes.attribute === 'city')?.attributes.value;
        const phone = attributes?.find((attribute) => attribute.attributes.attribute === 'phone')?.attributes.value;

        const state = attributes?.find((attribute) => attribute.attributes.attribute === 'state')?.attributes.value;
        const gender = attributes?.find((attribute) => attribute.attributes.attribute === 'gender')?.attributes.value;
        const race = attributes?.find((attribute) => attribute.attributes.attribute === 'race')?.attributes.value;
        const ethnicity = attributes?.find((attribute) => attribute.attributes.attribute === 'ethnicity')?.attributes
            .value;

        const roleId = attributes?.find((attribute) => typeof attribute.attributes.study_role_id === 'number')
            ?.attributes.study_role_id;

        const hadTarpeyo =
            attributes?.find((attribute) => attribute.attributes.attribute === 'patient_had_tarpeyo')?.attributes
                .value === '1';

        const isLiteVersion =
            attributes?.find((attribute) => attribute.attributes.attribute === 'lite_ver_enrolled')?.attributes
                .value === '1';

        const iganAgeDiagnosis = attributes?.find(
            (attribute) => attribute.attributes.attribute === 'age_at_IgAN_diagnosis'
        )?.attributes.value;

        const iganHasParticipated = attributes?.find(
            (attribute) => attribute.attributes.attribute === 'clinical_trial_participation'
        )?.attributes.value;

        const tokenizationConsent = attributes?.find(
            (attribute) => attribute.attributes.attribute === 'tokenization_consent'
        )?.attributes.value;

        return {
            id: person?.id,
            status,
            statusReason,
            firstName,
            lastName,
            email,
            city,
            state,
            address_1,
            address_2,
            zip_code,
            sex_at_birth,
            date_of_birth,
            timezone,
            roleId,
            phone,
            hadTarpeyo,
            isLiteVersion,
            gender,
            race,
            ethnicity,
            iganAgeDiagnosis,
            iganHasParticipated,
            tokenizationConsent,
            isLoading:
                isPersonAttributesLoading ||
                isRelationshipLoading ||
                isPersonRelationshipFetching ||
                !isPersonRelationshipSuccess ||
                !isPersonAttributesSuccess,
            isSuccess: isPersonAttributesSuccess && isPersonRelationshipSuccess
        };
    }, [
        person,
        attributes,
        isPersonAttributesLoading,
        isRelationshipLoading,
        isPersonRelationshipFetching,
        isPersonRelationshipSuccess,
        isPersonAttributesSuccess
    ]);

    return {
        ...data,
        refetch,
        isError: isPersonRelationshipError || isPersonAttributesError
    };
};

export const useGetCurrentUser = () => {
    const { setIsLoading } = useLoading();
    const { isAuthenticated } = useSelector((state) => state.authentication);
    const dispatch = useDispatch();

    const {
        user,
        isLoading: isCurrentUserLoading,
        isFetching: isCurrentUserFetching,
        isSuccess: isCurrentUserSuccess,
        ...rest
    } = useGetCurrentUserQuery(undefined, {
        skip: !isAuthenticated,
        selectFromResult: ({ data, ...rest }) => ({ user: data?.data, ...rest })
    });

    const {
        attributes,
        isLoading: areAttributesLoading,
        isFetching: areAttributesFetching,
        isSuccess: areAttributesSuccess
    } = useGetUserAttributesQuery(user?.id, {
        skip: user?.id === undefined,
        selectFromResult: ({ data, ...rest }) => ({ attributes: data?.data, ...rest })
    });

    const isLoading =
        isCurrentUserLoading ||
        isCurrentUserFetching ||
        !isCurrentUserSuccess ||
        areAttributesLoading ||
        areAttributesFetching ||
        !areAttributesSuccess;

    const personId = user?.attributes?.person_id;

    // useEffect(() => {
    //     setIsLoading(isLoading);
    // }, [isLoading]);

    return {
        user,
        isLiteVersion: attributes?.some((attribute) => attribute.attributes.attribute === 'lite_ver_enrolled'),
        personId,
        isLoading,
        isSuccess: isCurrentUserSuccess && areAttributesSuccess,
        ...rest
    };
};

export const useAuthenticate = () => {
    const dispatch = useDispatch();
    const { setIsLoading } = useLoading();
    const { getAccessTokenSilently } = useAuth0();
    const { logout } = useLogout();
    const { redirectToLogin } = useAuth0Redirections();

    const authenticate = useCallback(async () => {
        try {
            setIsLoading(true);
            const accessToken = await getAccessTokenSilently();
            dispatch(bloomReduxAuthenticate(accessToken));
        } catch (error) {
            logout();
            redirectToLogin();
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { authenticate };
};

export const useLogout = (to = APP_ROUTES.WELCOME) => {
    const { setIsLoading } = useLoading();
    const { logout: auth0Logout } = useAuth0();
    const dispatch = useDispatch();

    const logout = useCallback(() => {
        setIsLoading(true);
        dispatch(bloomReduxLogout());
        sessionStorage.clear();
        persistor.purge();
        auth0Logout({ returnTo: `${window.location.origin}${to}` });
    }, []);

    return { logout };
};
