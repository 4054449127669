import { Box, Chip, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from 'images/profile/check-white.svg';

export const ActivityDoneContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    maxWidth: '325px',
    width: '100%'
});

export const RewardBadge = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.gray75,
    backgroundColor: theme.palette.primary.gray10,
    border: `1px solid ${theme.palette.primary.gray10}`,
    borderRadius: '8px',
    boxSizing: 'border-box',
    height: '63px',
    width: '100%',
    position: 'relative',
    padding: '10px'
}));

export const RewardPoints = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: '700',
    color: theme.palette.primary.gray100
}));

export const LeafContainer = styled('div')(({ theme, noPadding, svgWidth, svgHeight }) => ({
    padding: `${noPadding ? '0px' : '8px 10px'}`,
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    backgroundColor: `${noPadding ? theme.palette.primary.transparent : theme.palette.primary.white}`,
    borderRadius: '50px',
    svg: {
        width: svgWidth,
        height: svgHeight
    }
}));

export const Goals = styled('div')({
    margin: '16px 0px'
});

export const Goal = styled('div')(({ theme }) => ({
    width: '100%',
    '& .reward-container': {
        width: '100%',
        color: theme.palette.primary.gray75,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px'
    }
}));

export const GoalTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray75,
    b: {
        color: theme.palette.primary.gray100
    }
}));

export const StyledSlider = styled(Slider)(({ theme, isCompleted, backgroundColor }) => ({
    '& .MuiSlider-thumb': {
        height: '18px',
        width: '18px',
        color: '#ffffff',
        backgroundColor: backgroundColor,
        backgroundImage: `url(${CheckIcon})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 13px',
        display: `${isCompleted ? 'flex' : 'none'}`,
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit'
        }
    },
    '& .MuiSlider-rail': {
        backgroundColor: theme.palette.primary.gray10,
        height: '8px'
    },
    '& .MuiSlider-track': {
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        height: '8px'
    }
}));

export const StyledChip = styled(Chip)(({ theme, isCompleted, fontWeight = '500' }) => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: fontWeight,
    fontSize: '12px',
    lineHeight: '145%',
    color: theme.palette.primary.gray75,
    background: theme.palette.primary.white,
    borderRadius: '4px',
    margin: '0 2.5px',
    svg: {
        width: '20px',
        height: '20px'
    }
}));
