export const surveyDataES = {
    0: {
        id: 0,
        type: 'skip'
    },
    1: {
        id: 1,
        title: 'Introduzca su número de teléfono',
        caregiverTitle: 'Introduzca su número de teléfono',
        type: 'phone',
        label: 'Phone',
        answer: '',
        values: [
            {
                label: 'Introducir número de teléfono',
                caregiverLabel: 'Introducir número de teléfono',
                value: ''
            }
        ]
    }
};
