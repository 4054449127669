import { Box } from '@mui/material';
import { FilledButton } from 'components/Buttons';
import { Loader } from 'components/loading';
import { ReactComponent as DoneCheckmarkIcon } from 'images/shared/done-checkmark.svg';
import { useRedirections } from 'navigation';
import { useActiveActivity } from 'pages/Main';
import { useActivities } from 'pages/NewActivities/Provider';
import { mapActivities } from 'pages/NewActivities/utils';
import { Amount, DollarSign, SuccessBox } from 'pages/onboarding/PaymentEarned/styles';
import { useTranslation } from 'react-i18next';
import { ThankYouPageWrapper, Container, HelperText, PageTitle, Row } from './styles';

export function TaskThankYouPage() {
    document.title = 'Thank you';
    const { redirectToActivities } = useRedirections();
    const { t } = useTranslation();

    const {
        activeActivity: { id, title }
    } = useActiveActivity();

    const {
        activities: { data, isLoading }
    } = useActivities();

    if (isLoading) {
        return <Loader />;
    }

    const mappedActivities = mapActivities(data);

    const subtasks = mappedActivities.find(
        (activity) => id === activity.data?.id || activity.subtasks?.find((subtask) => subtask.id === id)
    )?.subtasks;

    const subtasksCompletedCount = Array.isArray(subtasks)
        ? subtasks.filter((subtask) => subtask.status === 'completed').length
        : 0;

    const isGroupCompleted = Array.isArray(subtasks)
        ? subtasks.every((subtask) => subtask.status === 'completed')
        : true;

    const points = mappedActivities.find((activity) => activity.subtasks?.find((subtask) => subtask.id === id))?.point;

    return (
        <ThankYouPageWrapper>
            <Container>
                <Row>
                    <DoneCheckmarkIcon width={200} />
                </Row>

                <Row>
                    <PageTitle varient='h2'>
                        {t('activities:thankYou:titles')[title] ?? t('activities:thankYou:titles').default}
                    </PageTitle>
                </Row>

                <Row>
                    <HelperText varient='pl3'>
                        {isGroupCompleted
                            ? t('activities:thankYou:completed')
                            : t('activities:thankYou:task')
                                  .replace('{task}', subtasksCompletedCount)
                                  .replace('{amount}', subtasks.length)}
                    </HelperText>
                </Row>

                {isGroupCompleted && (
                    <Row>
                        <SuccessBox>
                            <Box display='flex' alignItems='center'>
                                <DollarSign>$</DollarSign>
                                <Amount>{points}</Amount>
                            </Box>
                            {t('activities:thankYou:payment')}
                        </SuccessBox>
                    </Row>
                )}

                <Row marginBottom={'0px'}>
                    <FilledButton onClick={redirectToActivities} fullWidth>
                        {t('activities:thankYou:done')}
                    </FilledButton>
                </Row>
            </Container>
        </ThankYouPageWrapper>
    );
}
