import { Box, Stack } from '@mui/material';
import { styled } from '@mui/system';

export const SpinnerContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '65vh',
});

export const Container = styled(Stack)(({ theme }) => ({
  height: '50vh',
  overflow: 'auto',
}));

export const NoProvidersSubtitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.gray50,
  fontWeight: 400,
  fontSize: '12px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  paddingTop: '5px',
  alignContent: 'center',
  justifyContent: 'center',
}));

export const SearchResultContainer = styled(Box)(({ theme }) => ({
  background: 'white',
  color: '#959CA0',
  fontWeight: 500,
  fontSize: '12px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  padding: '11px 0 11px 15px',
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: 'white',
  height: '300px',
  borderRadius: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
}));

export const StackContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  background: 'white',
  borderRadius: '0px 8px 8px 0px',
}));

export const NoProviderFoundText = styled(Box)(({ theme }) => ({
  color: '#2B3A41',
  fontWeight: 600,
  fontSize: '18px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  lineHeight: '140%',
  marginBottom: '20px',
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '400px',
}));
