import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { Container } from './styles';

export const Loader = () => {
    return (
        <Container>
            <CircularProgress color='primary' />
        </Container>
    );
};

Loader.defaultProps = {
    infoMessage: 'Loading'
};

Loader.propTypes = {
    infoMessage: PropTypes.string
};
