export const instructionsDataEN = [
    {
        id: 1,
        title: 'How do I share health records?',
        description: `Before you start, it may be useful to make a note of the healthcare portals you use. You will be
                    looking them up by name (such as, 'Patient Online Services'). Please have your login information
                    available before you begin.
                    <br />
                    <br />
                    In the next steps, you will 1. Search for the first healthcare portal on your list. 2. Log into
                    that healthcare portal. 3. Confirm permission to share the medical records. When you complete
                    those steps, you will be returned to the search screen, where you can add the next provider
                    portal.`
    },
    {
        id: 2,
        title: `Which doctor's records should I share for this study?`,
        description: `Please share records from the hospitals, clinics, or doctors involved in your care. Portals could include primary care doctor, Nephrologist, and other medical specialists involved in treating or managing health.`
    },
    {
        id: 3,
        title: `What records are needed for the study?`,
        description: `To understand the natural history of the condition, we will would like you to share your full record. Only those elements that are relevant to this study will be included in the study data.`
    },
    {
        id: 4,
        title: `What happens next?`,
        description: `Once you have shared records from each of your healthcare portals, click "Finish Sharing." That's it, you're all done!`
    },
    {
        id: 5,
        title: `What if I search and my provider portal isn't listed?`,
        description: `If your portal isn't listed, provide the name of the healthcare organization (such as "Mercy Hospital"), along with some location details. This information will help us improve portal access over the course of the study.`
    }
];