import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { StudyTabs, StudyTab, StudyTabPanel, OverviewTitle, OverviewContainer } from 'components/Study';
import {
    LeftStudyContainer,
    StudyContainer,
    TimelineContainer,
    OutlineButton,
    OverviewMainTitleContainer
} from 'components/Study';
import OverviewImage from 'images/timeline.png';
import { useRedirections } from 'navigation';
import { StyledBaseLayout } from 'pages/Profile/styles';
import { PARTICIPANT_STATUS } from 'pages/onboarding';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StudyOverviewModal from './overviewModal';
import Progress from './progress';
import Timeline from './timeline';
import { useParticipantsTasksData } from './useParticipantsTasksData';

export default function StudyMobile({
    selectedPanel,
    taskOccurrencies,
    totalTasks,
    tasks,
    isLoadingTasks,
    isWithdrawn
}) {
    const [tab, setTab] = useState(selectedPanel);
    const { t } = useTranslation();
    const theme = useTheme();
    const { redirectToFAQ } = useRedirections();

    const handleChange = (event, newSelectedTab) => {
        setTab(newSelectedTab);
    };

    const containerRef = useRef(null);

    useEffect(() => {
        containerRef?.current?.scrollTo(0, 0);
    }, [tab]);

    return (
        <Box>
            <StudyTabs value={tab} onChange={handleChange} aria-label='study tabs'>
                <StudyTab label={t('progress:mobile:progress')} />

                {(!isWithdrawn || (isWithdrawn && tasks?.completedTasks?.length > 0)) && (
                    <StudyTab label={t('progress:mobile:timeline')} />
                )}
            </StudyTabs>

            <Container padding='10px 20px' ref={containerRef}>
                <StudyTabPanel value={tab} index={0}>
                    <Progress taskOccurrencies={taskOccurrencies} totalTasks={totalTasks} />

                    <Box>
                        <OverviewTitle>{t('progress:index:sections:titles:study-progress')}</OverviewTitle>

                        <OverviewContainer sx={{ backgroundColor: theme.palette.primary.white }}>
                            <img alt='overview' src={OverviewImage} height='250px' width='150px' />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <OverviewMainTitleContainer>
                                    <Box marginBottom={2}>
                                        <Typography marginBottom={2} variant='h6'>
                                            {t('progress:index:study-progress:title')}
                                        </Typography>
                                        <Typography color='primary.gray75' variant='pl3'>
                                            {t('progress:index:study-progress:description')}
                                        </Typography>
                                    </Box>
                                    <OutlineButton
                                        variant='outlined'
                                        sx={{ width: 'fit-content' }}
                                        onClick={redirectToFAQ}
                                    >
                                        {t('progress:index:buttons:see-faq')}
                                    </OutlineButton>
                                </OverviewMainTitleContainer>
                            </Box>
                        </OverviewContainer>
                    </Box>
                </StudyTabPanel>

                <StudyTabPanel value={tab} index={1}>
                    <Timeline tasks={tasks} isLoadingTasks={isLoadingTasks} />
                </StudyTabPanel>
            </Container>
        </Box>
    );
}

const Container = styled(Box)(({ theme }) => ({
    overflow: 'hidden',
    overflowY: 'auto',
    height: 'calc(100dvh - 136px)'
}));
