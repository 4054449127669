import { FormControl, Grid, InputAdornment, MenuItem, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FilledButton } from 'components/Buttons';
import { TextInput } from 'components/Input/TextInput';
import { ErrorWrapper } from 'configUI/components';
import { ReactComponent as LocationIcon } from 'images/personalinformation/location.svg';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { US_STATES, menuItems } from './mock';
import { StyledFormLabel, StyledInputLabel, StyledSelect, WrappedTextMenuItem, getPlaceholderStyles } from './styles';

export const PersonalInformationForm = ({ form, onSubmit }) => {
    const { t } = useTranslation();
    const { control, formState, handleSubmit } = form;
    const { errors, isSubmitting, isValid } = formState;
    const disabled = isSubmitting || !isValid;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <StyledFormLabel>{t('onboarding:personalInformation:labels:address')}</StyledFormLabel>
                            <Typography fontSize='0.75rem' color='primary.gray75' mb='0.5rem' width='75%'>
                            {t('onboarding:personalInformation:address-description')}
                            </Typography>
                            <ErrorWrapper>
                                <Controller
                                    name='address_1'
                                    control={control}
                                    error={errors.address_1}
                                    render={({ field, fieldState }) => (
                                        <TextInput
                                            error={!!fieldState.error}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <LocationIcon color='#DFE2E3' />
                                                </InputAdornment>
                                            }
                                            fullWidth={true}
                                            placeholder={t('onboarding:personalInformation:placeholders:address')}
                                            disabled={isSubmitting}
                                            {...field}
                                        />
                                    )}
                                />
                            </ErrorWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <ErrorWrapper>
                                <Controller
                                    name='address_2'
                                    control={control}
                                    error={errors.address_2}
                                    render={({ field, fieldState }) => (
                                        <TextInput
                                            error={!!fieldState.error}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <LocationIcon color='#DFE2E3' />
                                                </InputAdornment>
                                            }
                                            fullWidth={true}
                                            placeholder={t('onboarding:personalInformation:placeholders:address2')}
                                            disabled={isSubmitting}
                                            {...field}
                                        />
                                    )}
                                />
                            </ErrorWrapper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <StyledFormLabel>{t('onboarding:personalInformation:labels:city')}*</StyledFormLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='city'
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    error={errors.city}
                                    render={({ field, fieldState }) => (
                                        <TextInput
                                            error={!!fieldState.error}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <LocationIcon color='#DFE2E3' />
                                                </InputAdornment>
                                            }
                                            fullWidth={true}
                                            placeholder={t('onboarding:personalInformation:placeholders:city')}
                                            disabled={isSubmitting}
                                            {...field}
                                        />
                                    )}
                                />
                            </ErrorWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledFormLabel>{t('onboarding:personalInformation:labels:state')}*</StyledFormLabel>
                            <ErrorWrapper>
                                <FormControl fullWidth>
                                    <Controller
                                        name='state'
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        error={errors.state}
                                        render={({ field, fieldState }) => {
                                            return (
                                                <>
                                                    <StyledInputLabel
                                                        sx={getPlaceholderStyles(field.value === '')}
                                                        id='state_label'
                                                    >
                                                        {t('onboarding:personalInformation:placeholders:state')}
                                                    </StyledInputLabel>
                                                    <StyledSelect
                                                        fullWidth
                                                        {...field}
                                                        error={!!fieldState.error}
                                                        label={t('onboarding:personalInformation:placeholders:state')}
                                                        labelId='state_label_label'
                                                        disabled={isSubmitting}
                                                    >
                                                        {US_STATES.map((item) => (
                                                            <MenuItem key={item} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </>
                                            );
                                        }}
                                    />
                                </FormControl>
                            </ErrorWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledFormLabel>{t('onboarding:personalInformation:labels:zip-code')}*</StyledFormLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='zip_code'
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    error={errors.zip_code}
                                    render={({ field, fieldState }) => (
                                        <TextInput
                                            error={!!fieldState.error}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <LocationIcon color='#DFE2E3' />
                                                </InputAdornment>
                                            }
                                            fullWidth={true}
                                            placeholder={t('onboarding:personalInformation:placeholders:zip-code')}
                                            disabled={isSubmitting}
                                            {...field}
                                        />
                                    )}
                                />
                            </ErrorWrapper>
                        </Grid>

                        <Grid item xs={12}>
                            <StyledFormLabel shrink={false}>{t('onboarding:personalInformation:labels:timezone')}*</StyledFormLabel>
                            <ErrorWrapper>
                                <FormControl fullWidth>
                                    <Controller
                                        name='timezone'
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        error={errors.timezone}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <StyledInputLabel
                                                    sx={getPlaceholderStyles(field.value === '')}
                                                    id='timezone_label'
                                                >
                                                    {t('onboarding:personalInformation:placeholders:timezone')}
                                                </StyledInputLabel>
                                                <StyledSelect
                                                    fullWidth
                                                    {...field}
                                                    error={!!fieldState.error}
                                                    label={t('onboarding:personalInformation:placeholders:timezone')}
                                                    labelId='timezone_label'
                                                    disabled={isSubmitting}
                                                >
                                                    {menuItems.map((item) => (
                                                        <WrappedTextMenuItem key={item.value} value={item.value}>
                                                            {item.value}
                                                        </WrappedTextMenuItem>
                                                    ))}
                                                </StyledSelect>
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </ErrorWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt='1.5rem'>
                    <FilledButton
                        disabled={disabled}
                        {...(isSubmitting
                            ? {
                                disabled: true,
                                startIcon: <CircularProgress size={24} color='secondary' />
                            }
                            : {})}
                        type='submit'
                        width='100%'
                    >
                        {t('onboarding:personalInformation:buttons:save')}
                    </FilledButton>
                </Grid>
            </Grid>
        </form>
    );
};
