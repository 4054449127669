export const surveyDataEN = {
    0: {
        id: 0,
        type: 'skip'
    },
    1: {
        id: 1,
        title: 'Please provide responses to the following two questions regarding IgAN.',
        caregiverTitle: 'Please provide responses to the following two questions regarding IgAN.',
        type: 'igAN',
        questions: [
            {
                id: 0,
                label: 'Age at diagnosis of IgAN',
                answer: '',
                values: [
                    {
                        label: 'Enter Age',
                        caregiverLabel: 'Enter Age',
                        value: ''
                    }
                ]
            },
            {
                id: 1,
                label: 'Have you participated in an IgAN clinical trial?',
                answer: '',
                values: [
                    {
                        label: 'Yes',
                        caregiverLabel: 'Yes',
                        value: 1
                    },
                    {
                        label: 'No',
                        caregiverLabel: 'No',
                        value: 0
                    }
                ]
            }
        ]
    }
};
