import { styled, Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { cards } from './mock';

const Card = ({ icon, title, description }) => {
    return (
        <Box display='flex' flexDirection='column' alignItems='center'>
            <Box marginBottom={'23px'}>{icon}</Box>
            <Typography variant='h5' textAlign={'center'} marginBottom='7px'>
                {title}
            </Typography>
            <Typography variant='pl3' align='center' color='primary.gray75'>
                {description}
            </Typography>
        </Box>
    );
};

const CardsContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 300px))',
    justifyContent: 'center',
    gridGap: '30px',
    margin: '4em auto 0 auto',
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr'
    }
}));

export const TarpeyoSteady = () => {
    const { t } = useTranslation();
    return (
        <Box margin='4em 0'>
            <Box display='flex' flexDirection='column' alignItems='center'>
                <Typography
                    variant='h1'
                    fontWeight='600'
                    margin='15px 0px'
                    textAlign='center'
                    fontSize={'30px'}
                    lineHeight={'40.5px'}
                >
                    {t('welcome:tarpeyoSteady:header')}
                </Typography>
                <Typography
                    variant='pn1'
                    display='block'
                    color='primary.gray75'
                    textAlign='center'
                    maxWidth='620px'
                    fontSize={'16px'}
                    fontWeight='400'
                >
                    <Trans>{t('welcome:tarpeyoSteady:subheader')}</Trans>
                </Typography>
                <Typography
                    marginTop='15px'
                    fontWeight={'bold'}
                    color='primary.gray100'
                    fontSize='18px'
                    lineHeight={'150%'}
                    fontStyle={'italic'}
                >
                    {t('welcome:tarpeyoSteady:tagline')}
                </Typography>
            </Box>

            <CardsContainer>
                {cards.map((card) => (
                    <Card
                        key={card.id}
                        icon={card.icon}
                        title={t('welcome:tarpeyoSteady:title')[card.id]}
                        description={t('welcome:tarpeyoSteady:description')[card.id]}
                    />
                ))}
            </CardsContainer>
        </Box>
    );
};
