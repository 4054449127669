// import { ReactComponent as Watch } from 'images/welcome/watch-fit.svg';
// import { ReactComponent as Hospital } from 'images/welcome/hospital.svg';
// import { ReactComponent as Doctor } from 'images/welcome/doctor.svg';
// import { ReactComponent as Laptop } from 'images/welcome/laptop.svg';

import { styled } from '@mui/system';

const Circle = styled('div')(({ theme }) => ({
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.gray,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    color: theme.palette.secondary.blue,
    fontSize: '24px',
    fontWeight: '700',
    textAlign: 'center'
}));

export const cards = [
    {
        id: 0,
        icon: <Circle>1</Circle>
    },
    {
        id: 1,
        icon: <Circle>2</Circle>
    },
    {
        id: 2,
        icon: <Circle>3</Circle>
    }
];
