import { styled } from '@mui/material';
import {
    Container as ActivityContainer,
    Header as ActivityHeader,
    Icon as ActivityIcon,
    Info,
    Title,
    Description,
    Points
} from 'pages/NewActivities/Activity';
import { ActivityGroupSubtask } from 'pages/NewActivities/ActivityGroupSubtask';
import { ClipboardListCheck } from 'pages/NewActivities/assets';
import { useTranslation } from 'react-i18next';

export function ActivityGroup(activity) {
    const { title, point, subtasks, status } = activity;
    const { t } = useTranslation();

    return (
        <Container>
            <Header>
                <Icon>
                    <ClipboardListCheck />
                </Icon>

                <Info>
                    <Title>{t('activities:index:tasks')?.[title]?.title}</Title>
                    <Description>{t('activities:index:tasks')?.[title]?.description}</Description>
                </Info>

                <Points status={status}>{point}</Points>
            </Header>

            {subtasks.map((activity, index) => (
                <ActivityGroupSubtask index={index} total={subtasks.length} {...activity} subtasks={subtasks} />
            ))}
        </Container>
    );
}

const Container = styled(ActivityContainer)(({ theme }) => ({
    padding: '25px 25px 0 25px',
    overflow: 'hidden'
}));

const Header = styled(ActivityHeader)(({ theme }) => ({
    paddingBottom: '25px'
}));

export const Icon = styled(ActivityIcon)(({ theme }) => ({
    minWidth: '50px',
    backgroundColor: '#E6F1E4'
}));
