export const APP_ROUTES = {
    ACTIVITIES: '/my-activities',
    CONTACT: '/welcome/contact',
    CONSENTS: '/onboarding/consents',
    DISQUALIFIED: '/disqualified',
    EMAIL_VERIFIED: '/email-verified',
    FAQ: '/welcome/FAQ',
    LOGIN: '/login',
    LANGUAGE_QUESTIONNAIRE: '/language-questionnaire',
    INFORMATION: '/onboarding/information',
    IGAN_QUESTIONNAIRE: '/igan-questionnaire',
    MEDICATIONS: '/medications',
    ONBOARDING: '/onboarding',
    PHONE: '/onboarding/phone',
    PHONE_QUESTIONNAIRE: '/phone-questionnaire',
    PRESCREENING: '/onboarding/prescreening',
    PRIVACY_POLICY: '/privacy',
    PROFILE: '/profile',
    PROFILE_CONSENTS: '/profile/consents',
    PROFILE_PAYMENTS: '/profile/payments',
    PROFILE_REGISTRY: '/profile/registry',
    PROFILE_WITHDRAW: '/profile/withdraw',
    PROFILE_VIEW: '/profile/view',
    PROGRESS: '/progress',
    REJECTED: '/rejected',
    RESOURCES: '/welcome/resources',
    SIGN_UP: '/signup',
    SIGN_OUT: '/signout',
    TERMS_OF_USE: '/welcome/terms',
    VERIFY_EMAIL: '/onboarding/verify-email',
    WITHDRAWN: '/withdrawn',
    WITHDRAWN_SUCCESS: '/withdrawn-success',
    TOKENIZATION_SUCCESS: '/tokenization-success',
    WELCOME: '/welcome',
    HEALTH_QUESTIONNAIRE: '/health-questionnaire',
    SYNC_DATA: '/sync-data',
    SYNC_DATA_INFO: '/sync-data-info',
    PROVIDER: '/provider',
    SYNC_DATA_FINAL: '/sync-data-final',
    TASK_THANK_YOU: '/task-thank-you',
    THANK_YOU: '/thank-you',
    CALLBACK: '/callback',
    ERROR: '/error',
    SIGN_IN: '/signin'
};
