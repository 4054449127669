import { styled } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.gray20
}));

export const Container = styled('div')(({ theme }) => ({
    margin: 20,
    maxWidth: '405px',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    padding: '40px 40px 40px 40px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        boxShadow: 'none',
        borderRadius: '0px'
    }
}));
