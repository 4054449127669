import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    width: '100vw',
    minHeight: '100vh',
}));

export const Container = styled('div')(({ theme }) => ({
    maxWidth: '425px',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    padding: '50px 40px 40px 40px',
    boxSizing: 'border-box',
}));
export const StyledFormLabel = styled('label')(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontWeight: 500,
    lineHeight: '2.4rem',
    fontSize: '14px',
    display: 'block',
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.primary.gray100,
    fontWeight: 500,
    '& .MuiFormControlLabel-label': {
        fontSize: '14px',
    },
    '& .MuiSvgIcon-root': {
        width: '26px',
        height: '26px',
    },
}));
