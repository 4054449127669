import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AccordionDetails, Box, Stack, styled, Typography } from '@mui/material';
import { ButtonWithArrow } from 'configUI/components/Buttons';
import { useIsMobile } from 'hooks/useIsScreenSize';
import { LANGUAGES } from 'internationalization';
import { useRedirections } from 'navigation';
import { BackToMyActivities } from 'pages/Medications/BackToMyActivities';
import { FAQWrapper, StyledAccordion, StyledAccordionSummary } from 'pages/Welcome/FAQ/styles';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { instructionsDataEN } from './instructionsDataEN';
import { instructionsDataES } from './instructionsDataES';

const Paragraph = ({ children, ...props }) => {
    return (
        <Typography
            fontSize='14px'
            color='primary.gray75'
            display='block'
            lineHeight='24px'
            textAlign='left'
            {...props}
        >
            {children}
        </Typography>
    );
};

export default function SearchProvidersInstructionPage() {
    const { redirectToShareMedicalRecords } = useRedirections();
    const { t, i18n } = useTranslation();
    const isMobile = useIsMobile();

    const accordionData = i18n.language === LANGUAGES.SPANISH ? instructionsDataES : instructionsDataEN;

    const [expandedMap, setExpandedMap] = useState({});

    const handleChange = (panel) => (event, isExpanded) => {
        if (expandedMap[panel]) {
            expandedMap[panel] = false;
        } else {
            expandedMap[panel] = true;
        }
        setExpandedMap({ ...expandedMap });
    };

    return (
        <Container>
            {
                <Header>
                    <BackToMyActivities />
                </Header>
            }

            <Box
                bgcolor='primary.gray20'
                width={'100%'}
                height={'100%'}
                overflow={'auto'}
                paddingTop={isMobile && '20px'}
            >
                <FAQWrapper padding={2} sx={{ paddingbottom: '0px', margin: '0 auto' }}>
                    <Box>
                        <Typography component='h2' variant='h2' fontWeight='600'>
                            {t('activities:emr:info:title')}
                        </Typography>
                        <Typography component='h4' variant='h4' fontWeight='600'>
                            {t('activities:emr:info:subtitle')}
                        </Typography>
                        <Typography variant='pb1' display='block' color='primary.gray75' margin='0.7em 0 1.5em 0'>
                            {t('activities:emr:info:description')}
                        </Typography>
                    </Box>
                    {accordionData.map((data) => {
                        return (
                            <StyledAccordion
                                key={data.id}
                                expanded={expandedMap[data.id] || false}
                                onChange={handleChange(data.id)}
                            >
                                <StyledAccordionSummary
                                    expandIcon={
                                        expandedMap[data.id] || false ? (
                                            <Box color='primary.main'>
                                                <RemoveIcon />
                                            </Box>
                                        ) : (
                                            <Box color='primary.main'>
                                                <AddIcon />
                                            </Box>
                                        )
                                    }
                                    aria-controls={`panel${data.id}-content`}
                                    id={`panel${data.id}-header`}
                                    expanded={(expandedMap[data.id] || false).toString()}
                                >
                                    <Typography variant='pb3' color='primary.main'>
                                        {data.title}
                                    </Typography>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <Trans>
                                        <Paragraph>{data.description}</Paragraph>
                                    </Trans>
                                </AccordionDetails>
                            </StyledAccordion>
                        );
                    })}
                    <Stack direction='row' justifyContent='flex-end' spacing='5px'>
                        <ButtonWithArrow sx={{ borderRadius: '8px' }} onClick={() => redirectToShareMedicalRecords()}>
                            {t('activities:emr:buttons:share')}
                        </ButtonWithArrow>
                    </Stack>
                </FAQWrapper>
            </Box>
        </Container>
    );
}

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '70px',
    width: '100%',
    paddingLeft: '40px'
}));

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100dvh - 90px)'
}));
