import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FilledButton } from 'components/Buttons';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
    addInProgressProvider,
    updateConnectingProvider,
    updateFinishDataShareEnabled
} from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { useRedirections } from 'navigation';
import CelebrationScreen from 'pages/Activities/CelebrationScreen';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export function SpecificFinalPage() {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { redirectToActivities, redirectToShareMedicalRecords } = useRedirections();

    const [searchParams, ,] = useSearchParams();
    let isSuccessful = searchParams.get('success') === 'true';
    const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
    console.log('🚀 ~ file: specific-final.js:25 ~ SpecificFinalPage ~ oneUpHealthData:', oneUpHealthData);
    const { inProgressProviders } = oneUpHealthData;

    useEffect(() => {
        if (!isSuccessful) {
            redirectToActivities();
        }
    }, [isSuccessful, redirectToActivities]);

    const provider = oneUpHealthData?.lastConnectedProvider;
    const providerName = provider?.name ?? 'Medical provider';

    if (isSuccessful && oneUpHealthData.connectingProvider) {
        const alreadyIn = inProgressProviders.find(
            (c) =>
                c.id?.toString() === provider.id.toString() &&
                c.participant_task_id == oneUpHealthData.connectingProvider?.participant_task_id
        );
        if (!alreadyIn) {
            dispatch(
                addInProgressProvider({
                    ...provider,
                    participant_task_id: oneUpHealthData.connectingProvider?.participant_task_id
                })
            );
        }
        dispatch(updateFinishDataShareEnabled(true));
        dispatch(updateConnectingProvider(null));
    }

    return (
        <Box
            sx={{
                bgcolor: theme.palette.primary.gray20,
                height: '100vh',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <CelebrationScreen
                    attributes={{
                        thankyouMsg: `${t('activities:emr:providers:thank-you')} ${providerName} ${t(
                            'activities:emr:providers:health-record'
                        )}`
                    }}
                    maxHeight={false}
                    maxWidth
                />
                <FilledButton
                    type='submit'
                    size='large'
                    sx={{ minWidth: '303px', marginBottom: '20px', marginTop: '30px' }}
                    onClick={redirectToShareMedicalRecords}
                >
                    {t('activities:emr:buttons:done')}
                </FilledButton>
            </Box>
        </Box>
    );
}
