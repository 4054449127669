import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GiftCard } from './gift-card-duotone 1.svg';
import { Container, InquireContainer } from './styles';

export const Sponsor = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Box display='flex' justifyContent='center'>
                <GiftCard />
            </Box>
            <Box>
                <Typography component='p' lineHeight='24px' fontSize='16px' fontWeight='400' color='primary.gray100'>
                    {t('welcome:home:sponsor-title')}
                </Typography>
            </Box>
            <Box>
                <InquireContainer>
                    <Typography component='p' fontSize='16px' fontWeight='400' color='primary.gray100'>
                        {t('welcome:home:sponsor-desc')}
                    </Typography>
                </InquireContainer>
            </Box>
            <Box>
                <Typography component='p' fontSize='12px' lineHeight='18px' fontWeight='400' color='primary.gray75'>
                    {t('welcome:home:sponsor-disclaimer')}
                </Typography>
            </Box>
        </Container>
    );
};
