import { useAuth0 } from '@auth0/auth0-react';
import { useGetPatient, useGetPerson } from 'authentication';
import { FilledButton, LinkButton } from 'components/Buttons';
import { useLoading } from 'components/loading';
import { getBaseURL } from 'config/API';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { useSubmitWithdrawalMutation } from 'ihp-bloom-redux/features/study/studyApi';
import { ReactComponent as Fail } from 'images/shared/fail-exclamationmark.svg';
import { useRedirections } from 'navigation';
import { Trans, useTranslation } from 'react-i18next';
import { Container, OtherStudyWrapper, PageTitle, Row } from './styles';

function OtherStudy({ handleClose }) {
    const { t } = useTranslation();
    const REASON = 'I want to participate in a different Registry';
    const { setIsLoading } = useLoading();
    const { id: personId, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();
    const { getAccessTokenSilently } = useAuth0();
    const { redirectToWithdrawnSuccess } = useRedirections();
    const [submitWithdrawal] = useSubmitWithdrawalMutation();

    const [editPersonAttributes] = useEditPersonAttributesMutation();
    const handleWithdrawalReasonSubmit = async (reason) => {
        setIsLoading(true);
        const finalId = isCaregiver ? patientId : personId;
        try {
            const url = getBaseURL() + '/v3/studies';
            const STUDY_NAME = 'Perform Patient Registry';
            const token = await getAccessTokenSilently();
            const studyIdOptions = {
                method: 'GET',
                url: url,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(url, studyIdOptions);
            let { data: studiesData } = await response.json();
            const studyId = Number(studiesData.find((personType) => personType.attributes.name === STUDY_NAME).id);
            const patientPayload = [{
                data: {
                    type: 'person-attributes',
                    attributes: {
                        attribute: 'tokenization_consent',
                        value: 'withdrawn'
                    }
                }
            }];

            await editPersonAttributes({
                personId: finalId,
                payload: patientPayload
            });

            const presonRes = await submitWithdrawal({ personId: personId, payload: { study_id: studyId, reason } });
            let patientRes;

            isCaregiver ? patientRes = await submitWithdrawal({ personId: patientId, payload: { study_id: studyId, reason } }) : patientRes = null;

            if (presonRes?.data?.status === 'withdrawn' || (isCaregiver && patientRes?.data?.status === 'withdrawn')) {
                redirectToWithdrawnSuccess();
            }

        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };
    const onConfirm = () => {
        handleWithdrawalReasonSubmit(REASON);
        handleClose();
    };
    const onCancel = () => {
        console.log('cancel');
        handleClose();
    };

    return (
        <>
            <OtherStudyWrapper>
                <Container>
                    <Row>
                        <Fail width={200} height={198} />
                    </Row>
                    <Row>
                        <Trans>
                            <PageTitle>{t('profile:withdraw:other-study-modal:title')}</PageTitle>
                        </Trans>
                    </Row>
                    <Row>
                        <FilledButton background='#E16058' onClick={onConfirm} fullWidth>
                            {t('profile:withdraw:other-study-modal:yes')}
                        </FilledButton>
                    </Row>
                    <Row>
                        <LinkButton color='#E16058' onClick={onCancel} fullWidth>
                            {t('profile:withdraw:other-study-modal:cancel')}
                        </LinkButton>
                    </Row>
                </Container>
            </OtherStudyWrapper>
        </>
    );
}

export default OtherStudy;
