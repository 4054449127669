import { Button, styled } from '@mui/material';
import { ArrowRight } from 'images/components';

export function ButtonWithArrow({ children, ...rest }) {
    return (
        <Container disableElevation {...rest}>
            {children}
            <ArrowRight />
        </Container>
    );
}

const Container = styled(Button)(({ theme }) => ({
    ...theme.typography.pb3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    height: '56px',
    padding: '0px 20.5px 0px 35px',
    color: 'white',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    border: '2px solid transparent',
    borderRadius: '8px',
    '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        '& svg': {
            transform: 'translateX(3px)'
        },
        '& path': {
            backgroundColor: 'black',
            fill: theme.palette.primary.main
        }
    }
}));
