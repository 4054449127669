import { Button, styled } from "@mui/material"

export const GhostButton = styled(Button)(({ theme }) => ({
    ...theme.typography.pb3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    height: "56px",
    padding: "0px 35px",
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    border: "2px solid transparent",
    "&:hover": {
        borderColor: theme.palette.primary.main,
        backgroundColor: "white",
        color: theme.palette.primary.main,
        "& svg": {
            transform: "translateX(3px)"
        },
        "& path": {
            backgroundColor: "black",
            fill: theme.palette.primary.main,
        }
    }
}));
