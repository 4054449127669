import { TextField, styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { ReactComponent as OpenPickerIcon } from './OpenPickerIcon.svg';

const CustomizedDatePicker2 = styled(DatePicker)(({ error, theme }) => ({
    '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px !important',
        borderColor: `${error ? theme.palette.primary.red : '#E9E9E9'} !important`
    },
    '& .MuiInputBase-root': {
        borderRadius: '6px',
        borderWidth: '1px',
        paddingRight: '20px'
    },
    '& .MuiInputBase-input': {
        // padding: '0.8rem 1.6rem',
        // padding: '20px',
        height: '48px',
        boxSizing: 'border-box',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontStyle: 'normal',
        lineHeight: '1.7rem',
        opacity: 0.8
    }
}));

const CustomizedTextInput = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'haserrors'
})(({ theme, haserrors }) => {
    return {
        '& .MuiInputBase-root': {
            background: '#FFFFFF',
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #DFE2E3 !important'
            },

            '& .MuiInputBase-input': {
                borderColor: theme.palette.primary.red75,
                height: '48px',
                boxSizing: 'border-box',
                fontFamily: 'Inter',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '1.7rem',
                opacity: 0.8
            }
        }
    };
});

export const CustomizedDatePicker = React.forwardRef(({ label, placeholder, error, ...props }, ref) => {
    console.log('DATE PICKER', { error });

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CustomizedDatePicker2
                components={{
                    OpenPickerIcon: OpenPickerIcon
                }}
                inputFormat='dd/MM/yyyy'
                ref={ref}
                label={label}
                {...props}
                error={error}
                renderInput={(params) => (
                    <CustomizedTextInput
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                            placeholder: placeholder
                        }}
                        fullWidth
                        error={error}
                    />
                )}
            />
        </LocalizationProvider>
    );
});
