import { Button } from '@mui/material';
import { Box, styled } from '@mui/system';

export const OverviewModalTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    fontWeight: 700,
    fontSize: '16px',
    color: theme.palette.primary.gray75,
    paddingTop: '60px',
    alignSelf: 'center'
}));

export const FuchsiaTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    fontWeight: 600,
    fontSize: '18px',
    color: theme.palette.primary.fuchsia75,
    paddingTop: '30px',
    paddingBottom: '10px'
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
    width: '100%',
    maxWidth: '720px',
    height: '90%',
    borderRadius: '10px',
    boxShadow: 24
}));

export const TitleSeparator = styled(Box)(({ theme }) => ({
    borderBottom: '3px solid ' + theme.palette.primary.gray50,
    width: '40px',
    marginTop: '20px',
    alignSelf: 'center'
}));

export const ContentContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '75%',
    flexDirection: 'column',
    alignSelf: 'center'
}));

export const OverviewSubtitleContainer = styled(Box)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '14px',
    marginTop: '15px'
}));

export const MainContainer = styled(Box)(({ theme }) => ({
    padding: '20px',
    bgcolor: theme.palette.primary.gray100
}));

export const TimelineTitleContainer = styled(Box)(({ theme }) => ({
    marginTop: '20px',
    color: theme.palette.secondary.gray4,
    fontWeight: 600,
    fontSize: '16px'
}));

export const OverviewListItemContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

export const OverviewFirstSubtitleContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.fuchsia5,
    padding: '10px',
    borderRadius: '5px',
    marginTop: '20px'
}));

export const CloseButton = styled(Button)(({ theme }) => ({
    background: theme.palette.primary.fuchsia75,
    color: theme.palette.primary.white,
    borderRadius: 8,
    padding: '15px 15px 15px 15px',
    alignSelf: 'center',
    marginBottom: '30px',
    marginTop: '30px',
    width: 240,
    height: 56,
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'none',
    '&: hover': {
        background: theme.palette.primary.fuchsia75
    }
}));

export const LeftContainer = styled(Box)(() => ({
    display: 'flex'
}));

export const RightContainer = styled(Box)(() => ({
    display: 'flex',
    marginRight: '20px',
    marginTop: '18px'
}));

export const IconWrapper = styled(Box)(() => ({
    width: '40px',
    height: '40px',
    paddingBottom: '25px'
}));

export const Title = styled('div')(({ theme }) => ({
    color: theme.palette.secondary.gray100,
    fontSize: 13,
    fontWeight: 400,
    marginTop: '18px',
    marginLeft: '25px'
}));

export const PointsPerWeek = styled('div')(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontSize: 13,
    fontWeight: 400
}));

export const TimelineTitle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.gray50,
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.gray20,
    padding: '46px 0px 0px 45px'
}));
