import { Box, CardMedia, Typography } from '@mui/material';
import { ReactComponent as ArrowRight } from 'images/shared/arrow-right-2.svg';
import { useRedirections } from 'navigation';
import { useTranslation } from 'react-i18next';
import LeftImage from './left-image.png';
import RightImage from './right-image.png';
import { ButtonLink, Card, CardContent, Container } from './styles';

const Title = () => {
    const { t } = useTranslation();
    return (
        <Box display='flex' flexDirection='column' alignItems='center' marginBottom='3em'>
            <Typography variant='h1' fontWeight='600' margin='15px 0px'>
                {t('welcome:home:resources')}
            </Typography>
            <Typography variant='pn1' display='block' color='primary.gray75' textAlign='center' maxWidth='420px'>
                {t('welcome:home:learn-more-about')}
            </Typography>
        </Box>
    );
};

const CustomizedCard = ({ image, title, description, button }) => {
    return (
        <Card>
            <CardMedia component='img' height='200' image={image} alt={title} />
            <CardContent>
                <Typography variant='h2' fontWeight='600' marginBottom='12px'>
                    {title}
                </Typography>
                <Typography variant='pn3' component='p' color='primary.gray75' marginBottom={'30px'}>
                    {description}
                </Typography>
                {button}
            </CardContent>
        </Card>
    );
};

export const Resources = () => {
    const { t } = useTranslation();
    const { redirectToResources, redirectToFAQ } = useRedirections();

    return (
        <Box>
            <Title />
            <Container>
                <Box display='flex' flexWrap='wrap' gap='20px'>
                    <Box flex='1'>
                        <CustomizedCard
                            image={LeftImage}
                            title={t('welcome:home:library')}
                            description={t('welcome:home:find-additional-documents')}
                            button={
                                <ButtonLink onClick={redirectToResources} endIcon={<ArrowRight width='16px' />}>
                                    {t('welcome:home:go-to-library')}
                                </ButtonLink>
                            }
                        />
                    </Box>
                    <Box flex='1'>
                        <CustomizedCard
                            image={RightImage}
                            title='FAQ'
                            description={t('welcome:home:find-the-answers')}
                            button={
                                <ButtonLink onClick={redirectToFAQ} endIcon={<ArrowRight width='16px' />}>
                                    {t('welcome:home:check-our-faq')}
                                </ButtonLink>
                            }
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};
