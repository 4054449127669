import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import activitiesSlice from '../features/activities/activitiesSlice';
import allUsersSlice from '../features/allUsers/allUsersSlice';
import { authenticationApiSlice } from '../features/authentication/authenticationApiSlice';
import authenticationReducer from '../features/authentication/authenticationSlice';
import basicInformationReducer from '../features/basicInformation/basicInformationSlice';
import cohortsReducer from '../features/cohorts/cohortsSlice';
import contactSlice from '../features/contactSlice/contactSlice';
import dataProviderReducer from '../features/dataProviders/dataProviderSlice';
import followupSliceReducer from '../features/followup/followupSlice';
import badgesReducer from '../features/gamification/badgesSlice';
import groupReducer from '../features/group/groupSlice';
import languageReducer from '../features/language/languageSlice';
import { apiSlice } from '../features/main/mainApiSlice';
import {
  medicationApiSlice,
  medicationSlice,
} from '../features/medicationSlice/medicationSlice';
import doseTakenSlice from '../features/medicationTracker/doseTakenSlice';
import medicationTrackerSlice from '../features/medicationTracker/medicationTrackerSlice';
import consentPageSlice from '../features/onboarding/consentPageSlice';
import createAccountSlice from '../features/onboarding/createAccountSlice';
import enrollmentSlice from '../features/onboarding/enrollmentSlice';
import hipaaSlice from '../features/onboarding/hipaaPageSlice';
import prescreeningSlice from '../features/onboarding/prescreeningQuestionSlice';
import verificationSlice from '../features/onboarding/verificationSlice';
import welcomePageSlice from '../features/onboarding/welcomePageSlice';
import { healthApiSlice } from '../features/oneUpHealth/oneUpHealthApi';
import { oneUpHealthSlice } from '../features/oneUpHealth/oneUpHealthSlice';
import organizationApi from '../features/organization/organizationApi';
import organizationReducer from '../features/organization/organizationSlice';
import paymentProviderReducer from '../features/paymentProviders/paymentProviderSlice';
import { profileApi } from '../features/profile/profileApi';
import profileSlice from '../features/profile/profileSlice';
import hyperwalletReducer from '../features/settings/hyperwalletSlice';
import paymentReducer from '../features/settings/paymentSlice';
import tremendousReducer from '../features/settings/tremendousSlice';
import emailsSlice from '../features/staticPages/emailsSlice';
import pageSlice from '../features/staticPages/pagesSlice';
import studyReducer from '../features/study/studySlice';
import { studyApiSlice } from '../features/study/studyApi';
import { studyApiSliceV3 } from '../features/study/studyApiV3';
import studyArmReducer from '../features/studyArms/studyArmSlice';
import { taskApiSlice } from '../features/tasks/taskApiSlice';
import { taskApiSlice as taskApiSliceV3 } from '../features/tasks/taskApiSliceV3';
import { participantTaskApiSlice } from '../features/tasks/participantTaskApiSlice';
import { participantTaskApiSlice as participantTaskApiSliceV3 } from '../features/tasks/participantTaskApiSliceV3';
import taskQuestionSlice from '../features/tasks/taskQuestionSlice';
import taskReducer from '../features/tasks/tasksSlice';
import telehealthProvidersSlice from '../features/tasks/telehealthProvidersSlice';
import emailTemplatesSlice from '../features/templateManager/emailSlice';
import notificationTemplatesSlice from '../features/templateManager/notificationSlice';
import smsTemplatesSlice from '../features/templateManager/smsSlice';
import uiComponentsReducer from '../features/uiComponents/uiComponentsSlice';
import urlReducer from '../features/url/urlSlice';
import { userApiSlice } from '../features/user/userApiSlice';
import { userApiSliceV3 } from '../features/user/userApiSliceV3';
import { personAttributesApiSlice } from '../features/user/personAttributesApiSlice';
import { profileAttributesApiSlice } from '../features/profile/profileAttributesApiSlice';
import userReducer from '../features/user/userSlice';
import consentSlice from '../features/consent/consentSlice';
import { entityApiSlice } from '../features/entity/entityApiSlice';
import { manualProvidersApiSlice } from '../features/oneUpHealth/manualProvidersApi';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import milestonesSlice from '../features/milestones/milestonesSlice';
import { tremendousApiSlice } from '../features/settings/tremendousApi';
import { consentApi } from '../features/consent/consentApi';
import { consentApiV3 } from '../features/consent/consentApiV3';
import { consentVersionApi } from '../features/consent/consentVersionApi';
import { televisitApiSlice } from '../features/followup/televisitApiSlice';
import { walletApiSlice } from '../features/profile/walletApiSlice';
import { filtersSlice } from '../features/filters/filtersSlice';
import { participantTaskSlice } from '../features/tasks/participantTaskSlice';

const reducers = combineReducers({
  user: userReducer,
  [userApiSlice.reducerPath]: userApiSlice.reducer,
  [userApiSliceV3.reducerPath]: userApiSliceV3.reducer,
  [personAttributesApiSlice.reducerPath]: personAttributesApiSlice.reducer,
  [profileAttributesApiSlice.reducerPath]: profileAttributesApiSlice.reducer,
  authentication: authenticationReducer,
  [authenticationApiSlice.reducerPath]: authenticationApiSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  healthApiSlice: healthApiSlice.reducer,
  oneUpHealthSlice: oneUpHealthSlice.reducer,
  uiComponents: uiComponentsReducer,
  organizationState: organizationReducer,
  badgesState: badgesReducer,
  [createAccountSlice.name]: createAccountSlice.reducer,
  [enrollmentSlice.name]: enrollmentSlice.reducer,
  [verificationSlice.name]: verificationSlice.reducer,
  [prescreeningSlice.name]: prescreeningSlice.reducer,
  studyState: studyReducer,
  groupState: groupReducer,
  studyArmState: studyArmReducer,
  cohortsState: cohortsReducer,
  language: languageReducer,
  dataProvider: dataProviderReducer,
  url: urlReducer,
  tremendous: tremendousReducer,
  hyperwallet: hyperwalletReducer,
  payment: paymentReducer,
  task: taskReducer,
  [taskQuestionSlice.name]: taskQuestionSlice.reducer,
  [telehealthProvidersSlice.name]: telehealthProvidersSlice.reducer,
  followup: followupSliceReducer,
  paymentProvider: paymentProviderReducer,
  [medicationApiSlice.reducerPath]: medicationApiSlice.reducer,
  [medicationSlice.name]: medicationSlice.reducer,
  basicInformation: basicInformationReducer,
  [medicationTrackerSlice.name]: medicationTrackerSlice.reducer,
  [doseTakenSlice.name]: doseTakenSlice.reducer,
  [profileApi.name]: profileApi.reducer,
  [profileSlice.name]: profileSlice.reducer,
  [pageSlice.name]: pageSlice.reducer,
  [allUsersSlice.name]: allUsersSlice.reducer,
  [welcomePageSlice.name]: welcomePageSlice.reducer,
  [emailsSlice.name]: emailsSlice.reducer,
  [hipaaSlice.name]: hipaaSlice.reducer,
  [consentPageSlice.name]: consentPageSlice.reducer,
  [contactSlice.name]: contactSlice.reducer,
  [emailTemplatesSlice.name]: emailTemplatesSlice.reducer,
  [smsTemplatesSlice.name]: smsTemplatesSlice.reducer,
  [notificationTemplatesSlice.name]: notificationTemplatesSlice.reducer,
  [activitiesSlice.name]: activitiesSlice.reducer,
  [milestonesSlice.name]: milestonesSlice.reducer,
  [contactSlice.name]: contactSlice.reducer,
  [taskApiSlice.reducerPath]: taskApiSlice.reducer,
  [taskApiSliceV3.reducerPath]: taskApiSliceV3.reducer,
  [participantTaskApiSlice.reducerPath]: participantTaskApiSlice.reducer,
  [participantTaskApiSliceV3.reducerPath]: participantTaskApiSliceV3.reducer,
  [activitiesSlice.name]: activitiesSlice.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [manualProvidersApiSlice.reducerPath]: manualProvidersApiSlice.reducer,
  [tremendousApiSlice.reducerPath]: tremendousApiSlice.reducer,
  [consentSlice.name]: consentSlice.reducer,
  [entityApiSlice.reducerPath]: entityApiSlice.reducer,
  [consentApi.reducerPath]: consentApi.reducer,
  [consentApiV3.reducerPath]: consentApiV3.reducer,
  [consentVersionApi.reducerPath]: consentVersionApi.reducer,
  [televisitApiSlice.reducerPath]: televisitApiSlice.reducer,
  [studyApiSlice.reducerPath]: studyApiSlice.reducer,
  [studyApiSliceV3.reducerPath]: studyApiSliceV3.reducer,
  [walletApiSlice.reducerPath]: walletApiSlice.reducer,
  [filtersSlice.name]: filtersSlice.reducer,
  [participantTaskSlice.name]: participantTaskSlice.reducer,
});

const persistConfig = {
  key: 'root',
  // persist only these reducers
  whitelist: ['user', 'oneUpHealthSlice', 'task', consentSlice.name],
};

const persistedReducer = (storageType) => {
  return persistReducer({ ...persistConfig, storage: storageType }, reducers);
};

export const store = (storageType) =>
  configureStore({
    reducer: persistedReducer(storageType),
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat([
        logger,
        apiSlice.middleware,
        taskApiSlice.middleware,
        authenticationApiSlice.middleware,
        userApiSlice.middleware,
        profileAttributesApiSlice.middleware,
        participantTaskApiSlice.middleware,
        studyApiSlice.middleware,
        consentApi.middleware,
        participantTaskApiSliceV3.middleware,
      ]);
    },
  });
