import { Box, Divider, Grid, InputAdornment, MenuItem, Typography, capitalize, styled } from '@mui/material';
import { TextInput } from 'components/Input/TextInput';
import { ErrorWrapper } from 'configUI/components';
import { ButtonWithCheck, GhostButton } from 'configUI/components/Buttons';
import { ReactComponent as CalendarIcon } from 'images/shared/calendar-alt.svg';
import { ReactComponent as UserCircle } from 'images/shared/user-circle.svg';
import { Email } from 'pages/Profile/assets';
import { dateFormater } from 'pages/Study/timelineCard';
import {
    StyledSelect,
    WrappedTextMenuItem
} from 'pages/onboarding/PersonalInformation/forms/PersonalInformationForm/styles';
import { PhoneInput, isValidPhoneNumber } from 'pages/onboarding/Phone/PhoneInput';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { menuItemsTimezone } from 'utils/timezones';
import Demographic from './Demographic';
import { StyledFormLabel, StyledSwitch, StyledSwitchLabel, StyledTitle } from './styles';

export const UserProfileForm = ({
    form,
    onSubmit,
    onError,
    isCaregiver,
    initialValues,
    isUpdatingPersonAttributes,
    iganProps,
    person
}) => {
    const [isValidPhone, setIsValidPhone] = useState(true);
    const { control, formState, handleSubmit } = form;
    const { errors } = formState;

    const { t } = useTranslation();

    function onHandleCancel() {
        iganProps.setOptions(iganProps.init.options);
        iganProps.setAge(iganProps.init.age);
        form.reset(initialValues);
    }

    const disabled =
        isUpdatingPersonAttributes ||
        !(form.formState.isDirty || iganProps.hasChanged) ||
        !iganProps.age ||
        iganProps.age < 1 ||
        iganProps.age > 100 ||
        iganProps.options.length === 0 ||
        !isValidPhone;

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Container>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography color='primary.gray75' fontSize='18px' fontWeight='600'>
                            {t('profile:view:sections:basic-information')}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={6}>
                            <StyledFormLabel required={true}>{t('profile:view:labels:first-name')}</StyledFormLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='first_name'
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    error={errors.first_name}
                                    render={({ field, fieldState }) => (
                                        <TextInput
                                            error={!!fieldState.error}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <UserCircle fill='#CACED0' width={'20px'} />
                                                </InputAdornment>
                                            }
                                            // disabled={true}
                                            fullWidth={true}
                                            placeholder={`${t('profile:view:placeholders:enter')} ${t(
                                                'profile:view:labels:first-name'
                                            )}`}
                                            {...field}
                                        />
                                    )}
                                />
                            </ErrorWrapper>
                        </Grid>
                        <Grid item xs={6}>
                            <StyledFormLabel required={true}>{t('profile:view:labels:last-name')}</StyledFormLabel>
                            <ErrorWrapper>
                                <Controller
                                    name='last_name'
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    error={errors.last_name}
                                    render={({ field, fieldState }) => (
                                        <TextInput
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <UserCircle fill='#CACED0' width={'20px'} />
                                                </InputAdornment>
                                            }
                                            // disabled={true}
                                            error={!!fieldState.error}
                                            fullWidth={true}
                                            placeholder={`${t('profile:view:placeholders:enter')} ${t(
                                                'profile:view:labels:last-name'
                                            )}`}
                                            {...field}
                                        />
                                    )}
                                />
                            </ErrorWrapper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledFormLabel> {capitalize(t('onboarding:phone:common:phone'))}</StyledFormLabel>
                        <ErrorWrapper>
                            <Controller
                                name='phone'
                                control={control}
                                error={errors.phone}
                                rules={{
                                    required: false
                                }}
                                render={({ field: { value, onChange, ...rest }, fieldState }) => {
                                    console.log({ value, rest, fieldState });

                                    return (
                                        <PhoneInput
                                            {...rest}
                                            autoFocus={false}
                                            phone={value}
                                            setPhone={(value) => {
                                                setIsValidPhone(isValidPhoneNumber(value));
                                                onChange(value);
                                            }}
                                            error={!!fieldState.error}
                                        />
                                    );
                                }}
                            />
                        </ErrorWrapper>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledFormLabel>{t('profile:view:labels:email')}</StyledFormLabel>
                        <ErrorWrapper>
                            <Controller
                                name='email'
                                control={control}
                                error={errors.email}
                                render={({ field, fieldState }) => (
                                    <TextInput
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <Email />
                                            </InputAdornment>
                                        }
                                        disabled={true}
                                        error={!!fieldState.error}
                                        fullWidth={true}
                                        placeholder={`${t('profile:view:placeholders:enter')} ${t(
                                            'profile:view:labels:email'
                                        )}`}
                                        {...field}
                                    />
                                )}
                            />
                        </ErrorWrapper>
                    </Grid>

                    {!isCaregiver && <Demographic control={control} errors={errors} iganProps={iganProps} />}
                    <Grid item xs={12}>
                        <Typography color='primary.gray75' fontSize='18px' fontWeight='600' marginTop='20px'>
                            {t('profile:view:sections:localization')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledFormLabel required={true}>{t('profile:view:labels:timezone')}</StyledFormLabel>
                        <ErrorWrapper>
                            <Controller
                                name='timezone'
                                control={control}
                                rules={{
                                    required: true
                                }}
                                error={errors.timezone}
                                render={({ field, fieldState }) => (
                                    <StyledSelect fullWidth {...field} error={!!fieldState.error}>
                                        {menuItemsTimezone.map((item) => (
                                            <WrappedTextMenuItem key={item.value} value={item.value}>
                                                {item.value}
                                            </WrappedTextMenuItem>
                                        ))}
                                    </StyledSelect>
                                )}
                            />
                        </ErrorWrapper>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledFormLabel required={true}>{t('profile:view:labels:language')}</StyledFormLabel>
                        <ErrorWrapper>
                            <Controller
                                name='language'
                                control={control}
                                rules={{
                                    required: true
                                }}
                                error={errors.language}
                                render={({ field, fieldState }) => (
                                    <StyledSelect fullWidth {...field} error={!!fieldState.error}>
                                        {Object.entries(t('profile:view:languages')).map(([key, value]) => (
                                            <MenuItem key={key} value={key}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </StyledSelect>
                                )}
                            />
                        </ErrorWrapper>
                    </Grid>

                    {isCaregiver && (
                        <>
                            <Grid item xs={12}>
                                <Divider sx={{ marginY: '20px' }} />
                            </Grid>

                            <Grid item xs={12}>
                                <StyledTitle>
                                    <Typography variant='h2'>{t('profile:view:titles:patient-profile')}</Typography>
                                </StyledTitle>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color='primary.gray75' fontSize='18px' fontWeight='600' marginTop='10px'>
                                    {t('profile:view:sections:basic-information')}
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={6}>
                                    <StyledFormLabel required={true}>
                                        {t('profile:view:labels:first-name')}
                                    </StyledFormLabel>
                                    <ErrorWrapper>
                                        <Controller
                                            name='patient_first_name'
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                            error={errors.patient_first_name}
                                            render={({ field, fieldState }) => (
                                                <TextInput
                                                    error={!!fieldState.error}
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <UserCircle fill='#CACED0' width={'20px'} />
                                                        </InputAdornment>
                                                    }
                                                    // disabled={true}
                                                    fullWidth={true}
                                                    placeholder={`${t('profile:view:placeholders:enter')} ${t(
                                                        'profile:view:labels:first-name'
                                                    )}`}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </ErrorWrapper>
                                </Grid>

                                <Grid item xs={6}>
                                    <StyledFormLabel required={true}>
                                        {t('profile:view:labels:last-name')}
                                    </StyledFormLabel>
                                    <ErrorWrapper>
                                        <Controller
                                            name='patient_last_name'
                                            control={control}
                                            rules={{
                                                required: true
                                            }}
                                            error={errors.patient_last_name}
                                            render={({ field, fieldState }) => (
                                                <TextInput
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <UserCircle fill='#CACED0' width={'20px'} />
                                                        </InputAdornment>
                                                    }
                                                    error={!!fieldState.error}
                                                    fullWidth={true}
                                                    placeholder={`${t('profile:view:placeholders:enter')} ${t(
                                                        'profile:view:labels:last-name'
                                                    )}`}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </ErrorWrapper>
                                </Grid>

                                <Grid item xs={12}>
                                    <StyledFormLabel>{t('profile:view:labels:date-of-birth')}</StyledFormLabel>
                                    <ErrorWrapper>
                                        <Controller
                                            name='date_of_birth'
                                            control={control}
                                            error={errors.date_of_birth}
                                            render={({ field, fieldState }) => {
                                                // 2000-01-01T00:00:00.000Z
                                                let year, month, day;

                                                if (typeof field.value === 'string') {
                                                    const fullDate = field.value.split('T')[0];
                                                    [year, month, day] = fullDate.split('-');
                                                }

                                                return (
                                                    <TextInput
                                                        {...field}
                                                        value={`${month}/${day}/${year}`}
                                                        error={!!fieldState.error}
                                                        endAdornment={
                                                            <InputAdornment position='end'>
                                                                <CalendarIcon color='#CACED0' width={'20px'} />
                                                            </InputAdornment>
                                                        }
                                                        disabled
                                                        fullWidth
                                                        placeholder={t(
                                                            'onboarding:prescreening:caregiver:placeholders:date-of-birth'
                                                        )}
                                                    />
                                                );
                                            }}
                                        />
                                    </ErrorWrapper>
                                </Grid>
                            </Grid>

                            <Demographic control={control} errors={errors} iganProps={iganProps} />
                        </>
                    )}

                    <Grid item xs={12}>
                        <Typography color='primary.gray75' fontSize='18px' fontWeight='600' marginTop='20px'>
                            {t('profile:view:sections:notifications')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box flex>
                            <ErrorWrapper>
                                <Controller
                                    name='sms_notification'
                                    control={control}
                                    error={errors.sms_notification}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <StyledSwitchLabel
                                                control={
                                                    <StyledSwitch
                                                        {...field}
                                                        disabled={!person.phone}
                                                        checked={field.value}
                                                    />
                                                }
                                                label={t('onboarding:phone:common:sms-notifications')}
                                            />
                                        );
                                    }}
                                />
                            </ErrorWrapper>
                        </Box>
                    </Grid>
                </Grid>

                <Box marginTop={3} display='flex' alignContent='center'>
                    <ButtonWithCheck
                        sx={{ color: 'white', marginRight: '10px' }}
                        disabled={disabled}
                        type='submit'
                        variant='contained'
                        color='primary'
                    >
                        {t('profile:view:buttons:save')}
                    </ButtonWithCheck>

                    <GhostButton type='reset' color='primary' onClick={onHandleCancel}>
                        {t('profile:view:buttons:cancel')}
                    </GhostButton>
                </Box>
            </Container>
        </form>
    );
};

const Container = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {}
}));
