import { FormHelperText } from '@mui/material';
import { styled } from '@mui/system';

export const ErrorMessage = styled(FormHelperText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.red75,
  marginTop: '5px',
  fontSize: '14px',

  '& svg': {
    marginRight: '5px',
  },
}));
