import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  activities: {
    startDate: '',
    endDate: '',
    selectedTypes: ['show_all']
  },
  rewards: {
    startDate: '',
    endDate: '',
    balances: ['show_all'],
    selectedTypes: ['show_all']
  },
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setActivities: (state, action) => {
      const { payload } = action;
      Object.assign(state,  { activities: payload });
    },
    setRewards: (state, action) => {
      const { payload } = action;
      Object.assign(state,  { rewards: payload });
    },
  },
});

export const {
  setActivities,
  setRewards,
} = filtersSlice.actions;

export default filtersSlice.reducer;
