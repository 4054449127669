import { Box, InputAdornment, Modal, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { FilledButton } from 'components/Buttons';
import { OutlineButton } from 'components/Buttons/Outline';
import { useIsMobile } from 'hooks/useIsScreenSize';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { ReactComponent as ArrowForwardIcon } from 'images/Task/arrow-forward-icon.svg';
import { ReactComponent as AddMoreLaterIcon } from 'images/data-sync/add-more-later.svg';
import { ReactComponent as BellIcon } from 'images/data-sync/bell.svg';
import { ReactComponent as ClockIcon } from 'images/data-sync/clock.svg';
import { ReactComponent as MedicalIcon } from 'images/data-sync/medical.svg';
import { ReactComponent as PlusIcon } from 'images/payment/purplePlus.svg';
import { ReactComponent as SearchIcon } from 'images/providers/search.svg';
import { ReactComponent as DollarIcon } from 'images/shared/dollar-sign.svg';
import { ReactComponent as CancelLogo } from 'images/shared/fail-exclamationmark.svg';
import { APP_ROUTES, useRedirections } from 'navigation';
import { BackToMyActivities } from 'pages/Medications/BackToMyActivities';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ConfirmationModal } from './ConfirmationModal';
import { ManualProviderModal } from './Modal/manualProviderModal';
import ProvidersList from './ProvidersList';
import {
    AddNewProviderContainer,
    AddNewProviderText,
    BottomContainer,
    Button,
    Container,
    ContentContainer,
    HeaderContainer,
    HeaderWrapper,
    LeftContainer,
    Link,
    Logo,
    RightHeaderItemContainer,
    RightHeaderTextWrapper,
    SearchBox,
    SearchProvidersTitle,
    Text,
    Title,
    Wrapper
} from './styles';

export const SearchProvidersDesktop = ({
    providers,
    loadingProviders,
    handleFinish,
    connectedProviders,
    setSearchTerm,
    setFiltered,
    setLoadingProviders,
    setLoadingConnectedProviders,
    setIsRedirecting,
    finishDataShareEnabled,
    hasConnectedProviders,
    isLoading,
    duration,
    timeRemaining,
    points,
    updateSelectedTask,
    handleAddMoreLater,
    participantTaskId,
    addManualProvider,
    deleteManualProvider
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
    const { inProgressProviders } = oneUpHealthData;
    const [hasSearchTerm, setHasSearchTerm] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const centeredStyle = { justifyContent: 'center', margin: '0 auto' };
    const isMobile = useIsMobile();
    const { redirectToActivities } = useRedirections();

    const title = (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SearchProvidersTitle>{t('activities:emr:title')}</SearchProvidersTitle>
        </Box>
    );

    const isActiveProviderInProgress = inProgressProviders?.find(
        (item) => item.participant_task_id === participantTaskId
    );
    const shouldShowPopUp = (finishDataShareEnabled || inProgressProviders?.length > 0) && isActiveProviderInProgress;

    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
    const onCloseCancelModal = () => setIsOpenCancelModal(false);
    const location = useLocation();
    const isEMR = location.pathname === APP_ROUTES.SYNC_DATA;

    const backButtonHandler = () => {
        if (isEMR && shouldShowPopUp) {
            setIsOpenCancelModal(true);
            return;
        }
        return redirectToActivities();
    };

    const cancelModal = (
        <Modal
            open={isOpenCancelModal}
            onClose={onCloseCancelModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{ zIndex: 9999999 }}
        >
            <Container>
                <Wrapper>
                    <CancelLogo />
                    <Title>{t('activities:cancel:title')}</Title>
                    <Text>{t('activities:cancel:subtitle')}</Text>
                    <Button background='#E16058' onClick={onCloseCancelModal} disabled={isLoading}>
                        {t('activities:cancel:button-text')}
                    </Button>
                    <Link color='#E16058' to={APP_ROUTES.ACTIVITIES} replace>
                        {t('activities:cancel:link-text')}
                    </Link>
                </Wrapper>
            </Container>
        </Modal>
    );

    const searchBar = (
        <SearchBox
            fullWidth
            placeholder={t('activities:emr:placeholders:search-providers')}
            startAdornment={
                <InputAdornment position='end' size='large'>
                    <SearchIcon />
                </InputAdornment>
            }
            disabled={loadingProviders}
            onChange={(e) => {
                setSearchTerm(e.target.value);
                setHasSearchTerm(e.target.value.length > 3);
                setFiltered();
            }}
            sx={{ minWidth: 0 }}
        />
    );

    const AddNewProviderBar = (
        <AddNewProviderContainer onClick={() => setShowSearchBar(true)}>
            <AddNewProviderText>{t('activities:emr:buttons:add-new')}</AddNewProviderText>
        </AddNewProviderContainer>
    );

    const bar = !hasConnectedProviders || showSearchBar ? searchBar : AddNewProviderBar;
    const filteredConnectedProviders = connectedProviders.reduce((a, b) => {
        const isDuplicate = a.some((p) => p.id === b.id);
        if (!isDuplicate) a.push(b);
        return a;
    }, []);

    return (
        <>
            {cancelModal}
            <WrapperContainer>
                <HeaderContainer>
                    <Box sx={{ display: 'flex' }}>
                        {<BackToMyActivities backHandler={backButtonHandler}></BackToMyActivities>}
                    </Box>

                    <HeaderWrapper>
                        <RightHeaderItemContainer>
                            <MedicalIcon />
                            <RightHeaderTextWrapper>{t('activities:emr:header-text')}</RightHeaderTextWrapper>
                        </RightHeaderItemContainer>
                        <RightHeaderItemContainer>
                            <ClockIcon />
                            <RightHeaderTextWrapper>{duration}</RightHeaderTextWrapper>
                        </RightHeaderItemContainer>
                        <RightHeaderItemContainer>
                            {timeRemaining && <BellIcon />}
                            <RightHeaderTextWrapper>{timeRemaining}</RightHeaderTextWrapper>
                        </RightHeaderItemContainer>
                        <RightHeaderItemContainer>
                            <DollarIcon width={20} fill={theme.palette.primary.main} />
                            <RightHeaderTextWrapper sx={{ color: theme.palette.primary.main }}>
                                {points}
                            </RightHeaderTextWrapper>
                        </RightHeaderItemContainer>
                    </HeaderWrapper>
                </HeaderContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1',
                        ...centeredStyle,
                        padding: isMobile ? '8px' : '0px'
                    }}
                >
                    <LeftContainer sx={{ minWidth: '100%' }}>
                        <ContentContainer>
                            {title}
                            {bar}
                            <Box sx={{ paddingTop: '5px' }}>
                                <ProvidersList
                                    providers={providers}
                                    connectedProviders={filteredConnectedProviders}
                                    loadingProviders={loadingProviders}
                                    setLoadingProviders={setLoadingProviders}
                                    setLoadingConnectedProviders={setLoadingConnectedProviders}
                                    setIsRedirecting={setIsRedirecting}
                                    hasConnectedProviders={hasConnectedProviders}
                                    isLoading={isLoading}
                                    hasSearchTerm={hasSearchTerm}
                                    setModalOpen={() => setIsOpenModal(true)}
                                    addManualProvider={addManualProvider}
                                    deleteManualProvider={deleteManualProvider}
                                    participantTaskId={participantTaskId}
                                />
                            </Box>
                        </ContentContainer>
                    </LeftContainer>
                </Box>
                <ManualProviderModal
                    open={isOpenModal}
                    onClose={() => {
                        setSearchTerm(null);
                        setHasSearchTerm(false);
                        setIsOpenModal(false);
                    }}
                    participantTaskId={participantTaskId}
                    addManualProvider={addManualProvider}
                />
                <BottomContainer>
                    <FilledButton
                        endIcon={<ArrowForwardIcon width={14} height={14} />}
                        type='submit'
                        height='50px'
                        size='large'
                        disabled={!(finishDataShareEnabled || connectedProviders?.length > 0)}
                        sx={{ minWidth: '130px', marginLeft: '5px' }}
                        onClick={() => setShowConfirmationModal(true)}
                    >
                        {t('activities:emr:buttons:finish')}
                    </FilledButton>
                    <OutlineButton
                        width='220px'
                        height='50px'
                        text={t('activities:emr:buttons:add-more')}
                        onClickHandler={handleAddMoreLater}
                        startIcon={
                            !isMobile ? (
                                <AddMoreLaterIcon width={20} height={20} fill={theme.palette.primary.main} />
                            ) : null
                        }
                        disabled={!(finishDataShareEnabled || connectedProviders?.length > 0)}
                    />
                </BottomContainer>
                <ConfirmationModal
                    open={showConfirmationModal}
                    onClose={() => setShowConfirmationModal(false)}
                    handleConfirmation={handleFinish}
                />
            </WrapperContainer>
        </>
    );
};

const WrapperContainer = styled(Box)(({ theme }) => ({
    bgcolor: theme.palette.primary.gray20,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100dvh',
    width: 'calc(100dvh - 90px)',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 'calc(100dvh - 90px)',
        left: '0'
    }
}));
