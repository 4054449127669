import { Box, Typography } from '@mui/material';
import { style, styled } from '@mui/system';
import { ReactComponent as ArrowRight } from 'images/shared/arrow-right-2.svg';
import { useRedirections } from 'navigation';
import { useTranslation } from 'react-i18next';
import LogoSource from './Tarpeyo_Touchpoints_brandguidlines_6 1.png';
import { HeroWrapper, GetStartedBtn } from './styles';

export const Hero = () => {
    const { redirectToSignUp } = useRedirections();
    const { t } = useTranslation();
    return (
        <HeroWrapper>
            <Box>
                <Typography component='h1' variant='h1' fontWeight='600' fontSize={'32px'}>
                    {t('welcome:home:hero-welcome')}
                    <br />
                    {t('welcome:home:perform-patient')}
                </Typography>
                <pre style={{ textWrap: 'wrap', whiteSpace: 'normal' }}>
                    <Typography
                        variant='pn1'
                        display='block'
                        color='primary.gray75'
                        margin='0.7em 0 1.5em 0'
                        fontSize={'18px'}
                        fontWeight='500'
                        lineHeight={'150%'}
                    >
                        {t('welcome:home:hero-desc').map((item) => item)}
                    </Typography>
                </pre>
                <GetStartedBtn onClick={redirectToSignUp} endIcon={<ArrowRight width='16px' />}>
                    {t('welcome:home:register')}
                </GetStartedBtn>
            </Box>
            <Img>
                <img src={LogoSource} alt='Tarpeyo Logo' />
            </Img>
        </HeroWrapper>
    );
};

const Img = styled(Box)(({ theme }) => ({
    '& img': {
        width: '400px',
        borderRadius: '50%',
        [theme.breakpoints.down('md')]: {
            objectFit: 'cover',
            maxWidth: '400px',
            width: '100%',
            height: '100%'
        }
    }
}));
