import { Auth0Provider } from '@auth0/auth0-react';
import { getAppDomain, getAudience, getAuth0ClientId } from 'config/API';
import { APP_ROUTES } from 'navigation';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const REDIRECT_URI = window.location.origin + APP_ROUTES.ONBOARDING;

export const AuthenticationProvider = ({ children }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        console.log(appState);
        navigate(appState?.returnTo || window.location.pathname);
    };
    console.log(getAuth0ClientId(), getAppDomain(), getAudience(), REDIRECT_URI);

    return (
        <Auth0Provider
            clientId={getAuth0ClientId()}
            domain={getAppDomain()}
            audience={getAudience()}
            redirectUri={REDIRECT_URI}
            onRedirectCallback={onRedirectCallback}
            cacheLocation='localstorage'
            useRefreshTokens
        >
            {children}
        </Auth0Provider>
    );
};

AuthenticationProvider.propTypes = {
    children: PropTypes.node.isRequired
};
