import { Box } from '@mui/material';
import { Footer } from 'pages/Welcome/Footer';
import { Nav } from 'pages/Welcome/Nav';
import { Outlet } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { isValidSourceName } from './SourceNames';

export function WelcomeLayout() {
    const [searchParams] = useSearchParams();
    const sourcename = searchParams.get('source');
    const parsedSourceName = typeof sourcename === 'string' ? sourcename.toUpperCase() : '';

    if (isValidSourceName(parsedSourceName)) {
        localStorage.setItem('sourcename', parsedSourceName);
    }

    return (
        <Box minHeight='100vh' bgcolor='primary.gray20'>
            <Nav />
            <Box>
                <Outlet />
            </Box>
            <Footer />
        </Box>
    );
}
