import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  [theme.breakpoints.up('sm')]: {
    width: '150px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '46px',
  },
}));

export const Divider = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'inverted',
})(({ theme, inverted }) => {
  const border = `1px solid ${theme.palette.primary.fuchsia25}`;
  return {
    [theme.breakpoints.up('sm')]: {
      ...(inverted ? { borderLeft: border } : { borderTop: border }),
    },
    [theme.breakpoints.down('sm')]: {
      ...(inverted ? { borderTop: border } : { borderLeft: border }),
    },
  };
});
