import { Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import { ThumbsUpIcon } from 'images/activity';
import { useTranslation } from 'react-i18next';

export const Empty = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <ThumbsUpIcon />

            <Typography variant='h2' color='primary.gray100' mt='30px' mb='12px'>
                {t('activities:index:empty:title')}
            </Typography>

            <Typography variant='pl3' color='primary.gray75'>
                {t('activities:index:empty:description')}
            </Typography>
        </Container>
    );
};

export const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flex: 1
});
