import { Box, Typography, styled } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { ReactComponent as ExclamationIcon } from 'images/profile/exclamation-circle.svg';
import { ACTIVITY_STATUS } from 'pages/NewActivities/constants';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const today = new Date();
const TZ_DIFFERENCE = today.getTimezoneOffset() * 60 * 1000;

export function Chips({
    required_behavior,
    status,
    start_date,
    end_date,
    status_date,
    duration,
    isAdhocTask,
    ...rest
}) {
    const parsedDuration = JSON.parse(duration);
    const estimatedSeconds = parsedDuration?.timing_duration;

    let estimatedTime = estimatedSeconds / 60;

    if (isNaN(estimatedTime) && isAdhocTask) {
        estimatedTime = 2;
    }

    const daysLeft = Math.abs(differenceInDays(new Date(), end_date)) + 1;
    const safeDaysLeft = daysLeft > 30 ? 30 : daysLeft;

    return (
        <Container>
            {status === ACTIVITY_STATUS.ACTIVE && required_behavior === 'must' && <Required />}
            {status === ACTIVITY_STATUS.IN_PROGRESS && <InProgress />}
            {isAdhocTask && required_behavior !== 'must' && status !== ACTIVITY_STATUS.COMPLETED && <Optional />}

            {(status === ACTIVITY_STATUS.ACTIVE || status === ACTIVITY_STATUS.IN_PROGRESS) && estimatedTime > 0 && (
                <Chip>
                    {estimatedTime} {estimatedTime > 1 ? 'MINS' : 'MIN'}
                </Chip>
            )}

            {status === ACTIVITY_STATUS.ACTIVE && required_behavior === 'must' && !isAdhocTask && (
                <DaysLeft days={safeDaysLeft} />
            )}

            {status === ACTIVITY_STATUS.COMPLETED && <Completed date={status_date} />}
            {status === ACTIVITY_STATUS.UPCOMING && <Upcoming date={start_date} />}
            {status === ACTIVITY_STATUS.EXPIRED && <Expired date={end_date} />}
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginRight: 'auto',
    gap: '6px'
}));

function Chip({ borderColor = 'primary.gray15', color = '#595959', children }) {
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            padding='7px 10px'
            border='1px solid'
            borderColor={borderColor}
            borderRadius='4px'
            textAlign='center'
        >
            <Typography variant='pn7' color={color}>
                {children}
            </Typography>
        </Box>
    );
}

function Required() {
    const { t } = useTranslation();

    return (
        <RequiredContainer sx={{ color: 'primary.main', borderColor: 'primary.main' }}>
            <ExclamationIcon />
            {t('activities:index:chips:required')}
        </RequiredContainer>
    );
}

function InProgress() {
    const { t } = useTranslation();

    return (
        <RequiredContainer sx={{ color: 'primary.main', borderColor: 'primary.main' }}>
            <ExclamationIcon />
            {t('activities:index:chips:in-progress')}
        </RequiredContainer>
    );
}

export const RequiredContainer = styled(Typography)(({ theme }) => ({
    ...theme.typography.pn7,
    padding: '7px 10px',
    color: theme.palette.primary.gray75,
    border: '1px solid',
    borderColor: theme.palette.primary.gray15,
    borderRadius: '4px',
    marginTop: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px'
}));

function Optional() {
    const { t } = useTranslation();
    return <Chip>{t('activities:index:chips:optional')}</Chip>;
}

function DaysLeft({ days }) {
    const { t } = useTranslation();

    return (
        <Chip>
            {days} {days !== 1 ? t('activities:index:days').toUpperCase() : t('activities:index:day').toUpperCase()}{' '}
            {t('activities:index:chips:left')}
        </Chip>
    );
}
export function Completed({ date }) {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Chip color='#40B933' borderColor='#40B933'>
                {t('activities:index:chips:completed')}
            </Chip>

            <Chip color='primary.gray75' borderColor='primary.gray15'>
                {Intl.DateTimeFormat('en-US').format(new Date(date))}
            </Chip>
        </Fragment>
    );
}

export function Expired({ date }) {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Chip color='#595959' borderColor='#595959'>
                {t('activities:index:chips:expired')}
            </Chip>

            <Chip color='#595959' borderColor='primary.gray15'>
                {Intl.DateTimeFormat('en-US').format(new Date(new Date(date).getTime() - TZ_DIFFERENCE))}
            </Chip>
        </Fragment>
    );
}

export function Upcoming({ date }) {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Chip color='#595959' borderColor='#595959'>
                {t('activities:index:chips:upcoming')}
            </Chip>

            <Chip color='#595959' borderColor='#595959'>
                {Intl.DateTimeFormat('en-US').format(new Date(date))}
            </Chip>
        </Fragment>
    );
}
