import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { EmptyMedicationsList } from 'pages/Medications/EmptyMedicationsList';
import { Medications } from 'pages/Medications/Medications';
import { useMedications } from 'pages/Medications/MedicationsProvider';

export default function MedicationsList() {
    const { isLoading, medications } = useMedications();

    if (isLoading) {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' flex={1} width='100%'>
                <CircularProgress />
            </Box>
        );
    }

    const filteredMedications =
        medications?.filter((med) => (med.end_date && med.isPreviousMedication ? false : true)) ?? [];

    if (filteredMedications.length === 0) {
        return <EmptyMedicationsList />;
    }

    return <Medications />;
}
