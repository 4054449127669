import { Alert, Box, styled } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UserProfileForm } from './UserProfileForm';

const EditUserProfileFormLogic = ({
    onSubmit,
    initialValues,
    isCaregiver,
    isUpdatingPersonAttributes,
    form,
    showSuccessAlert,
    showErrorAlert,
    setShowErrorAlert,
    setShowSuccessAlert,
    iganProps,
    setShowVerifyPhone,
    person
}) => {
    const formRef = useRef(null);
    const { t } = useTranslation();

    const handleSubmit = async (data) => {
        setShowErrorAlert(false);
        setShowSuccessAlert(false);

        try {
            await onSubmit(data);
        } catch (error) {
            setShowErrorAlert(true);
        } finally {
            if (formRef.current) {
                formRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    const onError = (errors, e) => {
        setShowSuccessAlert(false);
        setShowErrorAlert(true);

        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Container>
            {showSuccessAlert && (
                <Box margin={'20px 0px'}>
                    <Alert severity='success' onClose={() => setShowSuccessAlert(false)}>
                        {t('profile:view:alerts:updated-successfully')}
                    </Alert>
                </Box>
            )}

            {showErrorAlert && (
                <Box margin={'20px 0px'}>
                    <Alert onClose={() => setShowErrorAlert(false)} severity='error'>
                        {t('profile:view:alerts:updated-error')}
                    </Alert>
                </Box>
            )}

            <UserProfileForm
                isUpdatingPersonAttributes={isUpdatingPersonAttributes}
                ref={formRef}
                form={form}
                isCaregiver={isCaregiver}
                onSubmit={handleSubmit}
                onError={onError}
                initialValues={initialValues}
                iganProps={iganProps}
                setShowVerifyPhone={setShowVerifyPhone}
                person={person}
            />
        </Container>
    );
};

const Container = styled(Box)(({ theme }) => ({
    overflowY: 'auto'
}));

export { EditUserProfileFormLogic };
