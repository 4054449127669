import { Box, MenuItem, Typography, styled, useMediaQuery } from '@mui/material';
import { useLanguage } from 'internationalization';
import { APP_ROUTES } from 'navigation';
import { StyledSelect } from 'pages/onboarding/Prescreening/forms/Caregiver/styles';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as CalliditasLogo } from './calliditas_logo.svg';
import { ReactComponent as IQVIA } from './iqvia-logo.svg';
import { ReactComponent as IQVIAMobile } from './iqvia-mobile-logo.svg';
import { Container, FooterBox, StyledLink } from './styles';

const IQVIALogo = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    return isMobile ? <IQVIAMobile /> : <IQVIA />;
};

const SmallCircle = styled('span')({
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    background: '#C0C4C6',
    margin: '0 5px',
    display: 'inline-block',
    verticalAlign: 'middle'
});

export const Footer = () => {
    const { t } = useTranslation();
    const { changeLanguage, preferedLanguage } = useLanguage();
    const [selectedLanguage, setSelectedLanguage] = useState(preferedLanguage);

    function setLanguage(language) {
        setSelectedLanguage(language);
        changeLanguage(language);
    }

    return (
        <Container>
            <FooterBox>
                <Box>
                    <CalliditasLogo />
                </Box>
                <Box>
                    <Box display='flex' alignItems='center' gap='15px' justifyContent='center' marginBottom={1}>
                        <StyledLink to={APP_ROUTES.PRIVACY_POLICY}>{t('welcome:home:privacy-policy')}</StyledLink>
                        <StyledLink to={APP_ROUTES.TERMS_OF_USE}>{t('welcome:terms:terms-of-use')}</StyledLink>
                    </Box>

                    <Typography color='#80898D' variant='pl3' component='p' textAlign='center' gutterBottom>
                        <Trans>{t('welcome:home:footer-desc').map((item) => item)}</Trans>
                    </Typography>

                    <Typography color='#80898D' variant='pl3' component='p' textAlign='center'>
                        {t('welcome:home:calliditas-therapeutics')}
                        <SmallCircle />
                        {t('welcome:home:all-rights-reserved')}
                        <SmallCircle />
                        {preferedLanguage === 'us_en' ? '07/23' : '10/23'}
                        <SmallCircle />
                        {preferedLanguage === 'us_en' ? 'US-CALT-2200159 v3.0' : 'US-TAR-2300131'}
                    </Typography>

                    <Box display='flex' alignItems='center' justifyContent='center' marginTop={3} gap='15px'>
                        <Typography variant='pl3' color='primary.gray75'>
                            {t(t('welcome:home:select-language'))}
                        </Typography>

                        <StyledSelect
                            sx={{ width: '150px' }}
                            onChange={(e) => setLanguage(e.target.value)}
                            value={selectedLanguage}
                        >
                            {Object.entries(t('profile:view:languages')).map(([key, value]) => (
                                <MenuItem key={key} value={key}>
                                    {value}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </Box>
                </Box>

                <IQVIALogo />
            </FooterBox>
        </Container>
    );
};
