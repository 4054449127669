import { APP_ROUTES } from 'navigation';
import HealthInstructions from 'pages/EQ5D5L/HealthInstructions';
import { HealthQuestionnaire } from 'pages/EQ5D5L/HealthQuestionnaire';
import { useActiveActivity } from 'pages/Main';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

export function HealthQuestionnairePage() {
    const { activeActivity } = useActiveActivity();
    const [isInstructions, setIsInstructions] = useState(true);

    if (!activeActivity) {
        return <Navigate to={APP_ROUTES.ACTIVITIES} replace />;
    }

    if (isInstructions) {
        return <HealthInstructions setIsInstructions={setIsInstructions} />;
    }

    return <HealthQuestionnaire activeActivity={activeActivity} setIsInstructions={setIsInstructions} />;
}
