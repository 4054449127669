export function parseStudyResources(json) {
    const studyResourceThemes = {};

    // Extract included study resources for efficient lookup
    const studyResourcesMap = new Map(
        json.included
            .filter((item) => item.type === 'study-resources')
            .map((item) => [parseInt(item.id), item.attributes])
    );

    json.data.forEach((localization) => {
        const stuydResourceAttributes = studyResourcesMap.get(parseInt(localization.attributes.study_resource_id));
        const studyResourceThemeId = stuydResourceAttributes.study_resource_theme_id;

        if (!studyResourceThemes[studyResourceThemeId]) {
            studyResourceThemes[studyResourceThemeId] = {
                id: studyResourceThemeId,
                title: localization.attributes.theme_title,
                studyResources: []
            };
        }

        studyResourceThemes[studyResourceThemeId].studyResources.push({
            sort_order: stuydResourceAttributes.sort_order,
            study_resource_theme_id: stuydResourceAttributes.study_resource_theme_id,
            link: stuydResourceAttributes.link,
            public: stuydResourceAttributes.public,
            start_date: stuydResourceAttributes.start_date,
            end_date: stuydResourceAttributes.end_date,
            language: localization.attributes.language,
            theme_title: localization.attributes.theme_title,
            resource_title: localization.attributes.resource_title,
            resource_text: localization.attributes.resource_text,
            resource_call_to_action: localization.attributes.resource_call_to_action
        });
    });

    return Object.values(studyResourceThemes);
}
