import { Box, Input as MuiInput, Typography, styled } from '@mui/material';
import { FilledButton } from 'components/Buttons';
import { GhostButton } from 'configUI/components/Buttons';
import { PhoneSVG } from 'pages/onboarding/Phone/assets';
import { useVerifyOTP } from 'pages/onboarding/Phone/hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const isValidDigit = (digit) => validChars.includes(digit);

export function VerifyPhone({ onSuccess, onError, onCancel, sendOtp, isSendingOtp }) {
    const { t } = useTranslation();

    const [code, setCode] = useState(['', '', '', '', '', '']);

    const refs = useRef([]);
    const isInvalidCode = code.some((digit) => !isValidDigit(String(digit)));

    const { mutate: verifyOtp, isLoading: isVerifyingOtp } = useVerifyOTP({
        otpCode: code.join(''),
        onSuccess,
        onError
    });

    function changeCode(index, value) {
        const newCode = [...code];
        const digit = value.slice(-1);
        const number = isValidDigit(digit) ? Number(digit) : '';
        newCode[index] = number;
        setCode(newCode);

        if (!isValidDigit(digit) || index === code.length - 1) return;
        refs.current[index + 1].focus();
    }

    function deleteCode(event, index) {
        if (event.key === 'Backspace' && index > 0) {
            const hasValue = code[index];
            const newCode = [...code];
            newCode[index] = '';
            setCode(newCode);

            if (!hasValue) {
                refs.current[index - 1].focus();
            }
        }
    }

    function handleOnSubmit(event) {
        event.preventDefault();

        if (!isInvalidCode) {
            verifyOtp();
        }
    }

    useEffect(() => {
        refs.current = refs.current.slice(0, code.length);
    }, [code.length]);

    useEffect(() => {
        refs.current[0].focus();
    }, []);

    return (
        <Wrapper>
            <Container as='form' onSubmit={handleOnSubmit}>
                <PhoneSVG />

                <Typography variant='h2' color='primary.gray100' marginTop='25px' width='75%' textAlign='center'>
                    {t('onboarding:phone:verify:title')}
                </Typography>

                <Typography
                    variant='pl3'
                    color='primary.gray75'
                    marginTop='5px'
                    marginBottom='20px'
                    textAlign='center'
                    width='70%'
                >
                    {t('onboarding:phone:verify:description')}
                </Typography>

                <Inputs>
                    {code.map((value, index) => {
                        return (
                            <Input
                                inputRef={(element) => (refs.current[index] = element)}
                                value={value}
                                disableUnderline
                                inputProps={{ inputMode: 'numeric', style: { textAlign: 'center' } }}
                                onChange={(e) => changeCode(index, e.target.value)}
                                onKeyDown={(e) => deleteCode(e, index)}
                            />
                        );
                    })}
                </Inputs>

                <GhostButton
                    onClick={sendOtp}
                    sx={{ marginTop: '20px', width: '100%' }}
                    disabled={isVerifyingOtp || isSendingOtp}
                >
                    {isSendingOtp ? t('onboarding:phone:verify:resending') : t('onboarding:phone:verify:resend')}
                </GhostButton>

                <FilledButton
                    type='submit'
                    width='100%'
                    sx={{ marginTop: '25px', marginBottom: '5px' }}
                    disabled={isInvalidCode || isVerifyingOtp}
                >
                    {isVerifyingOtp ? t('onboarding:phone:verify:verifying') : t('onboarding:phone:verify:verify')}
                </FilledButton>

                <GhostButton onClick={onCancel} sx={{ width: '100%' }} disabled={isVerifyingOtp || isSendingOtp}>
                    {t('onboarding:phone:verify:button')}
                </GhostButton>
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    overflowY: 'auto'
}));

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '375px',
    padding: '50px 40px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    margin: '10px'
}));

const Inputs = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px'
}));

const Input = styled(MuiInput)(({ theme }) => ({
    width: '50px',
    height: '50px',
    fontWeight: 700,
    color: theme.palette.primary.gray100,
    textAlign: 'center',
    borderRadius: '8px',
    border: '1px solid #CACED0',
    outline: 'none',
    caretColor: theme.palette.primary.main,
    caretShape: 'bar',
    '&.Mui-focused': {
        borderColor: theme.palette.primary.main
    }
}));
