import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Typography, AccordionDetails, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { GetStarted } from '../GetStarted';
import { FAQWrapper } from './styles';
import { StyledAccordion, StyledAccordionSummary } from './styles';

const FAQPage = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const [expandedMap, setExpandedMap] = useState({});

    const handleChange = (panel) => (event, isExpanded) => {
        if (expandedMap[panel]) {
            expandedMap[panel] = false;
        } else {
            expandedMap[panel] = true;
        }
        setExpandedMap({ ...expandedMap });
    };

    return (
        <Box padding={!isMobile && 3} bgcolor='primary.gray20'>
            <FAQWrapper>
                <Box>
                    <Typography component='h1' variant='h1' fontWeight='600'>
                        {t('welcome:faq:header')}
                    </Typography>
                    <Typography variant='pn1' display='block' color='primary.gray75' margin='0.7em 0 1.5em 0'>
                        {t('welcome:faq:subheader')}
                    </Typography>
                </Box>
                {t('welcome:faq:title').map((data, i) => {
                    return (
                        <StyledAccordion key={i} expanded={expandedMap[i] || false} onChange={handleChange(i)}>
                            <StyledAccordionSummary
                                expandIcon={
                                    expandedMap[i] || false ? (
                                        <Box color='primary.blue'>
                                            <RemoveIcon />
                                        </Box>
                                    ) : (
                                        <Box color='primary.blue'>
                                            <AddIcon />
                                        </Box>
                                    )
                                }
                                aria-controls={`panel${i}-content`}
                                id={`panel${i}-header`}
                                expanded={(expandedMap[i] || false).toString()}
                            >
                                <Typography variant='pb3' color='primary.blue'>
                                    {data}
                                </Typography>
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                <Trans>
                                    <Typography
                                        fontSize='14px'
                                        color='primary.gray75'
                                        display='block'
                                        lineHeight='24px'
                                    >
                                        {t('welcome:faq:description')[i]}
                                    </Typography>
                                </Trans>
                            </AccordionDetails>
                        </StyledAccordion>
                    );
                })}

                <GetStarted />
            </FAQWrapper>
        </Box>
    );
};

export default FAQPage;
