import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as DollarIcon } from 'images/dollar-sign.svg';
import { ReactComponent as DoneCheckmarkIcon } from 'images/shared/done-checkmark.svg';
import { Row as StyledRow } from 'pages/TaskThankYouPage/styles';
import { Amount, SuccessBox } from 'pages/onboarding/PaymentEarned/styles';
import { useTranslation } from 'react-i18next';
import { ActivityDoneContainer } from './styles';

export const Row = styled(StyledRow)({
    width: '100% !important'
});

const CelebrationScreen = ({
    description,
    maxHeight = true,
    maxWidth,
    sx = {},
    attributes: { thankyouMsg, points, affected_goals = [], participant_rewards = [] }
}) => {
    const { t } = useTranslation();
    const style = maxHeight ? { ...sx, height: '100%' } : { ...sx };
    const thankYouStyle = maxWidth ? { maxWidth: '250px' } : {};

    return (
        <ActivityDoneContainer sx={{ ...style }}>
            <DoneCheckmarkIcon />

            <Typography variant='h2' align='center' sx={{ ...thankYouStyle }} marginTop='51px' color='primary.gray75'>
                {t('activities:thankYou:titles:EMR')}
            </Typography>

            <Typography align='center' marginTop='15px' sx={{ ...thankYouStyle }} color='primary.gray75' variant='pl3'>
                {t('activities:emr:providers:celebration-description')}
            </Typography>

            {participant_rewards?.map((reward) => (
                <Row>
                    <SuccessBox>
                        <Box display='flex' alignItems='center'>
                            <DollarIcon />
                            <Amount>{reward?.reward_amount}</Amount>
                        </Box>
                        {t('activities:emr:providers:payment')}
                    </SuccessBox>
                </Row>
            ))}
        </ActivityDoneContainer>
    );
};

export default CelebrationScreen;
