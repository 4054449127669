import { Box, Typography } from '@mui/material';
import { ReactComponent as GreyCoinsIcon } from 'images/payment/greyCoins.svg';
import { useTranslation } from 'react-i18next';

export function Empty() {
    const { t } = useTranslation();

    return (
        <Box
            backgroundColor='primary.gray20'
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            height='100%'
        >
            <GreyCoinsIcon />

            <Typography textAlign='center' variant='h5' color='primary.gray75' marginTop='20px' marginBottom='5px'>
                {t('profile:payments:empty:title')}
            </Typography>

            <Typography textAlign='center' fontSize='12px' color='primary.gray50' width='230px'>
                {t('profile:payments:empty:description')}
            </Typography>
        </Box>
    );
}
