import { Box } from '@mui/system';
import { ClipboardListIcon } from 'images/activity';
import { ReactComponent as ArticlePinkIcon } from 'images/activity/types/article-pink.svg';
import { ReactComponent as ArticleIcon } from 'images/activity/types/article.svg';
import { ReactComponent as CalendarPinkIcon } from 'images/activity/types/calendar-pink.svg';
import { ReactComponent as CalendarIcon } from 'images/activity/types/calendar.svg';
import { ReactComponent as CardIcon } from 'images/activity/types/card.svg';
import { ReactComponent as ChecklistIcon } from 'images/activity/types/checklist.svg';
import { ReactComponent as ClockIcon } from 'images/activity/types/clock.svg';
import { ReactComponent as ConsentIcon } from 'images/activity/types/consent-color.svg';
import { ReactComponent as ConsentPinkIcon } from 'images/activity/types/consent-pink.svg';
import { ReactComponent as EmrPinkIcon } from 'images/activity/types/emr-pink.svg';
import { ReactComponent as GameboardIcon } from 'images/activity/types/game-board.svg';
import { ReactComponent as GroupTaskPinkIcon } from 'images/activity/types/group-task-pink.svg';
import { ReactComponent as GroupTaskIcon } from 'images/activity/types/group-task.svg';
import { ReactComponent as MakeTelehealthIcon } from 'images/activity/types/make-telehealth.svg';
import { ReactComponent as MakeTelevisitIcon } from 'images/activity/types/make-televisit.svg';
import { ReactComponent as CallPinkIcon } from 'images/activity/types/phone-pink.svg';
import { ReactComponent as PlayCircleIcon } from 'images/activity/types/play-circle.svg';
import { ReactComponent as PollIcon } from 'images/activity/types/poll.svg';
import { ReactComponent as ProfilePinkIcon } from 'images/activity/types/profile-pink.svg';
import { ReactComponent as ProfileIcon } from 'images/activity/types/profile.svg';
import { ReactComponent as ScheduleTelehealthIcon } from 'images/activity/types/schedule-telehealth.svg';
import { ReactComponent as ScheduleTelevisitIcon } from 'images/activity/types/schedule-televisit.svg';
import { ReactComponent as SurveyPinkIcon } from 'images/activity/types/survey-pink.svg';
import { ReactComponent as TvPinkIcon } from 'images/activity/types/tv-pink.svg';
import { ReactComponent as VideoPinkIcon } from 'images/activity/types/video-pink.svg';
import { ReactComponent as Devices } from 'images/study/devices.svg';
import { ReactComponent as FollowUps } from 'images/study/followUps.svg';
import { ReactComponent as Games } from 'images/study/games.svg';
import { ReactComponent as HealthData } from 'images/study/healthData.svg';
import { ReactComponent as LabTests } from 'images/study/labTests.svg';
import { ReactComponent as Polls } from 'images/study/polls.svg';
import { ReactComponent as survey } from 'images/study/surveys.svg';
import { ReactComponent as Videos } from 'images/study/videos.svg';

export const SvgImageMap = {
    Games,
    survey,
    Polls,
    HealthData,
    Videos,
    FollowUps,
    LabTests,
    Devices,
    GameboardIcon,
    CalendarIcon
};

const iconStyles = {
    width: '50px',
    height: '50px',
    marginTop: '-50px',
    marginLeft: '-50px'
};

const overdueIconStyles = {
    width: '50px',
    height: '50px',
    marginTop: '-83px',
    marginLeft: '-81px'
};

export const ActivityTypesIconMap = {
    group: (
        <Box
            width='50px'
            height='50px'
            borderRadius='50%'
            display='flex'
            alignItems='center'
            justifyContent='center'
            bgcolor='#E6F1E4'
            boxSizing='border-box'
        >
            <ClipboardListIcon height='25px' />
        </Box>
    ),
    survey: (
        <Box bgcolor='#E2F0F5'>
            <HealthData />
        </Box>
    ),
    consent: (
        <Box
            sx={{
                width: '100px',
                marginLeft: '-40px'
            }}
        >
            <ConsentIcon />
        </Box>
    ),
    game: <GameboardIcon />,
    followUpSchedule: <CalendarIcon />,
    followUpCall: <CalendarIcon />,
    teleResearch: <CalendarIcon />,
    poll: <PollIcon />,
    video: <PlayCircleIcon />,
    connectCard: <CardIcon />,
    medicalHistory: <ClockIcon />,
    profile: (
        <Box sx={iconStyles}>
            <ProfileIcon />
        </Box>
    ),
    article: (
        <Box sx={iconStyles}>
            <ArticleIcon />
        </Box>
    ),
    'data-sharing': (
        <Box bgcolor='#E2F0F5'>
            <HealthData />
        </Box>
    ),
    'schedule-televisit': (
        <Box sx={iconStyles}>
            <ScheduleTelevisitIcon />
        </Box>
    ),
    'make-televisit': (
        <Box sx={iconStyles}>
            <MakeTelevisitIcon />
        </Box>
    ),
    'schedule-telehealth': (
        <Box sx={iconStyles}>
            <ScheduleTelehealthIcon />
        </Box>
    ),
    'make-telehealth': (
        <Box sx={iconStyles}>
            <MakeTelehealthIcon />
        </Box>
    ),
    call: (
        <Box sx={iconStyles}>
            <MakeTelehealthIcon />
        </Box>
    ),
    televisit_video: (
        <Box sx={iconStyles}>
            <MakeTelevisitIcon />
        </Box>
    )
};

export const ActivityTypesOverdueIconMap = {
    survey: (
        <Box
            sx={{
                width: '50px',
                height: '50px',
                marginTop: '-84px',
                marginLeft: '-79px'
            }}
        >
            <SurveyPinkIcon />
        </Box>
    ),
    consent: (
        <Box
            sx={{
                width: '50px',
                height: '50px',
                marginTop: '-80px',
                marginLeft: '-89px'
            }}
        >
            <ConsentPinkIcon />
        </Box>
    ),
    game: <GameboardIcon />,
    followUpSchedule: <CalendarIcon />,
    followUpCall: <CalendarIcon />,
    teleResearch: <CalendarIcon />,
    poll: <PollIcon />,
    video: (
        <Box sx={{ ...iconStyles, marginLeft: '-86px', marginTop: '-87px' }}>
            <VideoPinkIcon />
        </Box>
    ),
    connectCard: <CardIcon />,
    medicalHistory: <ClockIcon />,
    profile: (
        <Box sx={{ ...iconStyles, marginLeft: '-86px', marginTop: '-87px' }}>
            <ProfilePinkIcon />
        </Box>
    ),
    article: (
        <Box sx={{ ...iconStyles, marginLeft: '-84px', marginTop: '-83px' }}>
            <ArticlePinkIcon />
        </Box>
    ),
    'data-sharing': (
        <Box sx={{ ...overdueIconStyles, marginLeft: '-53px', marginTop: '-53px' }}>
            <EmrPinkIcon />
        </Box>
    ),
    'schedule-televisit': (
        <Box sx={overdueIconStyles}>
            <CalendarPinkIcon />
        </Box>
    ),
    televisit_video: (
        <Box sx={{ ...iconStyles, marginLeft: '-82px', marginTop: '-83px' }}>
            <TvPinkIcon />
        </Box>
    ),
    'schedule-telehealth': (
        <Box sx={overdueIconStyles}>
            <CalendarPinkIcon />
        </Box>
    ),
    'make-telehealth': (
        <Box sx={{ ...iconStyles, marginLeft: '-82px', marginTop: '-83px' }}>
            <CallPinkIcon />
        </Box>
    ),
    group: (
        <Box
            sx={{
                height: '47px'
            }}
        >
            <GroupTaskIcon />
        </Box>
    )
};

export const ActivityTypesLinksMap = (id, type) =>
    ({
        survey: `/activity/survey/${id}`,
        call: `/activity/make-call/${id}`,
        televisit_video: `/activity/make-call/${id}`,
        televisit_video_schedule: `/activity/schedule-call/${id}`,
        call_schedule: `/activity/schedule-call/${id}`,
        'schedule-televisit': `/activity/schedule-call/${id}`,
        // 'data-sharing': `/activity/sync-data?id=${id}`,
        consent: '/profile/consents',
        article: `/activity/article/${id}`,
        video: `/activity/video/${id}`
    }[type]);

export const activityTypeNamesMapping = {
    survey: 'Survey',
    game: 'Game',
    consent: 'Consent',
    teleresearch: 'Teleresearch',
    makeCall: 'Follow-up Call',
    poll: 'Poll',
    video: 'Video',
    connectCard: 'Connect Card',
    medicalHistory: 'Medical History',
    'data-sharing': 'Medical Record',
    profile: 'Profile',
    article: 'Article',
    'schedule-telehealth': 'Telehealth',
    'make-telehealth': 'Telehealth',
    'schedule-televisit': 'Televisit',
    'make-televisit': 'Televisit',
    call: 'Appointment',
    call_schedule: 'Appointment Scheduling',
    group: 'Group Task'
};
