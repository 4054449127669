import { Box, Button as MuiButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { TextField } from 'components/Input/TextField';
import { EmailLogo } from 'pages/onboarding/VerifyEmail/assets';
import { Link as ReactRouterLink } from 'react-router-dom';

export const MainContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '95vh'
}));

export const InstructionsContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.gray2,
    borderTop: '1px solid ' + theme.palette.primary.gray10,
    borderBottom: '1px solid ' + theme.palette.primary.gray10,
    flexGrow: 1
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
    height: '8vh',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '15px'
}));

export const MobileButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingTop: '15px',
    justifyContent: 'center'
}));

export const SpinnerContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '100px',
    margin: 'auto'
}));

export const LeftContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingTop: '125px',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        paddingTop: '0px'
    }
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
    maxWidth: '820px',
    width: '100%',
    margin: '0 auto'
}));

export const BottomContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    // height: '10vh',
    background: 'white',
    padding: '15px',
    boxSizing: 'border-box',
    width: '100%'
}));

export const RightContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '40%',
    padding: '50px',
    flexDirection: 'column',
    background: '#FBFBFB'
}));

export const ConnectContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.gray2,
    color: theme.palette.secondary.gray4,
    [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.primary.gray20
    }
}));

export const ConnectContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '40px',
    maxWidth: '295px',
    height: 'calc(100vh - 120px)',
    margin: 'auto',
    paddingTop: '59px',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '325px',
        height: '100vh',
        paddingTop: '0px'
    }
}));

export const getStyles = (theme) => ({
    h4: {
        lineHeight: '29px'
    },
    text: {
        lineHeight: '20px'
    }
});

export const SearchBox = styled(TextField)(({ theme }) => ({
    borderRadius: '0px',
    height: '7vh',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '10px',
    marginRight: 'auto',
    marginLeft: 'auto',
    minWidth: '320px',
    borderColor: theme.palette.primary.gray10,
    [theme.breakpoints.up('sm')]: {
        borderColor: theme.palette.primary.gray10,
        borderRadius: '8px',
        height: '50px',
        paddingLeft: '8px',
        paddingRight: '17px',
        marginTop: '0px'
    }
}));

export const SearchProvidersTitle = styled(Box)(({ theme }) => ({
    color: theme.palette.secondary.gray4,
    fontWeight: 600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    paddingBottom: '15px'
}));

export const SearchProvidersTitleMobile = styled(Box)(({ theme }) => ({
    color: theme.palette.secondary.gray4,
    fontWeight: 600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    paddingLeft: '15px'
}));

export const CentralContainer = styled(Box)(({ theme }) => ({
    paddingTop: '35px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    width: '60%'
}));

export const ShareMedicalRecords = styled(Box)(({ theme }) => ({
    color: '#444444',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '145%',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    marginLeft: '20px',
    cursor: 'pointer'
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    // height: '10vh',
    paddingTop: '25px',
    // paddingLeft: '25px',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px',
    flexWrap: 'wrap',
    gap: '20px'
}));

export const HeaderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    color: '#444444',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18.9px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    marginRight: '25px'
}));

export const RightHeaderItemContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginLeft: '10px'
}));

export const RightHeaderTextWrapper = styled(Box)(({ theme }) => ({
    marginTop: '-1px',
    marginLeft: '5px'
}));

export const AddNewProviderText = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.white,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    gap: '20px',
    paddingLeft: '20px',
    paddingRight: '20px'
}));

export const AddNewProviderContainer = styled(Box)(({ theme }) => ({
    borderRadius: '8px',
    minHeight: '57px',
    marginTop: '10px',
    marginRight: 'auto',
    marginLeft: 'auto',
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
}));

export const Container = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw',
    backgroundColor: theme.palette.primary.gray20
}));

export const Wrapper = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.primary.white,
    borderRadius: '8px',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    display: 'inline-flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '375px',
    minWidth: '375px',
    padding: '3.75rem 1rem'
}));

export const Logo = styled(EmailLogo)(() => ({
    marginBottom: '3.125rem'
}));

export const Title = styled(Typography)(({ theme }) => ({
    ...theme.typography.h2,
    marginBottom: '5px',
    textAlign: 'center',
    maxWidth: '90%'
}));

export const Text = styled(Typography)(({ theme }) => ({
    ...theme.typography.pl3,
    color: theme.palette.primary.gray75,
    marginBottom: '5px',
    textAlign: 'center',
    width: '80%'
}));

export const Button = styled(MuiButton)(({ theme }) => ({
    backgroundColor: '#E16058',
    borderRadius: '8px',
    color: theme.palette.primary.white,
    lineHeight: '1.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    height: '56px',
    marginTop: '3.125rem',
    marginBottom: '1.75rem',
    textTransform: 'capitalize',
    width: '80%',
    '&:hover': {
        backgroundColor: theme.palette.primary.darkRed
    }
}));

export const Link = styled(ReactRouterLink)(({ theme }) => ({
    color: '#E16058',
    fontSize: '.875rem',
    fontWeight: 600,
    lineHeight: '140%',
    textDecoration: 'none',
    '&:hover': {
        color: theme.palette.primary.darkRed
    }
}));
