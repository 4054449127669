import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const TermsWrapper = styled(Box)(({ theme }) => ({
    paddingBottom: '50px',
    backgroundColor: theme.palette.primary.gray20,
    margin: '5em auto 5em auto',
    maxWidth: '1080px',
    '@media (max-width: 768px)': {
        textAlign: 'center',
        padding: '0px 20px'
    }
}));
