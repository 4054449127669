/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { AchievementsContainer, AchievementsTitle, Title } from 'components/Study';
import { Loader } from 'configUI/components/Shared/Loader';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'utils/isMobile';
import AchievementCard from './achievementCard';
import { useParticipantsTasksData } from './useParticipantsTasksData';

export default function Progress({
    taskOccurrencies,
    totalTasks,
    currentDay = 0,
    daysTotal = 0,
    points = 0,
    level = 0
}) {
    const theme = useTheme();
    const marginX = isMobile() ? '15px' : '0px';
    const { t } = useTranslation();

    const {
        groupTasks,
        completedGroupTasks,
        surveyTasks,
        completedSurveyTasks,
        dataSharingTasks,
        completedDataSharingTasks,
        gameTasks,
        completedGameTasks,
        videoTasks,
        completedVideoTasks,
        isLoadingResults,
        labTestTasks,
        completedLabTestTasks,
        devicesTasks,
        completedDevicesTasks,
        callScheduleTasks,
        completedCallScheduleTasks
    } = useParticipantsTasksData();

    const gridData = useMemo(
        () => [
            {
                name: t('progress:index:sections:data:surveys'),
                type: 'survey',
                points: completedSurveyTasks?.length,
                totalPoints: surveyTasks?.length
            },
            {
                name: t('progress:index:sections:data:health-data'),
                type: 'HealthData',
                points: completedDataSharingTasks?.length,
                totalPoints: dataSharingTasks?.length
            }
        ],
        [
            completedSurveyTasks?.length,
            surveyTasks?.length,
            completedDataSharingTasks?.length,
            dataSharingTasks?.length,
            completedGameTasks?.length,
            gameTasks?.length,
            completedVideoTasks?.length,
            videoTasks?.length,
            completedCallScheduleTasks?.length,
            callScheduleTasks?.length,
            completedLabTestTasks?.length,
            labTestTasks?.length,
            completedDevicesTasks?.length,
            devicesTasks?.length
        ]
    );

    if (isLoadingResults) {
        return <Loader />;
    }

    return (
        <Container sx={{ backgroundColor: theme.palette.primary.gray2 }}>
            <Box marginX={marginX}>
                <Title>{t('progress:index:title')}</Title>
                <AchievementsTitle>{t('progress:index:sections:titles:my-achievements')}</AchievementsTitle>
            </Box>

            <AchievementsContainer>
                {gridData.map((achievement) => {
                    return (
                        <AchievementCard
                            key={achievement.name}
                            name={achievement.name}
                            imageName={achievement.type}
                            points={
                                achievement.name === t('progress:index:sections:data:surveys')
                                    ? completedGroupTasks.length
                                    : achievement.points
                            }
                            totalPoints={
                                achievement.name === t('progress:index:sections:data:surveys')
                                    ? groupTasks.length
                                    : achievement.totalPoints
                            }
                        />
                    );
                })}
            </AchievementsContainer>
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({}));
