import ExpandMore from '@mui/icons-material/ExpandMore';
import { AccordionDetails, Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as ArrowRight } from 'images/shared/arrow-right-2.svg';
import { useResources } from 'modules/resources';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ButtonLink,
    Card,
    ResourceLibraryWrapper,
    ResourcesList,
    StyledAccordion,
    StyledAccordionSummary
} from './styles';

const Linkify = ({ children }) => {
    const isUrl = (word) => {
        const urlPattern =
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gm;
        return word.match(urlPattern);
    };

    const addMarkup = (word) => {
        return isUrl(word) ? `<a href="${word.replace(/\n/g, '')}">${word.replace(/\n/g, '')}</a> \n` : word + ' ';
    };

    const words = children.split(/[\n\s]/);

    // Remove empty strings from the array
    const nonEmptyWords = words.filter((word) => word.trim() !== '');

    const formattedWords = nonEmptyWords.map((w, i) => addMarkup(w));
    const html = formattedWords.join('');

    return <span dangerouslySetInnerHTML={{ __html: html }} />;
};

const ResourceLibraryPage = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const [expandedMap, setExpandedMap] = useState({});
    const { isLoading, data } = useResources();

    const handleChange = (panel) => (event, isExpanded) => {
        if (expandedMap[panel]) {
            expandedMap[panel] = false;
        } else {
            expandedMap[panel] = true;
        }
        setExpandedMap({ ...expandedMap });
    };

    const callToAction = function (link) {
        window.open(link, '_blank') || window.location.replace(link);
    };

    useEffect(() => {
        setExpandedMap({});
    }, [data]);

    return (
        <Box padding={!isMobile && 3} bgcolor='primary.gray20'>
            <ResourceLibraryWrapper>
                <Box>
                    <Typography component='h1' variant='h1' fontWeight='600'>
                        {t('welcome:resources:header')}
                    </Typography>
                    <Typography
                        variant='pn1'
                        display='block'
                        color='primary.gray75'
                        margin='0.7em 0 1.5em 0'
                        maxWidth='550px'
                    >
                        {t('welcome:resources:subheader')}
                    </Typography>
                </Box>

                {isLoading ? (
                    <Box display='flex' alignItems='center' justifyContent='center' flex={1} bgcolor='primary.gray20'>
                        <CircularProgress />
                    </Box>
                ) : (
                    data?.map(({ title, studyResources }) => (
                        <StyledAccordion key={title} expanded={expandedMap[title]} onChange={handleChange(title)}>
                            <StyledAccordionSummary
                                expandIcon={
                                    <Box color='primary.blue'>
                                        <ExpandMore />
                                    </Box>
                                }
                                aria-controls={`panel${title}-content`}
                                id={`panel${title}-header`}
                                expanded={expandedMap[title]}
                            >
                                <Typography variant='h5' color='primary.gray100' lineHeight={0}>
                                    {title}
                                </Typography>
                            </StyledAccordionSummary>

                            <AccordionDetails>
                                <ResourcesList>
                                    {studyResources?.map(
                                        ({ resource_title, resource_text, link, resource_call_to_action }) => (
                                            <Card>
                                                <Typography component='h2' variant='h2' color='secondary.gray100'>
                                                    {resource_title}
                                                </Typography>
                                                <pre style={{ textWrap: 'wrap', whiteSpace: 'pre-wrap' }}>
                                                    <Typography component='pl3' variant='pl3' color='secondary.gray75'>
                                                        <Linkify>{resource_text}</Linkify>
                                                    </Typography>
                                                </pre>
                                                <ButtonLink
                                                    onClick={() => callToAction(link)}
                                                    endIcon={<ArrowRight width='16px' />}
                                                >
                                                    {resource_call_to_action}
                                                </ButtonLink>
                                            </Card>
                                        )
                                    )}
                                </ResourcesList>
                            </AccordionDetails>
                        </StyledAccordion>
                    ))
                )}
            </ResourceLibraryWrapper>
        </Box>
    );
};

export default ResourceLibraryPage;
