import { Box, styled, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { LeftArrow } from "../assets";

export function Header() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isHeader = pathname in headers;

    if (!isHeader) {
        return null;
    }

    const { title, to } = headers[pathname];

    return (
        <Container>
            <LeftArrow onClick={() => navigate(to)} width="15px" style={{ cursor: "pointer" }} />
            <Typography variant="h5">{title}</Typography>
        </Container>
    )
}

const headers = {
    "/profile/consents": {
        title: "My Consents",
        to: "profile/view"
    }
}

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: "1rem 2rem",
    gap: "10px",
    borderBottom: `1px solid ${theme.palette.primary.gray10}`,
    boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.04)",
}));