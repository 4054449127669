import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useGetPatient, useGetPerson } from 'authentication';
import { BaseLayout } from 'components/BaseLayout';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { useLanguage, useSyncAuth0Metadata } from 'internationalization';
import { APP_ROUTES, useRedirections } from 'navigation';
import { PhoneNumberUpdateModal, Container } from 'pages/Profile/UserProfile/PhoneNumberUpdateModal';
import { useShowPhoneNumberUpdateModal } from 'pages/Profile/UserProfile/Provider';
import { PARTICIPANT_STATUS } from 'pages/onboarding';
import { PhoneInvalid } from 'pages/onboarding/Phone/PhoneInvalid';
import { PhoneReachedLimit } from 'pages/onboarding/Phone/PhoneReachedLimit';
import { PhoneVerified } from 'pages/onboarding/Phone/PhoneVerified';
import { VerifyPhone } from 'pages/onboarding/Phone/VerifyPhone';
import { useSendOTP } from 'pages/onboarding/Phone/hooks';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { EditUserProfileFormLogic } from './EditUserProfileFormLogic';
import { StyledTitle, StyledTitleTag } from './styles';
import { getCaregiverPayload, getPayload } from './utils';

const UserProfile = () => {
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { redirectToPhoneVerification } = useRedirections();
    const { i18n, t } = useTranslation();
    const { mutate: updateAuth0Metadata } = useSyncAuth0Metadata();

    const {
        role,
        id: personId,
        isLoading: isPersonLoading,
        refetch: refetchPerson,
        status,
        isCaregiver,
        ...person
    } = useGetPerson();

    const {
        id: patientId,
        isLoading: isPatientLoading,
        refetch: refetchPatient,
        isSuccess: isPatientSuccess,
        ...patient
    } = useGetPatient();

    const [editPersonAttributes, { isLoading: isUpdatingPersonAttributes }] = useEditPersonAttributesMutation();
    const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { preferedLanguage } = useLanguage();
    const lastPreferedLanguage = useRef(preferedLanguage).current;

    const [igANOptions, setIgANOptions] = useState([]);
    const [igANAge, setIgANAge] = useState(undefined);

    const initIgAnOptions = {
        options: (isCaregiver ? patient.iganHasParticipated : person.iganHasParticipated)?.split(',') ?? [],
        age: igANAge
    };

    useEffect(() => {
        if ((!person.isLoading && person.isSuccess) || (!isPatientLoading && isPatientSuccess)) {
            setIgANOptions((isCaregiver ? patient.iganHasParticipated : person.iganHasParticipated)?.split(',') ?? []);
            setIgANAge(Number(isCaregiver ? patient.iganAgeDiagnosis : person.iganAgeDiagnosis) ?? undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [person.isLoading, person.isSuccess, isPatientLoading, isPatientSuccess]);

    const [showVerifyPhone, setShowVerifyPhone] = useState(false);
    const [showInvalidOtp, setShowInvalidOtp] = useState(false);
    const [showValidOtp, setShowValidOtp] = useState(false);
    const [showReachedLimit, setShowReachedLimit] = useState(false);

    const { mutate: sendOtp, isLoading: isSendOtpLoading } = useSendOTP({
        onSuccess: () => setShowVerifyPhone(true),
        onError: (error) => {
            if (error === 422) {
                setShowValidOtp(true);
                return;
            }

            if (error === 429) {
                setShowReachedLimit(true);
                return;
            }
        }
    });

    function optionsHaveChanged() {
        const set1 = new Set(igANOptions);
        const set2 = new Set(initIgAnOptions.options);

        if (set1.size !== set2.size) {
            return true;
        }

        for (const item of set1) {
            if (!set2.has(item)) {
                return true;
            }
        }

        return false;
    }

    const iganAgeHasChanged =
        Number(igANAge) !== Number(isCaregiver ? patient.iganAgeDiagnosis : person.iganAgeDiagnosis);

    const iganHasChanged = iganAgeHasChanged || optionsHaveChanged();

    const iganProps = {
        options: igANOptions,
        setOptions: setIgANOptions,
        age: igANAge,
        setAge: setIgANAge,
        init: initIgAnOptions,
        hasChanged: iganHasChanged
    };

    const toggleOnSmsNotification = Number(person.sms_notification) === 1 && Number(person.phoneVerified) === 1;

    const initialValues = {
        first_name: person.firstName ?? '',
        last_name: person.lastName ?? '',
        email: person.email ?? '',
        timezone: patient.timezone ?? person.timezone ?? '',
        patient_first_name: patient.firstName ?? '',
        patient_last_name: patient.lastName ?? '',
        sex_at_birth: person.sex_at_birth ?? patient.sex_at_birth ?? '',
        gender: person.gender ?? patient.gender ?? null,
        race: person.race ?? patient.race ?? '',
        ethnicity: person.ethnicity ?? patient.ethnicity ?? '',
        language: person.language ?? 'us_en',
        phone: person.phone ? person.phone.replace('+1', '') : '',
        sms_notification: toggleOnSmsNotification,
        date_of_birth: patient?.date_of_birth
    };

    const form = useForm({
        mode: 'onSubmit',
        defaultValues: initialValues
    });

    const handleSubmit = async (data) => {
        delete data.date_of_birth;

        if (data.phone === '' && !person.phone) {
            delete data.phone;
        }

        const hasChangedLanguage = typeof data.language === 'string' && data.language !== preferedLanguage;
        const hasChangedPhone = person?.phone?.replace('+1', '') !== data.phone;

        if (!hasChangedPhone) {
            delete data.phone;
        }

        const needPhoneVerification =
            Number(data.sms_notification) === 1 &&
            (Number(person.phoneVerified) === 0 || typeof person.phoneVerified === 'undefined' || hasChangedPhone);

        if (
            (Number(data.sms_notification) === 1 && !person.phone) ||
            (Number(data.sms_notification) === 1 && Number(person.phoneVerified) === 0) ||
            hasChangedPhone
        ) {
            delete data.sms_notification;
        }

        if (data.phone) {
            data.phone = '+1' + data.phone;
        }

        if (typeof data.sms_notification !== 'undefined') {
            data.sms_notification = Number(data.sms_notification);
        }

        const payload = getPayload(data, isCaregiver).concat([
            {
                data: {
                    type: 'person-attributes',
                    attributes: {
                        attribute: 'clinical_trial_participation',
                        value: igANOptions.join(',')
                    }
                }
            },
            {
                data: {
                    type: 'person-attributes',
                    attributes: {
                        attribute: 'age_at_IgAN_diagnosis',
                        value: Number(igANAge)
                    }
                }
            }
        ]);

        if (isCaregiver) {
            const caregiverPayload = getCaregiverPayload(data);

            const caregiver = editPersonAttributes({
                personId: personId,
                payload: caregiverPayload
            });

            const patient = editPersonAttributes({
                personId: patientId,
                payload
            });

            const [caregiverRequest] = await Promise.all([caregiver, patient]);

            if (!caregiverRequest.error) {
                if (hasChangedLanguage) {
                    i18n.changeLanguage(data.language);
                    updateAuth0Metadata(data.language);
                }

                if (hasChangedPhone) {
                    setShowSuccessAlert(true);
                    setShowModal(true);
                    return;
                }

                if (needPhoneVerification) {
                    setShowSuccessAlert(true);
                    sendOtp();
                    setShowVerifyPhone(true);
                    return;
                }

                setShowSuccessAlert(true);
                await refetchPatient();
                refetchPerson();
            } else {
                setShowErrorAlert(true);
                i18n.changeLanguage(lastPreferedLanguage);
            }
        } else {
            const personRequest = await editPersonAttributes({
                personId: personId,
                payload
            });

            if (!personRequest.error) {
                if (hasChangedLanguage) {
                    i18n.changeLanguage(data.language);
                    updateAuth0Metadata(data.language);
                }

                if (hasChangedPhone) {
                    setShowSuccessAlert(true);
                    setShowModal(true);
                    return;
                }

                if (needPhoneVerification) {
                    setShowSuccessAlert(true);
                    sendOtp();
                    setShowVerifyPhone(true);
                    return;
                }

                setShowSuccessAlert(true);
                refetchPerson();
            } else {
                setShowErrorAlert(true);
                i18n.changeLanguage(lastPreferedLanguage);
            }
        }
    };

    useEffect(() => {
        return () => {
            if (isCaregiver) {
                refetchPatient();
            }

            refetchPerson();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function resetForm() {
        form.reset({
            first_name: person.firstName ?? '',
            last_name: person.lastName ?? '',
            email: person.email ?? '',
            timezone: patient.timezone ?? person.timezone ?? '',
            patient_first_name: patient.firstName ?? '',
            patient_last_name: patient.lastName ?? '',
            sex_at_birth: person.sex_at_birth ?? patient.sex_at_birth ?? '',
            gender: person.gender ?? patient.gender ?? null,
            race: person.race ?? patient.race ?? '',
            ethnicity: person.ethnicity ?? patient.ethnicity ?? '',
            language: person.language ?? 'us_en',
            phone: person.phone ? person.phone.replace('+1', '') : '',
            sms_notification: toggleOnSmsNotification,
            date_of_birth: patient?.date_of_birth
        });
    }

    useEffect(() => {
        form.reset({
            first_name: person.firstName ?? '',
            last_name: person.lastName ?? '',
            email: person.email ?? '',
            timezone: patient.timezone ?? person.timezone ?? '',
            patient_first_name: patient.firstName ?? '',
            patient_last_name: patient.lastName ?? '',
            sex_at_birth: person.sex_at_birth ?? patient.sex_at_birth ?? '',
            gender: person.gender ?? patient.gender ?? null,
            race: person.race ?? patient.race ?? '',
            ethnicity: person.ethnicity ?? patient.ethnicity ?? '',
            language: person.language ?? 'us_en',
            phone: person.phone ? person.phone.replace('+1', '') : '',
            sms_notification: toggleOnSmsNotification,
            date_of_birth: patient?.date_of_birth
        });
    }, [
        person.firstName,
        person.lastName,
        person.email,
        patient.timezone,
        person.timezone,
        patient.firstName,
        patient.lastName,
        person.sex_at_birth,
        patient.sex_at_birth,
        person.gender,
        patient.gender,
        person.race,
        patient.race,
        person.ethnicity,
        patient.ethnicity,
        person.language,
        person.phone,
        toggleOnSmsNotification,
        form,
        patient.date_of_birth
    ]);

    if (status === PARTICIPANT_STATUS.WITHDRAWN) {
        return <Navigate to={APP_ROUTES.PROFILE_CONSENTS} replace />;
    }

    const isLoading =
        isPersonLoading ||
        (isCaregiver && (isPatientLoading || !isPatientSuccess)) ||
        form.formState.isSubmitting ||
        isUpdatingPersonAttributes;

    if (isLoading) {
        return (
            <Box display='flex' alignItems='center' justifyContent='center' flex={1}>
                <CircularProgress />
            </Box>
        );
    }

    if (isSendOtpLoading) {
        return (
            <Container sx={{ backgroundColor: 'rgba(244, 244, 244, 1)' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (showReachedLimit) {
        return (
            <Container>
                <PhoneReachedLimit
                    onClick={() => {
                        setShowReachedLimit(false);
                    }}
                />
            </Container>
        );
    }

    if (showInvalidOtp) {
        return (
            <Container>
                <PhoneInvalid
                    onClick={() => {
                        setShowInvalidOtp(false);
                    }}
                />
            </Container>
        );
    }

    if (showValidOtp) {
        return (
            <Container>
                <PhoneVerified
                    onClick={async () => {
                        setShowVerifyPhone(false);
                        setShowValidOtp(false);

                        if (isCaregiver) {
                            await refetchPatient();
                        }

                        await refetchPerson();
                        resetForm();
                    }}
                />
            </Container>
        );
    }

    if (showVerifyPhone) {
        return (
            <Container>
                <VerifyPhone
                    sendOtp={sendOtp}
                    onSuccess={async () => {
                        if (isCaregiver) {
                            await refetchPatient();
                        }

                        await refetchPerson();
                        resetForm();
                        setShowValidOtp(true);
                    }}
                    onCancel={async () => {
                        setShowVerifyPhone(false);

                        if (isCaregiver) {
                            await refetchPatient();
                        }

                        await refetchPerson();
                        resetForm();
                    }}
                    onError={() => {
                        setShowInvalidOtp(true);
                    }}
                />
            </Container>
        );
    }

    return (
        <>
            {showModal && (
                <PhoneNumberUpdateModal
                    onClick={() => {
                        setShowModal(false);
                    }}
                />
            )}

            {(!isTablet || isCaregiver) && (
                <StyledTitle>
                    {!isTablet && <Typography variant='h2'>{t('profile:view:titles:my-profile')}</Typography>}
                    {isCaregiver && <StyledTitleTag>{t('profile:view:titles:caregiver')}</StyledTitleTag>}
                </StyledTitle>
            )}

            <StyledBaseLayout
                header={null}
                scrollBg='transparent'
                content={
                    <Box display='flex' flex={1}>
                        {personId && (!isCaregiver || patientId) && (
                            <EditUserProfileFormLogic
                                form={form}
                                person={person}
                                patient={patient}
                                isUpdatingPersonAttributes={isUpdatingPersonAttributes}
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                isCaregiver={isCaregiver}
                                showSuccessAlert={showSuccessAlert}
                                showErrorAlert={showErrorAlert}
                                setShowErrorAlert={setShowErrorAlert}
                                setShowSuccessAlert={setShowSuccessAlert}
                                iganProps={iganProps}
                                setShowVerifyPhone={setShowVerifyPhone}
                            />
                        )}
                    </Box>
                }
            />
        </>
    );
};

export const StyledBaseLayout = styled(BaseLayout)(({ theme }) => ({
    width: '100%',
    boxSizing: 'border-box',

    [theme.breakpoints.up('sm')]: {
        // Let BaseLayout grow
        '& > .BaseLayout-scrollable': {
            // backgroundColor: theme.palette.primary.white,
            borderRadius: '8px'
        },
        // Remove sticky header behavior
        '& > .BaseLayout-header': {
            position: 'initial',
            borderBottom: 'none !important'
        }
    },

    [theme.breakpoints.down('md')]: {
        // marginBottom: '68px'
    }
}));

export default UserProfile;
