import { Box, styled } from '@mui/system';

import { FilledButton, LinkButton } from 'components/Buttons';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  [theme.breakpoints.down('sm')]: {
    borderTop: `1px solid ${theme.palette.primary.gray10}`,
    backgroundColor: theme.palette.primary.white,
    flexDirection: 'row-reverse',
    justifyContent: 'stretch',
    gap: '5px',
    padding: '15px 20px',
  },
}));

export const CancelButton = styled(LinkButton)(({ theme }) => ({
  height: '50px',
  width: '100%',
}));

export const OkButton = styled(FilledButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.fuchsia75,
  height: '56px',
  width: '100%',
}));
