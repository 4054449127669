import { styled, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'images/bloom-logo.svg';

export const ParticipantConsentsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 80px)',
    padding: '40px',
    overflow: 'hidden',
    boxShadow: 'box-shadow: 0px 0px 30px rgba(0, 84, 135, 0.05)',
    backgroundColor: theme.palette.primary.gray20,
    [theme.breakpoints.down('sm')]: {
        height: '100vh',
        padding: '0px'
    }
}));

export const Content = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: '700px',
    height: 'auto',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    padding: '60px 40px 40px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px'
}));

export const Header = styled('div')({
    width: '100%',
    maxWidth: '400px',
    margin: 'auto'
});

export const BloomLogo = styled(Logo)({
    width: '102px',
    height: '70px'
});

export const Row = styled('div')(({ marginBottom }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: marginBottom || '20px'
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray100,
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px'
    }
}));

export const HelperText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray75,
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
    }
}));
