import { Box, styled } from '@mui/material';
import { ButtonWithArrow } from 'configUI/components/Buttons';
import { Chips } from 'pages/NewActivities/Chips';
import { useTranslation } from 'react-i18next';
import { ACTIVITY_STATUS } from './constants';

export function ActivityFooter({ status, onClick, ...rest }) {
    const { t } = useTranslation();

    return (
        <Footer>
            <Chips status={status} {...rest} />

            {(status === ACTIVITY_STATUS.ACTIVE || status === ACTIVITY_STATUS.IN_PROGRESS) && (
                <Button onClick={onClick}>{t('activities:index:buttons:start')}</Button>
            )}
        </Footer>
    );
}

const Footer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: '25px',
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    }
}));

const Button = styled(ButtonWithArrow)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        minHeight: '56px',
        flex: 1,
        width: '100%'
    }
}));
