import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ReactComponent as Notification } from 'images/notification.svg';
import { ReactComponent as Archived } from 'images/profile/archive.svg';
import { ReactComponent as Signed } from 'images/profile/check-circle-solid-green.svg';
import { ReactComponent as Rejected } from 'images/profile/times-circle.svg';
import { useTranslation } from 'react-i18next';
import { TagRequiredSigned, TagRequiredUnsigned } from './styles';

const today = new Date();
const TZ_DIFFERENCE = today.getTimezoneOffset() * 60 * 1000;

const IconDate = ({ icon, date, ...rest }) => (
    <Stack direction='row' alignItems='center' spacing='7px' {...rest}>
        {icon}
        <Typography variant='pl5' color='primary.gray50'>
            {format(new Date(new Date(date).getTime() - TZ_DIFFERENCE), 'MM/dd/yyy')}
        </Typography>
    </Stack>
);

export const Tags = ({ isSigned, isMandatory, isArchived, isRejected, participant_consent_date }) => {
    const { t } = useTranslation();
    const icon = isSigned ? <Signed /> : isRejected ? <Rejected /> : isArchived ? <Archived /> : null;
    const tagList = [];

    if (isMandatory) {
        tagList.push(
            isSigned ? (
                <TagRequiredSigned key={1}> {t('components:consent-item:required')}</TagRequiredSigned>
            ) : (
                <TagRequiredUnsigned key={2}>
                    <Notification />
                    {t('components:consent-item:required')}
                </TagRequiredUnsigned>
            )
        );
    }

    // if (isArchived) {
    //     tagList.push(<TagArchived key={3}>ARCHIVED</TagArchived>);
    // }

    if (icon && participant_consent_date) {
        tagList.push(<IconDate key={4} icon={icon} date={participant_consent_date} />);
    }

    if (!!tagList.length) {
        return (
            <Stack direction='row' alignItems='center' spacing='10px'>
                {tagList}
            </Stack>
        );
    }

    return null;
};
