import { AccountCircle } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';
import { DollarIcon } from 'images/activity';
import { useRedirections } from 'navigation';
import { useActiveActivity } from 'pages/Main';
import { ActivityFooter } from 'pages/NewActivities/ActivityFooter';
import { WavePulse } from 'pages/NewActivities/assets';
import { ACTIVITY_STATUS } from 'pages/NewActivities/constants';
import { useTranslation } from 'react-i18next';
import { isAdhocTask } from './utils';

export function Activity(activity) {
    const { t } = useTranslation();

    const { title, point, status } = activity;
    const { setActiveActivity } = useActiveActivity();
    const {
        redirectToShareMedicalRecordsInstructions,
        redirectToLanguageQuestionnaire,
        redirectToPhoneQuestionnaire,
        redirectToIganQuestionnaire
    } = useRedirections();

    const isShareMedicalRecords = activity.title === 'Share Medical Records';
    const isLanguageAdhoc = activity.title === 'Set up Preferred Language';
    const isPhoneAdhoc = activity.title === 'Set up SMS text notifications';
    const isIganAdhoc = activity.title === 'Complete Patient IgAN Information';

    function handleOnClick() {
        localStorage.setItem('activeActivity', JSON.stringify(activity));
        setActiveActivity(activity);

        if (isShareMedicalRecords) {
            redirectToShareMedicalRecordsInstructions();
        }

        if (isPhoneAdhoc) {
            redirectToPhoneQuestionnaire();
        }

        if (isIganAdhoc) {
            redirectToIganQuestionnaire();
        }

        if (isLanguageAdhoc) {
            redirectToLanguageQuestionnaire();
        }
    }

    return (
        <Container>
            <Header>
                <Icon sx={{ backgroundColor: isAdhocTask(activity) ? '#83DFD9' : '#E2F0F5' }}>
                    {isAdhocTask(activity) ? (
                        <AccountCircle style={{ fill: 'white', height: '30px', width: '50px' }} />
                    ) : (
                        <WavePulse />
                    )}
                </Icon>

                <Info>
                    <Title>{t('activities:index:tasks')?.[title]?.title}</Title>
                    <Description>{t('activities:index:tasks')?.[title]?.description}</Description>
                </Info>

                {point && <Points status={status}>{point}</Points>}
            </Header>

            <ActivityFooter {...activity} isAdhocTask={isAdhocTask(activity)} onClick={handleOnClick} />
        </Container>
    );
}

export function Points({ status, children }) {
    return (
        <Box display='flex' alignItems='center' justifyContent='center' gap='8px' marginLeft='auto' marginBottom='auto'>
            <DollarIcon />

            {status === ACTIVITY_STATUS.EXPIRED && (
                <Typography variant='pl1' color='#999999' fontWeight={700}>
                    --
                </Typography>
            )}

            {(status === ACTIVITY_STATUS.ACTIVE ||
                status === ACTIVITY_STATUS.COMPLETED ||
                status === ACTIVITY_STATUS.IN_PROGRESS) && (
                <Typography variant='pl1' color='primary.main' fontWeight={700}>
                    {children}
                </Typography>
            )}

            {status === ACTIVITY_STATUS.UPCOMING && (
                <Typography variant='pl1' color='#EC6607' fontWeight={700}>
                    {children}
                </Typography>
            )}
        </Box>
    );
}

export const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)'
}));

export const Icon = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    width: '50px',
    minWidth: '50px',
    borderRadius: '50%'
}));

export const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '25px'
}));

export const Info = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
}));

export const Title = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.primary.gray100
}));

export const Description = styled(Typography)(({ theme }) => ({
    ...theme.typography.pl3,
    color: theme.palette.primary.gray75,
    maxWidth: '480px'
}));

export const Footer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: '25px'
}));
