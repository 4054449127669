/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import { useCheckUserStatus, useGetPatient, useGetPerson, useLogout } from 'authentication';
import { LinkButton } from 'components/Buttons';
import { PPRLogo } from 'components/PPRLogo';
import { EmptyConsents } from 'components/Profile';
import { useLoading } from 'components/loading';
import { useGetAllConsents, useNavigateToConsentDetails } from 'hooks/api';
import { APP_ROUTES, useRedirections } from 'navigation';
import { ConsentItem } from 'pages/onboarding/Consents/components';
import { PARTICIPANT_STATUS } from 'pages/onboarding/constants';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Content, Header, HelperText, PageTitle, ParticipantConsentsContainer, Row } from './styles';
import { use } from 'i18next';

export default function ParticipantConsents() {
    const { t } = useTranslation();
    document.title = t('onboarding:index:titles:/onboarding/consents');

    const { logout } = useLogout();
    const { id: personId, role, roleId, status, isLoading: isPersonLoading, isCaregiver } = useGetPerson();
    const { id: patientId, roleId: patientRoleId, isLoading: isPatientLoading } = useGetPatient();
    const [selectedConsent, setSelectedConsent] = useState(null);
    const { redirectToError } = useRedirections();
    const { verifyUserStatus } = useCheckUserStatus();

    const {
        consents,
        isLoading: isConsentsLoading,
        error: isConsentsError,
        isSuccess: isConsentsSuccess
    } = useGetAllConsents({
        // We have to send the id of the patient to sign the document
        personId: role === 'caregiver' ? patientId : personId,
        // If is caregiver, we have to send the study role id of the caregiver
        studyRole: roleId
    });

    const {
        isLoading: isNavigationLoading,
        isRedirecting,
        isError: isNavigateConsentDetailsError
    } = useNavigateToConsentDetails(
        {
            personId: role === 'caregiver' ? patientId : personId,
            studyRole: role === 'caregiver' ? patientRoleId : roleId
            // personId,
            // studyRole: roleId
        },
        selectedConsent
    );

    const { setIsLoading } = useLoading();

    const isLoading =
        isPersonLoading ||
        (isCaregiver && isPatientLoading) ||
        isConsentsLoading ||
        isNavigationLoading ||
        isRedirecting ||
        !isConsentsSuccess;

    const onSignClick = async (consentId) => {

        setIsLoading(true);
        const isUserStatusValid = await verifyUserStatus(PARTICIPANT_STATUS.REGISTERED);
        if (!isUserStatusValid) {
            setIsLoading(false);
            return;
        };

        const consent = consents.find((consent) => consent.id === consentId);
        if (consent) {
            setSelectedConsent(consent);
        }
    };

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (isConsentsError || isNavigateConsentDetailsError) {
            redirectToError();
        }
    }, [isConsentsError, isNavigateConsentDetailsError]);

    if (isLoading) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                height='100vh'
                flex={1}
                width='100%'
                bgcolor='primary.gray20'
            >
                <CircularProgress />
            </Box>
        );
    }

    if (status !== PARTICIPANT_STATUS.REGISTERED && status !== PARTICIPANT_STATUS.ENROLLED) {
        const isRecosenting = localStorage.getItem('isReconsenting');
        if (!Boolean(isRecosenting)) {
            return <Navigate to={APP_ROUTES.ONBOARDING} replace />;
        }
    }

    return (
        <ParticipantConsentsContainer>
            <Content>
                {consents.length > 0 && (
                    <Fragment>
                        <Header>
                            <Row marginBottom='20px'>
                                <PPRLogo />
                            </Row>
                            <Row marginBottom='10px'>
                                <PageTitle variant='h2'>{t('onboarding:consents:content:title')}</PageTitle>
                            </Row>
                            <Row marginBottom='0px'>
                                <HelperText variant='pl3'>{t('onboarding:consents:content:helper')}</HelperText>
                            </Row>
                        </Header>

                        {consents
                            ?.filter((consent) => consent?.status === 'active')
                            .map((consent) => (
                                <ConsentItem
                                    key={consent.id}
                                    consent={consent}
                                    onSignClick={() => onSignClick(consent.id)}
                                />
                            ))}
                    </Fragment>
                )}

                {(!consents?.length || isConsentsError) && <EmptyConsents />}
            </Content>

            {!isLoading && consents?.length === 0 && (
                <Row>
                    <LinkButton onClick={logout}>{t('onboarding:consents:buttons:back')}</LinkButton>
                </Row>
            )}
        </ParticipantConsentsContainer>
    );
}
