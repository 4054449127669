import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    const contextValue = useMemo(
        () => ({
            isLoading,
            setIsLoading
        }),
        [isLoading]
    );

    return <LoadingContext.Provider value={contextValue}>{children}</LoadingContext.Provider>;
};

LoadingProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const LoadingContext = createContext(null);
