import '@fontsource/inter';
import '@fontsource/montserrat';
import 'index.css';
import 'internationalization';

import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Loader, Loading, LoadingProvider } from 'components/loading';
import { Provider as ReduxProvider } from 'ihp-bloom-redux/app/redux';
import { store as bloomReduxStore } from 'ihp-bloom-redux/app/store';
import { StrictMode } from 'react';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import { Router } from 'routes';
import { theme } from 'theme';

if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false
        }
    }
});

const generatedStorage = bloomReduxStore(storage);
export const persistor = persistStore(generatedStorage);

export default function App() {
    return (
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <ReduxProvider store={generatedStorage}>
                    <PersistGate loading={Loader} persistor={persistor}>
                        <ThemeProvider theme={theme}>
                            <LoadingProvider>
                                <Loading />
                                {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools />}
                                <Router />
                            </LoadingProvider>
                        </ThemeProvider>
                    </PersistGate>
                </ReduxProvider>
            </QueryClientProvider>
        </StrictMode>
    );
}
