import { Box, Divider, styled } from '@mui/material';
import { ProfileCards } from './ProfileCards';
import { ProfileHeader } from './ProfileHeader';
import { ProfileFooter } from '.';

export function ProfileNav(props) {
    return (
        <Container>
            <ProfileHeader {...props} />
            <ProfileCards />
            <Divider sx={{ width: '100%', bgcolor: '#FBFBFB', opacity: 0.5 }} />
            <ProfileFooter />
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '1.875rem',
    padding: '3.125rem',
    backgroundColor: theme.palette.primary.gray2,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    zIndex: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100vh'
}));
