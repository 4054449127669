import { Box, styled } from '@mui/material';
import { useGetPerson } from 'authentication';
import { PARTICIPANT_STATUS } from 'pages/onboarding';
import { NavIcon } from './NavIcon';

export function NavMobile({ items, onClick, cancelModal }) {
    const { status } = useGetPerson();

    return (
        <>
            {cancelModal}
            <Container>
                {items.map((props) =>
                    status === PARTICIPANT_STATUS.WITHDRAWN && props.hideWhenWithdrawn ? null : (
                        <NavIcon {...props} onClick={onClick(props)} />
                    )
                )}
            </Container>
        </>
    );
}

const Container = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2.5rem',
    height: '68px',
    order: 2,
    borderTop: `1px solid ${theme.palette.primary.gray10}`,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    zIndex: 2,
    backgroundColor: 'white'
}));
