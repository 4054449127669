import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Wrapper = styled(Box)(({ selected, theme }) => ({
    border: '2px solid',
    borderRadius: '6px',
    borderColor: selected ? theme.palette.primary.main : theme.palette.primary.gray3,
    color: selected ? theme.palette.primary.main : theme.palette.primary.gray100,
    display: 'flex',
    flex: '1',
    // justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '@media only screen and (min-width: 768px)': {
        fontSize: '16px'
    },
    ':hover': {
        cursor: 'pointer'
    }
}));

export const OptionsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 2em 0;
    flex: 1;
`;
