import { Box, CircularProgress } from '@mui/material';
import { useGetPatient, useGetPerson, withAuth } from 'authentication';
import { AuthenticationNotifier } from 'authentication/AuthenticationNotifier';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { APP_ROUTES } from 'navigation';
import { ActiveActivityProvider, Nav } from 'pages/Main/components';
// import { ReconsentGuard } from 'pages/Main/components/ReconsentGuard';
import { Container, Content } from 'pages/Main/styles';
import { ActivitiesProvider } from 'pages/NewActivities';
import { useParticipantsTasksData } from 'pages/Study/useParticipantsTasksData';
import { PARTICIPANT_STATUS } from 'pages/onboarding';
import { useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function Main() {
    const { id: personId, status, isCaregiver, isLoading: isPersonLoading } = useGetPerson();
    const { id: patientId, isLoading: isPatientLoading } = useGetPatient();
    const finalId = isCaregiver ? patientId : personId;

    useParticipantsTasksData();

    useGetParticipantGoalStatsQuery(personId, {
        skip: !finalId
    });

    const isLoading = !personId || isPersonLoading || (isCaregiver && isPatientLoading);

    return (
        <AuthenticationNotifier>
            {isLoading ? (
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    flex={1}
                    height='100dvh'
                    width='100dvw'
                    position='fixed'
                    top={0}
                    left={0}
                    zIndex={99999}
                    bgcolor='primary.gray20'
                >
                    <CircularProgress />
                </Box>
            ) : status !== PARTICIPANT_STATUS.ENROLLED && status !== PARTICIPANT_STATUS.WITHDRAWN ? (
                <Navigate to={APP_ROUTES.ONBOARDING} replace />
            ) : null}

            <Container>
                <Nav />

                <Content>
                    <ActivitiesProvider>
                        <ActiveActivityProvider>
                            <Outlet />
                        </ActiveActivityProvider>
                    </ActivitiesProvider>
                </Content>
            </Container>
        </AuthenticationNotifier>
    );
}

export default withAuth(() => (
    // <ReconsentGuard>
    <Main />
    // </ReconsentGuard>
));

export { useActiveActivity } from 'pages/Main/components';
