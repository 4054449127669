import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FilledButton } from './styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    paddingY: '20px',
    borderRadius: '8px'
};

export default function NoAnswerModal({ open, handleClose }) {
    const { t } = useTranslation();
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Box padding={3}>
                        <Typography margin={2} color='primary.gray75' id='modal-modal-title' variant='h4'>
                            {t('activities:eq5d5l:modal:title')}
                        </Typography>
                        <FilledButton fullWidth onClick={handleClose} variant='contained'>
                            {t('activities:eq5d5l:buttons:close')}
                        </FilledButton>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
