import { CircularProgress, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

export function Loader() {
    const { t } = useTranslation();

    return (
        <Container>
            <CircularProgress />

            <Typography variant='h2' color='primary.gray100' mt='30px' mb='12px' textAlign='center'>
                {t('activities:index:loading:title')}
            </Typography>

            <Typography variant='pl3' color='primary.gray75'>
                {t('activities:index:loading:description')}
            </Typography>
        </Container>
    );
}

export const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flex: 1
});
