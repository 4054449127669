import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)(({ theme }) => ({
    position: 'fixed',
    display: 'flex',
    height: '100dvh',
    width: '100dvw',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
    }
}));

export const Content = styled(Box)(({ theme }) => ({
    position: 'fixed',
    display: 'flex',
    flex: 1,
    backgroundColor: theme.palette.primary.gray20,
    overflow: 'hidden',
    width: 'calc(100dvw - 90px)',
    left: '90px',
    height: '100dvh',
    top: 0,
    [theme.breakpoints.down('md')]: {
        left: 0,
        bottom: '68px',
        height: 'auto',
        flexDirection: 'column',
        width: '100dvw'
    }
}));

export const CancelContainer = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    height: '100dvh',
    justifyContent: 'center',
    width: '100dvw',
    backgroundColor: theme.palette.primary.gray20
}));
