import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const FilledButton = styled(Button)(({ theme, background, fontFamily, height, width }) => {
    const optionalWidth = width ? { width } : {};

    return {
        background: background ?? theme.palette.primary.main,
        color: theme.palette.primary.white,
        borderRadius: '8px',
        height: height ?? '56px',
        ...optionalWidth,
        fontFamily: fontFamily ?? 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '20px',
        textTransform: 'none',
        cursor: 'pointer',
        '&:disabled': {
            opacity: 0.6,
            cursor: 'not-allowed'
        },
        '&: hover': {
            background: background ?? theme.palette.primary.main
            // color: theme.palette.primary.main,
        },
        '&: disabled': {
            background: background ?? theme.palette.primary.fuchsia50,
            color: theme.palette.primary.white
        }
    };
});
