import { Box, Stack, styled, Typography } from '@mui/material';
import { ButtonWithArrow, GhostButton } from 'configUI/components/Buttons';
import { useRedirections } from 'navigation';
import { MEDICATIONS_STEPS, useMedications } from 'pages/Medications/MedicationsProvider';
import { useTranslation } from 'react-i18next';

export default function MedicationsInstructions() {
    const { t } = useTranslation();

    const { setStep } = useMedications();
    const { redirectToActivities } = useRedirections();

    return (
        <Container>
            <Wrapper>
                <Typography variant='pb4' color='primary.main' mb='7px'>
                    {t('activities:medications:instructions:header')}
                </Typography>
                <Typography variant='h2' color='#444444' mb='15px'>
                    {t('activities:medications:instructions:title')}
                </Typography>

                <pre style={{ textWrap: 'wrap', marginBottom: '50px', whiteSpace: 'pre-wrap' }}>
                    <Typography variant='pl3' color='primary.gray75'>
                        {t('activities:medications:instructions:description').map((item) => item)}
                    </Typography>
                </pre>

                <Stack direction='row' justifyContent='flex-end' spacing='5px'>
                    <GhostButton onClick={() => redirectToActivities()}>
                        {t('activities:medications:common:cancel')}
                    </GhostButton>

                    <ButtonWithArrow sx={{ width: '134px' }} onClick={() => setStep(MEDICATIONS_STEPS.LIST)}>
                        {t('activities:medications:common:start')}
                    </ButtonWithArrow>
                </Stack>
            </Wrapper>
        </Container>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
}));

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '700px',
    padding: '60px 50px 40px 50px',
    borderRadius: '8px',
    borderBottom: `1px solid ${theme.palette.primary.gray10}`,
    backgroundColor: 'white',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    [theme.breakpoints.down('sm')]: {
        margin: '10px',
        padding: '20px'
    }
}));
