import { useGetPatient, useGetPerson } from 'authentication';
import { getBaseURL } from 'config/API';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setParticipantTasks } from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const ActivitiesContext = createContext(null);
const getUrl = (person, page) => getBaseURL() + `/v3/app/people/${person}/participant-tasks?page=${page}`;
const TOTAL_PAGES = 5;

export function ActivitiesProvider({ children }) {
    const { id: personId, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();
    const [activities, setActivities] = useState({ data: [], isLoading: false });
    const { token } = useSelector((state) => state.authentication);
    const dispatch = useDispatch();

    const finalId = isCaregiver ? patientId : personId;

    const fetchActivities = useCallback(
        async (personId) => {
            try {
                setActivities((previous) => ({ ...previous, isLoading: true }));
                const promises = [];

                for (let i = 1; i <= TOTAL_PAGES; i++) {
                    const url = getUrl(personId, i);
                    const promise = fetch(url, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    promises.push(promise);
                }

                const responses = await Promise.all(promises);
                const data = await responses.reduce(async (promise, response) => {
                    const accumulator = await promise;
                    const { data } = await response.json();
                    return accumulator.concat(data);
                }, Promise.resolve([]));

                if (data.length > 0) {
                    dispatch(setParticipantTasks(data));
                }

                setActivities({ isLoading: false, data });
            } catch (error) {
                console.log('ERROR', error);
            }
        },
        [token]
    );

    useEffect(() => {
        if (finalId && activities.data.length === 0 && !activities.isLoading) {
            fetchActivities(finalId);
        }
    }, [finalId, activities, fetchActivities]);

    const contextValue = useMemo(
        () => ({
            activities,
            fetchActivities
        }),
        [activities, fetchActivities]
    );

    return <ActivitiesContext.Provider value={contextValue}>{children}</ActivitiesContext.Provider>;
}

export function useActivities() {
    const data = useContext(ActivitiesContext);

    if (data === null) {
        throw new Error('useActivities should be inside ActivitiesProvider');
    }

    return data;
}
