import { useGetCurrentUser } from 'authentication';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import {
    useGetConsentsListV1Query,
    useFetchConsentDetailsMutation,
    useSubmitConsentMutation,
    useGetConsentDocumentMutation
} from 'ihp-bloom-redux/features/consent/consentApi';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useNavigateToConsentDetails = (baseURL) => {
    const { personId } = useGetCurrentUser();
    const [fetchConsentDetails] = useFetchConsentDetailsMutation();
    const navigate = useNavigate();
    const [isRedirecting, setRedirecting] = useState(false);
    const navigateToConsentDetails = (consent_id, consent_version_id, isDocusign) => {
        setRedirecting(true);
        if (isDocusign) {
            fetchConsentDetails({
                person_id: personId,
                consent_id,
                consent_version_id
            }).then(({ data }) => {
                const redirectURl = data?.envelope?.redirect_url[0].url;
                if (redirectURl) window.location.replace(redirectURl);
            });
        } else {
            navigate(`${baseURL}/${consent_id}/${consent_version_id}`);
        }
    };
    return [navigateToConsentDetails, isRedirecting];
};

export const useRejectConsent = () => {
    const [signConsent, { isLoading: isRejecting }] = useSubmitConsentMutation();
    const rejectConsent = (consent_group_id) =>
        signConsent({
            status: 'rejected',
            consent_group_id,
            study_version_id: 1,
            consenting_party_id: 1,
            consent_version_id: 1,
            signed_on_behalf_id: 1
        })
            .then(console.log)
            .catch(console.error);
    return [rejectConsent, isRejecting];
};

export const useOpenSignedConsent = ({ personId }) => {
    const [isOpening, setOpening] = useState(false);
    const [documentNotFound, setDocumentNotFound] = useState(false);
    const [getConsentDocument] = useGetConsentDocumentMutation();
    const openSignedConsent = (consent_version_id) => {
        setOpening(true);
        getConsentDocument({ person_id: personId, consent_version_id })
            .then(({ error, data }) => {
                if (error && error.status === 404) {
                    setDocumentNotFound(true);
                    return;
                }
                if (data) {
                    setDocumentNotFound(false);
                    window.open(window.URL.createObjectURL(data));
                }
                setOpening(false);
            })
            .finally(() => setOpening(false));
    };
    return [openSignedConsent, isOpening, documentNotFound];
};

export const useGetConsents = (person) => {
    const { consents, isLoading, error } = useGetConsentsListV1Query(
        {
            person_id: person?.id,
            study_role_id: person?.studyRole?.id || 1,
            study_version_id: 1
        },
        {
            skip: !person,
            selectFromResult: ({ data, ...rest }) => {
                // for some weird reasons, the data structure returned keeps changing
                // from data.consents to data.data.consents.

                const consents = data?.consents || data?.data?.consents;
                return {
                    consents: consents?.length ? consents : [],
                    ...rest
                };
            }
        }
    );
    return { consents, isConsentsLoading: isLoading, error };
};
