import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as PhoneIcon } from 'images/shared/phone.svg';

export const ContactWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingBottom: '50px',
    backgroundColor: theme.palette.primary.gray20,
    margin: '5em auto 5em auto',
    maxWidth: '1080px',
    '@media (max-width: 768px)': {
        textAlign: 'center',
        padding: '0px 20px',
        flexDirection: 'column',
    },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '30px  ',
    '@media (max-width: 768px)': {
        padding: '0px 20px',
        flexDirection: 'column',
    },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.lightBlue,
}));

export const ItemBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    gap: 30,
    padding: '30px',
    backgroundColor: theme.palette.primary.white,
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.primary.gray15,
}));

export const PhoneBox = styled(Box)(({ theme }) => ({
    flex: 1,
}));
export const EmailBox = styled(Box)(({ theme }) => ({
    flex: 1,
}));

export const StyledPhoneIcon = styled(PhoneIcon)(({ theme }) => ({
    transform: "scaleX(-1)"
}));