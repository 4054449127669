import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { cards } from './mock';
import { Card, CardContainer } from './styles';

export const Description = () => {
    const { t } = useTranslation();
    return (
        <Box>
            <Box display='flex' flexDirection='column' alignItems='center'>
                <Typography variant='h1' fontWeight='600' margin='15px 0px'>
                    {t('welcome:howItWorks:header')}
                </Typography>
                <Typography variant='pn1' display='block' color='primary.gray75' textAlign='center' maxWidth='420px'>
                    {t('welcome:howItWorks:subheader')}
                </Typography>
            </Box>
            <CardContainer>
                {cards.map((card) => (
                    <Card
                        key={card.id}
                        icon={card.icon}
                        title={t('welcome:howItWorks:title')[card.id]}
                        description={t('welcome:howItWorks:description')[card.id]}
                    />
                ))}
            </CardContainer>
        </Box>
    );
};
