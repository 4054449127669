import { Box, Stack, styled } from '@mui/material';
import { ButtonWithArrow, GhostButton } from 'configUI/components/Buttons';
import { AddMedication } from 'pages/Medications/AddMedication';
import { DeleteMedicationModal } from 'pages/Medications/DeleteMedicationModal';
import { Medication } from 'pages/Medications/Medication';
import { MEDICATIONS_STEPS, useMedications } from 'pages/Medications/MedicationsProvider';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateMedicationModal } from './UpdateMedicationModal';

export function Medications() {
    const { t } = useTranslation();

    const { medications, removeMedication, setStep } = useMedications();
    const [showDeleteMedicationModal, setShowDeleteMedicationModal] = useState(false);
    const [showUpdateMedicationModal, setShowUpdateMedicationModal] = useState(false);
    const [selectedMedication, setSelectedMedication] = useState();

    function openShowUpdateMedicationModal(id) {
        setSelectedMedication(id);
        setShowUpdateMedicationModal(true);
    }

    function openShowDeleteMedicationModal(id) {
        setSelectedMedication(id);
        setShowDeleteMedicationModal(true);
    }

    function closeShowDeleteMedicationModal() {
        setShowDeleteMedicationModal(false);
    }

    function onRemoveMedication() {
        removeMedication(selectedMedication.medication);
        closeShowDeleteMedicationModal();
        setShowUpdateMedicationModal(false);
    }

    return (
        <Fragment>
            <Wrapper>
                <Box display='flex' gap='12px' flexDirection='column' padding='20px'>
                    {medications
                        ?.filter((med) => (med.end_date && med.isPreviousMedication ? false : true))
                        .map((medication) => (
                            <Medication
                                {...medication}
                                onUpdate={() => openShowUpdateMedicationModal(medication)}
                                onDelete={() => openShowDeleteMedicationModal(medication)}
                            />
                        ))}

                    <AddMedication />

                    <Stack direction='row' justifyContent='flex-end' spacing='5px' width='100%' mt='100px'>
                        <GhostButton onClick={() => setStep(MEDICATIONS_STEPS.INSTRUCTIONS)}>
                            {t('activities:medications:common:cancel')}
                        </GhostButton>

                        <ButtonWithArrow
                            sx={{ width: '134px' }}
                            disabled={medications.length === 0}
                            onClick={() => setStep(MEDICATIONS_STEPS.COMPLETED)}
                        >
                            {t('activities:medications:medications:next')}
                        </ButtonWithArrow>
                    </Stack>
                </Box>
            </Wrapper>

            {showUpdateMedicationModal && (
                <UpdateMedicationModal
                    medication={selectedMedication}
                    open={showUpdateMedicationModal}
                    onClose={() => setShowUpdateMedicationModal(false)}
                    openShowDeleteMedicationModal={openShowDeleteMedicationModal}
                    onRemove={onRemoveMedication}
                />
            )}

            <DeleteMedicationModal
                medication={selectedMedication}
                open={showDeleteMedicationModal}
                onClose={closeShowDeleteMedicationModal}
                onRemove={onRemoveMedication}
            />
        </Fragment>
    );
}

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '700px',
    flex: 1,
    gap: '12px',
    alignSelf: 'center'
}));
