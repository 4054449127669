import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Tags } from './Tags';
import { Container } from './styles';

export const Main = ({
    title,
    description,
    participant_consent_date,
    isSigned,
    isMandatory,
    isArchived,
    isRejected,
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <Container flex={1} {...rest}>
            <Stack spacing='10px'>
                <Stack spacing='7px'>
                    <Typography variant='pb3' color='primary.gray100'>
                        {t('components:consent-item:title')}
                    </Typography>

                    <Typography variant='pl5' color='primary.gray75'>
                        {t('components:consent-item:description')}
                    </Typography>
                </Stack>

                <Tags
                    isSigned={isSigned}
                    isMandatory={isMandatory}
                    isArchived={isArchived}
                    isRejected={isRejected}
                    participant_consent_date={participant_consent_date}
                />
            </Stack>
        </Container>
    );
};
