import { Accordion, AccordionSummary, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FilledButton } from 'components/Buttons';

export const ResourceLibraryWrapper = styled(Box)(({ theme }) => ({
    paddingBottom: '50px',
    backgroundColor: theme.palette.primary.gray20,
    margin: '5em auto 5em auto',
    maxWidth: '1080px',
    '@media (max-width: 768px)': {
        textAlign: 'center',
        padding: '0px 20px'
    }
}));

export const ResourcesList = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '20px',
    gridRowGap: '20px',
    marginTop: '10px',
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr'
    }
}));

export const Card = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 50px',
    gap: '30px',
    flex: '1 0 0',
    borderRadius: '8px',
    border: '1px solid #DFE2E3',
    background: 'white',
    alignItems: 'baseline'
}));

export const ButtonLink = styled(FilledButton)(({ disabled, theme }) => ({
    background: disabled ? theme.palette.primary.gray5 : theme.palette.primary.main,
    color: theme.palette.primary.white,
    textDecoration: 'none',
    alignItems: 'center',
    padding: '15px 25px 15px 30px',
    gap: '7px',
    height: 'auto',
    paddingTop: '15px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    marginTop: 'auto',
    textAlign: 'left'
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    background: 'transparent',
    color: theme.palette.primary.gray100,
    width: '100%',
    borderBottom: '1px solid',
    borderColor: theme.palette.primary.gray15,
    boxShadow: 'none',
    textAlign: 'left',
    '&.MuiAccordion-root:before': {
        content: 'none'
    }
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme, expanded }) => ({
    transition: 'none',
    borderBottom: expanded === 'true' ? 'solid 1px' : 'none',
    borderColor: theme.palette.primary.gray15,
    paddingTop: '20px',
    paddingBottom: '30px',
    '& .Mui-expanded': {
        margin: '0px !important'
    },
    '& .MuiAccordionSummary-content': {
        transition: 'none'
    }
}));
