import { ReactComponent as BloomLogo } from 'images/header/bloom-textual-logo.svg';
import { ReactComponent as LeafIcon } from 'images/shared/leaf-2.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { HeaderContainer, Logo, Reward } from './styles';

export const Header = ({ points, ...rest }) => (
  <HeaderContainer {...rest}>
    <Logo>
      <BloomLogo />
    </Logo>
    <Reward>
      {points}
      <LeafIcon width='24' height='24' />
    </Reward>
  </HeaderContainer>
)

Header.propTypes = {
  points: PropTypes.string.isRequired
};
