import { Modal } from '@mui/material';
import { FilledButton } from 'components/Buttons';
import { Trans, useTranslation } from 'react-i18next';
import { AcmeLogo, ButtonContainer, ContentWrapper, StyledLink, Text, Title } from './styles';

export const ConfirmationModal = ({ open, onClose, handleConfirmation }) => {
    const { t } = useTranslation();
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <ContentWrapper>
                <AcmeLogo />
                <Title>{t('activities:emr:confirmation-modal:title')}</Title>
                <Trans><Text>{t('activities:emr:confirmation-modal:subtitle')}</Text></Trans>
                <ButtonContainer>
                    <FilledButton fullWidth onClick={onClose}>
                        {t('activities:emr:confirmation-modal:buttons:share')}
                    </FilledButton>
                    <StyledLink onClick={handleConfirmation}>{t('activities:emr:confirmation-modal:buttons:done')}</StyledLink>
                </ButtonContainer>
            </ContentWrapper>
        </Modal>
    );
};
