import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useGetPatient, useGetPerson } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetParticipantTaskQuery } from 'ihp-bloom-redux/features/tasks/participantTaskApiSlice';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import {
    selectSelectedParticipanttaskAttributes,
    selectSelectedParticipanttaskId,
    setParticipantTask
} from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { useRedirections } from 'navigation';
import CelebrationScreen from 'pages/Activities/CelebrationScreen';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'utils/isMobile';
import FinalPageMobile from './index.mobile';
import { Container, InfoMessage, StyledCheckIcon, buttonTextStyle } from './style';
import { selectFromPatientTaskResult } from './utils';

function FinalPage() {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { redirectToActivities } = useRedirections();

    const { id: personId, isCaregiver, isLoading: isPersonLoading } = useGetPerson();
    const { id: patientId, isLoading: isPatientLoading } = useGetPatient();
    const finalId = isCaregiver ? patientId : personId;

    const selectedParticipantTaskAttributes = useSelector(selectSelectedParticipanttaskAttributes());
    const selectedTaskId = useSelector(selectSelectedParticipanttaskId());

    const {
        participantTaskDetails,
        isLoading: isLoadingParticipantTaskDetails,
        isSuccess: isParticipantTaskDetailsSuccess
    } = useGetParticipantTaskQuery(
        {
            participantId: finalId,
            participantTaskId: selectedTaskId
        },
        {
            skip: !finalId || !selectedTaskId,
            selectFromResult: selectFromPatientTaskResult
        }
    );

    const { refetch: refetchPayments } = useGetParticipantGoalStatsQuery(finalId, {
        skip: !finalId
    });

    const celebrationScreen = {
        compensationValue: participantTaskDetails?.task?.celebrationScreen?.value?.points || 10,
        thankyouMsg: participantTaskDetails?.task?.celebrationScreen?.value?.title,
        ...selectedParticipantTaskAttributes,
        participant_rewards: participantTaskDetails?.participant_rewards
    };

    if (
        !isLoadingParticipantTaskDetails &&
        isParticipantTaskDetailsSuccess &&
        !participantTaskDetails?.task?.celebrationScreen?.value?.display
    ) {
        console.log('REDIRECT TO ACTIVITIES FROM FinalPage');
        redirectToActivities();
    }

    if (
        isLoadingParticipantTaskDetails ||
        !isParticipantTaskDetailsSuccess ||
        isPersonLoading ||
        (isCaregiver && isPatientLoading)
    ) {
        return (
            <Box
                sx={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Container>
                    <CircularProgress />
                    <InfoMessage>{t('activities:emr:providers:loading-reward')}</InfoMessage>
                </Container>
            </Box>
        );
    }

    function handleOnSubmit() {
        dispatch(setParticipantTask(null));
        redirectToActivities();
        refetchPayments();
    }

    if (isMobile()) {
        return <FinalPageMobile celebrationScreen={celebrationScreen} onDone={handleOnSubmit} />;
    }

    return (
        <Box
            sx={{
                bgcolor: theme.palette.primary.gray20,
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                flex: '1'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
            >
                <CelebrationScreen attributes={{ ...celebrationScreen }} maxHeight={false} />
                <FilledButton
                    type='submit'
                    size='large'
                    sx={{ minWidth: '303px', marginBottom: '20px', marginTop: '30px' }}
                    onClick={handleOnSubmit}
                >
                    <>
                        <StyledCheckIcon />
                        <span style={buttonTextStyle}>{t('activities:emr:buttons:done')}</span>
                    </>
                </FilledButton>
            </Box>
        </Box>
    );
}

export default FinalPage;
