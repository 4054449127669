import { Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useGetPerson } from 'authentication';
import {
    OverviewTitle,
    LeftStudyContainer,
    OverviewContainer,
    OverviewMainTitleContainer,
    StudyContainer,
    TimelineContainer,
    OutlineButton
} from 'components/Study';
import OverviewImage from 'images/timeline.png';
import { useRedirections } from 'navigation';
import { StyledBaseLayout } from 'pages/Profile/UserProfile';
import { PARTICIPANT_STATUS } from 'pages/onboarding';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StudyMobile from './index.mobile';
import StudyOverviewModal from './overviewModal';
import Progress from './progress';
import Timeline from './timeline';
import { useParticipantsTasksData } from './useParticipantsTasksData';

export const allowedTaskTypes = ['data_sharing', 'survey', 'group'];
const deniedTitles = ['Health Questionnaire', 'Current Medication'];

const filterFunction = ({ task }) => {
    if (!allowedTaskTypes.includes(task.type) || deniedTitles.includes(task.title)) {
        return false;
    }

    return true;
};

const sortFunction = (a, b) => {
    return new Date(b.start_date) > new Date(a.start_date) ? -1 : 1;
};

export function Study() {
    document.title = 'Study Progress';
    const [overviewModalOpen, setOverviewModalOpen] = useState(false);
    const theme = useTheme();
    const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [taskOccurrencies] = useState({});
    const { redirectToFAQ } = useRedirections();
    const { status } = useGetPerson();
    const { t } = useTranslation();

    const { availableTasks, upcomingTasks, isLoadingResults, completedTasks, totalTasks, expiredTasks } =
        useParticipantsTasksData();

    const isWithdrawn = status === PARTICIPANT_STATUS.WITHDRAWN;

    const tasks = {
        availableTasks: isWithdrawn ? undefined : availableTasks?.filter(filterFunction).sort(sortFunction),
        upcomingTasks: isWithdrawn ? undefined : upcomingTasks?.filter(filterFunction).sort(sortFunction),
        completedTasks: completedTasks?.filter(filterFunction).sort(sortFunction),
        expiredTasks: isWithdrawn ? undefined : expiredTasks?.filter(filterFunction).sort(sortFunction)
    };

    const hasTasks =
        tasks?.completedTasks?.length > 0 ||
        tasks?.availableTasks?.length > 0 ||
        tasks?.upcomingTasks?.length > 0 ||
        tasks?.expiredTasks?.length > 0;

    if (isTablet) {
        return (
            <StudyMobile
                selectedPanel={0}
                taskOccurrencies={taskOccurrencies}
                totalTasks={totalTasks}
                tasks={tasks}
                isLoadingTasks={isLoadingResults}
                isWithdrawn={isWithdrawn}
            />
        );
    }

    return (
        <StudyContainer sx={{ backgroundColor: theme.palette.primary.gray2 }}>
            <LeftStudyContainer>
                <Progress taskOccurrencies={taskOccurrencies} totalTasks={totalTasks} />

                <Box>
                    <OverviewTitle>{t('progress:index:sections:titles:study-progress')}</OverviewTitle>

                    <OverviewContainer sx={{ backgroundColor: theme.palette.primary.white }}>
                        <img alt='overview' src={OverviewImage} height='250px' width='150px' />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <OverviewMainTitleContainer>
                                <Box marginBottom={2}>
                                    <Typography marginBottom={2} variant='h6'>
                                        {t('progress:index:study-progress:title')}
                                    </Typography>
                                    <Typography color='primary.gray75' variant='pl3'>
                                        {t('progress:index:study-progress:description')}
                                    </Typography>
                                </Box>
                                <OutlineButton variant='outlined' sx={{ width: 'fit-content' }} onClick={redirectToFAQ}>
                                    {t('progress:index:buttons:see-faq')}
                                </OutlineButton>
                            </OverviewMainTitleContainer>
                        </Box>
                    </OverviewContainer>
                </Box>
            </LeftStudyContainer>

            {(hasTasks || isLoadingResults) && (
                <TimelineContainer>
                    <Timeline tasks={tasks} isLoadingTasks={isLoadingResults} />
                </TimelineContainer>
            )}

            <StudyOverviewModal isOpen={overviewModalOpen} onClose={() => setOverviewModalOpen(false)} />
        </StudyContainer>
    );
}
