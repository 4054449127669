import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const studyApiSliceV3 = createApi({
  reducerPath: 'studyApiV3',
  baseQuery: baseQuery('v3'),
  endpoints: (build) => ({
    getStudyRoles: build.query({
      query: () => ({
        url: `/study-roles?filter[name]=participant`,
        method: 'GET',
        headers: {
          Accept: 'application/vnd.api+json',
        },
      }),
    }),
    getStudyRolesNoFilter: build.query({
      query: () => ({
        url: `/study-roles`,
        method: 'GET',
      }),
      providesTags: ['StudyRoles'],
    }),
  }),
});

export const { useGetStudyRolesQuery, useGetStudyRolesNoFilterQuery } = studyApiSliceV3;
