import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '12px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.white,
  position: 'fixed',
  boxSizing: 'border-box',
  zIndex: '2',
}));

export const Logo = styled(Box)({
  width: '51px',
  height: '35px',
});

export const Reward = styled((props) => (
  <Typography
    variant='h5'
    align='center'
    color='primary.fuchsia75'
    {...props}
  />
))({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
});
