import { Button, styled } from '@mui/material';
import { Check } from 'images/components';

export function ButtonWithCheck({ children, ...rest }) {
    return (
        <Container disableElevation {...rest}>
            <Check />
            {children}
        </Container>
    );
}

const Container = styled(Button)(({ theme }) => ({
    ...theme.typography.pb3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    height: '56px',
    padding: '0px 30px 0px 25px',
    color: 'white',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    border: '2px solid transparent',
    cursor: 'pointer',

    '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        '& path': {
            backgroundColor: 'black',
            fill: theme.palette.primary.main
        }
    },

    '&: disabled': {
        background: theme.palette.primary.mainLight,
        color: theme.palette.primary.white,
        cursor: 'not-allowed'
    },

    '& path': {
        fill: 'white'
    }
}));
