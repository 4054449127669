import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { useGetPerson, useLogout } from 'authentication';
import { StudyTabs, StudyTab } from 'components/Study';
import { APP_ROUTES } from 'navigation';
import { PARTICIPANT_STATUS } from 'pages/onboarding';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export function ProfileContent({ children }) {
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const hasTitle = pathname in contents;
    const showTitle = hasTitle && (isDesktop || contents[pathname].showTitleOnMobile) && contents[pathname].show;
    const title = t('profile:index:titles')[pathname];
    document.title = title ?? 'PERFORM Patient Registry';

    const containerRef = useRef(null);
    const { status } = useGetPerson();

    useEffect(() => {
        containerRef?.current?.scrollTo(0, 0);
    }, [pathname]);

    const [tab, setTab] = useState(
        (status === 'withdrawn' ? withdrawnTabs : tabs).findIndex((value) => value === pathname)
    );

    return (
        <Fragment>
            {!isDesktop && <Tabs tab={tab} setTab={setTab} />}

            <Container ref={containerRef}>
                {showTitle && <Typography variant='h2'>{t('profile:index:titles')[pathname]}</Typography>}
                {children}
            </Container>
        </Fragment>
    );
}

const contents = {
    [APP_ROUTES.PROFILE_PAYMENTS]: {
        show: true
    },
    [APP_ROUTES.PROFILE_CONSENTS]: {
        show: true
    },
    [APP_ROUTES.PROFILE_VIEW]: {
        show: false
    },
    [APP_ROUTES.PROFILE_REGISTRY]: {
        show: true
    },
    [APP_ROUTES.PROFILE_WITHDRAW]: {
        show: true
    }
};

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: '1.5rem',
    backgroundColor: theme.palette.primary.gray20,
    overflow: 'hidden',
    overflowY: 'auto',
    padding: '40px',
    [theme.breakpoints.down('md')]: {
        padding: '20px'
    }
}));

export default function Tabs() {
    const { status } = useGetPerson();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { logout } = useLogout();
    const { t } = useTranslation();

    const handleChange = (_event, newSelectedTab) => {
        navigate((status === 'withdrawn' ? withdrawnTabs : tabs)[newSelectedTab]);
    };

    const handleLogout = (event) => {
        event.stopPropagation();
        logout();
    };

    const tab = (status === 'withdrawn' ? withdrawnTabs : tabs).findIndex((route) => route === pathname);

    return (
        <StudyTabs value={tab} onChange={handleChange} aria-label='study tabs' variant='scrollable'>
            {status !== PARTICIPANT_STATUS.WITHDRAWN && <StudyTab label={t('profile:index:tabs:payments')} />}
            <StudyTab label={t('profile:index:tabs:consents')} />
            {status !== PARTICIPANT_STATUS.WITHDRAWN && <StudyTab label={t('profile:index:tabs:profile')} />}
            <StudyTab label={t('profile:index:tabs:registry')} />
            {status !== PARTICIPANT_STATUS.WITHDRAWN && <StudyTab label={t('profile:index:tabs:withdraw')} />}
            <StudyTab label={t('profile:index:tabs:logout')} onClick={handleLogout} />
        </StudyTabs>
    );
}

const tabs = [
    APP_ROUTES.PROFILE_PAYMENTS,
    APP_ROUTES.PROFILE_CONSENTS,
    APP_ROUTES.PROFILE_VIEW,
    APP_ROUTES.PROFILE_REGISTRY,
    APP_ROUTES.PROFILE_WITHDRAW
];

const withdrawnTabs = [APP_ROUTES.PROFILE_CONSENTS, APP_ROUTES.PROFILE_REGISTRY];
