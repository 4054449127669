import { Box } from '@mui/material';
import { Description } from '../Description';
import { GetStarted } from '../GetStarted';
import { Hero } from '../Hero';
import { Resources } from '../Resources';
import { Sponsor } from '../Sponsor';
import { TarpeyoSteady } from '../TarpeyoSteady';

const HomePage = () => {
    return (
        <>
            <Box padding={{ xs: '0em 1em', md: '0em 2em' }} bgcolor='primary.gray20' overflow={'auto'}>
                <Hero />
            </Box>
            <Box padding={{ xs: '2em 1em', md: '2em 2em' }} bgcolor='primary.white'>
                <TarpeyoSteady />
            </Box>
            <Box padding={{ xs: '2em 1em', md: '2em 2em' }}>
                <Description />
            </Box>
            <Box padding={{ xs: '2em 1em', md: '2em 2em' }}>
                <Sponsor />
            </Box>
            <Box padding={{ xs: '2em 1em', md: '2em 2em' }}>
                <GetStarted />
            </Box>
            <Box padding={{ xs: '2em 1em', md: '2em 2em' }}>
                <Resources />
            </Box>
        </>
    );
};

export default HomePage;
