import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { useGetPerson } from 'authentication';
import { getBaseURL } from 'config/API';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { LANGUAGES } from 'internationalization';
import { APP_ROUTES } from 'navigation';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const updateUserMetadataUrl = getBaseURL() + '/v3/users/update-metadata';

export function useSyncAuth0Metadata() {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async (language) => {
            const headers = new Headers();
            headers.set('Content-Type', 'application/json');

            const token = await getAccessTokenSilently();
            if (token) headers.set('Authorization', `Bearer ${token}`);
            else throw Error('');

            const response = await fetch(updateUserMetadataUrl, {
                headers,
                method: 'PATCH',
                body: JSON.stringify({
                    language
                })
            });

            if (response.ok) {
                return true;
            }

            throw response.status;
        }
    });
}

export function LanguageProvider({ children }) {
    const { language, id, refetch } = useGetPerson();
    const { i18n } = useTranslation();
    const [editPersonAttribute, { isError }] = useEditPersonAttributesMutation();
    const { pathname } = useLocation();

    const map = {
        'en-US': LANGUAGES.ENGLISH,
        'es-ES': LANGUAGES.SPANISH
    };

    const preferedLanguage =
        language ?? localStorage.getItem('language') ?? map[navigator.language] ?? LANGUAGES.ENGLISH;

    const changeLanguage = useCallback(
        async (language) => {
            localStorage.setItem('language', language);
            i18n.changeLanguage(language);

            if (language === preferedLanguage) {
                return;
            }

            if (pathname.includes(APP_ROUTES.WELCOME) || pathname === APP_ROUTES.PRIVACY_POLICY) {
                return;
            }

            if (id) {
                await editPersonAttribute({
                    personId: id,
                    payload: [
                        {
                            data: {
                                type: 'person-attributes',
                                attributes: {
                                    attribute: 'language',
                                    value: language
                                }
                            }
                        }
                    ]
                });

                refetch();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editPersonAttribute, i18n, id, preferedLanguage, refetch]
    );

    useEffect(() => {
        changeLanguage(preferedLanguage);
    }, [changeLanguage, preferedLanguage]);

    const contextValue = useMemo(
        () => ({
            changeLanguage,
            preferedLanguage,
            isError
        }),
        [changeLanguage, preferedLanguage, isError]
    );

    return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
}

const LanguageContext = createContext(null);

export function useLanguage() {
    const data = useContext(LanguageContext);

    if (data === null) {
        throw new Error('useLanguage should be inside LanguageProvider');
    }

    return data;
}
