import { Grid, MenuItem, Typography } from '@mui/material';
import { ErrorWrapper } from 'configUI/components';
import { IgANForm } from 'pages/onboarding/NewIgAN/Page';
import { StyledSelect } from 'pages/onboarding/PersonalInformation/forms/PersonalInformationForm/styles';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledFormLabel } from './styles';

export default function Demographic({ control, errors, iganProps }) {
    console.log('4', { iganProps });
    const { t } = useTranslation();

    return (
        <>
            <Grid item xs={12}>
                <Typography color='primary.gray75' fontSize='18px' fontWeight='600' marginTop='20px'>
                    {t('profile:view:sections:demographic-information')}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <StyledFormLabel required={true}>{t('profile:view:labels:sex-at-birth')}</StyledFormLabel>
                <ErrorWrapper>
                    <Controller
                        name='sex_at_birth'
                        control={control}
                        rules={{
                            required: true
                        }}
                        error={errors.sex_at_birth}
                        render={({ field, fieldState }) => (
                            <StyledSelect fullWidth disabled {...field} error={!!fieldState.error}>
                                {Object.entries(t('profile:view:sex-at-birth')).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        )}
                    />
                </ErrorWrapper>
            </Grid>
            <Grid item xs={6} marginTop='auto'>
                <StyledFormLabel required={true}>{t('profile:view:labels:gender')}</StyledFormLabel>
                <ErrorWrapper>
                    <Controller
                        name='gender'
                        control={control}
                        rules={{
                            required: true
                        }}
                        error={errors.gender}
                        render={({ field, fieldState }) => (
                            <StyledSelect fullWidth disabled {...field} error={!!fieldState.error}>
                                {Object.entries(t('profile:view:genders')).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        )}
                    />
                </ErrorWrapper>
            </Grid>
            <Grid item xs={6}>
                <StyledFormLabel required={true}>{t('profile:view:labels:race')}</StyledFormLabel>
                <ErrorWrapper>
                    <Controller
                        name='race'
                        control={control}
                        rules={{
                            required: true
                        }}
                        error={errors.race}
                        render={({ field, fieldState }) => (
                            <StyledSelect fullWidth disabled {...field} error={!!fieldState.error}>
                                {Object.entries(t('profile:view:races')).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        )}
                    />
                </ErrorWrapper>
            </Grid>
            <Grid item xs={6}>
                <StyledFormLabel required={true}>{t('profile:view:labels:ethnicity')}</StyledFormLabel>
                <ErrorWrapper>
                    <Controller
                        name='ethnicity'
                        control={control}
                        rules={{
                            required: true
                        }}
                        error={errors.ethnicity}
                        render={({ field, fieldState }) => (
                            <StyledSelect fullWidth disabled {...field} error={!!fieldState.error}>
                                {Object.entries(t('profile:view:ethnicity')).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        )}
                    />
                </ErrorWrapper>
            </Grid>

            {/* PATIENT IGAN INFORMATION */}
            <Grid item xs={12}>
                <Typography color='primary.gray75' fontSize='18px' fontWeight='600' my='20px'>
                    {t('profile:view:sections:patient-igan-information')}
                </Typography>

                {iganProps && <IgANForm {...iganProps} />}
            </Grid>
        </>
    );
}
