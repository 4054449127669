import { Box, Button as MuiButton, styled, Typography } from '@mui/material';
import { EmailLogo } from 'pages/onboarding/VerifyEmail/assets';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Container = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw',
    backgroundColor: theme.palette.primary.gray20
}));

export const Wrapper = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.primary.white,
    borderRadius: '8px',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    display: 'inline-flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '375px',
    padding: '3.75rem 1rem'
}));

export const Logo = styled(EmailLogo)(() => ({
    marginBottom: '3.125rem'
}));

export const Title = styled(Typography)(({ theme }) => ({
    ...theme.typography.h2,
    marginBottom: '5px',
    textAlign: 'center',
    maxWidth: '90%'
}));

export const Text = styled(Typography)(({ theme }) => ({
    ...theme.typography.pl3,
    color: theme.palette.primary.gray75,
    marginBottom: '5px',
    textAlign: 'center',
    width: '80%'
}));

export const Button = styled(MuiButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    color: theme.palette.primary.white,
    lineHeight: '1.25rem',
    fontSize: '1rem',
    fontWeight: 700,
    height: '56px',
    marginTop: '3.125rem',
    marginBottom: '1.75rem',
    textTransform: 'capitalize',
    width: '80%',
    '&:hover': {
        backgroundColor: theme.palette.primary.main
    },
    '&:disabled': {
        opacity: 0.6,
        cursor: 'not-allowed',
        color: 'rgba(255, 255, 255, .6)'
    }
}));

export const Link = styled(ReactRouterLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '.875rem',
    fontWeight: 600,
    lineHeight: '140%',
    textDecoration: 'none',
    '&:hover': {
        color: theme.palette.primary.main
    }
}));
