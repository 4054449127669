export const taskTypes = [
  {
    label: 'Survey',
    value: 'survey',
  },
  {
    label: 'Data sharing',
    value: 'dataSharing',
  },
  {
    label: 'TeleResearch',
    value: 'teleResearch',
  },
  {
    label: 'Video',
    value: 'video',
  },
];

export const errorCodes = {
  unauthorized: 401,
};

export const LOGOUT_TIME_IN_MINUTES = 30;

export const APP_ROUTES = {
  PERSONAL_INFO: "/onboarding/personal-information",
  DEMOGRAPHIC_INFO: "/onboarding/demographic-information",
  ELIGIBLE: "/onboarding/eligible",
  PROFILE: "/profile/view"
}