import { Box, styled, Typography } from '@mui/material';
import { ReactComponent as ChevronRight } from 'configUI/images/list/chevronRight.svg';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronRightActive } from '../assets';
import { ProfileCardIcon } from './ProfileCardIcon';

export function ProfileCard({ index, path, title, description, icon: Icon }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const isActive = path === location.pathname;
    const activeClassName = isActive && 'active';

    return (
        <Container onClick={() => navigate(path)} className={activeClassName}>
            <ProfileCardIcon className={activeClassName}>
                <Icon width='1.75rem' height='1.75rem' />
            </ProfileCardIcon>

            <InfoWrapper>
                <Typography variant='pb3'>{t('profile:index:cards')[index].title}</Typography>
                <Typography variant='pl5' color='#959CA0'>
                    {t('profile:index:cards')[index].description}
                </Typography>
            </InfoWrapper>

            {isActive ? <ChevronRightActive {...iconSize} /> : <ChevronRight {...iconSize} />}
        </Container>
    );
}

const iconSize = {
    width: '15px',
    height: '15px'
};

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    paddingTop: '1.125rem',
    paddingBottom: '1.125rem',
    paddingLeft: '1rem',
    paddingRight: '1.25rem',
    maxWidth: '20rem',
    backgroundColor: 'white',
    border: '2px solid transparent',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
    cursor: 'pointer',
    '&.active': {
        borderColor: theme.palette.primary.main
    }
}));

const InfoWrapper = styled(Box)({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '2px'
});
