export const ACTIVITY_STATUS = Object.freeze({
    ACTIVE: 'active',
    UPCOMING: 'upcoming',
    EXPIRED: 'expired',
    COMPLETED: 'completed',
    IN_PROGRESS: 'in_progress',
    INCOMPLETE: 'incomplete',
});

export const STATUS_FILTER = Object.freeze({
    ACTIVE: 'active',
    ALL: 'all'
});

export const STATUS_FILTER_VALUES = Object.values(STATUS_FILTER);
export const DATE_FILTER_VALUES = Object.values([30, 60, 90]);
