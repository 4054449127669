export const getMyProfileConfig = (data) => {
    const { data: attrList = [] } = data;
    const targetAttr = attrList.find((attr) => attr?.data.attributes.attribute === 'personal_details_configuration');

    if (!targetAttr) return {};
    const { value = {} } = targetAttr?.data?.attributes;

    return value?.my_profile;
};

// This function returns all values for the passed fields
const getFieldValues = (fields, personAttributes) => {
    if (!fields) return;
    let requiredAttributes = null;
    if (personAttributes) {
        fields.forEach((field) => {
            const attribute = personAttributes.find((item) => item.attributes.attribute === field);
            requiredAttributes = {
                ...requiredAttributes,
                [field]: attribute?.attributes.value || ''
            };
        });
    }
    return requiredAttributes;
};

// This function returns all required form fields from config
export const getFormFields = (config, personAttributes) => {
    if (!config) return {};
    const fieldsKeys = Object.keys(config);
    const fields = fieldsKeys.reduce((acc, currVal) => {
        if (config[currVal]) {
            if (currVal === 'language') {
                acc.push('languages');
            } else {
                acc.push(currVal);
            }
        }
        return acc;
    }, []);
    const formFields = getFieldValues(fields, personAttributes);
    return formFields;
};

const validPersonAttributes = [
    'first_name',
    'last_name',
    'ethnicity',
    'gender',
    'race',
    'sex_at_birth',
    'timezone',
    'language',
    'age_at_IgAN_diagnosis',
    'clinical_trial_participation',
    'phone',
    'sms_notification'
];

const validPatientAttributes = [
    'patient_first_name',
    'patient_last_name',
    'ethnicity',
    'gender',
    'race',
    'sex_at_birth',
    'age_at_IgAN_diagnosis',
    'clinical_trial_participation'
];

const validCaregiverAttributes = ['first_name', 'last_name', 'timezone', 'language', 'phone', 'sms_notification'];

const patientAttributesMap = {
    patient_first_name: 'first_name',
    patient_last_name: 'last_name'
};

export const getCaregiverPayload = (data) => {
    const validAttributes = validCaregiverAttributes;
    const formKeys = Object.keys(data);
    const form = formKeys
        .filter((key) => validAttributes.includes(key))
        .map((item) => {
            return {
                data: {
                    type: 'person-attributes',
                    attributes: {
                        attribute: item,
                        value: data[item]
                    }
                }
            };
        });

    return form;
};

export const getPayload = (data, isCaregiver) => {
    const validAttributes = isCaregiver ? validPatientAttributes : validPersonAttributes;
    const formKeys = Object.keys(data);

    if (data.age_at_IgAN_diagnosis) {
        data.age_at_IgAN_diagnosis = Number(data.age_at_IgAN_diagnosis);
    }

    if (data.clinical_trial_participation === '0' || data.clinical_trial_participation === '1') {
        data.clinical_trial_participation = Number(data.clinical_trial_participation);
    }

    const form = formKeys
        .filter((key) => validAttributes.includes(key))
        .map((item) => {
            return {
                data: {
                    type: 'person-attributes',
                    attributes: {
                        attribute: patientAttributesMap[item] || item,
                        value: data[item]
                    }
                }
            };
        });

    return form;
};

export const getMyProfileReasons = (data, reason) => {
    const { data: attrList = [] } = data;
    const targetAttr = attrList.find((attr) => attr?.data.attributes.attribute === 'withdrawal_reasons');

    if (!targetAttr) return {};
    const reasonsArray = targetAttr?.data?.attributes?.value[reason];
    let result = [];
    if (reasonsArray?.length > 0) {
        let index = 1;
        result = reasonsArray.map((r) => ({
            value: index++,
            label: r.labels[0].label
        }));
    }

    return result;
};

export const validatePhone = () => (phone) => !phone.includes('_') || 'This phone number is invalid';

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
