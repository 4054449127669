import { Typography } from '@mui/material';
import { useLogout } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { PPRLogo } from 'components/PPRLogo';
import { useTranslation } from 'react-i18next';
import { Container, LogoBox, Row, WithdrawSuccessWrapper } from './styles';

export default function WithdrawSuccess() {
    const { t } = useTranslation();
    const { logout } = useLogout();

    return (
        <WithdrawSuccessWrapper>
            <Container>
                <LogoBox>
                    <PPRLogo></PPRLogo>
                </LogoBox>
                <Row>
                    <Typography fontWeight='600' varient='h1' fontSize='20px' color='primary.gray75'>
                        {t('profile:withdraw:withdraw-success:title')}
                    </Typography>
                </Row>
                <Row>
                    <Typography varient='h2' fontSize='14px' color='primary.gray75'>
                        {t('profile:withdraw:withdraw-success:subtitle')}
                    </Typography>
                </Row>
                <FilledButton onClick={logout} fullWidth>
                    {t('profile:withdraw:withdraw-success:re-enroll')}
                </FilledButton>
            </Container>
        </WithdrawSuccessWrapper>
    );
}
