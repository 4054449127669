import { APP_ROUTES } from 'navigation';
import LanguageInstructions from 'pages/AdHoc/Language/LanguageInstructions';
import { LanguageQuestionnaire } from 'pages/AdHoc/Language/LanguageQuestionnaire';
import { useActiveActivity } from 'pages/Main';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

export function LanguageQuestionnairePage() {
    const { activeActivity } = useActiveActivity();
    const [isInstructions, setIsInstructions] = useState(true);

    if (!activeActivity) {
        return <Navigate to={APP_ROUTES.ACTIVITIES} replace />;
    }

    if (isInstructions) {
        return <LanguageInstructions setIsInstructions={setIsInstructions} />;
    }

    return <LanguageQuestionnaire activeActivity={activeActivity} setIsInstructions={setIsInstructions} />;
}
