import { CircularProgress } from '@mui/material';
import { Container } from 'components/loading/styles';

export const Loader = () => {
    return (
        <Container>
            <CircularProgress />
        </Container>
    );
};
