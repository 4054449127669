import { Typography, FormControlLabel, Select, InputLabel, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const WrappedTextMenuItem = styled(MenuItem)(({ theme }) => ({
    whiteSpace: 'unset !important',
    wordBreak: 'break-word !important'
}));

export const StyledFormLabel = styled('label')(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontWeight: 500,
    lineHeight: '2.4rem',
    fontSize: '14px',
    display: 'block'
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
    width: '100%',
    height: '46px',
    borderRadius: '4px',
    fontSize: '0.875rem',
    fontFamily: 'Inter',
    fontWeight: 500,
    borderWidth: '1px',
    backgroundColor: 'white',

    '&.MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.primary.gray15
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.gray15
        },
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.gray15
        },
        '&:disabled fieldset:disabled': {
            borderColor: theme.palette.primary.gray15
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main
        }
    }
}));

export const StyledFormTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontWeight: 600,
    fontSize: '18px',
    display: 'block'
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.primary.gray100,
    fontWeight: 500,
    '& .MuiFormControlLabel-label': {
        fontSize: '14px'
    },
    '& .MuiSvgIcon-root': {
        width: '26px',
        height: '26px'
    }
}));

export const getPlaceholderStyles = (hasFieldLabel) => ({
    top: hasFieldLabel ? '-4px' : '0px'
});

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    top: '-4px',
    color: theme.palette.primary.gray75,
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '8px'
}));
