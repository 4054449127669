import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const PrivacyPolicyWrapper = styled(Box)(({ theme }) => ({
    paddingBottom: '50px',
    backgroundColor: theme.palette.primary.gray20,
    margin: '5em auto 5em auto',
    maxWidth: '1080px',
    '@media (max-width: 768px)': {
        textAlign: 'center',
        padding: '0px 20px',
    },
}));

export const StyledList = styled('div')(({ theme }) => ({
    ...theme.typography,
    fontSize: '16px',
    color: theme.palette.primary.gray100,
    lineHeight: '24px',
    'ul.bold': {
        fontWeight: 600
    },
    'li': {
        marginBottom: '10px'
    },
}));

export const StyledLink = styled('a')(({ theme }) => ({
    color: theme.palette.primary.blue,
    textDecoration: 'none',
    fontWeight: 600,

    "&:visited": {
        color: theme.palette.primary.blue,
        textDecoration: 'none'
    }
}));