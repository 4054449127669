import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledLabel = styled(Typography)(({ theme, color }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.02em',
  color: color ?? theme.palette.primary.main,
  cursor: 'pointer'
}));

const StyledLabelBox = styled(Box)(() => ({
  display: 'flex',
  height: '56px',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
}));

export const LinkButton = ({ children, onClick , color, ...rest }) => (
  <StyledLabelBox {...rest}>
    <StyledLabel color={color} onClick={onClick}>
      {children}
    </StyledLabel>
  </StyledLabelBox>
);
