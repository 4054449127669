import { Box, Stack, styled, Typography } from '@mui/material';
import { ButtonWithArrow, GhostButton } from 'configUI/components/Buttons';
import { useIsMobile } from 'hooks/useIsScreenSize';
import { useRedirections } from 'navigation';
import { BackToMyActivities } from 'pages/Medications/BackToMyActivities';
import { Trans, useTranslation } from 'react-i18next';

export default function PhoneInstructions({ setIsInstructions }) {
    const { redirectToActivities } = useRedirections();
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    return (
        <Container>
            <Header>{<BackToMyActivities />}</Header>
            <Wrapper>
                <Typography variant='pb4' color='primary.main' mb='7px'>
                    {t('activities:adhoc:instruction:phone:title')}
                </Typography>
                <Typography variant='h2' color='#444444' mb='15px'>
                    {t('activities:adhoc:instruction:phone:subtitle')}
                </Typography>
                <Trans>
                    <Typography variant='pl3' color='primary.gray75' mb='50px'>
                        {t('activities:adhoc:instruction:phone:description')}
                    </Typography>
                </Trans>
                <Stack direction='row' justifyContent='flex-end' spacing='5px'>
                    <GhostButton sx={{ borderRadius: '8px' }} onClick={() => redirectToActivities()}>
                        {t('activities:adhoc:buttons:cancel')}
                    </GhostButton>
                    <ButtonWithArrow
                        sx={{ width: '134px', borderRadius: '8px' }}
                        onClick={() => setIsInstructions(false)}
                    >
                        {t('activities:adhoc:buttons:start')}
                    </ButtonWithArrow>
                </Stack>
            </Wrapper>
        </Container>
    );
}

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '70px',
    width: '100%',
    paddingLeft: '40px'
}));

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    position: 'relative',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100dvh - 90px)'
}));

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '700px',
    padding: '60px 50px 40px 50px',
    borderRadius: '8px',
    borderBottom: `1px solid ${theme.palette.primary.gray10}`,
    backgroundColor: 'white',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
        margin: '10px'
    }
}));
