import { Accordion, AccordionSummary, Box } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as ChevronRight } from 'configUI/images/list/chevronRight.svg';

export const ExpandIcon = (props) => (
  <Box sx={{ transform: 'rotate(90deg)' }} {...props}>
    <ChevronRight />
  </Box>
);

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: theme.palette.primary.white,
  boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px !important',
  width: '100%',
  '&.MuiAccordion-root:before': {
    content: 'none',
  }
}));

export const StyledAccordionSummary = styled(AccordionSummary)({
  transition: 'none',
  '& .Mui-expanded': {
    margin: '0px !important',
  },
  '& .MuiAccordionSummary-content': {
    transition: 'none',
  }
});
