import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetStarted } from '../GetStarted';
import { PrivacyPolicyWrapper, StyledLink, StyledList } from './styles';

const Header = ({ children }) => {
    return (
        <Typography component='h1' variant='h1' fontWeight='600' marginBottom='30px'>
            {children}
        </Typography>
    );
};

const PrivacyTitle = ({ children }) => {
    return (
        <Typography component='h2' variant='h2' fontWeight='600' color='primary.gray100' marginTop='30px'>
            {children}
        </Typography>
    );
};

const PrivacySubtitle = ({ children }) => {
    return (
        <Typography component='h6' variant='h6' fontStyle='italic' marginBottom='35px'>
            {children}
        </Typography>
    );
};

const PrivacyText = ({ children }) => {
    return (
        <Typography variant='pn1' display='block' color='primary.gray75' margin='0.7em 0 1.5em 0'>
            {children}
        </Typography>
    );
};
const PrivacyPolicyPage = () => {
    const { t } = useTranslation();

    return (
        <Box bgcolor='primary.gray20' px='24px'>
            <PrivacyPolicyWrapper>
                <Header>{t('welcome:privacy:main:title')}</Header>
                <PrivacySubtitle>{t('welcome:privacy:main:subtitle')}</PrivacySubtitle>
                <pre style={{ textWrap: 'wrap' }}>
                    <PrivacyText>
                        {t('welcome:privacy:main:effective-date').map(item => {
                            return item;
                        })}
                    </PrivacyText>
                </pre>

                <PrivacyTitle>{t('welcome:privacy:main:scope')}</PrivacyTitle>
                <PrivacyText>
                    {t('welcome:privacy:main:scope-text')}
                </PrivacyText>

                <PrivacyTitle>{t('welcome:privacy:main:topics')}</PrivacyTitle>
                <StyledList color='primary.blue'>
                    <ul>
                        {t('welcome:privacy:main:topics-links').map((link, index) => {
                            return <li>
                                <StyledLink href={`#${index + 1}`}>{link}</StyledLink>
                            </li>
                        })}
                    </ul>
                </StyledList>

                <section id='1'>
                    <PrivacyTitle>{t('welcome:privacy:section-1:title')}</PrivacyTitle>
                    <StyledList>
                        <ul>
                            {t('welcome:privacy:section-1:list').map(item => {
                                return <li>
                                    {item}
                                </li>
                            })}
                        </ul>
                    </StyledList>
                </section>

                <section id='2'>
                    <PrivacyTitle>{t('welcome:privacy:section-2:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-2:subtitle')}
                    </PrivacyText>
                    <StyledList>
                        <ul className='bold'>
                            {t('welcome:privacy:section-2:list').map(item => {
                                return <li>
                                    {item}
                                </li>
                            })}
                        </ul>
                    </StyledList>
                </section>

                <section id='3'>
                    <PrivacyTitle>{t('welcome:privacy:section-3:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-3:subtitle')}
                    </PrivacyText>
                    <StyledList>
                        <ul className='bold'>
                            {t('welcome:privacy:section-3:list').map(item => {
                                return <li>
                                    {item}
                                </li>
                            })}
                        </ul>
                    </StyledList>
                    <PrivacyText>
                        {t('welcome:privacy:section-3:footer-text')}
                    </PrivacyText>
                </section>

                <section id='4'>
                    <PrivacyTitle>{t('welcome:privacy:section-4:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-4:subtitle-1')}
                    </PrivacyText>
                    <PrivacyText>
                        {t('welcome:privacy:section-4:subtitle-2')}
                    </PrivacyText>
                </section>
                <section id='5'>
                    <PrivacyTitle>{t('welcome:privacy:section-5:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-5:subtitle-1')}
                    </PrivacyText>
                    <PrivacyText>
                        {t('welcome:privacy:section-5:subtitle-2')}
                    </PrivacyText>
                    <StyledList>
                        <ul className='bold'>

                            {t('welcome:privacy:section-5:list').map(item => {
                                console.log('typeof item', (typeof item));
                                if (typeof item === "object") {
                                    return <ul>
                                        {item.map(element => {
                                            return <li>
                                                {element}
                                            </li>
                                        })}
                                    </ul>
                                } else
                                    return <li>
                                        {item}
                                    </li>
                            })}

                        </ul>
                    </StyledList>
                </section>
                <section id='6'>
                    <PrivacyTitle>{t('welcome:privacy:section-6:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-6:subtitle-1')}
                    </PrivacyText>
                    <PrivacyText>
                        {t('welcome:privacy:section-6:subtitle-2')}
                    </PrivacyText>
                    <StyledList>
                        <ul className='bold'>
                            {t('welcome:privacy:section-6:list').map(item => {
                                return <li>
                                    {item}
                                </li>
                            })}
                        </ul>
                    </StyledList>
                    <PrivacyText>
                        {t('welcome:privacy:section-6:footer-text')}
                    </PrivacyText>
                </section>
                <section id='7'>
                    <PrivacyTitle>{t('welcome:privacy:section-7:title')}</PrivacyTitle>
                    {t('welcome:privacy:section-7:text').map(item => {
                        return <PrivacyText>
                            {item}
                        </PrivacyText>
                    })}
                </section>
                <section id='8'>
                    <PrivacyTitle>{t('welcome:privacy:section-8:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-8:subtitle')}
                    </PrivacyText>
                </section>
                <section id='9'>
                    <PrivacyTitle>{t('welcome:privacy:section-9:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-9:subtitle-1')}
                    </PrivacyText>
                    <PrivacyText>
                        {t('welcome:privacy:section-9:subtitle-2')}
                    </PrivacyText>
                </section>
                <section id='10'>
                    <PrivacyTitle>{t('welcome:privacy:section-10:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-10:subtitle')}
                    </PrivacyText>
                </section>
                <section id='11'>
                    <PrivacyTitle>{t('welcome:privacy:section-11:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-11:subtitle')}
                    </PrivacyText>
                </section>
                <section id='12'>
                    <PrivacyTitle>{t('welcome:privacy:section-12:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-12:subtitle-1')}
                    </PrivacyText>
                    <PrivacyText>
                        {t('welcome:privacy:section-12:subtitle-2')}
                    </PrivacyText>
                </section>
                <section id='13'>
                    <PrivacyTitle>{t('welcome:privacy:section-13:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-13:subtitle')}
                    </PrivacyText>
                </section>
                <section id='14'>
                    <PrivacyTitle>{t('welcome:privacy:section-14:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-14:subtitle')}
                    </PrivacyText>
                </section>
                <section id='15'>
                    <PrivacyTitle>{t('welcome:privacy:section-15:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-15:subtitle-1')}
                    </PrivacyText>
                    <PrivacyText>
                        {t('welcome:privacy:section-15:subtitle-2')}
                    </PrivacyText>
                    <PrivacyText>
                        {t('welcome:privacy:section-15:subtitle-3')}
                    </PrivacyText>
                </section>
                <section id='16'>
                    <PrivacyTitle>{t('welcome:privacy:section-16:title')}</PrivacyTitle>
                    <PrivacyText>
                        {t('welcome:privacy:section-16:subtitle-1')}
                    </PrivacyText>
                    <PrivacyText>
                        {t('welcome:privacy:section-16:subtitle-2')}
                    </PrivacyText>
                </section>
                <section id='17'>
                    <PrivacyTitle>{t('welcome:privacy:section-17:title')}</PrivacyTitle>
                    {t('welcome:privacy:section-17:text').map(item => {
                        return <PrivacyText>
                            {item}
                        </PrivacyText>
                    })}
                </section>

                <GetStarted />
            </PrivacyPolicyWrapper>
        </Box>
    );
};

export default PrivacyPolicyPage;
