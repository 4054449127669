import { Box, Stack, styled, Typography } from '@mui/material';
import { ButtonWithArrow } from 'configUI/components/Buttons';
import { MedicationPills } from 'pages/Medications/assets';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddMedicationModal } from './AddMedicationModal';

export function EmptyMedicationsList() {
    const { t } = useTranslation();

    const [showAddMedicationModal, setShowAddMedicationModal] = useState(false);

    function openAddMedicationModal() {
        setShowAddMedicationModal(true);
    }

    function closeAddMedicationModal() {
        setShowAddMedicationModal(false);
    }

    return (
        <Fragment>
            <Container>
                <Wrapper>
                    <Stack alignItems='center' justifyContent='center'>
                        <MedicationPills />
                        <Typography variant='h5' color='primary.gray75' mt='20px' mb='5px' textAlign='center'>
                            {t('activities:medications:empty-medications:title')}
                        </Typography>
                        <Typography variant='pl5' color='primary.gray50' textAlign='center' mb='120px'>
                            {t('activities:medications:empty-medications:description')}
                        </Typography>
                    </Stack>

                    <ButtonWithArrow onClick={openAddMedicationModal} sx={{ width: '100%' }}>
                        {t('activities:medications:empty-medications:buttons:add-medication')}
                    </ButtonWithArrow>
                </Wrapper>
            </Container>

            <AddMedicationModal open={showAddMedicationModal} onClose={closeAddMedicationModal} />
        </Fragment>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
}));

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '235px'
}));
