import { Box, IconButton, styled } from '@mui/material';

export const Card = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'showBorder'
})(({ theme, disabled, showBorder }) => ({
    backgroundColor: theme.palette.primary.white,
    border: '1px solid',
    borderColor: theme.palette.primary.gray5,
    borderRadius: '8px',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    display: 'flex',
    boxSizing: 'border-box',
    cursor: 'pointer',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        height: 'unset'
    },
    ...(showBorder && { border: `1px solid ${theme.palette.primary.fuchsia10}` }),
    '&:hover': {
        ...(disabled || { borderColor: theme.palette.primary.fuchsia75 })
    }
}));

export const Divider = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'inverted'
})(({ theme, inverted }) => {
    const border = `1px solid ${theme.palette.primary.gray10}`;
    return {
        [theme.breakpoints.up('sm')]: {
            ...(inverted ? { borderLeft: border } : { borderTop: border })
        },
        [theme.breakpoints.down('sm')]: {
            ...(inverted ? { borderTop: border } : { borderLeft: border })
        }
    };
});

export const GoToSignedConsent = styled(IconButton)(({ theme }) => ({
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '&: hover': {
        backgroundColor: 'transparent !important'
    }
}));
