import { LANGUAGES } from "internationalization";

export const surveyDataEN = {
    0: {
        id: 0,
        type: 'skip'
    },
    1: {
        id: 1,
        title: 'Please select your preferred language for your profile.',
        caregiverTitle:
            'Please select your preferred language for your profile.',
        type: 'radio',
        label: 'Available languages',
        answer: '',
        values: [
            {
                label: 'English',
                caregiverLabel: 'English',
                value: LANGUAGES.ENGLISH
            },
            {
                label: 'Spanish',
                caregiverLabel: 'Spanish',
                value: LANGUAGES.SPANISH
            }
        ]
    },
};
