import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { useGetPatient, useGetPerson } from 'authentication';
import { FilledButton, LinkButton } from 'components/Buttons';
import { useLoading } from 'components/loading';
import { getBaseURL } from 'config/API';
import { format } from 'date-fns';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { useSubmitWithdrawalMutation } from 'ihp-bloom-redux/features/study/studyApi';
import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as CheckedIcon } from 'images/shared/check-2.svg';
import { useRedirections } from 'navigation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, HelperText, PageTitle, Row, RowRadio, StyledFormControlLabel, WithdrawWrapper } from './styles';

function WithdrawReason({ handleClose, confirmCallback }) {
    const { t } = useTranslation();
    const [reason, setReason] = useState('');
    const { setIsLoading } = useLoading();
    const { id: personId, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();
    const { redirectToWithdrawnSuccess } = useRedirections();
    const [submitWithdrawal] = useSubmitWithdrawalMutation();
    const { getAccessTokenSilently } = useAuth0();
    const [editPersonAttributes] = useEditPersonAttributesMutation();

    const finalId = isCaregiver ? patientId : personId;
    const handleWithdrawalReasonSubmit = async (reason) => {
        setIsLoading(true);
        try {
            const url = getBaseURL() + '/v3/studies';
            const STUDY_NAME = 'Perform Patient Registry';
            const token = await getAccessTokenSilently();
            const studyIdOptions = {
                method: 'GET',
                url: url,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(url, studyIdOptions);
            let { data: studiesData } = await response.json();
            const studyId = Number(studiesData.find((personType) => personType.attributes.name === STUDY_NAME).id);
            const patientPayload = [{
                data: {
                    type: 'person-attributes',
                    attributes: {
                        attribute: 'tokenization_consent',
                        value: 'withdrawn'
                    }
                }
            }];

            await editPersonAttributes({
                personId: finalId,
                payload: patientPayload
            });

            const presonRes = await submitWithdrawal({ personId: personId, payload: { study_id: studyId, reason } });
            let patientRes;

            isCaregiver ? patientRes = await submitWithdrawal({ personId: patientId, payload: { study_id: studyId, reason } }) : patientRes = null;

            if (presonRes?.data?.status === 'withdrawn' || (isCaregiver && patientRes?.data?.status === 'withdrawn')) {
                redirectToWithdrawnSuccess();
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };


    const onSubmit = () => {
        console.log('submit');
        if (reason === 'I want to participate in a different Registry') {
            confirmCallback();
            handleClose();
        } else {
            handleWithdrawalReasonSubmit(reason);
            handleClose();
        }
    };
    const onSkip = () => {
        console.log('skip');
        handleClose();
    };

    function MyFormControlLabel(props) {
        const radioGroup = useRadioGroup();
        let checked = false;

        if (radioGroup) {
            checked = radioGroup.value === props.value;
        }

        return <StyledFormControlLabel checked={checked} {...props} />;
    }

    function CustomCheck() {
        return (
            <Box padding={'2px'}>
                <CheckedIcon width={'20px'} />
            </Box>
        );
    }

    return (
        <WithdrawWrapper>
            <Container>
                <Row>
                    <Logo width={'180px'} height={'auto'} />
                </Row>
                <Row>
                    <PageTitle>{t('profile:withdraw:withdraw-reason:title')}</PageTitle>
                </Row>
                <Row>
                    <HelperText>{t('profile:withdraw:withdraw-reason:subtitle')} {format(new Date(), 'd MMMM yyyy')}</HelperText>
                </Row>
                <RowRadio>
                    <RadioGroup onChange={(e) => setReason(e.target.value)}>
                        {Object.entries(t('profile:withdraw:withdraw-reason:reasons')).map(([key, value]) => {
                            return (
                                <MyFormControlLabel
                                    key={key}
                                    value={key}
                                    label={value}
                                    control={<Radio checkedIcon={<CustomCheck />} />}
                                />
                            );
                        })}
                    </RadioGroup>
                </RowRadio>
                <Row>
                    <FilledButton onClick={onSubmit} fullWidth>
                        {t('profile:withdraw:withdraw-reason:submit')}
                    </FilledButton>
                </Row>
                <Row>
                    <LinkButton onClick={onSkip} fullWidth>
                        {t('profile:withdraw:withdraw-reason:skip')}
                    </LinkButton>
                </Row>
            </Container>
        </WithdrawWrapper>
    );
}

export default WithdrawReason;
