import { Box } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { PPRLogo } from 'components/PPRLogo';
import { ReactComponent as DollarIcon } from 'images/dollar-sign.svg';
import { useRedirections } from 'navigation';
import { useTranslation } from 'react-i18next';
import { Amount, Container, HelperText, PageTitle, Row, SuccessBox, Wrapper } from './styles';

export function PaymentEarned() {
    const { t } = useTranslation();
    document.title = t('onboarding:index:titles:thank-you');

    const { redirectToPhoneVerification } = useRedirections();
    const { isCaregiver, ...person } = useGetPerson();
    const patient = useGetPatient();

    function handleOnClick() {
        if (isCaregiver) {
            patient?.refetch();
        }

        person?.refetch();
        redirectToPhoneVerification();
    }

    return (
        <Wrapper>
            <Container>
                <Row>
                    <PPRLogo width={200} />
                </Row>

                <Row marginBottom='10px'>
                    <PageTitle variant='h2'>{t('onboarding:index:info:payment-earned:title')}</PageTitle>
                </Row>

                <Row marginBottom='10px'>
                    <HelperText variant='pl3'>{t('onboarding:index:info:payment-earned:subtitle')}</HelperText>
                </Row>

                <Row marginBottom='25px'>
                    <SuccessBox>
                        <Box display='flex' alignItems='center'>
                            <DollarIcon />
                            <Amount>20</Amount>
                        </Box>
                        {t('onboarding:index:info:payment-earned:success-msg')}
                    </SuccessBox>
                </Row>

                <Row marginBottom='0'>
                    <FilledButton onClick={handleOnClick} fullWidth>
                        {t('onboarding:index:info:payment-earned:button-text')}
                    </FilledButton>
                </Row>
            </Container>
        </Wrapper>
    );
}
