import { useGetPerson, useLogout } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { PPRLogo } from 'components/PPRLogo';
import { APP_ROUTES } from 'navigation';
import { PARTICIPANT_STATUS } from 'pages/onboarding/constants';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Container, HelperText, PageTitle, PhoneBox, Row, StyledPhoneIcon, Wrapper } from './styles';

export default function Withdrawn() {
    const { t } = useTranslation();
    document.title = t('onboarding:index:titles:thank-you');
    const { logout } = useLogout();
    const { status } = useGetPerson();

    const handleOnClick = () => {
        logout();
    };

    if (status !== PARTICIPANT_STATUS.DISQUALIFIED && status !== PARTICIPANT_STATUS.WITHDRAWN) {
        return <Navigate to={APP_ROUTES.WELCOME} replace />;
    }

    return (
        <Wrapper>
            <Container>
                <Row>
                    <PPRLogo width={200} />
                </Row>
                <Row>
                    <PageTitle varient='h2'>{t('onboarding:index:info:withdrawn:title')}</PageTitle>
                </Row>
                <Row>
                    <HelperText varient='pl3'>
                        {t('onboarding:index:info:withdrawn:subtitle')}
                    </HelperText>
                </Row>
                <Row>
                    <PhoneBox>
                        <StyledPhoneIcon width={20}></StyledPhoneIcon>
                        1-833-444-8277
                    </PhoneBox>
                </Row>
                <Row marginBottom={'0px'}>
                    <FilledButton onClick={handleOnClick} fullWidth>
                        {t('onboarding:index:info:withdrawn:button-text')}
                    </FilledButton>
                </Row>
            </Container>
        </Wrapper>
    );
}
