import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

export const Container = styled(Box)(({ theme }) => ({
    maxWidth: '1080px',
    margin: '0 auto',
    padding: '60px',
    [theme.breakpoints.down('md')]: {
        padding: '60px 30px',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '60px 20px',
    },
}));

export const FooterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '2em',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.primary.gray75,
    fontWeight: '500',
    padding: '10px',
    fontSize: '18px',
    '&:hover': {
        color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    },
}));
