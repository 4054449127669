/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { PERSON_STATUS_REASON, useGetPatient, useGetPerson } from 'authentication';
import { useEditPersonMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { useRedirections } from 'navigation';
import { PARTICIPANT_STATUS } from 'pages/onboarding/constants';
import { useEffect } from 'react';

export function ConsentDeclinedPage() {
    const { redirectToOnboarding } = useRedirections();
    const { id: personId, refetch, isCaregiver } = useGetPerson();
    const { id: patientId } = useGetPatient();
    const [editPerson] = useEditPersonMutation();

    async function handleChange() {
        const promises = [];

        const personPromise = editPerson({
            personId: personId,
            payload: {
                data: {
                    type: 'people',
                    id: String(personId),
                    attributes: {
                        state: 'enrollment',
                        status: PARTICIPANT_STATUS.DISQUALIFIED,
                        status_reason: PERSON_STATUS_REASON.CONSENT_DECLINED
                    }
                }
            }
        });

        promises.push(personPromise);

        if (isCaregiver) {
            const patientPromise = editPerson({
                personId: patientId,
                payload: {
                    data: {
                        type: 'people',
                        id: String(patientId),
                        attributes: {
                            state: 'enrollment',
                            status: PARTICIPANT_STATUS.DISQUALIFIED,
                            status_reason: PERSON_STATUS_REASON.CONSENT_DECLINED
                        }
                    }
                }
            });

            promises.push(patientPromise);
        }

        await Promise.all(promises);
        refetch();
        redirectToOnboarding();
    }

    useEffect(() => {
        if (typeof isCaregiver === 'undefined') return;
        if (isCaregiver && !patientId) return;
        if (personId) {
            handleChange();
        }
    }, [patientId, personId, isCaregiver]);

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flex={1}
            height='100vh'
            bgcolor='primary.gray20'
        >
            <CircularProgress />
        </Box>
    );
}
