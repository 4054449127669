import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FilledButton } from 'components/Buttons';

export const Container = styled(Box)(({ theme }) => ({
    background: theme.palette.secondary.gray
}));

export const Wrapper = styled(Box)(({ theme }) => ({
    borderRadius: '9px',
    maxWidth: '1080px',
    padding: '80px 80px',
    margin: '0 auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.primary.blue,
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
        padding: '70px 30px',
        justifyContent: 'flex-start',
        textAlign: 'left',
        gap: '10px'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '70px 20px'
    }
}));

export const GetStartedBtn = styled(FilledButton)(({ theme }) => ({
    height: '50px',
    width: '147px',
    padding: '15px 25px 15px 30px',
    background: '#fff',
    color: theme.palette.primary.blue,
    fontSize: '15px',
    border: '2px solid transparent',
    '&:hover': {
        background: theme.palette.primary.blue,
        color: theme.palette.primary.white,
        borderColor: theme.palette.primary.white
    }
}));
