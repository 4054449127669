import { Select, Box, FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { width } from '@mui/system';

export const StyledFormLabel = styled('label')(({ theme, required }) => ({
    color: theme.palette.primary.gray75,
    fontWeight: 500,
    lineHeight: '2.4rem',
    fontSize: '14px',
    display: 'block',
    ...(required && {
        '&:after': {
            content: '"*"',
            color: theme.palette.primary.red,
            marginLeft: '4px'
        }
    })
}));

export const FileUploadContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
});

export const DeleteIcon = styled('span')(({ theme }) => ({
    fontSize: 15,
    color: theme.palette.primary.gray50,
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    '&:hover': {
        backgroundColor: 'rgba(244, 155, 29, 0.04)'
    },
    '&:after': {
        content: '"X"',
        fontFamily: 'Arial'
    }
}));

export const Card = styled(Box)(({ theme }) => ({
    padding: '10px',
    border: `1px solid ${theme.palette.primary.gray25}`,
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
        backgroundColor: '#F5F5F5'
    }
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
    background: theme.palette.primary.white,
    width: '100%',
    height: '46px',
    borderRadius: '4px',
    fontFamily: 'Inter',
    fontWeight: 500,
    borderWidth: '1px'
}));

export const StyledTitle = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px'
}));

export const StyledTitleTag = styled(Box)(() => ({
    backgroundColor: 'white',
    color: '#606B71',
    padding: '7px 10px',
    fontSize: '10px',
    border: '1px solid #DFE2E3',
    borderRadius: '4px'
}));

export const StyledSwitchLabel = styled(FormControlLabel)(({ theme, disabled }) => ({
    borderRadius: '8px',
    display: 'flex',
    margin: '0px',
    flex: 1,
    padding: '20px',
    border: '2px solid #DFE2E3',
    color: '#606B71',
    background: disabled ? 'transparent' : theme.palette.primary.white,
    fontSize: '14px'
}));

export const StyledSwitch = styled(Switch)(({ theme, disabled }) => ({
    marginRight: '50px',
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    cursor: disabled ? 'not-allowed' : 'pointer',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.mainLight
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
    }
}));
