import { useCheckUserStatus, useGetPerson } from 'authentication';
import { format } from 'date-fns';
import {
    useEditPersonAttributesMutation,
    useEditPersonMutation
} from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { useRedirections } from 'navigation';
import { PARTICIPANT_STATUS } from 'pages/onboarding/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { calculateAge } from 'utils/date';
import { PatientPrescreeningForm } from './PatientPrescreeningForm';

export function normalizeDate(date) {}

export function PatientPrescreeningFormLogic() {
    const { id: personId, refetch } = useGetPerson();
    const [editPersonAttribute, { isError: isPersonAttributesError }] = useEditPersonAttributesMutation();
    const [editPerson, { isError: isPersonError }] = useEditPersonMutation();
    const { redirectToError } = useRedirections();
    const { verifyUserStatus } = useCheckUserStatus();

    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            date_of_birth: null,
            patient_had_tarpeyo: null
        }
    });

    async function handleSubmit({ date_of_birth, patient_had_tarpeyo }) {
        const isUserStatusValid = await verifyUserStatus(PARTICIPANT_STATUS.AUTHENTICATED);
        if (!isUserStatusValid) return;

        try {
            const personAttributes = [
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'date_of_birth',
                            value: format(date_of_birth, 'yyyy-MM-dd') + 'T00:00:00.000Z'
                        }
                    }
                },
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'patient_had_tarpeyo',
                            value: Number(patient_had_tarpeyo)
                        }
                    }
                }
            ];

            const editPersonAttributesPromise = editPersonAttribute({ personId, payload: personAttributes });
            const isDisqualified = calculateAge(date_of_birth) < 18 || Number(patient_had_tarpeyo) === 0;

            const editPersonPromise = editPerson({
                personId,
                payload: {
                    data: {
                        type: 'people',
                        id: personId,
                        attributes: {
                            state: 'enrollment',
                            status: isDisqualified ? PARTICIPANT_STATUS.DISQUALIFIED : PARTICIPANT_STATUS.REGISTERED,
                            status_reason: isDisqualified ? 'Not-eligible' : 'ini'
                        }
                    }
                }
            });

            await Promise.all([editPersonPromise, editPersonAttributesPromise]);
            refetch();
        } catch (error) {
            redirectToError();
        }
    }

    useEffect(() => {
        if (isPersonAttributesError || isPersonError) {
            redirectToError();
        }
    }, [isPersonAttributesError, isPersonError]);

    return <PatientPrescreeningForm form={form} onSubmit={handleSubmit} />;
}
