import { Box, Typography } from '@mui/material';
import { useGetPerson } from 'authentication';
import { RadioLabel } from 'components/survey/radio-label';
import { OptionsWrapper } from 'components/survey/radio-label/styles';

export const Question = ({ components, onAnswer, answer, isRow }) => {
    console.log('🚀 ~ file: question.js:7 ~ Question ~ components:', components);
    const { isCaregiver } = useGetPerson();
    return (
        <Box flex sx={{ flexDirection: 'column', flex: 1, textAlign: 'left' }}>
            <Typography variant='h6'>{components.label}</Typography>
            <OptionsWrapper sx={isRow ? { flexDirection: 'row' } : {}}>
                {components.values.map((option, i) => (
                    <RadioLabel
                        isReverse={true}
                        key={i}
                        onClick={(e) => {
                            onAnswer({ [components.id]: option.value });
                        }}
                        label={isCaregiver ? option.caregiverLabel : option.label}
                        value={option.value}
                        checked={answer === option.value}
                    />
                ))}
            </OptionsWrapper>
        </Box>
    );
};
