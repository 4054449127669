import { Box, Typography, styled } from '@mui/material';
import { FilledButton } from 'components/Buttons';
import { PhoneInvalidSVG } from 'pages/onboarding/Phone/assets';
import { useTranslation } from 'react-i18next';

export function PhoneReachedLimit({ onClick }) {
    const { t } = useTranslation();

    return (
        <Container>
            <PhoneInvalidSVG style={{ marginTop: '60px' }} />

            <Typography variant='h2' color='primary.gray100' marginTop='50px' textAlign='center'>
                {t('onboarding:phone:limit:title')}
            </Typography>

            <FilledButton
                type='submit'
                width='100%'
                sx={{ marginTop: '50px', marginBottom: '5px', backgroundColor: 'primary.red75' }}
                onClick={onClick}
            >
                {t('onboarding:phone:invalid:button')}
            </FilledButton>
        </Container>
    );
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '325px',
    maxWidth: '325px',
    padding: '50px 40px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px'
}));
