import { Box, Typography, createStyles, styled } from '@mui/material';
import { FilledButton } from 'components/Buttons';
import { TextInput } from 'components/Input';
import { PPRLogo } from 'components/PPRLogo';
import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function IgANPage({ setShowDemographicInformation }) {
    const [age, setAge] = useState(null);
    const [options, setOptions] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const disabled = !age || age < 1 || age > 100 || options.length === 0;

    async function onSubmit() {
        dispatch(
            setValues({
                igan: {
                    age,
                    options
                }
            })
        );

        setShowDemographicInformation(true);
    }

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flex={1}
            height='100vh'
            bgcolor='primary.gray20'
        >
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flexDirection='column'
                pt='50px'
                pb='35px'
                px='40px'
                bgcolor='white'
                maxWidth='610px'
                borderRadius='8px'
            >
                <PPRLogo />

                <Typography variant='h2' color='primary.gray100' mt='25px' mb='35px'>
                    {t('onboarding:igan:title')}
                </Typography>

                <IgANForm age={age} setAge={setAge} options={options} setOptions={setOptions} onSubmit={onSubmit} />

                <FilledButton fullWidth disabled={disabled} onClick={onSubmit}>
                    {t('onboarding:igan:buttons:save')}
                </FilledButton>
            </Box>
        </Box>
    );
}

const RequiredTypography = styled(Typography)(({ theme }) => ({
    '&::after': {
        content: '"*"',
        color: '#DF2525'
    }
}));

export function IgANForm({ age, setAge, options = [], setOptions, onSubmit, init }) {
    const { t } = useTranslation();
    const isNaN = Number.isNaN(age);

    return (
        <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' width='100%'>
            <Box display='flex' flexDirection='column' gap='8px' width='100%' mb='25px'>
                <RequiredTypography display='flex' variant='pn3' color='primary.gray75'>
                    {t('onboarding:igan:labels:diagnosis_age')}
                </RequiredTypography>

                <TextInput
                    type='number'
                    placeholder={t('onboarding:igan:placeholders:diagnosis_age')}
                    onChange={(e) => setAge(e.target.value)}
                    value={age}
                />

                {!isNaN && age && (age < 1 || age > 100) && (
                    <Typography variant='pn3' color='primary.red'>
                        {t('onboarding:igan:errors:diagnosis_age')}
                    </Typography>
                )}
            </Box>

            <Box display='flex' flexDirection='column' gap='8px' width='100%' mb='25px'>
                <RequiredTypography variant='pn3' color='primary.gray75'>
                    {t('onboarding:igan:labels:has_participated')}
                </RequiredTypography>

                <IgANOptions options={options} setOptions={setOptions} />
            </Box>
        </Box>
    );
}

function IgANOptions({ options, setOptions }) {
    const { t } = useTranslation();

    const keys = Object.keys(t('onboarding:igan:options'));
    const entries = Object.entries(t('onboarding:igan:options'));

    function addIgANOption(option) {
        setOptions((previous) => {
            if (previous.includes(option)) {
                return previous.filter((opt) => opt !== option);
            } else {
                if (previous.includes(keys[0]) || previous.includes(keys[3])) {
                    return previous;
                }

                if (
                    (previous.includes(keys[1]) || previous.includes(keys[2])) &&
                    (option === keys[0] || option === keys[3])
                ) {
                    return previous;
                }
            }

            return previous.concat(option);
        });
    }

    return (
        <Box display='flex' flexDirection='column' gap='10px'>
            {entries.map(([key, value]) => {
                const isDisabled =
                    (options.includes(keys[0]) && key !== keys[0]) ||
                    (options.includes(keys[3]) && key !== keys[3]) ||
                    ((options.includes(keys[1]) || options.includes(keys[2])) && key !== keys[1] && key !== keys[2]);

                return (
                    <Box
                        display='flex'
                        padding='11px'
                        gap='10px'
                        alignItems='center'
                        justifyContent='space-between'
                        border='solid'
                        borderColor={options.includes(key) ? 'primary.main' : 'primary.gray15'}
                        borderRadius='8px'
                        sx={{
                            cursor: isDisabled ? 'not-allowed' : 'pointer'
                        }}
                        onClick={() => addIgANOption(key)}
                        bgcolor='white'
                    >
                        <Typography
                            variant='pn3'
                            color={options.includes(key) ? 'primary.main' : 'primary.gray100'}
                            fontWeight={options.includes(key) ? 700 : 500}
                        >
                            {value}
                        </Typography>

                        <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            minHeight='20px'
                            height='20px'
                            width='20px'
                            minWidth='20px'
                            bgcolor={options.includes(key) ? 'primary.main' : ''}
                            border={options.includes(key) ? '0' : 'solid'}
                            borderColor={options.includes(key) ? 'primary.main' : 'primary.gray25'}
                            borderRadius='8px'
                        >
                            {options.includes(key) && (
                                <svg
                                    width='12'
                                    height='9'
                                    viewBox='0 0 12 9'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M4.37891 8.34375C4.57422 8.53906 4.90625 8.53906 5.10156 8.34375L10.8438 2.60156C11.0391 2.40625 11.0391 2.07422 10.8438 1.87891L10.1406 1.17578C9.94531 0.980469 9.63281 0.980469 9.4375 1.17578L4.75 5.86328L2.54297 3.67578C2.34766 3.48047 2.03516 3.48047 1.83984 3.67578L1.13672 4.37891C0.941406 4.57422 0.941406 4.90625 1.13672 5.10156L4.37891 8.34375Z'
                                        fill='white'
                                    />
                                </svg>
                            )}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
}
