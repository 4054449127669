/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress } from '@mui/material';
import {
    PERSON_STATUS_REASON,
    useGetCurrentUser,
    useGetPatient,
    useGetPerson,
    useGetPersonRelationship
} from 'authentication';
import { useLoading } from 'components/loading';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { APP_ROUTES, useRedirections } from 'navigation';
import { isValidSourceName } from 'pages/Welcome/SourceNames';
import { PARTICIPANT_STATUS } from 'pages/onboarding/constants';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export function OnboardingPage() {
    const { user, isLoading: isAuth0Loading } = useAuth0();
    const { isLoading: isCurrentUserLoading } = useGetCurrentUser();

    const {
        id: personId,
        status,
        statusReason,
        isCaregiver,
        isError: isPersonError,
        phoneVerified,
        isLoading: isPersonLoading,
        ...person
    } = useGetPerson();

    const { id: patientId, isLoading: isPatientLoading, isError: isPatientError } = useGetPatient();

    const {
        isLoading: isPersonRelationshipLoading,
        isFetching: isPersonRelationshipFetching,
        isSuccess: isPersonRelationshipSuccess,
        isError: isPersonRelationshipError
    } = useGetPersonRelationship();

    const [editPersonAttribute, { isError: isPersonAttributesError }] = useEditPersonAttributesMutation();

    const { setIsLoading } = useLoading();
    const { redirectToError } = useRedirections();

    const isLoading =
        !status ||
        isPersonLoading ||
        isCurrentUserLoading ||
        (isCaregiver &&
            (isPatientLoading ||
                isPersonRelationshipLoading ||
                isPersonRelationshipFetching ||
                !isPersonRelationshipSuccess));

    console.log('ONBOARDING LOADING', {
        status,
        isCurrentUserLoading,
        isCaregiver,
        isPatientLoading,
        isPersonRelationshipLoading,
        isPersonRelationshipFetching,
        isPersonRelationshipSuccess
    });

    // const createRefSourceName = async () => {
    //     const finalId = isCaregiver ? patientId : personId;
    //     const sourceName = localStorage.getItem('sourcename');

    //     if (isValidSourceName(sourceName)) {
    //         const refSourceName = {
    //             data: {
    //                 type: 'person-attributes',
    //                 attributes: {
    //                     attribute: 'ref_source_name',
    //                     value: sourceName.toUpperCase()
    //                 }
    //             }
    //         };

    //         await editPersonAttribute({
    //             personId: finalId,
    //             payload: [refSourceName]
    //         });

    //         localStorage.removeItem('sourcename');
    //     }
    // };

    useEffect(() => {
        // if (!isLoading) {
        //     createRefSourceName();
        // }
        setIsLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (isPersonError || isPatientError || isPersonRelationshipError || isPersonAttributesError) {
            redirectToError();
        }
    }, [isPersonError, isPatientError, isPersonRelationshipError, isPersonAttributesError]);

    if (!isAuth0Loading && !user.email_verified) {
        return <Navigate to={APP_ROUTES.VERIFY_EMAIL} replace />;
    }

    if (isLoading) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flex={1}
                height='100vh'
                bgcolor='primary.gray20'
            >
                <CircularProgress />
            </Box>
        );
    }

    if (status === PARTICIPANT_STATUS.DISQUALIFIED && statusReason === PERSON_STATUS_REASON.CONSENT_DECLINED) {
        return <Navigate to={APP_ROUTES.WITHDRAWN} replace />;
    }

    if (status === PARTICIPANT_STATUS.DISQUALIFIED) {
        return <Navigate to={APP_ROUTES.DISQUALIFIED} replace />;
    }

    if (status === PARTICIPANT_STATUS.AUTHENTICATED) {
        return <Navigate to={APP_ROUTES.PRESCREENING} replace />;
    }

    // const hasPhone = typeof person.phone === 'string' && person.phone !== '';

    // if (hasPhone && Boolean(Number(phoneVerified))) {
    // } else {
    //     return <Navigate to={APP_ROUTES.PHONE} replace />;
    // }

    if (status === PARTICIPANT_STATUS.REGISTERED) {
        return <Navigate to={APP_ROUTES.CONSENTS} replace />;
    }

    // After signing the consent, the back end will set the status of the user to consented
    // If the user reject the consent, the back end will set the status to disqualified
    if (status === PARTICIPANT_STATUS.CONSENTED) {
        return <Navigate to={APP_ROUTES.INFORMATION} replace />;
    }

    return <Navigate to={APP_ROUTES.PROGRESS} replace />;
}
