import { useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { ProfileContent, ProfileNav } from './components';

const Profile = (props) => {
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

    return (
        <>
            {isDesktop && <ProfileNav {...props} />}

            <ProfileContent>
                <Outlet />
            </ProfileContent>
        </>
    );
};

export default Profile;
