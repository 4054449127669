import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Main = styled(Box)({
    display: 'flex',
    flexDirection: 'column'
});

export const Header = styled(Box)(({ theme }) => ({
    flex: '0 0 auto',
    top: 0,
    position: 'sticky',
    [theme.breakpoints.down('sm')]: {
        borderBottom: `1px solid ${theme.palette.primary.gray10}`
    }
}));

export const Footer = styled(Box)({
    flex: '0 0 auto',
    bottom: 0,
    position: 'sticky'
});

export const Scrollable = styled(Box)(({ theme, scrollBg }) => ({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: scrollBg ?? theme.palette.primary.white
}));

export const Content = styled(Box)({
    flex: '1 1 auto'
});
