import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { ButtonWithArrow } from 'configUI/components/Buttons';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { LANGUAGES, useLanguage } from 'internationalization';
import { useRedirections } from 'navigation';
import { useActivities } from 'pages/NewActivities/Provider';
import { useParticipantsTasksData } from 'pages/Study/useParticipantsTasksData';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTION_TYPES, INITIAL_STATE, reducerFn } from '../../EQ5D5L/helper';
import { Question } from '../../EQ5D5L/question';
import { SurveyProgressBar } from '../../EQ5D5L/survey-progress-bar';
import ErrorAnswerModal from '../error-answer-modal';
import {
    ButtonWrapper,
    ContentWrapper,
    FilledButton,
    QuestionTitle,
    SurveyPage,
    SurveyWrapper,
    TextButton
} from '../styles.js';
import { surveyDataEN } from './surveyDataEN';
import { surveyDataES } from './surveyDataES';

export const LanguageQuestionnaire = ({ activeActivity, setIsInstructions }) => {
    const participantTaskId = activeActivity.id;
    const { isCaregiver, id: personId } = useGetPerson();
    const { id: patientId } = useGetPatient();
    const { t, i18n } = useTranslation();

    const surveyData = i18n.language === LANGUAGES.SPANISH ? surveyDataES : surveyDataEN;

    const [isSubmiting, setIsSubmiting] = useState(false);
    const isLoading = isSubmiting;
    const { redirectToThankYou, redirectToError } = useRedirections();

    const [state, localDispatch] = useReducer(reducerFn, INITIAL_STATE);
    const { answers } = state;

    const finalId = isCaregiver ? patientId : personId;
    const [currentStep, setCurrentStep] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const { fetchActivities } = useActivities();

    const [updateParticipantTask] = useUpdateParticipantTaskMutation();
    const { refetch: refetchParticipantsTasksData } = useParticipantsTasksData();
    const { changeLanguage, isError } = useLanguage();

    const containerRef = useRef(null);

    async function handleSubmit(data) {
        try {
            setIsSubmiting(true);
            await changeLanguage(data[0]);

            if (isError) {
                redirectToError();
                return;
            } else {
                try {
                    await updateParticipantTask({
                        participantTaskId,
                        payload: {
                            data: {
                                type: 'participant-tasks',
                                id: String(participantTaskId),
                                attributes: {
                                    status: 'completed'
                                }
                            }
                        }
                    });

                    fetchActivities(finalId);
                    refetchParticipantsTasksData();
                    setIsSubmiting(false);
                    redirectToThankYou();
                } catch (error) {
                    redirectToError();
                }

                redirectToThankYou();
                return;
            }
        } catch (error) {
            setIsSubmiting(false);
            redirectToError();
        }
    }

    const onNext = () => {
        if (currentStep === 0) {
            setCurrentStep(currentStep + 1);
            return;
        }

        if (answers[currentStep]) {
            setCurrentStep(currentStep + 1);
            return;
        } else {
            handleOpenModal();
        }
    };

    const onPrevious = () => {
        if (currentStep === 0) return;
        setCurrentStep(currentStep - 1);
    };

    const onAnswer = (payload) => {
        localDispatch({
            type: ACTION_TYPES.QUESTION_UPDATE,
            payload
        });
    };

    const onFinalize = () => {
        const values = Object.values(answers);
        console.log('🚀 ~ file: IgANQuestionnaire.jsx:137 ~ onFinalize ~ values:', values);
        handleSubmit(values);
    };

    useEffect(() => {
        containerRef?.current?.scrollTo(0, 0);
    }, [currentStep]);

    if (isSubmiting) {
        return (
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flex={1}
                height='100vh'
                bgcolor='primary.gray20'
                width='100%'
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <SurveyWrapper ref={containerRef}>
            <ErrorAnswerModal open={openModal} handleClose={handleCloseModal} />
            <SurveyProgressBar numberOfSteps={1} current={1} />

            <ContentWrapper>
                <>
                    <Box>
                        {Object.values(surveyData).map((q, i) => {
                            if (q.type === 'skip' && i === currentStep) {
                                onNext();
                            }
                            if (q.type === 'radio')
                                return (
                                    i === currentStep && (
                                        <Box key={q.id}>
                                            <QuestionTitle>{isCaregiver ? q.caregiverTitle : q.title}</QuestionTitle>
                                            <SurveyPage>
                                                <Question
                                                    key={i}
                                                    components={q}
                                                    answer={answers[i]}
                                                    onAnswer={onAnswer}
                                                />
                                            </SurveyPage>
                                        </Box>
                                    )
                                );

                            return <></>;
                        })}
                    </Box>
                    {/* {FINALIZE STEP - if there are [0,1,2,3,4,5] pages this is currentStep 6} */}
                    {currentStep === Object.keys(surveyData).length && (
                        <Box>
                            <Typography marginBottom={'30px'} textAlign='center' color='primary.gray7' variant='h2'>
                                {t('activities:eq5d5l:final:title')}
                            </Typography>
                            <Box marginBottom={'30px'}>
                                <Typography color='primary.gray75' variant='pl3'>
                                    {t('activities:eq5d5l:final:description1')}
                                </Typography>
                            </Box>
                            <Box marginBottom={'30px'}>
                                <Typography marginBottom={'30px'} color='primary.gray75' variant='pl3'>
                                    {t('activities:eq5d5l:final:description2')}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </>

                <ButtonWrapper>
                    <TextButton
                        disabled={isLoading}
                        onClick={() => setIsInstructions(true)}
                        startIcon={<ArrowBackIcon />}
                    >
                        {t('activities:eq5d5l:buttons:previous')}
                    </TextButton>

                    {currentStep !== Object.keys(surveyData).length && (
                        <ButtonWithArrow
                            variant='contained'
                            disabled={currentStep === Object.keys(surveyData).length || isLoading}
                            onClick={onNext}
                        >
                            {t('activities:eq5d5l:buttons:next')}
                        </ButtonWithArrow>
                    )}

                    {currentStep === Object.keys(surveyData).length && (
                        <FilledButton
                            disabled={isLoading}
                            sx={{}}
                            variant='contained'
                            onClick={onFinalize}
                            startIcon={<CheckIcon />}
                        >
                            {t('activities:eq5d5l:buttons:finalize')}
                        </FilledButton>
                    )}
                </ButtonWrapper>
            </ContentWrapper>
        </SurveyWrapper>
    );
};
