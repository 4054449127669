import { Box, styled } from '@mui/system';
import { ReactComponent as Circle } from 'images/study/circle.svg';

export const TimelineCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
    position: 'relative',
    padding: '15px'
}));

export const LeftContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center'
}));

export const RightContainer = styled(Box)(() => ({
    display: 'flex',
    height: '40px'
}));

export const TitleContainer = styled(Box)(() => ({
    marginLeft: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
}));

export const Title = styled('div')(({ theme }) => ({
    color: theme.palette.secondary.gray100,
    fontSize: '14px',
    fontWeight: 600,
    alignSelf: 'top',
    lineHeight: '145%'
}));

export const CategoryTitleContainer = styled(Box)(() => ({
    marginTop: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
}));

export const CategoryTitle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.gray75,
    fontSize: 14,
    marginBottom: '10px',
    fontWeight: 700
}));

export const Info = styled('div')(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.secondary.gray4,
    fontWeight: 400,
    alignSelf: 'top',
    textTransform: 'capitalize'
}));

export const HeartPoints = styled('div')(({ theme }) => ({
    fontSize: '14px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginLeft: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const IconWrapper = styled(Box)(() => ({
    width: '40px',
    height: '40px',
    alignSelf: 'center'
}));

export const BulletWrapper = styled(Box)(() => ({
    width: '20px',
    height: '20px',
    marginLeft: '33px',
    marginTop: '18px',
    paddingBottom: '12px',
    alignSelf: 'center'
}));

export const VerticalLine = ({ theme, marginTop }) => (
    <Box
        sx={{
            backgroundColor: theme.palette.primary.gray25,
            width: '2px',
            borderLeft: `2px solid ${theme.palette.primary.white}`,
            borderRight: `2px solid ${theme.palette.primary.white}`,
            height: '26px',
            position: 'absolute',
            marginLeft: '38px',
            marginTop,
            zIndex: 10
        }}
    />
);

export const StyledCircle = styled(Circle)({
    margin: '0 2px 2px'
});
