import { Box, Typography, styled } from '@mui/material';
import { useGetPerson } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { PPRLogo as Logo } from 'components/PPRLogo';
import { GhostButton } from 'configUI/components/Buttons';
import { PhoneInput, isValidPhoneNumber } from 'pages/onboarding/Phone/PhoneInput';
import { useTranslation } from 'react-i18next';

export function ContactPreference({ onPhoneAttributeSaved, phone, setPhone, editPersonAttributesMutation, onSkip }) {
    const { t } = useTranslation();
    const { id: personId } = useGetPerson();
    const [editPersonAttributes] = editPersonAttributesMutation;

    async function handleOnSubmit(event) {
        event.preventDefault();

        const editPhoneAttribute = await editPersonAttributes({
            personId,
            payload: [
                {
                    data: {
                        type: 'person-attributes',
                        attributes: {
                            attribute: 'phone',
                            value: '+1' + phone
                        }
                    }
                }
            ]
        });

        if (!editPhoneAttribute.error) {
            onPhoneAttributeSaved();
        }
    }

    const isValidPhone = isValidPhoneNumber(phone);

    return (
        <Container as='form' onSubmit={handleOnSubmit}>
            <Logo />

            <Typography variant='h2' color='primary.gray100' marginTop='25px'>
                {t('onboarding:phone:contact-preference:title')}
            </Typography>

            <Typography
                variant='pl3'
                color='primary.gray75'
                marginTop='5px'
                marginBottom='30px'
                textAlign='center'
                width='90%'
            >
                {t('onboarding:phone:contact-preference:description')}
            </Typography>

            <PhoneInput phone={phone} setPhone={setPhone} />

            <Typography variant='pl5' color='primary.gray75' marginTop='8px' textAlign='left' width='100%'>
                {t('onboarding:phone:contact-preference:description-2')}
            </Typography>

            <FilledButton
                type='submit'
                sx={{ marginTop: '25px', marginBottom: '5px', width: '100%' }}
                disabled={!isValidPhone}
            >
                {t('onboarding:phone:contact-preference:save')}
            </FilledButton>

            <GhostButton onClick={onSkip} sx={{ width: '100%' }}>
                {t('onboarding:phone:contact-preference:skip')}
            </GhostButton>
        </Container>
    );
}

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '375px',
    padding: '50px 40px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px'
}));
