import { Box, Typography, styled } from '@mui/material';
import { useGetPatient, useGetPerson } from 'authentication';
import { FilledButton } from 'components/Buttons';
import { PhoneSVG } from 'pages/onboarding/Phone/assets';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function PhoneNumberUpdateModal({ onClick }) {
    const { t } = useTranslation();
    const { isCaregiver, ...person } = useGetPerson();
    const patient = useGetPatient();

    useEffect(() => {
        return () => {
            if (isCaregiver) {
                patient.refetch();
            }

            person.refetch();
        };
    }, []);

    return (
        <Container>
            <Wrapper>
                <PhoneSVG />

                <Typography variant='h2' color='primary.gray100' marginTop='25px' width='75%' textAlign='center'>
                    {t('onboarding:phone:number-update-modal:title')}
                </Typography>

                <Typography
                    variant='pl3'
                    color='primary.gray75'
                    marginTop='5px'
                    marginBottom='20px'
                    textAlign='center'
                    width='70%'
                >
                    {t('onboarding:phone:number-update-modal:description')}
                </Typography>

                <FilledButton width='100%' sx={{ marginTop: '25px', marginBottom: '5px' }} onClick={onClick}>
                    {t('onboarding:phone:number-update-modal:button')}
                </FilledButton>
            </Wrapper>
        </Container>
    );
}

export const Container = styled(Box)(() => ({
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: 'rgba(244, 244, 244, 0.90)'
}));

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '375px',
    padding: '50px 40px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px'
}));
