import { Box, Accordion, AccordionSummary } from '@mui/material';
import { styled } from '@mui/system';

export const FAQWrapper = styled(Box)(({ theme }) => ({
    paddingBottom: '50px',
    backgroundColor: theme.palette.primary.gray20,
    margin: '5em auto 5em auto',
    maxWidth: '1080px',
    '@media (max-width: 768px)': {
        textAlign: 'center',
        padding: '0px 20px'
    }
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    background: theme.palette.primary.white,
    borderRadius: '8px !important',
    marginBottom: '20px',
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.primary.gray15,
    boxShadow: 'none',
    textAlign: 'left',
    '&.MuiAccordion-root:before': {
        content: 'none'
    }
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme, expanded }) => ({
    transition: 'none',
    borderBottom: expanded === 'true' ? 'solid 1px' : 'none',
    borderColor: theme.palette.primary.gray15,
    '& .Mui-expanded': {
        margin: '0px !important'
    },
    '& .MuiAccordionSummary-content': {
        transition: 'none'
    }
}));
