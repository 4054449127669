import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined.js';
import { Typography } from '@mui/material';
import {
    FormContentWrapper,
    RequiredPill,
    SignBtnWrapper,
    Wrapper
} from 'pages/onboarding/Consents/components/ConsentItem/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ConsentItem = ({ consent, onSignClick }) => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <FormContentWrapper>
                <div>
                    <Typography variant='h6' sx={{ fontSize: '14px', fontWeight: 700 }}>
                        {t('components:consent-item:title')}
                    </Typography>
                    <Typography variant='body' sx={{ color: 'primary.gray75', fontSize: '12px', fontWeight: 400 }}>
                        {t('components:consent-item:description')}
                    </Typography>
                </div>
                {consent.mandatory && (
                    <RequiredPill color='primary.main'>
                        <ErrorOutlineOutlinedIcon sx={{ width: '20%' }} />
                        <span>{t('activities:index:chips:required')}</span>
                    </RequiredPill>
                )}
            </FormContentWrapper>
            <SignBtnWrapper onClick={onSignClick}>
                <Typography variant='body' sx={{ color: 'primary.main', fontSize: '12px', fontWeight: 500 }}>
                    {t('onboarding:consents:buttons:review-sign')}
                </Typography>
            </SignBtnWrapper>
        </Wrapper>
    );
};

ConsentItem.propTypes = {
    consent: PropTypes.object.isRequired,
    onSignClick: PropTypes.func.isRequired
};
