import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.primary.gray20,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    left: 0,
    position: 'absolute',
    gap: '10px',
    sx: {
        opacity: 0.85
    },
    top: 0,
    width: '100%',
    zIndex: 10
}));
